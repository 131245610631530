<template>
  <btn-icon
      :disabled="model.loading || disabled"
      :icon="isVoted?'heart':'heart-outline'" :title="`Нравится ${count}`"
      color="primary"
      @click.prevent.stop="isVoted ? unvote() : vote()"
  />
</template>

<script>
import {defineComponent} from 'vue'
import BtnIcon           from "@/tags/BtnIcon.vue";
import {Vote}            from "@/models/Vote";

export default defineComponent({
  name:       "VoteBtn",
  components: {BtnIcon},
  props:      ['type', 'id', 'type_id', 'user_id', 'is_voted', 'count'],
  data() {
    return {
      isVoted:  !!this.id,
      model:    Vote.get(this.id, {
        on: {
          save: () => {
            this.$emit('vote');
            this.disabled = true;
          }
        }
      }),
      disabled: false
    }
  },

  methods: {
    vote() {
      this.model.fill({
        type:    this.type,
        type_id: this.type_id,
        user_id: this.user_id,
      })
      this.model.save();
    },
    unvote() {
      this.model.remove();
      this.$emit('vote');
      this.disabled = true;
    }
  }
})
</script>

<style scoped>

</style>
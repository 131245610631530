import {Model} from "@/models/Model";

export class SupportContact extends Model {
    modelUri = 'support-contact';
    labels = {
        name:        'ФИО',
        direction:   'Направление',
        phone:       'Телефон',
        email:       'Электронная почта',
        avatar_file: 'Фото',
    }

    fields = [
        'name',
        'avatar_file',
        'direction',
        'phone',
        'email',
    ];
}
<template>
  <v-card>
    <v-title :title="title"/>
    <v-card-title>
      <span class="mr-5">{{ title }}</span>
      <span>
        <category-form @save="models.load()">
          <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
        </category-form>
      </span>
      <toggle-deleted v-model="showDeleted" class="ml-2"/>
      <v-spacer/>
      <v-text-field v-model="search" :label="$t('btn.search')" append-icon="mdi-magnify" clearable hide-details/>
    </v-card-title>
    <data-table :headers="headers" :models="models">
      <template v-slot:item.color="{ item }">
        <v-chip :color="item.color" dark>{{ item.color }}</v-chip>
      </template>
      <template v-slot:item.is_default="{ item }">
        <v-checkbox v-model="item.is_default" :false-value="0" :true-value="1" class="d-inline-block ma-0" hide-details readonly/>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <span v-if="!item.deleted_at" class="ml-2">
            <category-form :id="item.id" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </category-form>
          </span>
          <span v-if="!item.deleted_at && !item.permanent" class="ml-2">
            <delete :id="item.id" :name="item.name" @remove="models.load()"/>
          </span>
          <span v-if="item.deleted_at" class="ml-2">
            <restore :id="item.id" :name="item.name" @restore="models.load()"/>
          </span>
        </div>
      </template>
    </data-table>
  </v-card>
</template>

<script>
import {Search}   from '@/models/Search.ts';
import {debounce} from 'lodash/function';

import Delete        from '@/views/taskCategory/Delete.vue';
import {mapGetters}  from 'vuex';
import ToggleDeleted from '@/views/taskCategory/ToggleDeleted.vue';
import Restore       from '@/views/taskCategory/Restore.vue';
import DataTable     from '@/tags/DataTable.vue';
import VTitle        from '@/tags/VTitle.vue';
import BtnIcon       from '@/tags/BtnIcon.vue';
import CategoryForm  from '@/views/taskCategory/CategoryForm.vue';

export default {
  name:       'Index',
  components: {CategoryForm, BtnIcon, VTitle, DataTable, Restore, ToggleDeleted, Delete},
  data:     () => ({
    title:  'Категории задач',
    search: '',
    models: Search.create({
      uri:    'task-category',
      fields: ['id', 'name', 'color', 'priority', 'is_default', 'deleted_at'],
      params: {deleted: 0},
      filter: {or: [{name: {like: ''}}]}
    }).load(),
    showDeleted: 0
  }),
  computed: {
    ...mapGetters(['user', 'role']),
    headers() {
      return [
        {text: 'Название', value: 'name', sortable: true},
        {text: 'Цвет', value: 'color', sortable: true},
        {text: 'Приоритет', value: 'priority', sortable: true, align: 'center'},
        {text: 'По умолчанию', value: 'is_default', sortable: true, align: 'center'},
        {text: 'Действия', value: 'actions', align: 'end'},
      ];
    }
  },
  watch:    {
    search(value) {
      this.models.config.filter.or[0].name.like = value || '';
      this.update(this);
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    }
  },
  methods:  {
    update: debounce((vm) => vm.models.load(), 200),
    utcToDatetime(value) {
      return this.$d(value * 1000, 'datetime');
    }
  }
};
</script>

<style scoped></style>
<template>
  <div>
    <v-form ref="form" :dosabled="model.loading" class="px-4" @submit.prevent.stop="submitForm()">
      <v-text-field
          v-model="model.attr.username"
          :rules="model.rule('username')"
          label="Ф.И.О."
      />
      <v-text-field
          v-model="model.attr.email"
          :rules="model.rule('email')"
          label="E-mail"
      />
      <phone-input
          v-model="model.attr.phone"
          :rules="model.rule('phone')"
          label="Телефон"
      />
      <password-input
          v-model="model.attr.password"
          :rules="model.rule('password')"
          label="Пароль"
      />
      <div class="d-flex my-2">
        <v-btn :to="{name:'Login'}" color="primary" outlined>Войти</v-btn>
        <v-spacer/>
        <v-btn :disabled="model.loading" :loading="model.loading" class="primary" type="submit" @click="submitForm()">Далее</v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>

import {Form}        from "@/models/Form";
import PhoneInput    from "@/tags/PhoneInput.vue";
import PasswordInput from "@/tags/PasswordInput.vue";

export default {
  name:       "RegistrationUserForm",
  components: {PasswordInput, PhoneInput},

  data() {
    return {
      model: Form.create({
        uri:   'auth/registration-user',
        attrs: ['username', 'email', 'phone', 'password'],
        on:    {
          send:  (data) => {
            if (data) {
              this.$emit('complete', this.model.attr)
            }
          },
          error: (data, status) => {
            this.$refs.form.validate();
          }
        }
      })
    }
  },
  methods: {
    submitForm() {
      this.model.send();
    }
  }
}
</script>


<style scoped>
</style>
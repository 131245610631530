<template>
  <v-card>
    <v-title title="Типы услуг"/>
    <v-card-title>
      <span class="mr-5">Типы услуг</span>
      <span>
      <support-service-form v-if="can('support-service.manage')" @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </support-service-form>
      </span>
      <toggle-deleted v-if="can('support-service.manage')" v-model="showDeleted" class="ml-2"/>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
          hide-details
      />
    </v-card-title>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.price="{ item }">
        {{ formatMoney(item.price) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div v-if="can('support-service.manage')" class="text-right">
          <span v-if="!item.deleted_at" class="ml-2">
            <support-service-form :id="item.id" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </support-service-form>
          </span>
          <span v-if="!item.deleted_at" class="ml-2">
            <delete :id="item.id" :name="item.name" @remove="models.load()"/>
          </span>
          <span v-if="item.deleted_at" class="ml-2">
            <restore :id="item.id" :name="item.name" @restore="models.load()"/>
          </span>
        </div>
      </template>
    </data-table>
  </v-card>
</template>

<script>
import {Search}   from "@/models/Search";
import {debounce} from "lodash/function";

import Delete             from "./Delete";
import {mapGetters}       from "vuex";
import ToggleDeleted      from "./ToggleDeleted";
import Restore            from "./Restore";
import DataTable          from "@/tags/DataTable";
import VTitle             from "@/tags/VTitle";
import BtnIcon            from "@/tags/BtnIcon";
import SupportServiceForm from "./SupportServiceForm";
import {formatMoney}      from "@/helpers/stringHelpers";


export default {
  name:       "Index",
  components: {SupportServiceForm, BtnIcon, VTitle, DataTable, Restore, ToggleDeleted, Delete},
  data: () => ({
    search: '',
    models: Search.create({
      uri:    'support-service',
      fields: ['id', 'name', 'price', 'description', 'created_at', 'updated_at', 'deleted_at'],
      params: {deleted: 0},
      filter: {or: [{name: {like: ''}}]}
    }).load(),
    showDeleted: 0
  }),
  computed: {
    ...mapGetters(['user', 'role', 'can']),
    headers() {
      return [
        {text: 'Название', value: 'name', sortable: true},
        {text: 'Цена', value: 'price', sortable: true},
        {text: 'Описание', value: 'description'},
        {text: 'Действия', value: 'actions', align: 'end'}
      ]
    }
  },
  watch: {
    search(value) {
      this.models.config.filter.or[0].name.like = value || '';
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    }
  },
  methods: {
    update: debounce((vm) => vm.models.load(), 200),
    utcToDatetime(value) {
      return this.$d(value * 1000, 'datetime');
    },
    formatMoney
  }
}
</script>

<style scoped>

</style>
<template>
  <modal v-model="modal" :closable="true" min-width="400">
    <v-card-text class="text-center">
      <v-progress-circular
          :indeterminate="loading"
          :rotate="-90"
          :size="100"
          :value="progress"
          :width="16"
          class="mt-n4"
          color="primary"
      >
        <h2>{{ logoutTimer }}</h2>
      </v-progress-circular>
      <h2 class="mt-4 mb-2">Сеанс походит к концу</h2>
      <div>Стоит нажать остаться в системе, если не хочешь выйти</div>
    </v-card-text>
    <template #actions>
      <v-btn :disabled="loading" color="primary" @click="cancel">Остаться в системе</v-btn>
      <v-spacer/>
      <v-btn :disabled="loading" color="primary" outlined @click="logout">Выйти</v-btn>
    </template>
  </modal>
</template>

<script>

import Modal                      from "@/tags/Modal.vue";
import {mapGetters, mapMutations} from "vuex";

export default {
  name:       "SessionExpire",
  components: {Modal},
  created() {
    setInterval(() => this.checkAuth(), 1000);
  },
  data() {
    return {
      now: Date.now(),
      modal:          false,
      loading:        false
    }
  },
  computed: {
    ...mapGetters(['session', 'isGuest']),
    progress() {
      return 100 - 100 * Math.max(60 - this.logoutTimer, 0) / 60
    },
    calcExpiredAfter() {
      if (this.isGuest || !this.session?.period) {
        return 0;
      }
      let period = this.now - (this.session.active_at * 1000);

      return (this.session.period * 1000) - period;
    },
    logoutTimer() {
      return Math.max(0, Math.ceil(this.calcExpiredAfter / 1000) - 4);
    }
  },
  watch:    {
  },
  methods:  {
    ...mapMutations(['setLockActive']),
    checkAuth() {
      this.now = Date.now();
      if (this.isGuest) {
        this.cancel();
        return;
      }
      const expiredAfter = this.calcExpiredAfter;
      if (expiredAfter > 65000 || expiredAfter === 0) {
        this.cancel();
        return;
      }
      if (!this.modal) {
        this.showExpiredModal();
      }
      if (this.logoutTimer === 0) {
        this.logout();
        if (expiredAfter < 0) {
          this.$socket.request('auth/session')
        }
      }
    },
    showExpiredModal() {
      this.modal = true;
      this.setLockActive(true);
    },
    logout() {
      this.loading = true;
      this.$socket.once('logout', () => {
        this.cancel();
      });
      this.axios.post('auth/logout');
    },
    cancel() {
      this.modal = false;
      this.loading = false;
      this.setLockActive(false);
    }
  }
}
</script>


<style scoped>

</style>
<template>
  <data-table
      :headers="headers"
      :models="models"
  >
    <template v-slot:item.type="{ item }">
      {{ types[item.type] }}
    </template>
    <template v-slot:item.required="{ item }">
      <v-checkbox v-model="item.required" class="d-inline-block mt-0" hide-details readonly/>
    </template>
    <template v-slot:item.actions="{ item }">
      <div class="text-right">
          <span v-if="!item.deleted_at" class="ml-2">
            <field-form :id="item.id" :type-id="typeId" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </field-form>
          </span>
        <span v-if="!item.deleted_at && !item.permanent" class="ml-2">
            <delete :id="item.id" :name="item.name" @remove="models.load()"/>
          </span>
        <span v-if="item.deleted_at" class="ml-2">
            <restore :id="item.id" :name="item.name" @restore="models.load()"/>
          </span>
      </div>
    </template>
  </data-table>
</template>
<script>
import FieldForm    from "./FieldForm.vue";
import Restore      from "./Restore.vue";
import BtnIcon      from "@/tags/BtnIcon.vue";
import Delete       from "./Delete.vue";
import DataTable    from "@/tags/DataTable.vue";
import {Search}     from "@/models/Search";
import {mapGetters} from "vuex";
import {debounce}   from "lodash";

export default {
  name:       "FieldList",
  components: {DataTable, Delete, BtnIcon, Restore, FieldForm},
  props:      {
    search: {
      type:    String,
      default: ""
    },
    showDeleted: {
      type:    Number,
      default: 0
    },
    typeId: {
      type: Number
    }
  },
  mounted() {
    if (this.typeId) {
      this.models.setFilter({type_id: this.typeId});
    }
    this.models.load();
  },
  data: () => ({
    models: Search.create({
      uri:    'client-field',
      fields: ['id', 'name', 'type', 'required', 'lead_type.name', 'deleted_at'],
      expand: ['lead_type'],
      params: {deleted: 0},
      filter: {or: [{name: {like: ''}}]}
    }),
    types: [
      'Текст',
      'Число',
      'Переключатель',
      'Список'
    ]
  }),
  computed: {
    ...mapGetters(['user', 'role']),
    headers() {
      const headers = [
        {text: 'Название', value: 'name', sortable: true},
        {text: 'Тип данных', value: 'type', sortable: true},
        {text: 'Обязательное', value: 'required', align: 'center'},
        {text: 'Действия', value: 'actions', align: 'end'}
      ];

      if (!this.typeId) {
        headers.splice(1, 0, {text: 'Тип лида', value: 'lead_type.name'});
      }

      return headers;
    }
  },
  watch: {
    search(value) {
      this.models.config.filter.or[0].name.like = value || '';
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    },
    typeId(value) {
      if (value) {
        this.models.setFilter({type_id: value}).load();
      }
    }
  },
  methods: {
    update: debounce((vm) => vm.models.load(), 200),
    utcToDatetime(value) {
      return this.$d(value * 1000, 'datetime');
    }
  }
}
</script>

<style scoped>

</style>
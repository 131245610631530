import Vue     from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import en      from 'vuetify/src/locale/en';
import ru      from 'vuetify/src/locale/ru';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark:    false,
        options: {
            customProperties: true
        },
        themes: {
            light: {
                // primary:   '#3F51B5',
                primary:          '#e12435',
                'primary-lite':   '#FFEBEE',
                secondary:        '#320000',
                accent:           '#673AB7',
                // error:     '#F44336',
                // info:      '#0A586A',
                // success:   '#558B2F',
                // warning:   '#8E760F',
                // anchor:    '#aeb0d8'
                'secondary-lite': '#ad9999'
            }
        }
    },
    lang: {
        locales: {en, ru},
        current: 'ru'
    }
});
<template>
  <modal v-model="modal" :closable="false" :loading="model.loading" :title="testTitle" max-width="600px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <template v-if="!started">
        <v-list class="transparent" rounded>
          <v-list-item v-if="!model.loading" class="white" color="secondary">
            <v-list-item-content>
              <v-list-item-title class="text-center">Тест <b>{{ model.attr.test?.name }}</b></v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                <ul>
                  <li>Вопросов: <b>{{ model.attr.test?.questionCount }}</b></li>
                  <li>Время на прохождение: <b>{{ timerFormat }}</b></li>
                  <li>{{ model.attr.test?.description }}</li>
                </ul>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="white" color="secondary">
            <v-list-item-content>
              <v-list-item-title class="text-center"><b>Инструкция</b> по прохождению</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                <ul>
                  <li>Соблюдайте время прохождения теста</li>
                  <li>Только один вариант ответа на вопросы</li>
                  <li>Проходите тест в тишине и в спокойствии</li>
                </ul>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="white" color="secondary">
            <v-list-item-content>
              <v-list-item-title class="text-center">Во время прохождения теста <b>нельзя</b></v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                <ul>
                  <li>Повторно ответить на вопрос</li>
                  <li>Перейти на другую страницу</li>
                  <li>Обновлять страницу</li>
                </ul>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
      <template v-else>

        <v-progress-linear :value="timerProgress" background-opacity="0" class="mb-4" color="primary lighten-4" height="16" rounded>
          <v-progress-linear :value="progress" color="primary" height="16" rounded>
            <b class="white--text">{{ timerFormat }}</b>
          </v-progress-linear>
        </v-progress-linear>

        <v-expand-transition>
          <template v-if="model.loading">
            <v-list class="transparent" rounded>
              <v-list-item class="white" color="secondary">
                <v-list-item-content>
                  <v-list-item-subtitle class="text-center">Загрузка...</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
          <template v-else>

            <div v-if="model.questions.message" class="text-center">
              <v-list class="transparent" rounded>
                <v-list-item class="white" color="secondary">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-center text-wrap">{{ model.questions.message }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-btn color="primary" @click="close()">Закрыть</v-btn>
            </div>
            <div v-else class="text-center">
              <h3>{{ model.questions.question.name }}</h3>
              <div class="mb-2 small text--disabled">Выберите один из ответов</div>
              <v-list :disabled="model.loading" class="mb-2 transparent text-left" rounded>
                <v-list-item-group v-model="result">
                  <v-list-item v-for="answer in model.questions.answers" :key="answer.id" :value="answer.id" class="white" color="secondary">
                    <template #default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap" v-text="answer.name"/>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon :color="active?'primary':'grey'">mdi-check-circle</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-btn :disabled="!result" color="primary" @click="answer()">Выбрать</v-btn>
            </div>
          </template>
        </v-expand-transition>
      </template>
    </v-card-text>
    <v-dialog v-model="successDialog" content-class="elevation-0" max-width="600px">
      <v-img :src="successImg" class="blink" max-height="600px" max-width="600px"/>
      <audio ref="successAlarm" src="@/assets/deals/success.mp3"/>
    </v-dialog>
    <template #actions>
      <template v-if="!started">
        <v-btn color="primary" @click="close()">Закрыть</v-btn>
        <v-spacer/>
        <v-btn :disabled="model.loading" color="primary" @click="start()">Ознакомлен</v-btn>
      </template>
    </template>
  </modal>
</template>

<script>
import Modal      from "../../tags/Modal";
import {HrResult} from "@/models/HrResult";
import {random}   from "lodash";

export default {
  name:       "HrResultPlay",
  components: {Modal},
  props:      ['id'],
  data() {
    return {
      modal:         false,
      model:         HrResult.get(this.id, {
        with: ['test'],
        on:   {
          answer: () => {
            this.result = undefined;
            this.model.loadQuestions();
          },
          question: () => {
            if (!this.model.attr.started_at) {
              this.model.attr.started_at = this.model.questions.test.started_at;
            }
            if (this.model.questions.message) {
              this.runSuccessImg();
            }
          }
        }
      }),
      started:       false,
      datetime:      Math.floor((new Date().getTime() / 1000)),
      timeout:       null,
      successDialog: false,
      result:        undefined,
    }
  },
  computed: {
    testTitle() {
      return this.model.attr.test?.name ? 'Прохождение теста: ' + this.model.attr.test?.name : 'Загружается...';
    },
    timer() {
      if (this.model.attr.started_at) {
        return Math.max(this.model.attr.test?.timeout - (this.datetime - this.model.attr.started_at), 0);
      }
      return this.model.attr.test?.timeout || 0;
    },
    timerFormat() {
      let sec = this.timer % 60;
      if (sec < 10) {
        sec = '0' + sec;
      }
      return Math.floor(this.timer / 60) + ':' + sec;
    },
    progress() {
      if (this.model.questions.message) {
        return 100;
      }
      let questionCount = this.model.attr.test?.questionCount;
      if (!questionCount) {
        return 0;
      }
      let answeredCount = this.model.questions.test?.answeredCount;
      return 100 * answeredCount / questionCount;
    },
    timerProgress() {
      let timeout = this.model.attr.test?.timeout || 0;
      let timer   = this.timer;
      if (!timeout) {
        return 0;
      }
      return 100 - (100 * timer / timeout);
    },
    successImg() {
      return require('@/assets/deals/success.' + random(1, 4) + '.png');
    }
  },
  watch:    {
    modal(value) {
      if (value) {
        this.model.load();
        window.onbeforeunload = function () {
          return false;
        }
        this.timeout = setInterval(() => {
          this.datetime = Math.floor((new Date().getTime() / 1000));
        }, 10)
      } else {
        window.onbeforeunload = undefined;
        clearInterval(this.timeout);
      }
    }
  },
  methods:  {
    start() {
      this.started = true;
      this.model.loadQuestions();
    },
    close() {
      this.started = false;
      this.modal = false;
      this.$emit('close');
    },
    answer() {
      this.model.sendAnswer(this.result);
    },
    runSuccessImg() {
      this.successDialog = true;
      clearInterval(this.timeout);
      setTimeout(() => (this.successDialog = false), 3000);
      setTimeout(() => this.$refs.successAlarm.play(), 0);
    }
  }
}
</script>

<style scoped>
.answer:hover {
  background-color: #eee;
}

.blink {
  animation: blink-animation 1s steps(10, jump-both) infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
<template>
  <v-card>
    <v-title title="Список сделок"/>
    <v-card-title>
      <span class="mr-5">Список сделок</span>
      <toggle-deleted v-if="can('deal.delete')" v-model="showDeleted" class="ml-2"/>
      <btn-icon :icon="!showFilter?'filter-outline':'filter-off-outline'" :title="showFilter?'Скрыть фильтр':'Показать фильтр'" class="ml-2" color="info" @click="toggleFilter"/>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
      />
    </v-card-title>
    <v-expand-transition>
      <deal-filter-form v-model="searchParams" :class="showFilter?'':'d-none'" @input="updateFilter"/>
    </v-expand-transition>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.sum="{ item }">
        {{ formatMoney(item.sum) }}
      </template>
      <template v-slot:item.price="{ item }">
        {{ formatMoney(item.price) }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip v-if="item.status" :color="item.status.color" dark>{{ item.status.name }}</v-chip>
      </template>
      <template v-slot:item.rewarded="{ item }">
        <v-checkbox v-model="item.rewarded" :color="getRewardedColor(item)" class="d-inline-block" readonly/>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <span class="ml-2">
            <deal-view :id="item.id" :only-view="true" @update="models.load()">
              <btn-icon color="info" icon="information" title="Подробности"/>
            </deal-view>
          </span>
          <span v-if="canDelete(item)" class="ml-2">
            <delete :id="item.id" :name="item.name" @remove="models.load()"/>
          </span>
          <span v-if="item.deleted_at" class="ml-2">
            <restore :id="item.id" :name="item.name" @restore="models.load()"/>
          </span>
        </div>
      </template>
    </data-table>
  </v-card>
</template>

<script>
import {Search}   from "@/models/Search";
import {debounce} from "lodash/function";

import Delete         from "@/views/deals/Delete";
import {mapGetters}   from "vuex";
import ToggleDeleted  from "@/views/deals/ToggleDeleted";
import Restore        from "@/views/deals/Restore";
import DataTable      from "@/tags/DataTable";
import VTitle         from "@/tags/VTitle";
import BtnIcon        from "@/tags/BtnIcon";
import DealView       from "@/views/deals/DealView";
import DealFilterForm from "@/views/deals/DealFilterForm";
import {formatMoney}  from "@/helpers/stringHelpers";


export default {
  name:       "Index",
  components: {DealFilterForm, DealView, BtnIcon, VTitle, DataTable, Restore, ToggleDeleted, Delete},
  data: () => ({
    search: '',
    models: Search.create({
      uri:    'deal',
      fields: ['id', 'fullName', 'num', 'sum', 'price', 'income', 'rewarded', 'price_post', 'income_post', 'rewarded_post', 'status_id', 'status.name', 'status.color', 'bank.name', 'deleted_at'],
      expand: ['status', 'bank'],
      params: {deleted: 0, search: '', rewarded: 0},
      filter: {}
    }),
    showDeleted:  0,
    showFilter:   true,
    searchParams:     {filter: {}, params: {}},
    createdID:        undefined,
    dateCreatedStart: undefined,
    dateCreatedEnd:   undefined
  }),
  computed: {
    ...mapGetters(['can']),
    headers() {
      return [
        {text: 'Клиент', value: 'fullName'},
        {text: 'Кредитор', value: 'bank.name'},
        {text: 'Номер Договора', value: 'num'},
        {text: 'Сумма кредита', value: 'sum'},
        {text: 'Доход компании', value: 'price'},
        {text: 'Статус', value: 'status'},
        {text: 'Оплачено', value: 'rewarded', align: 'center'},
        {text: 'Действия', value: 'actions', align: 'end'}
      ]
    }
  },
  watch: {
    search(value) {
      this.models.params.search = value || '';
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    }
  },
  methods: {
    update: debounce((vm) => vm.models.load(), 200),
    formatMoney,
    utcToDatetime(value) {
      if (!value) {
        return 'Не указано';
      }
      return this.$d(value * 1000, 'datetime');
    },
    canDelete(item) {
      return !item.deleted_at && this.can('deal.delete')
    },
    updateFilter() {
      this.models.config.filter = Object.assign({}, this.searchParams.filter);
      this.models.params = Object.assign({}, this.models.params, this.searchParams.params);
      this.update(this);
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    getRewardedColor(item) {
      return ['secondary', 'warning', 'primary'][item.rewarded];
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <modal v-model="modal" :loading="model.loading" :title="id?$t('user.edit'):$t('user.create')" max-width="1000px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.username" :rules="model.rule('username')" label="ФИО"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.email" :rules="model.rule('email')" label="Электронная почта"/>
          </v-col>
          <v-col cols="12" md="6">
            <phone-input v-model="model.attr.phone" :rules="model.rule('phone')" label="Телефон"/>
          </v-col>
          <v-col cols="12" md="6">
            <password-input v-model="model.attr.password" :label="$t('user.password')" :rules="model.rule('password')"/>
          </v-col>
          <v-col cols="12" md="6">
            <file-input v-model="model.attr.avatar_file" :rules="model.rule('avatar_file')" accept=".png,.jpg,.svg" icon="mdi-file-image" label="Аватар"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-select v-model="model.attr.background" :items="backgroundList" :rules="model.rule('background')" label="Фон"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.income_goal" :rules="model.rule('income_goal')" label="Цель по квартальному доходу"/>
          </v-col>
        </v-row>
        <h3 class="mt-4 mb-2">Настройки безопасности {{ canSafety ? '' : '(только просмотр)' }}</h3>
        <v-row>
          <v-col cols="12" md="6">
            <v-switch
                v-model="userSafety.twoAuth"
                :disabled="!canSafety"
                :false-value="0"
                :true-value="1"
                label="Двухфакторная авторизация"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-switch
                v-model="userSafety.ipLink"
                :disabled="!canSafety"
                :false-value="0"
                :true-value="1"
                label="Привязка к IP"
            />
          </v-col>
          <v-col cols="12">
            <v-slider
                v-model="userSafety.maxSessions"
                :disabled="!canSafety"
                :hint="hintSessionValue"
                :max="sliderMaxSessions.max"
                :min="sliderMaxSessions.min"
                :step="sliderMaxSessions.step"
                inverse-label
                label="Максимальное количество сессий"
                persistent-hint
                @input="hintSessions(userSafety.maxSessions)"
            />
          </v-col>
        </v-row>
        <template v-if="can('office.manage') || canEditRole">
          <h3 class="mt-4 mb-2">Настройки привязки</h3>
          <v-row>
            <v-col v-if="can('office.manage')" cols="12" md="6">
              <v-autocomplete v-model="model.attr.office_id" :items="officeList" :rules="model.rule('office_id')" item-text="name" item-value="id" label="Офис"/>
            </v-col>
            <v-col v-if="canEditRole" cols="12" md="6">
              <v-select v-model="roleName" :items="roleList" :label="$t('user.role')" item-text="description" item-value="name"/>
            </v-col>
            <v-col cols="12" md="6">
            </v-col>
          </v-row>
        </template>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal         from "@/tags/Modal";
import {User}        from "@/models/User";
import {mapGetters}  from "vuex";
import PhoneInput    from "@/tags/PhoneInput";
import FileInput     from "@/tags/FileInput";
import PasswordInput from "@/tags/PasswordInput";


export default {
  name:       "UserForm",
  components: {PhoneInput, Modal, FileInput, PasswordInput},
  props:      ['id'],
  created() {
    this.initSettingValue()
  },
  data() {
    return {
      modal:          false,
      roleName:       'manager',
      roleList:       [],
      officeList:     [],
      backgroundList: [
        'Весна.jpg',
        'Зима.jpg',
        'Космос.jpg',
        'Лес.jpg',
        'Лето.jpg',
        'МоскваСити.jpg',
        'Небо.jpg',
        'Осень.jpg',
        'Пляж.jpg'
      ],
      model: User.get(this.id, {
        with: ['role', 'setting'],
        on:   {
          load:  () => {
            this.roleName = this.model.attr.role?.name;
            this.initDefaultSettingValue(false)
          },
          save:  () => {
            if (this.canEditRole) {
              this.model.attr.role = {name: this.roleName}
              this.model.saveRole();
            } else {
              this.close()
              this.$emit('save');
            }
          },
          saveRole: () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      updated: 0,
      hintSessionValue: 'Неограниченно'
    }
  },
  computed: {
    ...mapGetters(['user', 'can']),
    canEditRole() {
      return this.user?.id !== this.id && (this.can('role.manage') || this.can('user.manage'));
    },
    canSafety() {
      return this.can('user.safety');
    },
    sliderMaxSessions() {
      return {
        min:  0,
        max:  10,
        step: 1
      }
    },
    userSafety: {
      get() {
        this.updated;
        return this.model.attr.setting?.safety || this.initSettingValue().safety;
      },
      set(value) {
        this.model.attr.setting.safety = value;
        this.hintSessions(this.userSafety.maxSessions)
      },
    },
  },
  watch:   {
    modal(value) {
      if (value) {
        this.initSettingValue();
        if (this.id) {
          this.model.load();
        } else {
          this.model.clear();
          this.initDefaultSettingValue();
        }
        if (this.canEditRole) this.axios.get('role/list').then((response) => this.roleList = response.data)
        if (this.can('office.manage')) this.axios.get('office/list').then((response) => this.officeList = response.data)
      }
    }
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.modal = false
    },
    initSettingValue() {
      return this.model.attr.setting = {safety: {}};
    },
    initDefaultSettingValue(rewrite = true) {
      this.axios.get('setting/get', {params: {name: 'user.setting.safety'}})
          .then((response) => {
            const {twoAuth, ipLink, maxSessions} = response.data;
            const safety = {twoAuth, ipLink, maxSessions}
            if (rewrite) {
              this.userSafety = Object.assign({}, safety);
            } else {
              this.userSafety = Object.assign({}, safety, this.userSafety);
            }
            this.updated++;
            this.hintSessions(this.userSafety.maxSessions)
          })
    },
    hintSessions(value) {
      this.hintSessionValue = value ? value.toString() : 'Неограниченно'
    },
  }
}
</script>

<style scoped>

</style>
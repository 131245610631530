<template>
  <div class="mb-3">
    <div class="d-flex ">
      <v-spacer/>
      <service-form v-if="canCreate()" ref="create" :client-id="id" class="mt-n10" @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </service-form>
    </div>
    <data-table
        :headers="headers"
        :models="models"
        hide-footer
    >
      <template v-slot:item.price="{item}">
        {{ formatMoney(item.price) }}
      </template>
      <template v-slot:item.income="{item}">
        {{ formatMoney(item.income) }}
      </template>
      <template v-slot:item.rewarded="{ item }">
        <v-checkbox v-model="item.rewarded" class="d-inline-block" readonly/>
      </template>
      <template v-slot:item.actions="{ item }">
        <div v-if="canUpdate(item)" class="text-right">
          <service-form :id="item.id" ref="update" :client-id="id" @save="models.load()">
            <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
          </service-form>
        </div>
      </template>
    </data-table>
  </div>
</template>

<script>
import {Search}      from "@/models/Search";
import {mapGetters}  from "vuex";
import DataTable     from "@/tags/DataTable";
import BtnIcon       from "@/tags/BtnIcon";
import ServiceForm   from "./ServiceForm";
import {formatMoney} from "@/helpers/stringHelpers";


export default {
  name:       "ServiceList",
  components: {ServiceForm, BtnIcon, DataTable},
  props:      {
    id: {
      type: Number
    },
    onlyView: {
      type:    Boolean,
      default: false
    }
  },
  data: () => ({
    models: Search.create({
      uri:    'service',
      fields: ['id', 'type.name', 'price', 'income', 'rewarded'],
      sort: {field: 'created_at', sortAsc: false},
      expand: ['type'],
      params: {deleted: 0},
      filter: {client_id: null}
    }),
    showDeleted: 0
  }),
  computed: {
    ...mapGetters(['can']),
    showActions() {
      if (this.onlyView) {
        return false;
      }
      for (const item of this.models.result) {
        if (this.canUpdate(item)) {
          return true;
        }
      }
      return false;
    },
    headers() {
      let headers = [
        {text: 'Тип услуги', value: 'type.name'},
        {text: 'Стоимость услуги', value: 'price'},
        {text: 'Доход менеджера', value: 'income'},
        {text: 'Оплачено', value: 'rewarded', align: this.showActions ? 'center' : 'end'}
      ];
      if (this.showActions) headers.push({text: 'Действия', value: 'actions', align: 'end'});
      return headers;
    },

  },
  watch:   {},
  methods: {
    fillParams() {
      this.models.config.filter.client_id = this.id;
      this.models.load();
    },
    canCreate() {
      return !this.onlyView && (this.can('service.manage') || this.can('service.reward'));
    },
    canUpdate(item) {
      return !this.onlyView && (this.can('service.manage') || (!item.rewarded && this.can('service.reward')));
    },
    formatMoney
  }
}
</script>

<style scoped>

</style>
<template>
  <session-expire/>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {throttle}                             from "lodash";
import SessionExpire                          from "@/layouts/components/SessionExpire.vue";

export default {
  name: "AuthService",
  components: {SessionExpire},
  mounted() {
    this.attachListeners();
    setTimeout(() => this.startActive(), 1000);
  },
  beforeDestroy() {
    this.detachListeners();
    this.stopActive();
  },
  data() {
    return {
      actionEvents:      [
        'click',
        'touchstart',
        'mousewheel',
        'mousemove',
        'keydown',
      ],
      keepActiveTimeout: undefined,
      sessionModal:      false,
      activity: false,
    };
  },
  computed: {
    ...mapGetters(['authKey', 'session', 'keepActive', 'lockActive', 'isGuest', 'user']),
    listeners() {
      return {
        'socket':  (data) => this.setSocket(data),
        'setting': (data) => this.setSetting(data),
        'user':    (data) => this.loginUser(data),
        'session': (data) => this.updateSession(data),
        'logout':  (data) => this.logoutUser(data),
        'stat':   (data) => this.setStats(data),
        'notify': (data) => this.setNotify(data),
        'connect': () => this.initAuth(),
      }
    }
  },
  watch:    {
    keepActive(value) {
      if (value && !this.isGuest) {
        this.keepActiveTimeout = setInterval(this.sendActive(), 30000);
      } else if (this.keepActiveTimeout) {
        clearInterval(this.keepActiveTimeout);
      }
    },
    lockActive(value) {
      if (!value) {
        this.sendActive(this, true)();
      }
    },
    isGuest(value) {
      if (!value && !this.activity) {
        setTimeout(() => this.startActive());
      } else if (value) {
        this.stopActive();
      }
    }
  },
  methods:  {
    ...mapMutations(['setSocket', 'setSetting', 'setSession', 'setUser', 'updateAuthKey', 'setStats', 'setNotify']),
    ...mapActions(['logout', 'login']),
    attachListeners() {
      const listeners = this.listeners;
      for (const event in listeners) {
        this.$socket.on(event, listeners[event]);
      }
    },
    detachListeners() {
      const listeners = this.listeners;
      for (const event in listeners) {
        this.$socket.off(event, listeners[event]);
      }
    },
    async initAuth() {
      try {
        await this.$socket.request('/auth/login', {token: this.authKey, tz: new Date().getTimezoneOffset()})
      }
      catch (e) {
        this.logoutUser()
      }
    },
    loginUser(data) {
      this.updateAuthKey();
      this.login({user: data, callback: () => this.$emit('login')});
    },
    updateSession(data) {
      this.setSession(data);
    },
    logoutUser(data) {
      if (data === 'current' || this.session?.id === data) {
        this.logout(() => this.$emit('logout'));
      }
    },
    sendActive(vm, force = false) {
      return throttle(() => {
        if (!vm?.isGuest && !vm?.lockActive) {
          vm?.$socket.request('auth/session')
        }
      }, force ? 10 : 30000)
    },
    startActive() {
      for (let event of this.actionEvents) {
        window.addEventListener(event, this.sendActive(this));
      }
      this.activity = true;
    },
    stopActive() {
      for (let event in this.actionEvents) {
        window.removeEventListener(event, this.sendActive(this));
      }
      this.activity = false;
    }
  },
}
</script>
<template>
  <v-card>
    <v-title title="Статусы сделок"/>
    <v-card-title>
      <span class="mr-5">Статусы сделок</span>
      <span>
      <status-form @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </status-form>
      </span>
      <toggle-deleted v-model="showDeleted" class="ml-2"/>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
          hide-details
      />
    </v-card-title>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.color="{ item }">
        <v-chip :color="item.color" dark>{{ item.color }}</v-chip>
      </template>
      <template v-slot:item.is_default="{ item }">
        <v-checkbox v-model="item.is_default" :false-value="0" :true-value="1" class="d-inline-block ma-0" hide-details readonly/>
      </template>
      <template v-slot:item.editable="{ item }">
        <v-checkbox v-model="item.editable" :false-value="0" :true-value="1" class="d-inline-block ma-0" hide-details readonly/>
      </template>
      <template v-slot:item.fix_sum="{ item }">
        <v-checkbox v-model="item.fix_sum" :false-value="0" :true-value="1" class="d-inline-block ma-0" hide-details readonly/>
      </template>
      <template v-slot:item.available_status_ids="{item}">
        {{ availableStatuses(item) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <span v-if="!item.deleted_at" class="ml-2">
            <status-form :id="item.id" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </status-form>
          </span>
          <span v-if="!item.deleted_at && !item.permanent" class="ml-2">
            <delete :id="item.id" :name="item.name" @remove="models.load()"/>
          </span>
          <span v-if="item.deleted_at" class="ml-2">
            <restore :id="item.id" :name="item.name" @restore="models.load()"/>
          </span>
        </div>
      </template>
    </data-table>

  </v-card>
</template>

<script>
import {Search}   from "@/models/Search";
import {debounce} from "lodash/function";

import Delete        from "@/views/dealStatuses/Delete";
import ToggleDeleted from "@/views/dealStatuses/ToggleDeleted";
import Restore       from "@/views/dealStatuses/Restore";
import DataTable     from "@/tags/DataTable";
import VTitle        from "@/tags/VTitle";
import BtnIcon       from "@/tags/BtnIcon";
import StatusForm    from "@/views/dealStatuses/StatusForm";
import {isNull}      from "lodash";


export default {
  name:       "Index",
  components: {StatusForm, BtnIcon, VTitle, DataTable, Restore, ToggleDeleted, Delete},
  data:     () => ({
    search:      '',
    models:      Search.create({
      uri:    'deal-status',
      fields: ['id', 'name', 'color', 'permanent', 'is_default', 'editable', 'fix_sum', 'available_status_ids', 'deleted_at'],
      params: {deleted: 0},
      filter: {or: [{name: {like: ''}}]},
    }).load(),
    showDeleted: 0,
  }),
  computed: {
    headers() {
      return [
        {text: 'Название', value: 'name', sortable: true},
        {text: 'Цвет', value: 'color', sortable: true},
        {text: 'По умолчанию', value: 'is_default', sortable: true, align: 'center'},
        {text: 'Редактируемый', value: 'editable', sortable: true, align: 'center'},
        {text: 'Фикс. суммы', value: 'fix_sum', sortable: true, align: 'center'},
        {text: 'Доступно статусов', value: 'available_status_ids', sortable: true, align: 'center'},
        {text: 'Действия', value: 'actions', align: 'end'},
      ]
    }
  },
  watch:    {
    search(value) {
      this.models.config.filter.or[0].name.like = value || '';
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    },
  },
  methods:  {
    update: debounce((vm) => vm.models.load(), 200),
    utcToDatetime(value) {
      return this.$d(value * 1000, 'datetime');
    },
    availableStatuses(item) {
      return isNull(item.available_status_ids) ? 'Не выбрано' : item.available_status_ids.length;
    }
  }
}
</script>

<style scoped>

</style>
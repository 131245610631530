<template>
  <div>
    <v-progress-linear v-if="model.loading" class="mb-n1" indeterminate/>
    <v-form ref="form" :disabled="model.loading" class="pt-4">
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="model.attr.value.video_start.url" :loading="model.loading" :rules="model.rule('value')" clearable label="Ссылка на видео"/>
        </v-col>
        <v-col cols="12">
          <v-btn :disabled="model.loading" color="primary" @click="model.send()">{{ $t('btn.save') }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import {Form} from "@/models/Form";

export default {
  name:       "VideoStartForm",
  components: {},
  props:      {
    show: {
      type:    Boolean,
      default: false
    }
  },
  created() {
    this.initValue();
  },
  mounted() {
    this.loadSetting();
  },
  data: () => ({
    model: Form.create({
      uri: {
        addr:   'setting/set',
        params: {name: 'content'}
      },
      attrs: ['value'],
      on:    {
        error: () => {
          this.$refs.form.validate();
        }
      }
    })
  }),
  watch: {
    show(value) {
      if (value) {
        this.loadSetting();
      }
    }
  },
  methods: {
    loadSetting() {
      this.initValue();
      this.model.loading = true;
      this.axios.get('setting/get', {params: {name: 'content.video_start.url'}})
          .then(response => {
            this.model.attr.value.video_start.url = response.data || '';
            this.model.loading = false;
          }).catch(() => {
        this.model.loading = false;
      })
    },
    initValue() {
      this.model.attr = {value: {video_start: {url: ''}}}
    }
  }
}
</script>

<style scoped>

</style>
import {Model} from '@/models/Model';
import Vue     from 'vue';

export class Task extends Model {
    modelUri = 'task';
    labels = {
        name:        'Название',
        description: 'Описание',
        notice_date: 'Дата выполнения',
        status_id:   'Статус',
        user_id:     'Исполнитель',
        office_id:   'Офис',
        category_id: 'Категория'
    };
    fields = [
'name',
'description',
'notice_date',
'status_id',
'user_id',
'category_id'
];

    setFavorite(value: boolean) {
        this.loading = true;
        Vue.axios
            .post('task/set-favorite?id=' + this.attr.id, {value})
            .then(() => {
                this.loading = false;
                this.emit('setFavorite');
            })
            .catch(() => (this.loading = false));
    }
}
<template>
    <v-card-text>
        <v-form ref="form" :disabled="models.loading">
            <data-list :models="models">
                <template v-slot:item="{ item }">
                    <v-col cols="12">
                        <hr-answer-list-item :ref="`hrAnswerListItem${item.id}`" :id="item.id" :question-id="questionId" @remove="models.load()"/>
                    </v-col>
                </template>
                <template #append v-if="questionId">
                    <v-col cols="12">
                        <hr-answer-list-item :question-id="questionId" @save="models.load()"/>
                    </v-col>
                </template>
            </data-list>

            <div class="text-right"></div>
        </v-form>
        <v-btn :disabled="models.loading" color="primary" @click="save">{{ questionId ? $t('btn.save') : 'Создать' }}</v-btn>
    </v-card-text>
</template>

<script>
import {Search} from '@/models/Search.ts';

import DataList         from '@/tags/DataList.vue';
import HrAnswerListItem from '@/views/hrTestManage/HrAnswerListItem.vue';

export default {
  name:       'HrAnswerList',
  components: {DataList, HrAnswerListItem},
  props:      {
    questionId: {
      type:    Number,
      default: null
    }
  },
  data() {
    return {
      models: Search.create({
                uri:    'hr-answer',
                fields: ['id', 'name', 'correct'],
                params: {deleted: 0},
                filter: {question_id: null}
            })
        };
    },
    watch: {
        questionId(value) {
            this.models.config.filter.question_id = value;
            this.models.load();
        }
    },
    created() {
        this.models.config.filter.question_id = this.questionId;
        this.models.load();
    },
    methods: {
        save() {
          this.$emit('save');
          Object.keys(this.$refs)
              .filter((ref) => {
                return !!ref.includes('hrAnswerListItem');

              })
              .map((key) => this.$refs[key]?.model.save());
        }
    }
};
</script>

<style scoped></style>
<template>
  <v-card>
    <v-title title="Калькулятор"/>
    <v-card-title>
      Калькулятор
    </v-card-title>
    <div class="d-flex justify-center">
      <v-card class="mb-5" elevation="0" max-width="1000px" width="100%">
        <v-tabs v-model="tabs" fixed-tabs>
          <v-tab>Ипотечный</v-tab>
          <v-tab>Потребительский</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <calculator type="ipoteka"/>
          </v-tab-item>
          <v-tab-item>
            <calculator type="potreb"/>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import VTitle     from "../../tags/VTitle";
import Calculator from "@/views/calculator/Calculator";

export default {
  name:       "Index",
  components: {Calculator, VTitle},
  data() {
    return {
      tabs: null
    }
  }
}
</script>

<style scoped>

</style>
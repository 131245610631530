<template>
  <v-list-item :disabled="disabled" inactive>
    <v-list-item-icon class="mr-4">
      <v-icon color="secondary">{{ `mdi-${getDeviceIcon}` }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-text="getDeviceInfo"/>
      <v-list-item-subtitle v-text="getClientInfo"/>
      <v-list-item-subtitle :class="getClassActivity" v-text="getActivity"/>
    </v-list-item-content>
    <v-list-item-action>
      <v-icon v-if="current" class="mr-1" color="success">mdi-check</v-icon>
      <btn-icon v-else :disabled="loading" :loading="loading" color="secondary" icon="close" title="Завершить сеанс" @click="disable"/>
    </v-list-item-action>
  </v-list-item>
</template>
<script>

import BtnIcon  from "@/tags/BtnIcon.vue";
import UAParser from "ua-parser-js";

export default {
  name:       "SessionItem",
  components: {BtnIcon},
  props:      {
    item: {
      type: Object
    },
    current: {
      type:    Boolean,
      default: false
    },
    disabled: {
      type:    Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false
    }
  },
  watch:    {},
  computed: {
    parsedUA() {
      return new UAParser(this.item.user_agent);
    },
    getBrowser() {
      const browser = this.parsedUA.getBrowser();
      if (!browser.name) {
        return 'Неизвестный браузер';
      }
      return [browser.name, (browser.version.split('.').shift() || '')].join(' ');
    },
    getDevice() {
      const device = this.parsedUA.getDevice();
      if (!device.type) {
        return undefined;
      }
      return [device.vendor, device.model].filter((v) => !!v).join(' · ');
    },
    getOS() {
      const os = this.parsedUA.getOS();
      return [os.name, os.version].filter((v) => !!v).join(' ');
    },
    getIP() {
      return 'IP ' + this.item.ip_addr;
    },
    getActivity() {
      return this.current ? 'Активный' : this.item.last_activity + ' назад';
    },
    getClassActivity() {
      return this.current ? 'success--text' : 'text--disabled';
    },
    getDeviceInfo() {
      return [this.getDevice, this.getOS].filter((v) => !!v).join(' · ');
    },
    getClientInfo() {
      return [this.getBrowser, this.getIP].join(' · ');
    },
    getDeviceType() {
      return this.parsedUA.getDevice().type;
    },
    getDeviceIcon() {
      return {
        console:  'console',
        mobile:   'cellphone',
        tablet:   'laptop',
        smarttv:  'television-classic',
        wearable: 'watch-variant',
        embedded: 'devices'
      }[this.getDeviceType] || 'monitor';
    }
  },
  methods: {
    disable() {
      this.loading = true;
      this.axios.post('session/disable?id=' + this.item.id).finally(() => {
        this.loading = false;
        this.$emit('update');
      });
    }
  }
}
</script>


<style scoped>

</style>
<template>
  <div>
    <template v-for="question in questions">
      <div v-if="question.selected_answers" :key="question.id">
        <div class="d-flex align-start py-1">
          <v-icon class="mr-1 ml-n2" color="primary">{{ icon }}</v-icon>
          <div>
            <h3>{{ question.name }}</h3>
            <div :class="{'text--disabled':!showAnswer(question.selected_answers)}" class="font-weight-medium"> {{ showAnswer(question.selected_answers) || 'Пропущено' }}</div>
          </div>
        </div>
        <div v-if="question.questions.length" class="pl-4">
          <ms-result-question-item :questions="question.questions" is-child/>
        </div>
      </div>
    </template>
  </div>
</template>
<script>

export default {
  name:       "MsResultQuestionItem",
  components: {},
  props:      {
    questions: {
      type:     Array,
      required: true
    },
    isChild: {
      type:    Boolean,
      default: false
    }
  },
  computed: {
    icon() {
      return 'mdi-menu-right';
    }
  },
  methods: {
    showAnswer(answers) {
      return answers.map(answer => answer.name).join(', ')
    }
  }
}
</script>
<style scoped>

</style>
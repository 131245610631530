<template>
  <div class="login-form-layer d-flex justify-center fill-height align-center">
    <v-title title="Запрос восстановления пароля"/>
    <v-card class="login-form" max-width="500px">
      <v-card-title class="justify-center pt-6">Запрос восстановления пароля</v-card-title>
      <v-progress-linear v-if="model.loading" class="mb-n1" indeterminate/>
      <v-card-text class="px-8 pt-4">
        <v-scroll-y-transition mode="out-in">
          <v-alert v-if="success" class="white--text" color="success">
            На вашу почту отправлена ссылка для восстановления пароля.
          </v-alert>
          <v-form v-if="!success" ref="form" :disabled="model.loading" @submit.prevent="model.send()">
            <v-text-field ref="email" v-model="model.attr.email" :rules="model.rule('email')" label="Электронная почта" @keyup.enter="$refs.password.focus()"/>
          </v-form>
        </v-scroll-y-transition>
      </v-card-text>
      <v-card-actions class="justify-center pb-6">
        <v-btn v-if="!success" :disabled="model.loading" color="primary" @click="model.send()">Восстановить пароль</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {Form} from "@/models/Form";
import VTitle from "@/tags/VTitle";


export default {
  name:       "PasswordResetRequest",
  components: {VTitle},
  data() {
    return {
      success: false,
      model:   Form.create({
        uri:   'auth/password-reset-request',
        attrs: ['email'],
        on:    {
          send: (data) => {
            if (data === true) {
              this.success = true
            }
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      })
    };
  },
  methods: {
  }
};
</script>

<style scoped>

.login-form {
  width: 100%;
  max-width: 500px;
}
</style>
<template>
  <div class="login-form-layer d-flex justify-center fill-height align-center">
    <v-title title="Регистрация"/>
    <v-card class="registration-form">
      <v-card-title class="justify-center pt-6">Регистрация</v-card-title>
      <v-card-text class="pa-0" style="width: 100%">
        <v-stepper v-model="step" class="transparent elevation-0 registration">
          <v-stepper-header>
            <v-stepper-step
                :complete="step > 1"
                step="1"
            >
              Шаг 1
            </v-stepper-step>
            <v-divider/>
            <v-stepper-step
                :complete="step > 2"
                step="2"
            >
              Шаг 2
            </v-stepper-step>
            <v-divider/>
            <v-stepper-step
                :complete="step > 3"
                step="3"
            >
              Шаг 3
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <registration-user-form @complete="completeUser"/>
            </v-stepper-content>

            <v-stepper-content step="2">
              <registration-office-type @back="cancelOfficeType" @complete="completeOfficeType"/>
            </v-stepper-content>

            <v-stepper-content step="3">
              <registration-office-form :type="officeType" :user="user" @back="cancelOffice" @complete="completeOffice"/>
            </v-stepper-content>

            <v-stepper-content step="4">
              <registration-complete/>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import VTitle                 from "@/tags/VTitle.vue";
import RegistrationUserForm   from "@/views/auth/RegistrationUserForm.vue";
import RegistrationOfficeType from "@/views/auth/RegistrationOfficeType.vue";
import RegistrationOfficeForm from "@/views/auth/RegistrationOfficeForm.vue";
import RegistrationComplete   from "@/views/auth/RegistrationComplete.vue";

export default {
  name:       "Registration",
  components: {RegistrationComplete, RegistrationOfficeForm, RegistrationOfficeType, RegistrationUserForm, VTitle},
  data() {
    return {
      user:   null,
      officeType: null,
      office: null,
      step:       1,
    }
  },
  watch:   {},
  methods: {
    completeUser(data) {
      this.step = 2;
      this.user = data;
    },
    completeOfficeType(data) {
      this.step       = 3;
      this.officeType = data;
    },
    cancelOfficeType() {
      this.step       = 1;
      this.officeType = null;
    },
    completeOffice(data) {
      this.step   = 4;
      this.office = data;
    },
    cancelOffice() {
      this.step = 2;
    }
  }
}
</script>


<style scoped>
.registration {
  min-width: 40vw;
}
</style>
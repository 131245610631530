<template>
  <modal v-model="modal" :loading="model.loading" :title="'Сделка: '+(this.model.attr.num || '...')" max-width="1000px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-row class="ma-0">
      <v-col class="pa-0" cols="12">
        <v-card-text>
          <h3 class="mb-2">Информация о сделке</h3>
          <r-card class="py-4">
            <v-row class="mb-2">
              <v-col cols="12" md="4">
                <list-item :title="model.labels.fullName">{{ model.attr.fullName }}</list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.bank_id">{{ bankName }}</list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.sum">{{ formatMoney(model.attr.sum) }}</list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.num">{{ model.attr.num }}</list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.agreement_date">{{ formatDate(model.attr.agreement_date) }}</list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.created_at">{{ utcToDatetime(model.attr.created_at) }}</list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.status_id">
                  <v-chip :color="statusColor" dark>{{ statusName }}</v-chip>
                </list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.office_id">{{ officeName }}</list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.manager_id">{{ managerName }}</list-item>
              </v-col>
            </v-row>
          </r-card>

          <r-card class="mt-2 py-4">
            <v-row>
              <v-col cols="12">
                <list-item :title="model.labels.description">
                  <div class="text-pre">{{ model.attr.description || 'Нет описания' }}</div>
                </list-item>
              </v-col>
            </v-row>
          </r-card>

          <h3 class="mt-4 mb-2">Оплаты</h3>
          <v-data-table
              :headers="paymentsHeaders"
              :items="paymentsList"
              disable-pagination
              hide-default-footer
          >
            <template #item.type="{item }">
              <span :class="{'grey--text':item.id > 0,'text--darken-2':1}" class="font-weight-bold">{{ item.type }}</span>
            </template>
            <template #item.price="{item }">
              {{ formatMoney(item.price) }}
            </template>
            <template #item.income="{item }">
              {{ formatMoney(item.income) }}
            </template>
            <template #item.rewarded="{item }">
              <v-checkbox v-model="item.rewarded" :color="getRewardedColor(item)" class="d-inline-block" readonly/>
            </template>
          </v-data-table>
        </v-card-text>
      </v-col>
    </v-row>
    <template #actions>
      <deal-form v-if="canEdit" :id="id" @save="update()">
        <v-btn color="primary">{{ $t('btn.edit') }}</v-btn>
      </deal-form>
      <deal-print-form v-if="canPrint" :deal="model.attr">
        <adaptive-btn :disabled="model.loading" class="ml-2" icon-prepend="printer" label="Распечатать"/>
      </deal-print-form>
    </template>
  </modal>
</template>

<script>
import Modal           from "@/tags/Modal";
import ListItem        from "@/tags/ListItem";
import {mapGetters}    from "vuex";
import {Deal}          from "@/models/Deal";
import {formatMoney}   from "@/helpers/stringHelpers";
import DealForm        from "./DealForm";
import RCard           from "@/tags/RCard.vue";
import {DealPayment}   from "@/models/DealPayment";
import {DEAL_STATUSES} from "@/models/DealStatus";
import DealPrintForm   from "@/views/deals/DealPrintForm.vue";
import AdaptiveBtn     from "@/tags/AdaptiveBtn.vue";

export default {
  name:       "DealView",
  components: {AdaptiveBtn, DealPrintForm, RCard, DealForm, ListItem, Modal},
  props:      ['id', 'onlyView'],
  data() {
    return {
      modal:        false,
      model:        Deal.get(this.id, {
        with: [
          'client',
          'client.contractFiles',
          'client.type',
          'bank',
          'status',
          'manager',
          'manager.office',
          'payments',
          'payments.type'
        ]
      }),
      paymentModel: new DealPayment(),

    }
  },
  computed: {
    ...mapGetters(['can']),
    canEdit() {
      return !this.onlyView && (this.model.attr.status?.editable || this.can('deal-payment.manage'));
    },
    canPrint() {
      return !this.onlyView && this.can('deal.manage') && this.model.attr.status_id !== DEAL_STATUSES.DENIED;
    },
    bankName() {
      return this.model.attr.bank?.name;
    },
    statusName() {
      return this.model.attr.status?.name;
    },
    statusColor() {
      return this.model.attr.status?.color;
    },
    officeName() {
      return this.model.attr.manager?.office?.name
    },
    managerName() {
      return this.model.attr.manager?.username;
    },
    paymentsList() {
      if (!this.model.attr.payments) {
        return [];
      }


      const sum      = {
        id:       0,
        type:     'Всего',
        price:    this.model.attr.price,
        income:   this.model.attr.income,
        rewarded: this.model.attr.rewarded,
      }
      const rewarded = {
        id:       -1,
        type:     'Оплачено',
        price:    0,
        income:   0,
        rewarded: this.model.attr.rewarded,
      }
      const payments = this.model.attr.payments.map(payment => {
        payment.rewarded *= 2;
        payment.type = payment.type.name;
        if (payment.rewarded) {
          rewarded.price += payment.price;
          rewarded.income += payment.income;
        }
        return payment;
      })
      const items    = [...payments, rewarded, sum];
      return items;
    },
    paymentsHeaders() {
      return [
        {text: this.paymentModel.labels.type_id, value: 'type',},
        {text: this.paymentModel.labels.price, value: 'price', align: 'end'},
        {text: this.paymentModel.labels.income, value: 'income', align: 'end'},
        {text: this.paymentModel.labels.rewarded, value: 'rewarded', align: 'center'},
      ]
    }
  },
  watch:    {
    modal(value) {
      if (value) {
        this.model.attr.id = this.id;
        this.model.load();
      }
    }
  },
  methods:  {
    formatMoney,
    formatDate(date) {
      if (!date) {
        return 'Не указано';
      }
      return this.$d(new Date(date).getTime(), 'date');
    },
    utcToDatetime(value) {
      if (!value) {
        return 'Не указано';
      }
      return this.$d(value * 1000, 'datetime');
    },
    update() {
      this.model.load();
      this.$emit('update');
    },
    getRewardedColor(item) {
      return ['secondary', 'warning', 'primary'][item.rewarded];
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <modal v-model="modal" :loading="model.loading" :title="id?'Изменение типа услуги':'Создание типа услуги'" max-width="500px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <v-autocomplete v-model="model.attr.type_id" :items="typeList" :label="model.labels.type_id" :readonly="readonlyType" :rules="model.rule('type_id')" item-text="name" item-value="id"/>
          </v-col>
          <v-col cols="12">
            <mask-input v-model="model.attr.price" :label="model.labels.price" :mask="mask" :readonly="readonly" :rules="model.rule('price')" suffix="₽" unmask/>
          </v-col>
          <v-col cols="12">
            <mask-input v-model="model.attr.income" :label="model.labels.income" :mask="mask" :readonly="readonly" :rules="model.rule('income')" suffix="₽" unmask/>
          </v-col>
          <v-col cols="12">
            <confirm :title="confirmTitle" @confirm="changeReward">
              <v-switch :disabled="disableRewarded" :label="model.labels.rewarded" :rules="model.rule('rewarded')" :value="rewarded" readonly/>
            </confirm>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal        from "@/tags/Modal";
import {Service}    from "@/models/Service";
import MaskInput    from "@/tags/MaskInput.vue";
import Confirm      from "@/tags/Confirm.vue";
import {mapGetters} from "vuex";


export default {
  name:       "ServiceTypeForm",
  components: {Confirm, MaskInput, Modal},
  props:      ['id', 'clientId'],
  data() {
    return {
      modal:       false,
      model:       Service.get(this.id, {
        on: {
          load: () => {
            this.oldRewarded = this.model.attr.rewarded
            this.rewarded    = !!this.model.attr.rewarded
          },
          save:  () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      typeList:    [],
      rewarded:    false,
      oldRewarded: 0,
      mask:        {
        alias:          'numeric',
        digits:         2,
        min:            0,
        groupSeparator: ' '
      }
    }
  },
  computed: {
    ...mapGetters(['can']),
    readonlyType() {
      return !this.model.isNew;
    },
    readonly() {
      return !!this.oldRewarded;
    },
    disableRewarded() {
      return (this.oldRewarded && !this.can('service.manage')) || !this.can('service.reward')
    },
    confirmTitle() {
      return this.rewarded ?
             'Вы действительно хотите отменить оплату?' :
             'Подтвердите оплату услуги';
    },
  },
  watch:    {
    modal(value) {
      if (value) {
        if (this.id) {
          this.model.load();

        } else {
          this.model.clear();
          this.model.attr.client_id = this.clientId;
        }
        this.axios.get('service-type/list').then((response) => this.typeList = response.data)
      }
    }
  },
  methods:  {
    close() {
      this.$refs.form.reset();
      this.modal = false
    },
    changeReward() {
      this.model.attr.rewarded = +(!this.model.attr.rewarded)
      this.rewarded            = !!this.model.attr.rewarded;
    }
  }
}
</script>

<style scoped>

</style>
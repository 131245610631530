<template>
  <div class="nav-menu">
    <v-list dense expand shaped>
      <div v-for="group in links" :key="group.name">
        <v-list-group v-if="groupCan(group)" :class="{current:groupActive(group),'mb-1':true}" :value="groupActive(group)" color="primary">
          <template v-slot:activator>
            <v-list-item-icon class="mr-4">
              <v-icon>{{ group.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-html="group.name"/>
          </template>
          <div v-for="link in group.links" :key="link.path">
            <v-list-item v-if="showLink(link)" :to="{ name: link.path }" color="secondary" exact-path>
              <v-list-item-icon class="mr-0 ml-8" style="opacity: 0.5">
                <v-icon>mdi-circle-small</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ link.name }}</v-list-item-title>
            </v-list-item>
          </div>
        </v-list-group>
      </div>
    </v-list>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'NavBar',
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['can', 'office']),
    links() {
      return [
        {
          name:  'Панель клиента',
          icon:  'mdi-account-box-outline',
          links: [
            {
              name: 'Клиенты',
              path: 'Clients',
            },
            {
              name: 'Календарь',
              path: 'ClientCalendar',
            },
            {
              name: 'Сделки',
              path: 'Deals',
            },
            {
              name: 'Импорт клиентов',
              path: 'ClientImport',
            },
            {
              name: 'Экспорт клиентов',
              path: 'ClientExport',
            },
            {
              name: 'Калькулятор',
              path: 'Calculator',
            },
            {
              name: 'Верификация',
              path: 'Links',
            },
            {
              name: 'ФинСервисSF',
              path: 'FinService',
            },
            {
              name: 'Документы',
              path: 'DocumentsClient',
            }
          ]
        },
        {
          name:  'Статистика',
          icon:  'mdi-chart-box-outline',
          links: [
            {
              name: 'Статистика',
              path: 'Stat',
            }
          ]
        },
        {
          name:  'Партнеры',
          icon:  'mdi-bank-outline',
          links: [
            {
              name: 'Список партнеров',
              path: 'BankList',
            }
          ]
        },
        {
          name:  'Новости',
          icon:  'mdi-newspaper',
          links: [
            {
              name: 'Новости',
              path: 'News',
            }
          ]
        },
        {
          name:  'Планировщик',
          icon:  'mdi-calendar-check-outline',
          links: [
            {
              name: 'Планировщик',
              path: 'Tasks',
            }
          ]
        },
        {
          name:  'Панель<br/>администратора',
          icon:  'mdi-shield-crown-outline',
          links: [
            {
              name: this.$t('user.title'),
              path: 'Users',
            },
            {
              name: 'Офисы',
              path: 'Offices',
            },
            {
              name: 'Банки',
              path: 'Banks',
            },
            {
              name: 'Источники клиентов',
              path: 'ClientSources',
            },
            {
              name: 'Типы лидов',
              path: 'LeadTypes',
            },
            {
              name: 'Доп. поля',
              path: 'ClientField',
            },
            {
              name: 'Типы услуг',
              path: 'ServiceTypes',
            },
            {
              name: 'Типы оплаты сделок',
              path: 'DealPaymentType',
            },
            {
              name: 'Причины отказа',
              path: 'RejectionReasons',
            },
            {
              name: 'Причины возврата',
              path: 'RefundReasons',
            },
            {
              name: 'Статусы клиентов',
              path: 'ClientStatuses',
            },
            {
              name: 'Статусы сделок',
              path: 'DealStatuses',
            },
            {
              name: 'Категории клиентов',
              path: 'ClientCategory',
            },
            {
              name: 'Шаблоны договоров',
              path: 'ContractFile',
            },

            {
              name: 'Категории верификации',
              path: 'LinkCategory',
            },
            {
              name: 'Категории видео',
              path: 'VideoCategory',
            },
            {
              name: 'Категории задач',
              path: 'TaskCategory',
            },
            {
              name: this.$t('role.title'),
              path: 'Roles',
            },
            {
              name: 'Управление HR SF Test',
              path: 'HrTestManage',
            },
            {
              name: 'Мини-скрипты',
              path: 'MsScript',
            },
            {
              name: 'Настройки',
              path: 'Setting',
            }
          ]
        },
        {
          name:  'Обучение',
          icon:  'mdi-school-outline',
          links: [
            {
              name: 'Видеоматериалы',
              path: 'Videos',
            },
            {
              name: 'Статьи о кредитах',
              path: 'Article',
            },
            {
              name: 'HR SF Test',
              path: 'HrTest',
            },
            {
              name: 'Мероприятия',
              path: 'Event',
            },
            {
              name: 'Документы',
              path: 'DocumentsEducation',
            },
            {
              name: 'Книги',
              path: 'Book',
            }
          ]
        },
        {
          name:  'Помощь',
          icon:  'mdi-help-circle-outline',
          links: [
            {
              name: 'Вопросы и ответы',
              path: 'Faq',
            },
            {
              name: 'Услуги',
              path: 'SupportService',
            },
            {
              name: 'Контакты',
              path: 'SupportContact',
            }
          ]
        }
      ];
    }
  },
  methods:  {
    showLink(link) {
      const route = this.getRouteByPath(link.path);
      if (!route) {
        return false;
      }
      const permissions = route.meta?.permissions;
      if (!permissions) {
        return true;
      }
      switch (typeof permissions) {
        case 'string':
          return this.can(permissions);
        case 'object':
          for (const permission of permissions) {
            if (this.can(permission)) {
              return true;
            }
          }
          return false;
        case 'function':
          return permissions(this);
      }
      return false;
    },
    getRouteByPath(path) {
      return this.$router.options.routes.find(route => route.name === path);
    },
    groupActive(group) {
      let route = this.$route;
      for (let link of group.links) {
        if (link.path === route.name) {
          return true;
        }
      }
      return false;
    },
    groupCan(group) {
      for (let link of group.links) {
        if (this.showLink(link)) return true;
      }
      return false;
    }
  }
};
</script>

<style scoped></style>
<style>
.nav-menu .v-list-item {
  padding: 0 12px 0 20px;
}

.nav-menu .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled), .nav-menu .theme--light.v-icon {
  color: #320000CC;
}

.nav-menu .current .v-list-group__header, .nav-menu .v-list-group__header:hover {
  background-image: linear-gradient(270deg, #FFBDBD, #FFFCFC);
  background-color: transparent;
}

.nav-menu .v-list-group__header.v-list-item:hover:before {
  opacity: 0;
}

.nav-menu .v-list-group__items .v-list-item:before {
  background-color: transparent;
  background-image: linear-gradient(270deg, #320000, #FFFCFC)
}
</style>
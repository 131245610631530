<template>
  <v-text-field
      ref="field"
      v-model="currentValue"
      :hint="hint"
      :label="label"
      :maxlength="maxlength"
      :minlength="minlength"
      :persistent-hint="persistentHint"
      :rules="rules"
      :disabled="disabled"
      :prefix="prefix"
      :readonly="readonly"
      :suffix="suffix"
      @blur="removeMask"
  />
</template>

<script>
import Inputmask from 'inputmask'

export default {
  name:  "MaskInput",
  props: {
    value:  {
      default: null
    },
    label:  {
      type:    String,
      default: ''
    },
    rules:  {
      type: Array
    },
    mask:   {
      type: [String, Object]
    },
    unmask: {
      type:    Boolean,
      default: false
    },
    hint:   {
      type: String
    },
    persistentHint: {
      type: Boolean
    },
    minlength: {
      type: Number
    },
    maxlength: {
      type: Number
    },
    prefix:    {
      type: String
    },
    suffix:    {
      type: String
    },
    readonly:  {
      type:    Boolean,
      default: false,
    },
    disabled:  {
      type:    Boolean,
      default: false,
    },
  },
  mounted() {
    this.applyMask();
  },
  data() {
    return {
      currentValue: null,
      inputMask:    null,
      input:        null,
      maskOptions:  {}
    }
  },
  watch:   {
    value(value) {
      if (this.maskOptions.alias === 'numeric') {
        this.currentValue = parseFloat(value);
      } else {
        this.currentValue = value;
      }
    },
  },
  methods: {
    applyMask() {
      this.input       = this.$refs.field.$el.querySelector('input');
      this.maskOptions = typeof this.mask === 'string' ? {mask: this.mask} : this.mask;
      this.inputMask   = Inputmask(this.maskOptions);
      this.inputMask.mask(this.input);
      if (this.maskOptions.alias === 'numeric') {
        this.currentValue = parseFloat(this.value);
      } else {
        this.currentValue = this.value;
      }
    },
    removeMask() {
      let value = this.currentValue;
      try {
        if (this.unmask) {
          if (this.input.inputmask) {
            if (this.input.inputmask.opts.placeholder === '_') {
              value = value ? value.replace(new RegExp(this.input.inputmask.opts.placeholder, 'g'), '') : '';
            } else {
              value = typeof value === 'string' ? this.inputMask.unmaskedvalue(value) : value;
              if (this.maskOptions.alias === 'numeric') {
                value = parseFloat(value);
              }
            }
          }
        }
      }
      catch (e) {
        console.warn('Не удалось снять маску');
      }
      if (this.unmask && typeof value === 'number' && isNaN(value)) {
        value = undefined;
      }
      this.$emit('input', value);
    }
  }
}
</script>

<style scoped>

</style>
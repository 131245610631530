import {Model} from "@/models/Model";

export class ClientStatus extends Model {
    modelUri = 'client-status';
    labels = {
        name: 'Название',
        color:                'Цвет',
        is_default:           'По умолчанию',
        available_status_ids: 'Доступные статусы'
    }

    fields = [
        'name',
        'color',
        'is_default',
        'available_status_ids',
    ];
}

export enum CLIENT_STATUSES {
    NEW = 1,
    CALLBACK = 2,
    MEET = 3,
    DENIED = 4,
    SIGNED_CONTRACT = 5,
    DIFFICULT_QUESTION = 6,
    COMPLETE = 7,
    REFUND = 8,
    TRANSFERRED = 9,
    CALLBACK_PLUS = 10,
}
import {Model} from "@/models/Model";

export class MsQuestion extends Model {
    modelUri = 'ms-question';

    labels = {
        id:                   'ID',
        script_id:            'Скрипт',
        name:                 'Текст вопроса',
        required:             'Обязательный',
        multiple:             'Множественный выбор',
        required_question_id: 'Требуемый вопрос',
        required_answer_id:   'Требуемый ответ',
        created_at:           'Создано',
        updated_at:           'Обновлено',
        deleted_at:           'Удалено'
    }
    fields = [
        'script_id',
        'name',
        'required',
        'multiple',
        'required_question_id',
        'required_answer_id'
    ];
}
<template>
  <modal v-model="modal" :loading="loading" :title="modalTitle" max-width="800px">
    <template #activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <template v-if="!file_id">
        <v-list color="transparent" rounded>

          <template v-if="filesList.length">
            <h3 class="mb-2">Выберите шаблон</h3>
            <v-list-item-group v-model="file_id">
              <v-list-item v-for="file in filesList" :key="file.id" :value="file.id" class="white" color="secondary">
                <v-list-item-content>
                  <v-list-item-title>{{ file.name }}</v-list-item-title>
                  <v-list-item-subtitle class="text-wrap">{{ file.description }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </template>

          <template v-else>
            <v-list-item class="white text-center" color="secondary" disabled>
              <v-list-item-title>Нет шаблонов для распечатки для данного типа лида: {{ deal.client?.type.name }}</v-list-item-title>
            </v-list-item>
          </template>

        </v-list>
      </template>
      <template v-else>


        <template v-if="loading">
          <v-list color="transparent" rounded>
            <v-list-item class="white text-center" color="secondary" disabled>
              <v-list-item-title>Идет загрузка данных...</v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
        <template v-else-if="!printInfo">
          <v-list color="transparent" rounded>
            <v-list-item class="white text-center" color="secondary" disabled>
              <v-list-item-title>Не удалось загрузить данные.</v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
        <template v-else>
          <div v-for="(infoList, category) in printInfoList" :key="category">
            <h3 class="mb-2">{{ category }}</h3>
            <v-list color="transparent" rounded>
              <v-list-item v-for="info in infoList" :key="info.tag" class="white" color="secondary">
                <v-list-item-content>
                  <template v-if="!isEdit(info)">
                  <v-list-item-subtitle>{{ info.name }}</v-list-item-subtitle>
                    <v-list-item-title ref="info_value" class="text-wrap">{{ getValue(info) }}</v-list-item-title>
                  </template>
                  <template v-else>
                    <v-text-field v-model="values[info.tag]" :label="info.name" hide-details/>
                  </template>
                </v-list-item-content>
                <v-list-item-action v-if="!isEdit(info)" class="flex-row">
                  <btn-icon v-if="canSwitch(info)" color="info" icon="swap-horizontal" title="Следующее значение" @click="switchChoice(info)"/>
                  <btn-icon v-if="canEdit(info)" color="primary" icon="pencil" title="Изменить" @click="editItem(info)"/>
                </v-list-item-action>
              </v-list-item>

            </v-list>
          </div>
        </template>


      </template>
    </v-card-text>
    <template #actions>
      <v-btn :disabled="disabledPrintBtn" color="primary" @click="loadPrintFile()">Распечатать договор</v-btn>
    </template>
  </modal>
</template>
<script>

import Modal              from "@/tags/Modal.vue";
import {groupBy, isArray} from "lodash";
import BtnIcon            from "@/tags/BtnIcon.vue";

export default {
  name:       "DealPrintForm",
  components: {BtnIcon, Modal},
  props:      {
    deal: {
      type:     Object,
      required: true,
    }
  },
  data() {
    return {
      modal:         false,
      loading:       false,
      file_id:       undefined,
      printInfo:     undefined,
      disabledPrint: false,
      choices: {},
      values:  {},
    };
  },
  computed: {
    modalTitle() {
      return this.deal.num ? 'Распечатка договора по сделке ' + this.deal.num : 'Загрузка информации...';
    },
    filesList() {
      return this.deal.client?.contractFiles || [];
    },
    printInfoList() {
      const values = this.printInfo.info.values;
      return groupBy(values, 'category')
    },
    disabledPrintBtn() {
      return !this.printInfo || this.loading;
    },
  },
  watch:    {
    modal(value) {
      if (value) {
        this.file_id   = undefined
        this.printInfo = undefined;
      }
    },
    file_id(value) {
      if (value) {
        this.loadPrintInfo();
      }
    },
    'printInfo.info.values'(values) {
      values ||= []
      for (const value of values) {
        if (isArray(value.value)) {
          this.choices[value.tag] = 0;
        }
      }
    }
  },
  methods:  {
    close() {
      this.modal = false;
    },
    loadPrintInfo() {
      this.loading = true;
      this.axios.get('deal/print-info', {params: {id: this.deal.id, file_id: this.file_id}})
          .then(response => {
            this.printInfo = response.data;
            this.loading   = false;
          }).catch(() => {
        this.loading = false;
      })
    },
    loadPrintFile() {
      this.loading = true;
      this.axios.post('deal/print-file', {choices: this.choices, values: this.values}, {
        params:       {id: this.deal.id, file_id: this.file_id},
        responseType: 'blob'
      }).then(response => {
        this.loading  = false;
        const href    = URL.createObjectURL(response.data);
        const link    = document.createElement('a');
        link.href     = href;
        link.download = 'Договор:  ' + this.deal.num.replace('/', ' (') + ').docx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.close();
      }).catch(() => {
        this.loading = false;
      })
    },
    getValue(item) {
      if (Object.hasOwn(this.values, item.tag)) {
        return this.values[item.tag];
      }
      if (Object.hasOwn(this.choices, item.tag)) {
        return item.value[this.choices[item.tag]];
      }
      return item.value;
    },
    canSwitch(item) {
      return isArray(item.value);
    },
    canEdit(item) {
      return item.editable;
    },
    isEdit(item) {
      return Object.hasOwn(this.values, item.tag);
    },
    editItem(item) {
      this.values[item.tag] = this.getValue(item);
      this.$forceUpdate();
    },
    switchChoice(item) {
      this.choices[item.tag]++;
      this.choices[item.tag] %= item.value.length;
      this.$forceUpdate();
    },
  }
}
</script>

<style scoped>

</style>
export default {
    btn: {
        close:       'Close',
        save:        'Save',
        confirm:     'Confirm',
        search:      'Search',
        edit:        'Edit',
        delete:      'Delete',
        restore:     'Restore',
        manage:      'Manage',
        create:      'Create',
        block:       'Block',
        unblock:     'Unblock',
        hideDeleted: 'Hide Deleted',
        showDeleted: 'Show Deleted',
    },
    login: {
        login:    'Login',
        password: 'Password',
        signIn:   'Sign In',
        signOut:  'Sign Out',
        auth:     'Authorization'
    },
    role:  {
        title:         'Roles',
        create:        'Create Role',
        manage:        'Manage Role',
        edit:          'Edit Role',
        confirmDelete: 'Do you want to delete role {name}?',
        name:          'Name',
        code:          'Code',
        permissions:   'Permissions',
        users:         'Users',
        actions:       'Actions'
    },
    user:  {
        title:          'Users',
        create:         'Create User',
        edit:           'Edit User',
        confirmDelete:  'Do you want to delete user {name}?',
        confirmRestore: 'Do you want to restore user {name}?',
        login:          'Login',
        email:          'E-mail',
        password:       'Password',
        role:           'Role',
        actions:        'Actions'
    },
    error: {
        notFound: 'This page was not found'
    }
}
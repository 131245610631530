<template>
  <v-form ref="form" :dosabled="model.loading" class="px-4" @submit.prevent.stop="submitForm()">
    <div v-for="(field, name) in fields" :key="name">
      <template v-if="field.type==='text'">
        <v-text-field
            v-if="!hidden[name]"
            v-model="model.attr[name]"
            :hint="field.hint"
            :label="field.label"
            :rules="model.rule(name)"
            @blur="autofill"
        />
      </template>
      <template v-else-if="field.type==='phone'">
        <phone-input
            v-if="!hidden[name]"
            v-model="model.attr[name]"
            :hint="field.hint"
            :label="field.label"
            :rules="model.rule(name)"
        />
      </template>
      <template v-else-if="field.type==='file'">
        <file-input
            v-if="!hidden[name]"
            v-model="model.attr[name]"
            :hint="field.hint"
            :label="field.label"
            :rules="model.rule(name)"
            accept=".png,.jpg,.svg"
            icon="mdi-file-image"
        />
      </template>
    </div>
    <div class="d-flex my-2">
      <v-btn color="primary" outlined @click="$emit('back')">Назад</v-btn>
      <v-spacer/>
      <v-btn :disabled="isLoading" :loading="isLoading" color="primary" type="submit" @click="submitForm()">Готово</v-btn>
    </div>
  </v-form>
</template>

<script>
import {Form}        from "@/models/Form";
import {OFFICE_TYPE} from "@/models/Office";
import PhoneInput    from "@/tags/PhoneInput.vue";
import FileInput     from "@/tags/FileInput.vue";


const fields = {
  name:     {
    label:    'Название офиса',
    type:     'text',
    hidden:   (type) => false,
    autofill: (fields, type) => fields.user.username,
    hint:     '',
  },
  org_name: {
    label:    'Наименование организации',
    type:     'text',
    hidden:   (type) => false,
    autofill: (fields, type) => {
      switch (type) {
        case OFFICE_TYPE.LLC:
          return 'ООО ';
        case OFFICE_TYPE.IP:
          return `ИП ${fields.user.username}`;
        case OFFICE_TYPE.PERSONAL:
          return fields.user.username;
      }
      return '';
    },
    hint:     '',
  },
  email:    {
    label:    'E-mail',
    type:     'text',
    hidden:   (type) => false,
    autofill: (fields, type) => fields.user.email,
    hint:     '',
  },
  phone:    {
    label:    'Телефон',
    type:     'phone',
    hidden:   (type) => false,
    autofill: (fields, type) => fields.user.phone,
    hint:     '',
  },

  real_address:      {
    label:    'Фактический адрес',
    type:     'text',
    hidden:   (type) => false,
    autofill: (fields, type) => '',
    hint:     '',
  },
  legal_address:     {
    label:    'Юридический адрес',
    type:     'text',
    hidden:   (type) => type === OFFICE_TYPE.PERSONAL,
    autofill: (fields, type) => fields.real_address,
    hint:     '',
  },
  post_address:      {
    label:    'Почтовый адрес',
    type:     'text',
    hidden:   (type) => type === OFFICE_TYPE.PERSONAL,
    autofill: (fields, type) => fields.real_address,
    hint:     '',
  },
  ogrn:              {
    label:    'ОГРН',
    type:     'text',
    hidden:   (type) => type === OFFICE_TYPE.PERSONAL,
    autofill: (fields, type) => type === OFFICE_TYPE.PERSONAL ? fields.inn : '',
    hint:     '',
  },
  inn:               {
    label:    'ИНН',
    type:     'text',
    hidden:   (type) => false,
    autofill: (fields, type) => '',
    hint:     '',
  },
  kpp:               {
    label:    'КПП',
    type:     'text',
    hidden:   (type) => [OFFICE_TYPE.PERSONAL, OFFICE_TYPE.IP].includes(type),
    autofill: (fields, type) => {
      return [OFFICE_TYPE.PERSONAL, OFFICE_TYPE.IP].includes(type) ? fields.inn : ''
    },
    hint:     '',
  },
  payment_account:   {
    label:    'Расчетный счет',
    type:     'text',
    hidden:   (type) => false,
    autofill: (fields, type) => '',
    hint:     '',
  },
  corporate_account: {
    label: 'Корреспондентский счет',
    type:     'text',
    hidden:   (type) => false,
    autofill: (fields, type) => '',
    hint:     '',
  },
  bank_name:         {
    label:    'Наименование банка',
    type:     'text',
    hidden:   (type) => false,
    autofill: (fields, type) => '',
    hint:     '',
  },
  bik:               {
    label:    'БИК',
    type:     'text',
    hidden:   (type) => false,
    autofill: (fields, type) => '',
    hint:     '',
  },
  general_manager:   {
    label:    'Генеральный директор',
    type:     'text',
    hidden:   (type) => type === OFFICE_TYPE.PERSONAL,
    autofill: (fields, type) => fields.user.username,
    hint:     '',
  },
  logo_file:         {
    label:    'Логотип',
    type:     'file',
    hidden:   (type) => false,
    autofill: (fields, type) => null,
    hint: 'Файл svg или png 300x100',
  }
}


export default {
  name:       "RegistrationOfficeForm",
  components: {FileInput, PhoneInput},
  props:      ['type', 'user'],
  data() {
    return {
      model:  Form.create({
        uri:   'auth/registration-office',
        attrs: Object.keys(fields),
        on:    {
          send:  (data) => {
            if (data) {
              this.$emit('complete', this.model.attr)
            }
          },
          error: (data, status) => {
            this.$refs.form.validate();
          }
        }
      }),
      fields: fields,
      hidden: {},
    }
  },
  computed: {
    isLoading() {
      return this.model.loading;
    },
    officeType() {
      return this.type;
    }
  },
  watch:    {
    user(value) {
      this.model.attr.user = value
      this.resetForm();
      this.autofill();
      this.updateHidden();
      this.fixLabel();
    },
    officeType(value) {
      this.model.attr.type = value
      this.resetForm();
      this.autofill();
      this.updateHidden();
      this.fixLabel();
    }
  },
  methods:  {
    submitForm() {
      this.model.send();
    },
    resetForm() {
      Object.keys(this.model.attr).forEach((fieldName) => {
        if (!['user', 'type'].includes(fieldName)) {
          this.model.attr[fieldName] = null;
        }
      })
    },
    updateHidden() {
      this.hidden = {};
      Object.keys(this.fields).forEach((fieldName) => {
        this.hidden[fieldName] = fields[fieldName].hidden(this.officeType);
      });
      console.log(this.hidden);
    },
    autofill() {
      const fieldNames = Object.keys(fields);
      fieldNames.forEach((fieldName) => {
        if (!this.model.attr[fieldName]) {
          const autofillValue = fields[fieldName].autofill(this.model.attr, this.officeType);
          if (autofillValue) {
            this.model.attr[fieldName] = autofillValue;
          }
        }
      })
    },
    fixLabel() {
      this.fields.ogrn.label = this.type === OFFICE_TYPE.IP ? 'ОГРНИП' : 'ОГРН';
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-list-item dense>
    <b>{{ title }}</b>
    <v-spacer/>
    <span>{{ value }}</span>
  </v-list-item>
</template>

<script>
export default {
  name:  "ListString",
  props: ['title', 'value']
}
</script>

<style scoped>

</style>
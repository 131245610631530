<template>
  <modal v-model="modal" :loading="loading" max-width="400px" title="Статистика">
    <template v-slot:activator="{on, attrs}">
      <slot v-bind="attrs" v-on="on"/>
    </template>
    <v-subheader>На сегодня</v-subheader>
    <list-string :value="stat.today?.new" title="Новый"/>
    <v-divider/>
    <list-string :value="stat.today?.callback" title="Перезвонить"/>
    <v-divider/>
    <list-string :value="stat.today?.meet" title="Встретиться"/>
    <v-divider/>
    <list-string :value="stat.today?.expired" title="Просрочено"/>

    <v-subheader>На завтра</v-subheader>
    <list-string :value="stat.tomorrow?.callback" title="Перезвонить"/>
    <v-divider/>
    <list-string :value="stat.tomorrow?.meet" title="Встретиться"/>

    <v-subheader>За последние 90 дней</v-subheader>
    <list-string :value="stat.quarter?.signed" title="Подписан договор"/>
    <v-divider/>
    <list-string :value="stat.quarter?.complete" title="Одобрено"/>
    <v-divider/>
    <list-string :value="stat.quarter?.rewarded" title="Оплачено"/>
    <v-divider/>
    <list-string :value="formatMoney(stat.quarter?.sum)" title="Доход менеджера"/>
  </modal>
</template>

<script>
import Modal         from "@/tags/Modal";
import ListString    from "@/tags/ListString";
import {formatMoney} from "@/helpers/stringHelpers";
import {mapGetters}  from "vuex";
import {isEmpty}     from "lodash";

export default {
  name:       "StatView",
  components: {ListString, Modal},
  data() {
    return {
      modal: false,
    }
  },
  computed: {
    ...mapGetters(['stats']),
    loading() {
      return !this.stats || isEmpty(this.stats);
    },
    stat() {
      return this.stats || {today: {}, tomorrow: {}, quarter: {}}
    }
  },
  methods:  {
    formatMoney
  }

}
</script>

<style scoped>

</style>
<template>
  <v-card class="pa-6 fill-height">
    <v-title title="Главная"/>
    <v-alert border="top" class="mx-auto mb-6 text-center" color="primary" colored-border elevation="2" max-width="900px" type="success">
      {{ user.username }}, желаем Вам продуктивного и эффективного рабочего дня! С большим уважением, Ваш Success Finance!
    </v-alert>
    <video-start-view class="d-block text-center">
      <v-btn color="primary">Посмотреть вступительное видео</v-btn>
    </video-start-view>
  </v-card>
</template>

<script>
import VTitle         from "@/tags/VTitle.vue";
import {mapGetters}   from "vuex";
import VideoStartView from "@/views/dashboard/VideoStartView";

export default {
  name:       'Index',
  components: {VideoStartView, VTitle},
  data() {
    return {}
  },

  computed: {
    ...mapGetters(['user'])
  },
  watch:    {
    model(value) {
      console.log(value);
    }
  },
  methods:  {}
}
</script>
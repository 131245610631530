<template>
  <modal v-model="modal" :loading="model.loading" max-width="500px" title="Создать тест">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col v-if="can('office.manage')" cols="12">
            <v-autocomplete v-model="model.attr.office_id" :items="officeList" :label="model.labels.office_id" :rules="model.rule('office_id')" item-text="name" item-value="id" @change="updateManagerList"/>
          </v-col>
          <v-col v-if="can('user.manage')" cols="12">
            <v-autocomplete v-model="model.attr.user_id" :items="managerList" :label="model.labels.user_id" :rules="model.rule('user_id')" item-text="username" item-value="id"/>
          </v-col>
          <v-col cols="12">
            <v-autocomplete v-model="model.attr.test_id" :items="hrTestList" :label="model.labels.test_id" :rules="model.rule('test_id')" item-text="name" item-value="id"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import {HrResult}   from "@/models/HrResult";
import Modal        from "../../tags/Modal";
import {mapGetters} from "vuex";

export default {
  name:       "HrResultForm",
  components: {Modal},
  data() {
    return {
      modal: false,
      model: HrResult.get(null, {
        on: {
          save: () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      officeList:  [],
      managerList: [],
      hrTestList:  []
    }
  },
  computed: {
    ...mapGetters(['can', 'office'])
  },
  watch: {
    modal(value) {
      if (value) {
        this.model.clear();
        this.axios.get('hr-test/list').then((response) => this.hrTestList = response.data);
        if (this.can('office.manage')) this.axios.get('office/list').then((response) => this.officeList = response.data);
        if (this.can('user.manage')) this.axios.get('user/list').then((response) => this.managerList = response.data);
      }
    }
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.modal = false
    },
    updateManagerList() {
      if (this.can('user.manage')) this.axios.get('user/list?office_id=' + this.model.attr.office_id).then((response) => this.managerList = response.data)
    }
  }

}
</script>

<style scoped>

</style>
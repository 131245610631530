import {Model} from "@/models/Model";


export class Deal extends Model {
    modelUri = 'deal';

    labels = {
        fullName:       'Клиент',
        num:            'Номер договора',
        client_id:      'Клиент',
        manager_id:     'Менеджер',
        office_id:      'Офис',
        status_id:      'Статус сделки',
        bank_id:        'Кредитор',
        number:         'Номер',
        sum:            'Сумма кредита',
        agreement_date: 'Дата договора',
        description:    'Описание',
        created_at:     'Создан',
        updated_at:     'Изменен',
        deleted_at:     'Удален'
    }

    fields = [
        'client_id',
        'manager_id',
        'status_id',
        'bank_id',
        'number',
        'sum',
        'agreement_date',
        'description'
    ];
}
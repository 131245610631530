<template>
  <div>
    <template v-if="!hideHeader">
      <h3 class="mt-4 mb-2">Комментарии</h3>
    </template>
    <comment-form v-if="!onlyView && top" :id="id" ref="form" :top="top" :type="type" class="mb-3" @save="fillParams"/>
    <comment-list :id="id" ref=list :top="top" :type="type"/>
    <comment-form v-if="!onlyView && !top" :id="id" ref="form" :top="top" :type="type" class="mb-3" @save="fillParams"/>
  </div>
</template>

<script>
import CommentForm from "./CommentForm";
import CommentList from "./CommentList";

export default {
  name:       "Comments",
  components: {CommentList, CommentForm},
  props: {
    id:         {
      type:     Number,
      required: true,
    },
    type:       {
      type:     String,
      required: true,
    },
    onlyView:   {
      type:    Boolean,
      default: false,
    },
    autoload:   {
      type:    Boolean,
      default: false,
    },
    top:        {
      type:    Boolean,
      default: false
    },
    hideHeader: {
      type:    Boolean,
      default: false
    }
  },
  mounted() {
    if (this.autoload) {
      this.fillParams();
    }
  },
  methods: {
    fillParams() {
      if (!this.onlyView) this.$refs.form.fillParams();
      this.$refs.list.fillParams();
    }
  }
}
</script>

<style scoped>

</style>
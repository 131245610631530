<template>
  <v-card class="fill-height">
    <v-title title="Статьи о кредитах и недвижимости"/>
    <iframe class="fill-height fill-width b-0" src="https://fin-success.biz/iframe.html"/>
    <!--    <iframe class="fill-height fill-width b-0" src="https://fin-success.biz/poleznyye-stati-o-kreditakh-i-nedvizhimosti"/>-->
  </v-card>
</template>

<script>
import VTitle from "../../tags/VTitle";

export default {
  name:       "Index",
  components: {VTitle},
}
</script>

<style scoped>
.b-0 {
  border: 0;
}
</style>
<template>
  <div>
    <template v-if="type==='string_decline'">
      <v-row>
        <v-col cols="12">
          <v-select v-model="transformation.case" :items="cases" :menu-props="{offsetY: true}" item-text="name" item-value="value" label="Падеж"/>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="transformation.prefix" label="Префикс"/>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="transformation.suffix" label="Суффикс"/>
        </v-col>
        <v-col cols="4">
          <v-switch v-model="transformation.suffix_case" :disabled="!canSuffixCase" label="Склонять"/>
        </v-col>
      </v-row>
    </template>

    <template v-if="type==='string_regex'">
      <v-row>
        <v-col v-if="templates.length" cols="12">
          <v-select v-model="template" :items="templates" item-text="name" item-value="value" label="Примеры выражений"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="transformation.regex" label="Выражение"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="transformation.result" label="Шаблон"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="example_text" label="Пример текста"/>
        </v-col>
        <v-col cols="12">
          <b>Результат: </b> <code>{{ example }}</code>
        </v-col>
        <v-col cols="12">
          <h3>Инструкции:</h3>
          <p>
            Подробная <a href="https://learn.microsoft.com/ru-ru/dotnet/standard/base-types/regular-expression-language-quick-reference" target="_blank">инструкция по ссылке
            <v-icon color="primary" small>mdi-open-in-new</v-icon>
          </a>
          </p>
        </v-col>
      </v-row>
    </template>

    <template v-if="type==='number_text'">
      <v-row>
        <v-col cols="12">
          <v-select v-model="transformation.case" :items="cases" :menu-props="{offsetY: true}" item-text="name" item-value="value" label="Падеж"/>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="transformation.prefix" label="Префикс"/>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="transformation.suffix" :hint="transformation.suffix_pluralize ? 'В ед. числе':''" label="Суффикс" persistent-hint/>
        </v-col>
        <v-col cols="4">
          <v-switch v-model="transformation.suffix_pluralize" :disabled="!canSuffixPluralize" label="Мн. число"/>
        </v-col>
      </v-row>
    </template>

    <template v-if="type==='number_money'">
      <v-row>
        <v-col cols="4">
          <v-text-field v-model="transformation.prefix" label="Префикс"/>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="transformation.suffix" label="Суффикс"/>
        </v-col>
        <v-col cols="4">
          <v-switch v-model="transformation.cents" label="Копейки"/>
        </v-col>
      </v-row>
    </template>

    <template v-if="type==='number_format'">
      <v-row>
        <v-col cols="12">
          <span class="digits">12</span>
          <v-text-field v-model="transformation.thousands_separator" label="Тыс." size="2"/>
          <span class="digits">345</span>
          <v-text-field v-model="transformation.thousands_separator" label="Тыс." size="2"/>
          <span class="digits">678</span>
          <template v-if="transformation.decimals > 0">
            <v-text-field v-model="transformation.decimal_separator" label="Дел." size="2"/>
            <span class="digits">{{ (round(.12345678, transformation.decimals) * (10 ** transformation.decimals)) }}</span>
          </template>
        </v-col>
        <v-col cols="12">
          <v-slider v-model="transformation.decimals" :max="8" :min="0" :step="1" inverse-label label="Цифр после запятой" thumb-label="always"/>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="transformation.prefix" label="Префикс"/>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="transformation.suffix" :hint="transformation.suffix_pluralize ? 'В ед. числе':''" label="Суффикс" persistent-hint/>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="transformation.suffix_pluralize" :disabled="!canSuffixPluralize" label="Мн. число"/>
        </v-col>
      </v-row>
    </template>

    <template v-if="type==='date_format'">
      <v-row>
        <v-col v-if="templates.length" cols="12">
          <v-select v-model="template" :items="templates" item-text="name" item-value="value" label="Примеры шаблонов"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="transformation.template" label="Шаблон"/>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="transformation.prefix" label="Префикс"/>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="transformation.suffix" label="Суффикс"/>
        </v-col>
        <v-col cols="12">
          <b>Результат: </b> <code>{{ example }}</code>
        </v-col>
        <v-col cols="12">
          <h3>Инструкции:</h3>
          <p>
            <code>${d}</code>: число без ведущего нуля (7)<br/>
            <code>${dd}</code>: число с ведущим нулем (07)<br/>
            <code>${D}</code>: число текстом (семь)<br/>
            <code>${DD}</code>: порядковое число текстом (седьмое)<br/>
            <code>${DDD}</code>: род. пад. порядк. числа (седьмого)<br/>
            <code>${m}</code>: номер месяца без ведущего нуля (2)<br/>
            <code>${mm}</code>: номер месяца c ведущим нулем (02)<br/>
            <code>${M}</code>: название месяца сокр. (фев.)<br/>
            <code>${MM}</code>: название месяца (февраль)<br/>
            <code>${MMM}</code>: название месяца род. пад. (февраля)<br/>
            <code>${y}</code>: последние две цифры года (24)<br/>
            <code>${yy}</code>: число года (2024)<br/>
            <code>${Y}</code>: год текстом (...четыре)<br/>
            <code>${YY}</code>: порядковый год текстом (...четвертый)<br/>
            <code>${YYY}</code>: порядковый год текстом род. пад. (...четвертого)<br/>
          </p>
        </v-col>
      </v-row>
    </template>

    <template v-if="type==='array_implode'">
      <v-row>
        <v-col cols="4">
          <span class="digits">Значение1</span>
          <v-text-field v-model="transformation.separator" label="Разд." size="5"/>
          <span class="digits">Значение3</span>
          <v-text-field v-model="transformation.separator" label="Разд." size="5"/>
          <span class="digits">Значение3</span>
        </v-col>
      </v-row>
    </template>


  </div>
</template>
<script>

import {NAME_CASE}      from "@/helpers/stringHelpers";
import {isEmpty, round} from "lodash";

export default {
  name:       "TransformationTagForm",
  components: {},
  props:      ['type', 'value', 'isNew'],
  data() {
    return {
      transformation: this.value,
      template:       {},
      templates:      [],
      example_text:   '',
    };
  },
  computed: {
    cases() {
      return [
        {
          name:  'Именительный (кто, что)',
          value: NAME_CASE.NOMINATIVE,
        },
        {
          name:  'Родительный (кого, чего)',
          value: NAME_CASE.GENITIVE,
        },
        {
          name:  'Дательный (кому, чему)',
          value: NAME_CASE.DATIVE,
        },
        {
          name:  'Винительный (кого, что)',
          value: NAME_CASE.ACCUSATIVE,
        },
        {
          name:  'Творительный (кем, чем)',
          value: NAME_CASE.ABLATIVE,
        },
        {
          name:  'Предложный (о ком, о чем)',
          value: NAME_CASE.PREPOSITIONAL,
        },
      ]
    },
    presets() {
      return {
        string_decline: {
          type:        'string_decline',
          prefix:      '',
          suffix:      '',
          suffix_case: false,
          case:        NAME_CASE.NOMINATIVE,
        },
        string_regex:   {
          type:      'string_regex',
          templates: [
            {
              name:  'Свой вариант',
              value: {
                regex:  '',
                result: '',
              }
            },
            {
              name:  'Телефон',
              value: {
                regex:  '(\\d)(\\d{3})(\\d{3})(\\d{2})(\\d{2})',
                result: '+$1 ($2) $3-$4-$5',
              }
            },
            {
              name:  'Серия и номер паспорта',
              value: {
                regex:  '(\\d{2})(\\d{2})(\\d{6})',
                result: '$1 $2 $3',
              }
            },
            {
              name:  'СНИЛС',
              value: {
                regex:  '(\\d{3})(\\d{3})(\\d{3})(\\d{2})',
                result: '$1-$2-$3-$4',
              }
            },
          ],
        },
        number_text:    {
          type:             'number_text',
          prefix:           '',
          suffix:           '',
          suffix_pluralize: false,
          case:             NAME_CASE.NOMINATIVE,
        },
        number_money:   {
          type:   'number_money',
          prefix: '',
          suffix: '',
          cents:  false,
        },
        number_format:  {
          type:                'number_format',
          prefix:              '',
          suffix:              '',
          suffix_pluralize:    false,
          decimals:            0,
          decimal_separator:   ',',
          thousands_separator: ' ',
        },
        date_format:    {
          type:      'date_format',
          prefix:    '',
          suffix:    '',
          templates: [
            {
              name:  'Свой вариант',
              value: {
                template: '',
              }
            },
            {
              name:  '07.02.2024',
              value: {
                template: '${dd}.${mm}.${yy}',
              }
            },
            {
              name:  '7 февраля 2024 г.',
              value: {
                template: '${d} ${MMM} ${yy} г.',
              }
            },
            {
              name:  'Текст',
              value: {
                template: '${DDD} ${MMM} ${YYY} года',
              }
            },
          ]
        },
        array_implode:  {
          type:      'array_implode',
          separator: ', ',
        }
      }
    },
    example() {
      const values = {
        d:   '7',
        dd:  '07',
        D:   'семь',
        DD:  'седьмое',
        DDD: 'седьмого',
        m:   '2',
        mm:  '02',
        M:   'фев.',
        MM:  'февраль',
        MMM: 'февраля',
        y:   '24',
        yy:  '2024',
        Y:   'две тысячи двадцать четыре',
        YY:  'две тысячи двадцать четвертый',
        YYY: 'две тысячи двадцать четвертого',
      }
      let result   = '';
      switch (this.type) {
        case 'string_regex':
          try {
            const regexp = new RegExp(this.transformation.regex, 'g');
            return this.example_text.replace(regexp, this.transformation.result);
          }
          catch (e) {
            return '[error regex]';
          }
        case 'date_format':
          result = this.transformation.template || '';
          for (let i in values) {
            result = result.replace('${' + i + '}', values[i])
          }
          return result;
      }
      return null
    },
    canSuffixCase() {
      return this.transformation.case !== NAME_CASE.NOMINATIVE && /[А-яёЁ]/.test(this.transformation.suffix);
    },
    canSuffixPluralize() {
      return (!this.transformation.case || this.transformation.case === NAME_CASE.NOMINATIVE) && /[А-яёЁ]/.test(this.transformation.suffix);
    }
  },
  watch:    {
    type(newValue, oldValue) {
      if (newValue) {
        this.fillPreset(newValue);
      }
    },
    value(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.transformation = newValue;
      }
    },
    transformation(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.$emit('input', newValue);
      }
    },
    template(newValue, oldValue) {
      if (!isEmpty(oldValue)) {
        this.transformation = Object.assign({}, this.transformation, newValue);
      }
    }
  },
  methods:  {
    round,
    fillPreset(value) {
      let preset   = this.presets[value] || false;
      let template = {value: {}};
      if (!preset) {
        return;
      }
      this.templates = preset.templates || [];
      if (this.templates.length) {
        template = preset.templates[0] || {value: {}};
        delete preset.templates;
        this.template = template.value;
      }

      if (this.type !== this.transformation.type) {
        this.transformation = Object.assign({}, preset, template.value);
      }
    },
  }
}
</script>


<style scoped>

</style>
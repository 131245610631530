<template>
  <v-card>
    <v-title title="Банки"/>
    <v-card-title>
      <span class="mr-5">Банки</span>
      <span>
      <bank-form @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </bank-form>
      </span>
      <toggle-deleted v-model="showDeleted" class="ml-2"/>
      <bank-file-form v-if="can('bank-file.manage')">
        <btn-icon class="ml-2" color="blue-grey" icon="cog" title="Настройка"/>
      </bank-file-form>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
          hide-details
      />
    </v-card-title>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.logo="{ item }">
        <v-img v-if="item.logo" :src="apiUri(item.logo)" contain max-height="40px" max-width="120px" position="left"/>
      </template>
      <template v-slot:item.phone="{ item }">
        <span class="text-no-wrap">{{ formatPhone(item.phone) }}</span>
      </template>
      <template v-slot:item.view="{ item }">
        {{ item.view ? 'Да' : 'Нет' }}
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <span v-if="!item.deleted_at" class="ml-2">
            <bank-form :id="item.id" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </bank-form>
          </span>
          <span v-if="!item.deleted_at" class="ml-2">
            <delete :id="item.id" :name="item.name" @remove="models.load()"/>
          </span>
          <span v-if="item.deleted_at" class="ml-2">
            <restore :id="item.id" :name="item.name" @restore="models.load()"/>
          </span>
        </div>
      </template>
    </data-table>

  </v-card>
</template>

<script>
import {Search}   from "@/models/Search";
import {debounce} from "lodash/function";

import Delete                from "@/views/bank/Delete";
import {mapGetters}          from "vuex";
import ToggleDeleted         from "@/views/bank/ToggleDeleted";
import Restore               from "@/views/bank/Restore";
import BankForm              from "@/views/bank/BankForm";
import DataTable             from "@/tags/DataTable";
import VTitle                from "@/tags/VTitle";
import BtnIcon               from "@/tags/BtnIcon";
import {apiUri, formatPhone} from "@/helpers/stringHelpers";
import BankFileForm          from "@/views/bank/BankFileForm.vue";


export default {
  name:       "Index",
  components: {BankFileForm, BankForm, BtnIcon, VTitle, DataTable, Restore, ToggleDeleted, Delete},
  data: () => ({
    search: '',
    models: Search.create({
      uri:    'bank',
      fields: ['id', 'logo', 'name', 'agent', 'email', 'phone', 'view', 'deleted_at'],
      params: {deleted: 0, manage: 1},
      filter: {or: [{name: {like: ''}}, {agent: {like: ''}}]}
    }).load(),
    showDeleted: 0
  }),
  computed: {
    ...mapGetters(['user', 'role', 'can']),
    headers() {
      return [
        {text: 'Логотип', value: 'logo'},
        {text: 'Название банка', value: 'name', sortable: true},
        {text: 'Представитель', value: 'agent', sortable: true},
        {text: 'E-mail', value: 'email', sortable: true},
        {text: 'Телефон', value: 'phone', sortable: true},
        {text: 'Виден всем', value: 'view', sortable: true},
        {text: 'Действия', value: 'actions', align: 'end'}
      ]
    }
  },
  watch: {
    search(value) {
      this.models.config.filter.or[0].name.like = value || '';
      this.models.config.filter.or[1].agent.like = value || '';
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    }
  },
  methods: {
    update: debounce((vm) => vm.models.load(), 200),
    formatPhone,
    apiUri
  }
}
</script>

<style scoped>

</style>
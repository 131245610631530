<template>
  <data-list
      :models="models"
      class="width-100"
      hide-footer
  >
    <template v-slot:item="{ item }">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>
            <v-row>
              <v-col v-if="item.user">
                {{ item.user.username }}
              </v-col>
              <v-col class="text-right">
                {{ utcToDatetime(item.created_at) }}
              </v-col>
            </v-row>
          </v-list-item-subtitle>
          <v-list-item-title class="text-pre-wrap">{{ replaceDatetime(item.text) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </data-list>
</template>

<script>
import DataList from "@/tags/DataList";
import {Search} from "@/models/Search";

export default {
  name:       "CommentList",
  components: {DataList},
  props: {
    id:   {
      type:     Number,
      required: true,
    },
    type: {
      type:     String,
      required: true,
    },
    top:  {
      type:    Boolean,
      default: false,
    }
  },
  data() {
    return {
      models: Search.create({
        uri:    'comment',
        fields: ['id', 'text', 'created_at', 'user.username'],
        expand: ['user'],
        filter: {
          type:    this.type,
          type_id: this.id
        },
        sort:   {
          field: 'created_at', sortAsc: !this.top,
        }
      })
    }
  },
  methods: {
    fillParams() {
      this.models.config.filter.type = this.type;
      this.models.config.filter.type_id = this.id;
      this.models.load();
    },
    utcToDatetime(value) {
      if (!value) {
        return 'Не указано';
      }
      return this.$d(value * 1000, 'datetime');
    },
    replaceDatetime(value) {
      const dates = value.match(/{{(\d+)\|datetime}}/g);
      if (dates) {
        dates.forEach((date) => {
          let utc = +date.replace(/{{(\d+)\|datetime}}/g, '$1');
          value   = value.replace(date, this.utcToDatetime(utc));
        })
      }
      return value;
    }
  }
}
</script>

<style scoped>

</style>
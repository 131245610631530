<template>
  <modal v-model="modal" max-width="1000px" title="Дубликаты">
    <template v-slot:activator>
      <slot/>
    </template>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.status="{ item }">
        <v-chip v-if="item.status" :color="item.status.color" dark>{{ item.status.name }}</v-chip>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ utcToDatetime(item.created_at) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <span class="ml-2">
            <btn-icon v-if="showDuplicateBtn" color="primary" icon="account-multiple-plus" title="Заполнить из профиля" @click="copyProfile(item)"/>
            <client-view :id="item.id" :only-view="clientOnlyView(item)" @update="models.load()">
              <btn-icon color="info" icon="information" title="Подробности"/>
            </client-view>
          </span>
        </div>
      </template>
    </data-table>
  </modal>
</template>

<script>
import Modal                from "@/tags/Modal";
import {Search}             from "@/models/Search";
import DataTable            from "@/tags/DataTable";
import BtnIcon              from "@/tags/BtnIcon";
import {mapGetters}         from "vuex";
import {debounce}           from "lodash";
import {CLIENT_FIELDS_COPY} from "@/models/Client";

export default {
  name:       "ClientDuplicate",
  props: {
    id:               {
      required: true,
    },
    search:           {
      default: '',
    },
    showDuplicateBtn: {
      type:    Boolean,
      default: false,
    }
  },
  components: {BtnIcon, ClientView: () => import('@/views/clients/ClientView'), DataTable, Modal},
  data() {
    return {
      modal:    false,
      models:   Search.create({
        uri:    'client',
        fields: [
          'id',
          'fullName',
          'manager.username',
          'manager.office.name',
          'status.name',
          'status.color',
          'created_at',
          'type.name',
          'manager_id',
          ...CLIENT_FIELDS_COPY
        ],
        expand: ['status', 'type', 'category', 'manager', 'manager.office'],
        params: {duplicate: 1},
        filter: {
          or: [
            {phone: this.search},
            {extra_phone: this.search},
            {email: this.search}
          ],
          id: {neq: this.id}
        },
        on:     {
          load: () => {
            this.$emit('load', this.models.result.length)
          }
        },
        sort:   {field: 'notice_date', sortAsc: false}
      }),
      debounce: false,
    }
  },
  watch:    {
    search(value) {
      if (this.validPhone(value) || this.validEmail(value)) {
        this.models.config.filter.or[0].phone = value;
        this.models.config.filter.or[1].extra_phone = value;
        this.models.config.filter.or[2].email = value;
        if (!this.debounce) {
          this.models.load();
          this.debounce = true;
        } else {
          this.update(this);
        }
      } else {
        this.$emit('load', 0)
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'can']),
    headers() {
      let headers = [
        {text: 'ФИО', value: 'fullName'},
        {text: 'Статус', value: 'status'},
        {text: 'Тип лида', value: 'type.name'},
        {text: 'Дата создания', value: 'created_at'},
        {text: 'Менеджер', value: 'manager.username'},
        {text: 'Офис', value: 'manager.office.name'}
      ];
      if (this.can('client.view.double')) {
        headers.push({text: 'Действия', value: 'actions', align: 'end'});
      }
      return headers
    },
  },
  methods:  {
    close() {
      this.modal = false;
    },
    utcToDatetime(value) {
      if (!value) {
        return 'Не указано';
      }
      return this.$d(value * 1000, 'datetime');
    },
    validPhone(value) {
      if (!value) {
        return false;
      }
      value = value.replace(/\D/g, '');
      return value.length > 10;
    },
    validEmail(value) {
      if (!value) {
        return false;
      }
      return !!value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    },
    clientOnlyView(item) {
      return item.manager_id !== this.user.id;
    },
    copyProfile(item) {
      const data = {};
      CLIENT_FIELDS_COPY.forEach(field => {
        let value = item[field];
        if (![null, undefined, NaN, ''].includes(value)) {
          data[field] = value;
        }
      })
      this.$emit('copy-profile', data);
      this.close();
    },
    update: debounce(vm => vm.models.load(), 200),
  }
}
</script>

<style scoped>

</style>
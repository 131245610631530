import {Model} from "@/models/Model";

export class MsResult extends Model {
    modelUri = 'ms-result';

    labels = {
        id:         'ID',
        script_id:  'Скрипт',
        client_id:  'Клиент',
        status:     'Статус',
        answers:    'Ответы',
        files:      'Файлы',
        created_at: 'Создано',
        updated_at: 'Обновлено',
        deleted_at: 'Удалено'
    }

    fields = [
        'script_id',
        'client_id',
        'action',
        'question_id',
        'selected_answers',
        'file_id',
        'selected_files',
        'period',
        'key',
        'type'
    ];

}
import {Model} from "@/models/Model";

export class Office extends Model {
    modelUri = 'office';
    labels = {
        name:              'Название офиса',
        description:       'Описание',
        type: 'Тип организации',
        org_name:          'Наименование организации',
        email:             'Электронная почта',
        legal_address:     'Юридический адрес',
        real_address:      'Фактический адрес',
        post_address:      'Почтовый адрес',
        ogrn:              'ОГРН',
        inn:               'ИНН',
        kpp:               'КПП',
        payment_account:   'Расчетный счет',
        corporate_account: 'Корреспондентский счет',
        bank_name:         'Наименование банка',
        bik:               'БИК',
        phone:             'Телефон',
        general_manager:   'Генеральный директор',
        is_head:           'Видимость банков',
        show_denied:       'Передача лидов',
        hr_test:           'HR SF Test',
        logo_file:         'Логотип'
    }
    fields = [
        'name',
        'description',
        'type',
        'org_name',
        'email',
        'legal_address',
        'real_address',
        'post_address',
        'ogrn',
        'inn',
        'kpp',
        'payment_account',
        'corporate_account',
        'bank_name',
        'bik',
        'phone',
        'general_manager',
        'is_head',
        'show_denied',
        'hr_test',
        'logo_file'
    ];
}

export enum OFFICE_TYPE {
    LLC = 0,
    IP = 1,
    PERSONAL = 2,
}

export enum OFFICE_TYPE_NAME {
    LLC = 'Общество с ограниченной ответственностью',
    IP = 'Индивидуальный предприниматель',
    PERSONAL = 'Самозанятый или Частное лицо'
}
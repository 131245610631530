import {Target}            from "@/models/Target";
import Vue                 from "vue";
import {CancelTokenSource} from "axios";
import {isArray}           from "highcharts";
import {isEmpty, isObject} from "lodash";


interface FormOptions {
    attrs?: string[];
    uri?: FormURI | string;
    on?: Record<string, Function>;
    fill?: Record<string, any>;
}

interface FormURI {
    addr: string;
    params?: Record<string, string>
}


/**
 Form.create({
 attrs: ['username', 'email', 'password'],
 on:    {
 send:  () => {
 this.setRole();
 },
 load:  (data) => {
 this.role        = data.role?.name;
 },
 error: () => {
 this.$refs.form.validate()
 }
 }
 })
 */

export class Form extends Target {
    attr: Record<string, any> = {};
    uri: string = '';
    errors: Record<string, string>[] = []
    loading: boolean = false;
    cancelTokenSource: CancelTokenSource


    constructor(options: FormOptions = {}) {
        super()
        this.set(options)
        this.cancelTokenSource = Vue.axios.CancelToken.source();
    }

    static create(options: FormOptions = {}) {
        return new this(options);
    }

    set(options: FormOptions = {}) {
        if (options.uri) {
            if (typeof options.uri === 'string') {
                this.setUri(options.uri)
            } else {
                this.setUri(options.uri.addr, options.uri.params);
            }
        }
        if (options.attrs) {
            this.setAttr(options.attrs);
        }
        if (options.on) {
            for (let event in options.on) {
                this.on(event, options.on[event])
            }
        }
        if (options.fill) {
            this.fill(options.fill);
        }
        return this
    }

    setAttr(attrs: string[]) {
        attrs.forEach(attr => {
            this.attr[attr] = ''
        })
        return this;
    }

    setUri(uri: string, params?: Record<string, string>) {
        let query = new URLSearchParams();
        if (params) {
            for (let i in params) query.set(i, params[i])
        }
        this.uri = uri + ((params) ? '?' + query.toString() : '');
        return this;
    }

    load(uri: string, params?: Record<string, string>) {
        this.loading = true;
        this.cancelTokenSource.cancel();
        this.cancelTokenSource = Vue.axios.CancelToken.source();
        Vue.axios.get(uri, {params, cancelToken: this.cancelTokenSource.token})
            .then((response) => {
                this.loading = false;
                this.emit('load', response.data)
                this.fill(response.data);
            }).catch((error) => {
            console.error(error);
            this.loading = false;
            this.emit('error', error.response?.data, error.response?.status);
        });
        return this
    }

    fill(attrs: object) {
        this.attr = Object.assign({}, this.attr, attrs);
        return this;
    }

    send() {
        this.loading = true;
        this.errors = [];
        this.cancelTokenSource.cancel();
        this.cancelTokenSource = Vue.axios.CancelToken.source();
        let form = new FormData();
        for (let name in this.attr) {
            let value = this.attr[name];
            if (value === undefined || value === null) {
                value = '';
            }
            if (isArray(value)) {
                if (isEmpty(value)) {
                    form.append(name, '');
                }
                for (let val of value) {
                    form.append(name + '[]', val);
                }
            } else if (isObject(value) && !(value instanceof File)) {
                value = this.getObjectForm(name, value);
                for (let key in value) {
                    form.append(key, (value as any)[key]);
                }
            } else {
                form.append(name, value);
            }
        }
        let config = {
            cancelToken: this.cancelTokenSource.token,
            headers:     {'Content-Type': 'multipart/form-data'}
        }
        Vue.axios.post(this.uri, form, config)
            .then((response) => {
                this.loading = false;
                this.emit('send', response.data)
            })
            .catch((error) => {
                this.loading = false;
                console.warn(error);
                if (error.response) {
                    this.errors = error.response.data;
                    this.emit('error', this.errors, error.response.status)
                }
            });
        return this;
    }

    rule(attr: string) {
        let rule: string[] | boolean[] = [true];
        if (typeof this.errors !== 'object') {
            return rule;
        }
        for (let error of this.errors) {
            if (attr === error['field'])
                rule = [error['message']]
        }
        return rule;
    }

    private getObjectForm(name: string, object: object) {
        const result = {};
        for (let i in object) {
            const curName = name + '[' + i + ']';
            const value = (object as any)[i];
            if (isObject(value) && !(value instanceof File)) {
                const values = this.getObjectForm(curName, value);
                Object.assign(result, values);
            } else {
                (result as any)[curName] = value;
            }
        }
        return result;
    }
}
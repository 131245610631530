import {Model} from "@/models/Model";

export class BankFile extends Model {
    modelUri = 'bank-file';
    labels = {
        id:          'ID',
        name:        'Название',
        description: 'Описание',
        required:    'Обязательный',
        created_at:  'Создано',
        updated_at:  'Обновлено',
        deleted_at:  'Удалено'
    }

    fields = [
        'name',
        'description',
        'required'
    ];
}
<template>
  <confirm :loading="model.loading" :title="'Вы действительно хотите заблокировать менеджера '+name+'?'" color="error" @confirm="model.block()">
    <btn-icon :title="$t('btn.block')" color="secondary" icon="account-cancel-outline"/>
  </confirm>
</template>

<script>
import Confirm from "@/tags/Confirm";
import BtnIcon from "@/tags/BtnIcon";
import {User}  from "@/models/User";

export default {
  name:       "Block",
  components: {BtnIcon, Confirm},
  props:      ['id', 'name'],
  data() {
    return {
      model: User.get(this.id, {
        on: {
          save: () => {
            this.$emit('block')
          }
        }
      })
    }
  },
}
</script>

<style scoped>

</style>
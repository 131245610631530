<template>
  <modal v-model="modal" :loading="model.loading" max-width="500px" title="Редактирование профиля">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <password-input v-model="model.attr.password" :label="$t('user.password')" :rules="model.rule('password')"/>
          </v-col>
          <v-col cols="12">
            <file-input v-model="model.attr.avatar_file" :rules="model.rule('avatar_file')" accept=".png,.jpg,.svg" icon="mdi-file-image" label="Аватар"/>
          </v-col>
          <v-col cols="12">
            <v-select v-model="model.attr.background" :items="backgroundList" :rules="model.rule('background')" label="Фон"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="model.attr.income_goal" :rules="model.rule('income_goal')" label="Цель по квартальному доходу"/>
          </v-col>
        </v-row>
        <h3 class="mt-4 mb-2">Настройки безопасности {{ canSafety ? '' : '(только просмотр)' }}</h3>
        <v-row>
          <v-col cols="12" md="6">
            <v-switch
                v-model="userSafety.twoAuth"
                :disabled="!canSafety"
                :false-value="0"
                :true-value="1"
                label="Двухфакторная авторизация"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-switch
                v-model="userSafety.ipLink"
                :disabled="!canSafety"
                :false-value="0"
                :true-value="1"
                label="Привязка к IP"
            />
          </v-col>
          <v-col cols="12">
            <v-slider
                v-model="userSafety.maxSessions"
                :disabled="!canSafety"
                :hint="hintSessions(userSafety.maxSessions)"
                :max="sliderMaxSessions.max"
                :min="sliderMaxSessions.min"
                :step="sliderMaxSessions.step"
                inverse-label
                label="Максимальное количество сессий"
                persistent-hint
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal         from "@/tags/Modal";
import {User}        from "@/models/User";
import {mapGetters}  from "vuex";
import FileInput     from "@/tags/FileInput";
import PasswordInput from "@/tags/PasswordInput";


export default {
  name:       "UserSelfForm",
  components: {PasswordInput, Modal, FileInput},
  props:      ['id'],
  created() {
    this.initSettingValue();
  },
  data() {
    return {
      modal:          false,
      backgroundList: [
        'Весна.jpg',
        'Зима.jpg',
        'Космос.jpg',
        'Лес.jpg',
        'Лето.jpg',
        'МоскваСити.jpg',
        'Небо.jpg',
        'Осень.jpg',
        'Пляж.jpg'
      ],
      model: User.get(this.id, {
        with: ['role', 'setting'],
        on:   {
          load: () => {
            this.initDefaultSettingValue(false)
          },
          save:  () => {
            this.close()
            this.$emit('save');
          },
          saveRole: () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      updated: 0,
    }
  },
  computed: {
    ...mapGetters(['user', 'can']),
    canSafety() {
      return this.can('user.safety');
    },
    sliderMaxSessions() {
      return {
        min:  0,
        max:  10,
        step: 1
      }
    },
    userSafety: {
      get() {
        this.updated;
        return this.model.attr.setting?.safety || this.initSettingValue().safety;
      },
      set(value) {
        this.model.attr.setting.safety = value;
      }
    },
  },
  watch:    {
    modal(value) {
      if (value) {
        this.initSettingValue();
        if (this.id) {
          this.model.load();
        } else {
          this.model.clear();
          this.initDefaultSettingValue();
        }
      }
    }
  },
  methods:  {
    close() {
      this.$refs.form.reset();
      this.modal = false;
    },
    initSettingValue() {
      return this.model.attr.setting = {safety: {}};
    },
    initDefaultSettingValue(rewrite = true) {
      this.axios.get('setting/get', {params: {name: 'user.setting.safety'}})
          .then((response) => {
            const {twoAuth, ipLink, maxSessions} = response.data;
            const safety                         = {twoAuth, ipLink, maxSessions}
            if (rewrite) {
              this.userSafety = Object.assign({}, safety);
            } else {
              this.userSafety = Object.assign({}, safety, this.userSafety);
            }
            this.updated++;
          })
    },
    hintSessions(value) {
      return value ? value.toString() : 'Неограниченно'
    }
  }
}
</script>

<style scoped>

</style>
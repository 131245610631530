<template>
  <v-card>
    <v-title title="Видеоматериалы"/>
    <v-card-title>
      <span class="mr-5">Видеоматериалы</span>
      <span>
      <video-form v-if="can('video.manage')" @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </video-form>
      </span>
      <toggle-deleted v-if="can('video.manage')" v-model="showDeleted" class="ml-2"/>
      <v-spacer/>
      <v-select
          v-model="category"
          :items="categoryList"
          class="ml-2"
          clearable
          hide-details
          item-text="name"
          item-value="id"
          label="Категория"
      />
      <v-select
          v-model="sort"
          :items="sortList"
          class="ml-2"
          hide-details
          item-text="name"
          item-value="value"
          label="Сортировка"
      />
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          class="ml-2"
          clearable
          hide-details
      />
    </v-card-title>
    <data-grid
        :cols="12"
        :headers="headers"
        :lg="4"
        :models="models"
        :sm="6"
    >
      <template #item="{ item }">
        <video-item :item="item" @update="models.load()"/>
      </template>
    </data-grid>
  </v-card>
</template>

<script>
import {Search}      from "@/models/Search";
import {debounce}    from "lodash/function";
import {mapGetters}  from "vuex";
import ToggleDeleted from "@/views/links/ToggleDeleted";
import VTitle        from "@/tags/VTitle";
import BtnIcon       from "@/tags/BtnIcon";
import VideoForm     from "./VideoForm";
import VideoItem     from "@/views/videos/VideoItem.vue";
import DataGrid      from "@/tags/DataGrid.vue";


export default {
  name:       "Index",
  components: {DataGrid, VideoItem, VideoForm, BtnIcon, VTitle, ToggleDeleted},
  mounted() {
    this.axios.get('video-category/list').then((response) => this.categoryList = response.data)
  },
  data:     () => ({
    search:       '',
    category:     null,
    categoryList: [],
    sortList: [
      {name: 'Новые', value: 'id'},
      {name: 'Популярные', value: 'votes'},
    ],
    sort:     'id',
    models:       Search.create({
      uri:    'video',
      fields:  ['id', 'name', 'url', 'preview', 'public', 'fixed', 'description', 'category.name', 'created_at', 'updated_at', 'deleted_at'],
      expand: ['category', 'isVoted', 'voteCount'],
      params: {deleted: 0},
      perPage: 24,
      filter:  {or: [{name: {like: ''}}, {description: {like: ''}}]},
    }).load(),
    showDeleted:  0,
  }),
  computed: {
    ...mapGetters(['can']),
    headers() {
      return [
        {text: 'Превью', value: 'preview', width: 1},
        {text: 'Название', value: 'name', sortable: true},
        {text: 'Категория', value: 'category.name'},
        {text: 'Действия', value: 'actions', align: 'end'},
      ]
    }
  },
  watch:    {
    search(value) {
      this.models.config.filter.or[0].name.like        = value || '';
      this.models.config.filter.or[1].description.like = value || '';
      this.update(this)
    },
    category(value) {
      if (value) this.models.config.filter.category_id = value;
      else delete this.models.config.filter.category_id
      this.update(this);
    },
    sort(value) {
      this.models.setSort(value, false)
      this.update(this);
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    },
  },
  methods:  {
    update: debounce((vm) => vm.models.load(), 200),
    utcToDatetime(value) {
      return this.$d(value * 1000, 'datetime');
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="wrap mx-md-n6 pa-0">
    <menu class="container pa-md-0">
      <v-row>
        <v-col class="px-0 px-md-3" cols="12" md="10" offset-md="1">
          <div class="links my-n3">
          <span v-for="link in links"
                :key="link.name"
                class="link"
          >
            <a
                :href="`#${link.name}`">
              <b>{{ link.title }}</b>
          </a>
          </span>
          </div>
        </v-col>
      </v-row>
    </menu>
  </div>
</template>
<script>

export default {
  name: "SectionLinks",
  data() {
    return {
      links: [
        {
          title: "pro CRM SF",
          name:  "SectionInfo",
        },
        {
          title: "Agent SF",
          name:  "SectionAgentSF",
        },
        {
          title: "Функционал",
          name:  "SectionFunctional",
        },
        {
          title: "Встроенные проекты",
          name:  "SectionIntegration",
        },
        {
          title: "Команда",
          name:  "SectionTeam",
        },

      ]
    }
  }
}
</script>


<style lang="scss" scoped>
.wrap {
  background-color: #f5f5f5;
}

.links {
  display: flex;
  justify-content: space-between;

  .link {
    margin-left: 20px;
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d9d9d9;

    & a {
      color: #232936;
      display: flex;
      width: 100%;
      height: 100%;
      text-decoration: none;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding: 5px
    }

    &:first-child {
      margin-left: 0;
      background-color: #E12435;

      & a {
        color: white;
      }
    ;

    }
  }
}

@media (max-width: 1263px) {
  .links {
    flex-wrap: wrap;
    margin: -5px;

    .link {
      flex-basis: calc(50% - 10px);
      margin: 0 5px 10px;

      &:first-child {
        margin-left: 5px;

      }

      &:nth-child(5) {
        flex-basis: calc(100% - 10px);
        margin-bottom: 0;
      }
    }
  }
}
</style>
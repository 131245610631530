<template>
  <modal v-model="modal" :loading="models.loading" max-width="800" title="Настройка списка файлов банков">
    <template #activator>
      <slot/>
    </template>
    <data-list :models="models">
      <template #item="{ item }">
        <bank-file-item :value="item" @remove="remove"/>
      </template>
    </data-list>
    <template #actions>
      <v-btn color="primary" @click="close">Закрыть</v-btn>
      <v-spacer/>
      <v-btn :disabled="models.loading" color="primary" @click="add">
        <v-icon>mdi-plus</v-icon>
        Добавить тип файла
      </v-btn>
    </template>
  </modal>
</template>
<script>

import {Search}     from "@/models/Search";
import Modal        from "@/tags/Modal.vue";
import DataList     from "@/tags/DataList.vue";
import BankFileItem from "@/views/bank/BankFileItem.vue";

export default {
  name:       "BankFileForm",
  components: {BankFileItem, DataList, Modal},
  data() {
    return {
      modal:  false,
      models: Search.create({
        uri:    'bank-file',
        fields: ['id', 'name', 'description', 'required', 'deleted_at'],
        params: {deleted: 0},
        filter: {}
      })
    }
  },
  computed: {},
  watch:    {
    modal(value) {
      if (value) {
        this.models.load();
      }
    }
  },
  methods: {
    close() {
      this.modal = false;
    },
    add() {
      const item = {_id: Math.random()};
      this.models.result.push(item);
    },
    remove(removeItem) {
      this.models.result = this.models.result.filter(item => !((removeItem._id && removeItem._id === item._id) || (removeItem.id && removeItem.id === item.id)));
    }
  }
}
</script>


<style scoped>

</style>
<template>
  <div id="SectionAgentSF" class="wrap mx-md-n6 pa-0 mt-16">
    <section class="container text--white pa-md-0">
      <v-row>
        <v-col class="pt-md-16" cols="12" md="5" offset-md="1">
          <div>
            <span class="span-accent">Агент SF</span>
          </div>
          <ul class="info-list pa-3 pa-md-0 mt-6">
            <li>
              Монетизируйте текущую базу клиентов
            </li>
            <li>
              Постоянный бесплатный доступ pro CRM SF для Агентов SF
            </li>
            <li>
              Совместные сделки по ипотеке, кредиту под залог и без, банкротству, страховании и бизнес-кредитам
            </li>
            <li>
              Максимальные выплаты на рынке
            </li>
            <li>
              Прямой доступ к банкам
            </li>
            <li>
              Наставничество профессионалов рынка
            </li>
            <li>
              Подача в два и более банков сразу
            </li>
            <li>
              Доступ ко всем банкам, страховым и лизинговым компаниям, иным партнерам
            </li>
            <li>
              <a href="#SectionPartners">Наши партнеры</a>
            </li>
            <li>
              Также работаем по таким услугам: оценка, кредит наличными, факторинг, банковские гарантии, рефинансирование, любые сложные сделки
            </li>
            <li>
              Более 40 банков-партнеров
            </li>
          </ul>
          <div class="px-3 px-md-0 mt-4">
            <v-btn :block="$vuetify.breakpoint.mobile" :to="{name:'Registration'}" color="primary">Зарегистрироваться</v-btn>
          </div>
        </v-col>
        <v-col class="pa-0 d-flex" cols="12" md="6">
          <div class="bookmark mx-auto mx-md-0">
            <div>
              <h3 class="mb-6">Четыре варианта сотрудничества:</h3>
              <p>
                <b>10%</b> - передача лида
              </p>
              <p>
                <b>20%</b> - передача лида с документами
              </p>
              <p>
                <b>30%-50%</b> - совместная сделка
              </p>
              <p>
                <b>До 80%</b> от КВ (АВ) партнера при проведении сделки через платформу/куратора
              </p>
            </div>
          </div>
          <div class="photo align-self-end">
            <img alt="Agent SF" src="@/assets/index/agent-photo.png"/>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>
<script>

export default {
  name: "SectionAgentSF"
}
</script>


<style scoped>
.wrap {
  background: #232936;
}

ul {
  color: #fff;
}

a {
  color: #fff !important;
  text-decoration: underline;
}

.bookmark {
  color: #fff;
  width: 304px;
  min-width: 30%;
  height: 720px;
  max-height: 100%;
  background-image: url('@/assets/index/agent-bookmark.png');
  background-position-x: center;
  display: flex;
  align-items: center;
  padding: 0 24px 140px;

  b {
    font-size: 24px;
  }

}

.photo {
  width: 100%;

  img {
    max-width: 100%;
  }
}

@media (max-width: 959px) {
  .photo {
    display: none;
  }

  .bookmark {
    width: 100%;
    padding: 32px 16px;
    height: auto;
    background: #E12435;
  }
}
</style>
<template>
  <v-form ref="form" :class="{top}" :disabled="model.loading" class="form" @submit.prevent="model.save()">
    <v-fab-transition>
      <v-btn v-if="!showForm" :class="{top}" absolute class="expand-btn" color="primary" fab right top @click="openForm">
        <v-icon>mdi-message-text</v-icon>
      </v-btn>
    </v-fab-transition>
    <template v-if="showForm">
      <v-textarea ref="textarea" v-model="model.attr.text" :label="model.labels.text" :loading="model.loading" :rules="model.rule('text')" auto-grow rows="1"/>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">Отправить</v-btn>
    </template>
  </v-form>
</template>

<script>

import {Comment} from "@/models/Comment";


export default {
  name:  "CommentForm",
  props: {
    id:   {
      type:     Number,
      required: true,
    },
    type: {
      type:     String,
      required: true,
    },
    top:  {
      type:    Boolean,
      default: false,
    }
  },
  data() {
    return {
      model: Comment.get(null, {
        on: {
          save: () => {
            this.clear();
            this.closeForm();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      showForm: false
    }
  },
  methods: {
    clear() {
      this.model.attr.id = null;
      this.model.attr.text = '';
    },
    fillParams() {
      this.model.fill({
        type:    this.type,
        type_id: this.id
      })
    },
    closeForm() {
      this.showForm = false;
    },
    openForm() {
      this.showForm = true;
      this.$nextTick(() => this.$refs.textarea.focus());
    }
  }
}
</script>

<style lang="scss" scoped>
.expand-btn {
  right: 24px !important;

  &.top {
    top: -20px !important;
    margin-top: -10px !important;
  }
}

.form {
  position: relative;
  padding-top: 24px;

  &.top {
    padding-top: 0;
  }
}
</style>
<template>
  <div>
    <h3 class="mb-2">{{ type.name }}</h3>
    <v-row>
      <v-col cols="12" md="4">
        <mask-input
            v-model="model.attr.price"
            :label="model.labels.price"
            :mask="mask"
            :readonly="readonlyPrice"
            :rules="model.rule('price')"
            suffix="₽"
            unmask
        />
      </v-col>
      <v-col cols="12" md="4">
        <mask-input
            v-model="model.attr.income"
            :label="model.labels.income"
            :mask="mask"
            :readonly="readonlyIncome"
            :rules="model.rule('income')"
            suffix="₽"
            unmask
        />
      </v-col>
      <v-col class="d-flex flex-wrap justify-space-between" cols="12" md="4">
        <confirm :disabled="disableRewarded" :title="confirmTitle" @confirm="changeReward">
          <v-switch
              :disabled="disableRewarded"
              :false-value="0"
              :label="model.labels.rewarded"
              :rules="model.rule('rewarded')"
              :true-value="1"
              :value="model.attr.rewarded"
              readonly
          />
        </confirm>
        <btn-icon v-if="showDelete" class="mt-3" color="error" icon="delete" title="Удалить" @click="remove()"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import MaskInput          from "@/tags/MaskInput.vue";
import {DealPayment}      from "@/models/DealPayment";
import Confirm            from "@/tags/Confirm.vue";
import {mapGetters}       from "vuex";
import {round}            from "lodash";
import BtnIcon            from "@/tags/BtnIcon.vue";
import {DEAL_PRICE_TYPES} from "@/models/DealPaymentType";

export default {
  name:       "DealPaymentForm",
  components: {BtnIcon, Confirm, MaskInput},
  props:      {
    value: {
      type:     Object,
      required: true,
    },
    deal:  {
      type:     Object,
      required: true,
    },
    bank:  {
      type: Object,
    }
  },
  beforeMount() {
    this.$nextTick(() => {
      this.fillData();
      this.calcValues();
    });
  },
  data() {
    return {
      model:       DealPayment.get(null, {
        on:   {
          save:  () => {
            this.oldRewarded         = +this.model.attr.rewarded;
            this.model.attr.rewarded = +this.model.attr.rewarded;
            this.$emit('save');
          },
          error: () => {
            this.$emit('error');
          }
        },
        with: ['type'],
      }),
      oldRewarded: +!!(+this.value.rewarded && this.value.id),
      needCalc:    false,
      mask:        {
        alias:          'numeric',
        digits:         2,
        min:            0,
        groupSeparator: ' '
      },
    };
  },
  watch:    {
    'deal.sum'(value) {
      if (!value) {
        return;
      }
      if (typeof value === 'string') {
        return;
      }
      if (!this.canCalcValues) {
        return;
      }
      if ([
        DEAL_PRICE_TYPES.PERCENTAGE,
        DEAL_PRICE_TYPES.KV_PERCENTAGE
      ].includes(this.type.price_type)) {
        this.calcPrice();
      }
      if ([
        DEAL_PRICE_TYPES.PERCENTAGE,
        DEAL_PRICE_TYPES.KV_PERCENTAGE,
        DEAL_PRICE_TYPES.PRICE_PERCENTAGE
      ].includes(this.type.income_type)) {
        this.calcIncome();
      }
    },
    'deal.id'(value) {
      this.model.attr.deal_id = value;
    },
    bank(value) {
      if (value && [this.type.price_type, this.type.income_type].includes(DEAL_PRICE_TYPES.KV_PERCENTAGE)) {
        this.calcValues();
      }
    },
    value() {
      this.fillData();
      this.calcValues();
    },
    'model.attr.price'() {
      if (this.canCalcValues && this.type.income_type === DEAL_PRICE_TYPES.PRICE_PERCENTAGE) {
        this.calcIncome();
      }
    },

  },
  computed: {
    ...mapGetters(['can']),
    type() {
      return this.value.type;
    },
    readonlyPrice() {
      if (this.needCalc) {
        return false;
      } else {
        if (!(this.can('deal-payment.manage') || this.can('deal-payment.reward'))) {
          return true;
        }
        if (this.oldRewarded) {
          return true;
        }
        if (this.type.editable) {
          return false;
        }
        return this.type.price_type !== DEAL_PRICE_TYPES.MANUAL;
      }
    },
    readonlyIncome() {
      if (this.needCalc) {
        return false;
      } else {
        if (!(this.can('deal-payment.manage') || this.can('deal-payment.reward'))) {
          return true;
        }
        if (this.oldRewarded) {
          return true;
        }
        if (this.type.editable) {
          return false;
        }
        return this.type.income_type !== DEAL_PRICE_TYPES.MANUAL;
      }
    },
    disableRewarded() {
      if (this.oldRewarded) {
        return !this.can('deal-payment.manage');
      }
      return !this.can('deal-payment.reward');
    },
    canCalcValues() {
      if (this.oldRewarded) {
        return false;
      }
      if (this.value.id) {
        return false;
      }
      return !!this.deal.sum;
    },
    confirmTitle() {
      return this.model.attr.rewarded ?
             'Отменить оплату сделки' :
             'Подтвердите оплату сделки';
    },
    showDelete() {
      return !this.value.id && !this.type.required;
    }
  },
  methods:  {
    fillData() {
      this.model.attr = this.value;
    },
    changeReward() {
      this.model.attr.rewarded = +!(+this.model.attr.rewarded);
    },
    calcValues() {
      if (this.canCalcValues) {
        this.calcPrice();
        this.calcIncome();
      }
    },
    calcPrice() {
      this.needCalc = true;
      switch (this.type.price_type) {
        case DEAL_PRICE_TYPES.PERCENTAGE:
          this.model.attr.price = round(this.deal.sum * this.type.price_value * 0.01, 2);
          break;
        case DEAL_PRICE_TYPES.KV_PERCENTAGE:
          this.model.attr.price = round(this.deal.sum * this.type.price_value * this.bank.commission_reward * 0.0001, 2);
          break;
        case DEAL_PRICE_TYPES.FIXED:
          this.model.attr.price = round(this.type.price_value, 2);
          break;
      }
      this.needCalc = false;
    },
    calcIncome() {
      this.needCalc = true;
      switch (this.type.income_type) {
        case DEAL_PRICE_TYPES.PERCENTAGE:
          this.model.attr.income = round(this.deal.sum * this.type.income_value * 0.01, 2);
          break;
        case DEAL_PRICE_TYPES.KV_PERCENTAGE:
          this.model.attr.income = round(this.deal.sum * this.type.income_value * this.bank.commission_reward * 0.0001, 2);
          break;
        case DEAL_PRICE_TYPES.FIXED:
          this.model.attr.income = round(this.type.income_value, 2);
          break;
        case DEAL_PRICE_TYPES.PRICE_PERCENTAGE:
          this.model.attr.income = round(this.model.attr.price * this.type.income_value * 0.01, 2);
          break;
      }
      this.needCalc = false;
    },
    save() {
      this.$nextTick(() => this.model.save());
    },
    remove() {
      this.$emit('remove', this.type);
    }
  }
};
</script>

<style scoped>

</style>
import {Form}   from "@/models/Form";
import {Target} from "@/models/Target";
import Vue      from "vue";

interface ModeLOptions {
    with?: string[];
    data?: Record<string, any>;
    on?: Record<string, Function>;
}


export class Model extends Target {
    primaryKey: string = 'id';
    modelUri: string = '';
    loading: boolean = false;
    attr: Record<string, any> = {}
    fields: string[] = [];
    labels: Record<string, string> = {}
    private _form: Form;
    private _with: string[] = []

    constructor(data?: object, options?: ModeLOptions) {
        super();
        this._form = Form.create(
            {
                on: {
                    load: (data: object) => {
                        this.fill(data);
                        this._form.attr = this.attr
                        this.loading = false
                        this.emit('load', this);
                    },
                    send: (data: object) => {
                        let isNew = this.isNew;
                        this.fill(data);
                        this.loading = false;
                        this.emit('save', this, isNew);
                    },
                    error: (data: object, status: string) => {
                        this.loading = false;
                        this.emit('error', data, status);
                    }
                }

            }
        )
        if (data) {
            this.fill(data)
        }
        if (options) {
            this.setOptions(options);
        }
    }

    get isNew() {
        return typeof this.attr[this.primaryKey] === 'undefined' || !this.attr[this.primaryKey];
    }

    static get(id: any, options?: ModeLOptions) {
        let data = {[(new this).primaryKey]: id};
        if (options && options.data) {
            data = Object.assign({}, options.data, data);
        }
        return (new this(data, options));
    }

    setOptions(options: ModeLOptions) {
        if (options.with) {
            this.with(options.with);
        }
        if (options.on) {
            for (let event in options.on) {
                this.on(event, options.on[event])
            }
        }
    }

    load() {
        this.clear(true);
        let loadUri = this.modelUri + '/' + 'view';
        this.loading = true;
        this._form.load(loadUri, {
            id:     this.attr[this.primaryKey],
            expand: this._with.join(',')
        });
        return this;
    }

    fill(attrs: object) {
        this.attr = Object.assign({}, this.attr, attrs);
        return this;
    }

    save(attrs?: string[]) {
        let saveUri = this.modelUri + '/' + (this.isNew ? 'create' : 'update');
        this.loading = true;
        this._form.attr = {};

        for (let field of this.fields) {
            if (attrs !== undefined && attrs.indexOf(field) < 0) {
                continue;
            }
            if (typeof this.attr[field] !== 'undefined') {
                this._form.attr[field] = this.attr[field];
            }
        }
        this._form.setUri(saveUri, {
            id:     this.attr[this.primaryKey],
            expand: this._with.join(',')
        }).send();
    }

    remove() {
        let deleteUri = this.modelUri + '/' + 'delete';
        this.loading = true;
        Vue.axios.post(deleteUri + '?id=' + this.attr[this.primaryKey])
            .then(() => {
                this.emit('remove');
                this.loading = false;
            }).catch(() => this.loading = false)
    }

    restore() {
        let restoreUri = this.modelUri + '/' + 'restore';
        this.loading = true;
        Vue.axios.post(restoreUri + '?id=' + this.attr[this.primaryKey])
            .then(() => {
                this.emit('restore');
                this.loading = false;
            }).catch(() => this.loading = false)
    }

    rule(attr: string) {
        return this._form.rule(attr);
    }

    with(attr: string[]) {
        this._with = attr;
    }

    clear(savePrimary: boolean = false) {
        this.attr = savePrimary ? {[this.primaryKey]: this.attr[this.primaryKey]} : {}
    }
}
import {Model} from "@/models/Model";

export class SupportService extends Model {
    modelUri = 'support-service';
    labels = {
        name:        'Название',
        price:       'Цена',
        description: 'Описание'
    }

    fields = [
        'name',
        'price',
        'description'
    ];
}
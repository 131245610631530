<template>
  <r-card>
    <v-row class="ma-0 pa-2">
      <v-col v-for="item in firstData" :key="item.value" class="pa-2" cols="12" lg="3" sm="6">
        <info-card
            :inverse="item.inverse"
            :loading="loading"
            :prefix="item.prefix"
            :previous="prevValue(item)"
            :title="item.name"
            :type="item.type"
            :value="value(item)"
        />
      </v-col>
    </v-row>

    <v-divider/>

    <v-row class="ma-0 pa-2">
      <v-col v-for="item in otherData" :key="item.value" class="pa-2" cols="12" lg="3" sm="6">
        <info-card
            :inverse="item.inverse"
            :loading="loading"
            :prefix="item.prefix"
            :previous="prevValue(item)"
            :title="item.name"
            :type="item.type"
            :value="value(item)"
        />
      </v-col>
    </v-row>
  </r-card>
</template>

<script>
import RCard    from "@/tags/RCard.vue";
import InfoCard from "@/views/stat/InfoCard.vue";

export default {
  name:       "StatMain",
  components: {InfoCard, RCard},
  props:      {
    stat:    {
      type: Object
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      firstData: [
        {name: 'Выручка компании', value: 'price', prefix: '₽'},
        {name: 'Доход менеджеров', value: 'income', prefix: '₽'},
        {name: 'Количество сделок', value: 'dealCompleted'},
        {name: 'Количество клиентов', value: 'clientCount'},
      ],
      otherData: [
        {name: 'Выручка компании по сделкам', value: 'dealPrice', prefix: '₽'},
        {name: 'Выручка компании по кросс-продажам', value: 'servicePrice', prefix: '₽'},
        {name: 'Количество авторизаций', value: 'authCount'},
        {name: 'Сумма одобренных кредитов', value: 'sum', prefix: '₽'},
        {name: 'Количество одобрений', value: 'dealApproved'},
        {name: 'Средний чек по сделкам', value: 'dealAvgPrice', prefix: '₽'},
        {name: 'Количество кросс-продаж', value: 'serviceCount'},
        {name: 'Средний чек по кросс-продажам', value: 'serviceAvgPrice', prefix: '₽'},
        {name: 'Количество отказников', value: 'clientDeniedCount', inverse: true},
        {name: 'Средний цикл сделки', value: 'dealAvgCycle', type: 'period'},
      ]
    }
  },
  methods: {
    value(item) {
      return this.stat ? this.stat[item.value] : null;
    },
    prevValue(item) {
      return this.stat ? this.stat.previous[item.value] : null;
    }
  }
}
</script>

<style scoped>

</style>
import en                         from '@/messages/en'
import ru                         from '@/messages/ru'
import VueI18n, {DateTimeFormats} from "vue-i18n";
import Vue                        from "vue";


const messages = {
    en,
    ru,
}

const dateTimeFormats: DateTimeFormats = {
    en: {
        date:     {
            year:  'numeric',
            month: 'short',
            day:   'numeric'
        },
        datetime: {
            year:   'numeric',
            month:  'short',
            day:    'numeric',
            hour:   'numeric',
            minute: 'numeric'
        },
        time:     {
            hour:   'numeric',
            minute: 'numeric'
        }
    },
    ru: {
        date:     {
            year:  'numeric',
            month: 'short',
            day:   'numeric'
        },
        datetime: {
            year:   'numeric',
            month:  'short',
            day:    'numeric',
            hour:   'numeric',
            minute: 'numeric'
        },
        time:     {
            hour:   'numeric',
            minute: 'numeric'
        }
    }
}

Vue.use(VueI18n);

export default new VueI18n(
    {
        locale: 'ru',
        messages,
        dateTimeFormats
    }
);
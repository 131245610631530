import {Model} from '@/models/Model';

export class HrTest extends Model {
    modelUri = 'hr-test';
    labels = {
        name:        'Название',
        description: 'Описание',
        timeout:     'Время прохождения (мин)'
    };

    fields = ['name', 'description', 'timeout'];
}
import {Socket}              from "@/components/Socket";
import Vue, {PluginFunction} from "vue";

declare module "vue/types/vue" {
    interface Vue {
        $socket: Socket;
    }

    interface VueConstructor {
        $socket: Socket;
    }
}

class VueSocket {
    static install: PluginFunction<Socket>
}

VueSocket.install = function (Vue, socket) {
    if (!socket) {
        console.error('You have to install socket');
        return;
    }
    Vue.$socket = socket;

    Object.defineProperties(Vue.prototype, {
        $socket: {
            get() {
                return socket;
            }
        }
    })
}


let token = localStorage.getItem('token') || btoa(Math.random().toString());
localStorage.setItem('token', token);
const socket = new Socket(process.env.VUE_APP_SOCKET_ADDR, {token});

Vue.use(VueSocket, socket);

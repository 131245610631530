<template>
  <modal v-model="modal" :loading="model.loading" :title="id?'Изменение мини-скрипта':'Создание мини-скрипта'" max-width="600px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          </v-col>
          <v-col cols="12">
            <v-autocomplete v-model="model.attr.type_id" :disabled="!model.isNew" :items="typeList" :label="model.labels.type_id" :rules="model.rule('type_id')" item-text="name" item-value="id"/>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="model.attr.description" :label="model.labels.description" :rules="model.rule('description')"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="model.attr.button_text" :label="model.labels.button_text" :rules="model.rule('button_text')"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal from "@/tags/Modal";

import {MsScript} from "@/models/MsScript";


export default {
  name:       "MsScriptForm",
  components: {Modal},
  props:      ['id'],
  data() {
    return {
      modal: false,
      model: MsScript.get(this.id, {
        on: {
          save: () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      typeList: []
    }
  },
  computed: {},
  watch:    {
    modal(value) {
      if (value) {
        this.id && this.model.load();
        !this.id && this.model.clear();
        this.axios.get('lead-type/list').then((response) => this.typeList = response.data);
      }
    },
    'model.attr.type_id'(value) {
      if (this.model.isNew && !this.model.attr.button_text) {
        const buttonText = 'Сформировать список документов для одобрения ';
        const type = this.typeList.find(item => item.id === value);
        this.model.fill({button_text: type ? buttonText + type.name.toLowerCase() : ''});
      }
    }
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.modal = false
    }
  }
}
</script>

<style scoped>

</style>
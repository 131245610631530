import {Model} from "@/models/Model";

export class ClientSource extends Model {
    modelUri = 'client-source';
    labels = {
        name:       'Название',
        is_default: 'По умолчанию'
    }

    fields = [
        'name',
        'is_default',
    ]
}
import {Model} from '@/models/Model';

export class TaskCategory extends Model {
    modelUri = 'task-category';
    labels = {
        name:       'Название',
        color:      'Цвет',
        priority:   'Приоритет',
        is_default: 'По умолчанию',
    };

    fields = [
        'name',
        'color',
        'priority',
        'is_default'
    ];
}
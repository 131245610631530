export class Target {
    protected listeners: Record<string, Function[]> = {};

    on(event: string, callback: Function): this {
        if (typeof this.listeners[event] === 'undefined') {
            this.listeners[event] = []
        }
        this.listeners[event].push(callback)
        return this;
    }

    off(event: string): this {
        if (typeof this.listeners[event] === 'object') {
            delete this.listeners[event]
        }
        return this;
    }

    emit(event: string, ...args: any): this {
        let callbacks = this.listeners[event] || [];
        for (let callback of callbacks) {
            callback(...args)
        }
        return this
    }
}
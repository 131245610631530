<template>
  <div>
    <v-row class="mx-1 mb-2">
      <v-col v-if="can('office.manage')" cols="12" lg="3" md="4" sm="6">
        <v-autocomplete v-model="office" :items="officeList" clearable hide-details item-text="name" item-value="id" label="Офис" @change="updateManagerList"/>
      </v-col>
      <v-col v-if="can('user.manage')" cols="12" lg="3" md="4" sm="6">
        <v-autocomplete v-model="manager" :items="managerList" clearable hide-details item-text="username" item-value="id" label="Менеджер"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name:  "ClientCalendarFilterForm",
  props: ['value'],
  mounted() {
    if (this.can('office.manage')) {
      this.axios.get('office/list').then((response) => this.officeList = response.data);
      this.office = this.user.office_id;
    }
    if (this.can('user.manage')) {
      this.axios.get('user/list').then((response) => this.managerList = response.data);
      this.manager = this.user.id;
    }
    this.update();
  },
  data() {
    return {
      search:      this.value,
      office:      undefined,
      manager:     undefined,
      officeList:  [],
      managerList: []
    }
  },
  watch: {
    office(value) {
      this.search.params.office = value;
      this.update();
    },
    manager(value) {
      if (value) this.search.filter.manager_id = value;
      else delete this.search.filter.manager_id;
      this.update();
    }
  },
  computed: {
    ...mapGetters(['can', 'user'])
  },
  methods: {
    update() {
      this.$emit('input', this.search);
    },
    updateManagerList() {
      this.axios.get('user/list' + (this.office ? '?office_id=' + this.office : '')).then((response) => this.managerList = response.data)
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-row class="mx-1 mb-2">
      <v-col cols="12" lg="3" md="4" sm="6">
        <v-select v-model="statuses" :items="statusList" clearable hide-details item-text="name" item-value="id" label="Статус" multiple/>
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6">
        <v-select v-model="dateNotice" :items="dateNoticeList" clearable hide-details item-text="label" item-value="value" label="Дата выполнения"/>
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6">
        <datetime-utc-input v-model="dateNoticeStart" hide-details label="Начало периода" only-date/>
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6">
        <datetime-utc-input v-model="dateNoticeEnd" hide-details label="Конец периода" only-date/>
      </v-col>
      <v-col v-if="can('office.manage')" cols="12" lg="3" md="4" sm="6">
        <v-autocomplete v-model="office" :items="officeList" clearable hide-details item-text="name" item-value="id" label="Офис" @change="updateManagerList"/>
      </v-col>
      <v-col v-if="can('user.manage')" cols="12" lg="3" md="4" sm="6">
        <v-autocomplete v-model="manager" :items="managerList" clearable hide-details item-text="username" item-value="id" label="Менеджер"/>
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6">
        <v-select v-model="category" :items="categoryList" clearable hide-details item-text="name" item-value="id" label="Категория" multiple/>
      </v-col>
      <v-col>
        <v-switch v-model="favorite" hide-details label="Избранные"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {isEmpty}                from 'lodash';
import {dateUtcDayOffset}       from '@/helpers/dateHelper';
import {mapActions, mapGetters} from 'vuex';
import DatetimeUtcInput         from "@/tags/DatetimeUtcInput.vue";

export default {
  name:       'TaskFilterForm',
  components: {DatetimeUtcInput},
  props:      ['value'],
  mounted() {
    this.axios.get('task-status/list').then((response) => (this.statusList = response.data));
    if (this.can('office.manage')) {
      this.axios.get('office/list').then((response) => (this.officeList = response.data));
      this.office = this.user.office_id;
    }
    if (this.can('user.manage')) {
      this.axios.get('user/list').then((response) => (this.managerList = response.data));
      this.manager = this.user.id;
    }
    this.axios.get('task-category/list').then((response) => {
      this.categoryList = response.data;
    });
    this.filterQuery();
  },
  data() {
    return {
      search:          this.value,
      dateNoticeStart: undefined,
      dateNoticeEnd:   undefined,
      statuses:        [],
      statusList:      [],
      dateNotice:      undefined,
      dateNoticeList:  [
        {
          label: 'На сегодня',
          value: 'today'
        },
        {
          label: 'На завтра',
          value: 'tomorrow'
        },
        {
          label: 'На послезавтра',
          value: 'after_tomorrow'
        },
        {
          label: 'Просрочено',
          value: 'expired'
        }
      ],
      office:       undefined,
      manager:      undefined,
      category:     undefined,
      officeList:   [],
      managerList:  [],
      categoryList: [],
      favorite:     false
    };
  },
  watch: {
    dateNoticeStart(value) {
      this.search.filter.notice_date ||= {};
      if (value) this.search.filter.notice_date.gte = value;
      else delete this.search.filter.notice_date.gte;
      if (isEmpty(this.search.filter.notice_date)) delete this.search.filter.notice_date;
      this.update();
    },
    dateNoticeEnd(value) {
      this.search.filter.notice_date ||= {};
      if (value) this.search.filter.notice_date.lt = value + 3600 * 24;
      else delete this.search.filter.notice_date.lt;
      if (isEmpty(this.search.filter.notice_date)) delete this.search.filter.notice_date;
      this.update();
    },
    statuses(value) {
      if (isEmpty(value)) delete this.search.filter.status_id;
      else this.search.filter.status_id = {in: value};
      this.update();
    },
    dateNotice(value) {
      if (this.search.filter.status_id) {
        delete this.search.filter.status_id.nin;
      }
      switch (value) {
        case 'today':
          this.dateNoticeStart = dateUtcDayOffset();
          this.dateNoticeEnd = dateUtcDayOffset();
          break;
        case 'tomorrow':
          this.dateNoticeStart = dateUtcDayOffset(1);
          this.dateNoticeEnd = dateUtcDayOffset(1);
          break;
        case 'after_tomorrow':
          this.dateNoticeStart = dateUtcDayOffset(2);
          this.dateNoticeEnd = dateUtcDayOffset(2);
          break;
        case 'expired':
          this.dateNoticeStart = undefined;
          this.dateNoticeEnd = dateUtcDayOffset(-1);
          this.search.filter.status_id ||= {};
          this.search.filter.status_id.nin = [
            4,
            5
          ]; // Отказано, Выполнено
          break;
        default:
          this.dateNoticeStart = undefined;
          this.dateNoticeEnd = undefined;
          break;
      }
      if (isEmpty(this.search.filter.status_id)) delete this.search.filter.status_id;
    },
    office(value) {
      this.search.params.office = value;
      this.update();
    },
    manager(value) {
      if (value) this.search.filter.user_id = value;
      else delete this.search.filter.user_id;
      this.update();
    },
    favorite(value) {
      this.search.params.favorite = value ? 1 : 0;
      this.update();
    },
    category(value) {
      if (isEmpty(value)) delete this.search.filter.category_id;
      else this.search.filter.category_id = {in: value};
      this.update();
    },
    '$route.query'() {
      this.filterQuery();
    }
  },
  computed: {
    ...mapGetters([
      'can',
      'user'
    ])
  },
  methods: {
    ...mapActions(['updateNotify']),
    filterQuery() {
      if (this.$route.query.time !== undefined) {
        this.search.filter.notice_date = {
          gte: parseInt(this.$route.query.time)
        }
      } else {
        this.dateNotice = 'today';
      }
      this.updateNotify('task');
      this.update();
    },
    update() {
      this.$emit('input', this.search);
    },
    updateManagerList() {
      this.axios.get('user/list' + (this.office ? '?office_id=' + this.office : '')).then((response) => (this.managerList = response.data));
    }
  }
};
</script>

<style scoped></style>
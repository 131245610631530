<template>
  <modal v-model="modal" :loading="model.loading" :title="id?'Изменение тега':'Создание тега'" max-width="500px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-list class="mb-4 mx-n4" dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Категория:</v-list-item-subtitle>
              <v-list-item-title><h3>{{ tag.category }}</h3></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Поле:</v-list-item-subtitle>
              <v-list-item-title><h3>{{ tag.name }}</h3></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-row>
          <v-col cols="12">
            <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="model.attr.description" :label="model.labels.description" :rules="model.rule('description')"/>
          </v-col>
          <v-col cols="12">
            <v-switch
                v-model="model.attr.editable"
                :false-value="0"
                :label="model.labels.editable"
                :rules="model.rule('editable')"
                :true-value="1"
            />
          </v-col>
          <template v-if="showTransformationForm">
            <v-col cols="12">
              <v-select
                  v-model="typeFormat"
                  :items="transformationsList"
                  :menu-props="{offsetY: true}"
                  item-text="name"
                  item-value="value"
                  label="Форматирование"
              />
              <transformation-tag-form v-if="showTransformationForm" ref="transformationForm" v-model="transformation" :is-new="!!id" :type="typeFormat"/>
            </v-col>
          </template>
          <template v-if="showValues">
            <v-col v-for="(value,i) in model.attr.values" :key="`value_${i}`" cols="12">
              <v-text-field v-model="model.attr.values[i]" :label="`Значение ${i+1}`">
                <template #append-outer>
                  <btn-icon icon="delete" title="Удалить" @click="removeValue(i)"/>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn color="success" @click="addValue()">Добавить значение</v-btn>
            </v-col>
          </template>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal                 from "@/tags/Modal";
import {ContractTag}         from "@/models/ContractTag";
import TransformationTagForm from "@/views/contractFiles/tags/TransformationTagForm.vue";
import BtnIcon               from "@/tags/BtnIcon.vue";


export default {
  name:       "ContractTagForm",
  components: {BtnIcon, TransformationTagForm, Modal},
  props:      ['id', 'tag'],
  data() {
    return {
      modal:          false,
      model:          ContractTag.get(this.id, {
        on: {
          load: () => {
            this.typeFormat     = this.model.attr?.transformation?.type || null;
            this.transformation = this.model.attr.transformation || {};
            if (this.showTransformationForm) {
              this.$nextTick(() => {
                this.$refs.transformationForm.fillPreset(this.transformation?.type);
              });
            }
          },
          save:  () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      typeFormat:     null,
      transformation: {},
    }
  },
  computed: {
    valueType() {
      return this.tag.value_type;
    },
    availableTransformations() {
      const transformations = {
        string: ['string_decline', 'string_regex'],
        number: ['number_text', 'number_format', 'number_money'],
        date:   ['date_format'],
        array:  ['array_implode']
      }
      return transformations[this.valueType] || [];
    },
    transformationsList() {
      const transformationLabels = [
        {
          'name':  'Без форматирования',
          'value': null,
        },
        {
          'name':  'Падеж слова',
          'value': 'string_decline',
        },
        {
          'name':  'Регулярное выражение',
          'value': 'string_regex',
        },
        {
          'name':  'Как текст',
          'value': 'number_text',
        },
        {
          'name':  'Как деньги (текст)',
          'value': 'number_money',
        },
        {
          'name':  'Форматирование',
          'value': 'number_format',
        },
        {
          'name':  'Форматирование',
          'value': 'date_format',
        },
        {
          'name':  'Склейка',
          'value': 'array_implode',
        },
      ]
      return transformationLabels.filter(item => this.availableTransformations.includes(item.value) || item.value === null);
    },
    showTransformationForm() {
      return this.tag.value_type !== 'const' && (this.model.isNew || !this.model.loading);
    },
    showValues() {
      return this.model.attr.type === 'contract_tag';
    },
  },
  watch:    {
    modal(value) {
      if (value) {
        if (this.id) {
          this.model.load();
        } else {
          this.model.clear();
          this.model.fill({
            name:     this.tag.name,
            type:     this.tag.tag.split(':')[0],
            property: this.tag.tag.split(':')[1],
            values:   [],
          });
        }
        this.loadLists();
      }
    },
    transformation(value) {
      this.model.attr.transformation = value;
    }
  },
  methods:  {
    close() {
      this.$refs.form.reset();
      this.modal = false
    },
    loadLists() {
      this.axios.get('lead-type/list').then(response => this.typeList = response.data)
    },
    addValue() {
      this.model.attr.values.push('');
    },
    removeValue(idx) {
      delete this.model.attr.values[idx];
      this.model.attr.values = this.model.attr.values.filter(value => value);
    }
  },

}
</script>

<style scoped>

</style>
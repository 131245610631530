import { render, staticRenderFns } from "./SupportServiceForm.vue?vue&type=template&id=5aa97007&scoped=true"
import script from "./SupportServiceForm.vue?vue&type=script&lang=js"
export * from "./SupportServiceForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aa97007",
  null
  
)

export default component.exports
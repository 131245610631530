<template>
  <modal v-model="modal" :loading="model.loading" :title="'Настройка вопросов: '+(this.model.attr.name || '...')" max-width="1200px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-expansion-panels :disabled="model.loading" accordion class="mt-1 ms-questions-list" focusable multiple>
      <template v-if="hasQuestions">
        <ms-question-item
            v-for="question in questionsList"
            :key="question._id||question.id"
            :question="question"
            :questions="model.attr.questions"
            :show-deleted="showDeleted"
            @remove:question="removeQuestion"
            @update:question="updateQuestion"
            @create:question="createQuestion"
        />
      </template>
      <v-expansion-panel v-else disabled>
        <v-expansion-panel-header hide-actions>Нет вопросов</v-expansion-panel-header>
      </v-expansion-panel>
    </v-expansion-panels>
    <template #actions>
      <v-btn color="primary" @click="close">Закрыть</v-btn>
      <v-btn color="primary" @click="toggleShowDeleted">
        <v-icon class="mr-2" small>mdi-eye{{ showDeleted ? '-off' : '' }}</v-icon>
        {{ showDeleted ? 'Скрыть' : 'Показать' }} удаленные
      </v-btn>
      <v-spacer/>
      <v-btn color="primary" @click="createQuestion">
        <v-icon class="mr-2" small>mdi-plus</v-icon>
        Добавить вопрос
      </v-btn>
    </template>
  </modal>
</template>

<script>
import Modal          from "@/tags/Modal.vue";
import {MsScript}     from "@/models/MsScript";
import MsQuestionItem from "@/views/msScript/MsQuestionItem.vue";

export default {
  name:       "MsQuestionList",
  components: {MsQuestionItem, Modal},
  props:      ['id'],
  data() {
    return {
      modal: false,
      model: MsScript.get(this.id, {
        with: ['questions.answers.filesList'],
        on:   {
          load: () => {
            this.toggleShowDeleted();
          }
        }
      }),
      showDeleted: true
    }
  },
  computed: {
    hasQuestions() {
      return !!this.questionsList.length;
    },
    questionsList() {
      this.showDeleted;
      if (!this.model.attr.questions) {
        return [];
      }
      const questions = [];
      const questionsMap = new Map();

      for (const question of this.model.attr.questions) {
        if (!this.showDeleted && question.deleted_at) {
          continue;
        }
        question.questions = [];
        question.requiredQuestion = null;
        question.required_question_id = +question.required_question_id || null;
        question.required_answer_id = +question.required_answer_id || null;
        if (question.id) {
          questionsMap.set(question.id, question);
        }
      }

      for (const question of this.model.attr.questions) {
        if (!this.showDeleted && question.deleted_at) {
          continue;
        }
        if (question.required_question_id) {
          const parentQuestion = questionsMap.get(question.required_question_id);
          if (parentQuestion) {
            parentQuestion.questions.push(question);
            question.requiredQuestion = parentQuestion;
          }
        } else questions.push(question);
      }

      return questions;
    }
  },
  watch: {
    modal(value) {
      if (value && this.id) {
        this.model.load();
      }
    }
  },
  methods: {
    close() {
      this.model.attr.questions = [];
      this.modal = false;
      this.$emit('save');
    },
    createQuestion(question) {
      let baseQuestion = {
        script_id:            this.id,
        required_answer_id:   null,
        required_question_id: null,
        required:             0,
        multiple:             0,
        answers:              [],
        questions:            [],
        _id:                  Math.random()
      }
      if (question) {
        baseQuestion = {...baseQuestion, ...question};
      }
      this.model.attr.questions.push(baseQuestion)
    },
    removeQuestion(question) {
      const key = question._id ? '_id' : 'id';
      const idx = this.model.attr.questions.findIndex(item => item[key] === question[key]);
      if (idx >= 0) {
        this.model.attr.questions.splice(idx, 1);
      }
    },
    updateQuestion(question) {
      const key = question._id ? '_id' : 'id';
      const idx = this.model.attr.questions.findIndex(item => item[key] === question[key]);
      if (idx >= 0) {
        this.model.attr.questions[idx] = question;
      }
    },
    toggleShowDeleted() {
      setTimeout(() => this.showDeleted = !this.showDeleted, 0);
    }
  }
}
</script>
<style>
.ms-questions-list .v-expansion-panel-content__wrap {
  padding-right: 0;
}

.ms-questions-list .v-expansion-panel-content {
  background-color: #3200000b;
}

.ms-questions-list .v-expansion-panel-header {
  padding: 0 24px;
}


</style>
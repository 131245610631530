<template>
  <div v-if="isEmpty(folder.files)" class="text--secondary mt-4">
    Файлов не найдено
  </div>
  <div v-else class="mt-4 mx-n1 d-flex flex-wrap">
    <v-hover v-for="item in folder.files" :key="item.id">
      <template #default="{ hover }">
        <v-card :elevation="hover?2:0" class="file-card ma-1">
          <a :href="apiUri(item.path)" class="text-decoration-none" target="_blank">
            <v-badge :value="!getPublic(item)" bottom icon="mdi-lock" offset-x="24" offset-y="20">
              <v-icon :color="getColor(item)" class="file-icon">{{ getIcon(item) }}</v-icon>
            </v-badge>
            <v-subheader class="file-name">{{ item.name }}</v-subheader>
          </a>
          <v-menu v-if="can('document.manage')" v-model="showMenu[item.id]" left offset-x>
            <template #activator="{on,attrs}">
              <v-btn v-bind="attrs" v-on="on" absolute class="menu-btn" icon x-small>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group>
                <file-form v-if="!item.deleted_at" :id="item.id" :category="category" :show-deleted="showDeleted" @save="$emit('update')">
                  <v-list-item @click="closeMenu">Изменить</v-list-item>
                </file-form>
                <file-delete v-if="!item.deleted_at" :id="item.id" :name="item.name" @click="closeMenu" @remove="$emit('update')"/>
                <file-restore v-if="item.deleted_at" :id="item.id" :name="item.name" @click="closeMenu" @restore="$emit('update')"/>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-card>
      </template>
    </v-hover>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {isEmpty}    from "lodash";
import {apiUri}     from "@/helpers/stringHelpers";
import FileForm     from "@/views/documents/FileForm";
import FileDelete   from "@/views/documents/FileDelete";
import FileRestore  from "@/views/documents/FileRestore";

export default {
  name:       "FileList",
  components: {FileRestore, FileDelete, FileForm},
  props:      {
    folder: {
      type: Object
    },
    category: {
      type: Number
    },
    showDeleted: {
      type: Number
    }
  },
  data() {
    return {
      showMenu: []
    };
  },
  computed: {
    ...mapGetters(['can'])
  },
  methods: {
    isEmpty,
    apiUri,
    getExt(item) {
      return item.path.split('.').pop();
    },
    getIcon(item) {
      return 'mdi-file-' + {
        pdf:  'document',
        docx: 'document',
        xlsx: 'table'
      }[this.getExt(item)]
    },
    getColor(item) {
      if (item.deleted_at) {
        return 'secondary';
      }
      return {
        pdf:  'red',
        docx: 'blue',
        xlsx: 'green'
      }[this.getExt(item)];
    },
    getPublic(item) {
      if (!this.can('document.manage')) {
        return true;
      }
      return item.public;
    },
    closeMenu() {
      this.showMenu = [];
    }
  }
}
</script>

<style scoped>
.file-card {
  max-width: 128px;
}

.file-icon:before {
  font-size: 128px;
}

.file-name {
  font-size: .75em;
  max-width: 128px;
  text-align: center;
  white-space: break-spaces;
  word-break: break-word;
  margin-bottom: 4px;
  color: #000 !important;
  justify-content: center;
  height: auto;
}

.menu-btn {
  top: 2px;
  right: 2px;
}
</style>
<template>
  <div>
    <v-progress-linear v-if="model.loading" class="mb-n1" indeterminate/>
    <v-form ref="form" :disabled="model.loading" class="pt-4">
      <v-row>
        <template v-if="dailyQuotes.length > 0">
          <v-col v-for="(item,i) in dailyQuotes" :key="i" cols="12">
            <v-text-field :key="'f'+i" v-model="dailyQuotes[i]" :label="'Текст цитаты #'+(i+1)" :loading="model.loading" :rules="model.rule('value')" clearable>
              <template #append-outer>
                <btn-icon color="error" icon="delete" title="Удалить цитату" @click="removeQuote(i)"/>
              </template>
            </v-text-field>
          </v-col>
        </template>
        <template v-else>
          <v-col class="text--secondary" cols="12">
            Цитаты не добавлены
          </v-col>
        </template>
        <v-col cols="12">
          <v-btn :disabled="model.loading" class="mb-2 mr-2" color="primary" @click="sendForm()">{{ $t('btn.save') }}</v-btn>
          <v-btn :disabled="model.loading" class="mb-2 mr-2" color="secondary" @click="addQuote()">Добавить</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import {Form}  from "@/models/Form";
import BtnIcon from "@/tags/BtnIcon";

export default {
  name:       "DailyQuotesForm",
  components: {BtnIcon},
  props:      {
    show: {
      type:    Boolean,
      default: false
    }
  },
  created() {
    this.initValue();
  },
  mounted() {
    this.loadSetting();
  },
  data: () => ({
    model: Form.create({
      uri: {
        addr:   'setting/set',
        params: {name: 'content'}
      },
      attrs: ['value'],
      on:    {
        error: () => {
          this.$refs.form.validate();
        }
      }
    })
  }),
  watch: {
    show(value) {
      if (value) {
        this.loadSetting();
      }
    }
  },
  computed: {
    dailyQuotes: {
      get() {
        return this.model.attr.value.daily_quotes;
      },
      set(value) {
        this.model.attr.value.daily_quotes = value;
      }
    }
  },
  methods: {
    addQuote() {
      this.dailyQuotes.push('');
    },
    removeQuote(i) {
      this.dailyQuotes.splice(i, 1);
    },
    sendForm() {
      this.dailyQuotes = this.dailyQuotes.filter(v => v);
      this.model.send();
    },
    loadSetting() {
      this.initValue();
      this.model.loading = true;
      this.axios.get('setting/get', {params: {name: 'content.daily_quotes'}})
          .then(response => {
            this.model.attr.value.daily_quotes = response.data || [];
            this.model.loading = false;
          }).catch(() => {
        this.model.loading = false;
      })
    },
    initValue() {
      this.model.attr = {value: {daily_quotes: []}}
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <modal v-model="modal" :loading="model.loading" :title="titleText" max-width="800px">
    <template v-slot:activator>
      <slot/>
    </template>

    <ms-result-form-create v-if="currentStage===0" v-model="model.attr" :disabled="model.loading" :scripts="scripts" @input="model.save()"/>
    <ms-result-form-questions v-if="currentStage===1" ref="question" v-model="model.attr" :disabled="model.loading" @input="model.save()" @mounted="questionAvailable = true"/>
    <ms-result-form-files v-if="currentStage===2" ref="file" v-model="model.attr" :disabled="model.loading" :rules="model.rule('selected_files')" @input="model.save()" @mounted="fileAvailable = true"/>


    <ms-result-view v-if="!isChild && !model.isNew" :id="model.attr.id" ref="view" is-child @mounted="viewAvailable = true"/>
    <template #actions>
      <v-btn v-if="showCloseBtn" class="mr-2" color="primary" @click="close">Закрыть</v-btn>
      <v-btn v-if="showBackBtn" :disabled="disableBackBtn" class="mr-2" color="primary" @click="goBack">Назад</v-btn>
      <v-spacer/>
      <v-btn v-if="showSkipBtn" :disabled="disableSkipBtn" color="primary" @click="skip">Пропустить</v-btn>
    </template>
  </modal>
</template>
<script>

import Modal                 from "@/tags/Modal.vue";
import {MsResult}            from "@/models/MsResult";
import MsResultFormCreate    from "@/views/msScript/MsResultFormCreate.vue";
import MsResultFormQuestions from "@/views/msScript/MsResultFormQuestions.vue";
import MsResultFormFiles     from "@/views/msScript/MsResultFormFiles.vue";

export default {
  name:       "MsResultForm",
  components: {MsResultView: () => import('@/views/msScript/MsResultView'), MsResultFormFiles, MsResultFormQuestions, MsResultFormCreate, Modal},
  props:      {
    id: {
      type: Number
    },
    clientId: {
      type: Number
    },
    scripts: {
      type: Array
    },
    isChild: {
      type:    Boolean,
      default: false
    }
  },
  data() {
    return {
      modal: false,
      model: MsResult.get(this.id, {
        with: [
          'script',
          'questions',
          'answersList',
          'answersList.answers',
          'answersList.selected_answers',
          'currentQuestion',
          'currentQuestion.answers',
          'requiredFilesList',
          'filesList',
          'filesList.files',
          'currentFile',
          'relatedFiles',
          'relatedFiles.files'
        ],
        on: {
          load: () => {
            this.$nextTick(() => {
              this.onOpen();
            })
            if (this.currentStage === 1) {
              this.$nextTick(() => this.$refs.question.updateQuestions())
            }
            if (this.currentStage === 2) {
              this.$nextTick(() => this.$refs.file.updateFiles())
            }
          },
          save: () => {
            this.updated = true;
            if (this.currentStage === 1) {
              this.$nextTick(() => this.$refs.question.updateQuestions());
            }
            if (this.currentStage === 2) {
              this.$nextTick(() => this.$refs.file.updateFiles());
            }
          },
          error: () => {
          }
        }
      }),
      updated:           false,
      questionAvailable: false,
      fileAvailable:     false,
      viewAvailable:     false
    }
  },
  computed: {
    showCloseBtn() {
      return this.model.isNew || this.model.status === 2;
    },
    showBackBtn() {
      return [1, 2].includes(this.currentStage);
    },
    disableBackBtn() {
      if (this.currentStage === 1 && this.questionAvailable) {
        return this.$refs.question.disableBackBtn;
      }
      if (this.currentStage === 2 && this.fileAvailable) {
        return this.$refs.file.disableBackBtn;
      }
      return true;
    },
    showSkipBtn() {
      return [1, 2].includes(this.currentStage);
    },
    disableSkipBtn() {
      if (this.currentStage === 1 && this.questionAvailable) {
        return this.$refs.question.disableSkipBtn
      }
      if (this.currentStage === 2 && this.fileAvailable) {
        return this.$refs.file.disableSkipBtn;
      }
      return true;
    },
    currentStage() {
      if (this.model.isNew && !this.id) {
        return 0;
      }
      return this.model.attr.status + 1;
    },
    titleText() {
      return [
        'Анкета для сбора списка документов',
        'Анкета: ' + this.model.attr?.script?.name,
        'Загрузка: ' + this.model.attr?.script?.name,
        'Завершено: ' + this.model.attr?.script?.name
      ][this.currentStage];
    }
  },
  watch: {
    modal(value) {
      if (value) {
        if (this.id) {
          this.model.fill({id: this.id});
          this.model.load();
        } else {
          this.model.clear();
          this.model.fill({client_id: this.clientId});
        }
      } else {
        this.onClose();
      }
    },
    currentStage(newStage, oldStage) {
      if (newStage > 1 && oldStage < newStage && (this.viewAvailable || this.isChild)) {
        this.showView();
      }
    }
  },
  methods: {
    close() {
      this.modal = false;
    },
    onClose() {
      if (this.updated) {
        this.$emit('save');
      }
      this.model.clear();
      this.questionAvailable = false;
      this.fileAvailable = false;
      this.viewAvailable = false;
    },
    open() {
      this.modal = true;
    },
    onOpen() {
      this.$nextTick(() => {
        this.questionAvailable = !!this.$refs.question;
        this.fileAvailable = !!this.$refs.file;
        this.viewAvailable = !!this.$refs.view;
      })
    },
    goBack() {
      if (this.currentStage === 1 && this.questionAvailable) {
        this.$refs.question.goBack();
      }
      if (this.currentStage === 2 && this.fileAvailable) {
        this.$refs.file.goBack();
      }
    },
    skip() {
      if (this.currentStage === 1 && this.questionAvailable) {
        this.$refs.question.skip();
      }
      if (this.currentStage === 2 && this.fileAvailable) {
        this.$refs.file.skip();
      }
    },
    showView() {
      if (this.isChild) {
        this.updated = true;
        this.close();
      } else {
        this.$refs.view.open();
      }
    }
  }
}
</script>
<style scoped>

</style>
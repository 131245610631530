<template>
  <div id="SectionPartners" class="wrap mx-md-n6 pa-0 mt-16">
    <section class="container text--white pa-md-0">
      <v-row justify="center">
        <v-col cols="12" md="11" offset-md="1">
          <div class="partners">
            <div v-for="i in 10" :key="i" class="partner-item d-flex justify-space-between">
              <img :alt="`Partner ${i}`" :src="require(`@/assets/index/partners/${i}.png`)" class="partner-logo"/>
            </div>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
export default {
  name: "SectionPartners"
}
</script>

<style scoped>
.partners {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.partner-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;

  & .partner-logo {
    width: 300px
  }
}

@media (max-width: 600px) {
  .partner-item {
    padding: 8px;
  }
}
</style>
<template>
  <modal v-model="modal" :loading="model.loading" :title="id ? 'Изменение теста' : 'Создание теста'" max-width="1000px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          </v-col>
          <v-col col="12">
            <v-textarea v-model="model.attr.description" :label="model.labels.description" :rules="model.rule('description')"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="timeoutFormatted" :label="model.labels.timeout" :rules="model.rule('timeout')" type="number"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal    from '@/tags/Modal.vue';
import {HrTest} from '@/models/HrTest.ts';

export default {
  name:       'HrTestForm',
  components: {Modal},
  props:      ['id'],
  data() {
    return {
      modal:  false,
      editor: false,
      model:  HrTest.get(this.id, {
        on: {
          save: () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      })
    };
  },
  computed: {
    timeoutFormatted: {
      get() {
        return this.model.attr.timeout / 60 || '';
      },
      set(value) {
        this.model.attr.timeout = value * 60;
      }
    }
  },
  watch: {
    modal(value) {
      if (value) {
        this.id && this.model.load();
        !this.id && this.model.clear();
      }
    },
    testTimeout(value) {
      this.model.attr.timeout = value * 60;
    }
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.modal = false;
    }
  }
};
</script>

<style scoped></style>
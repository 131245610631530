import {Model} from "@/models/Model";


export class DocumentFolder extends Model {
    modelUri = 'document-folder';
    labels = {
        name:   'Название',
        public: 'Для всех'
    }
    fields = [
        'name',
        'public',
        'category'
    ]
}
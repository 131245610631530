<template>
  <v-menu :disabled="$vuetify.breakpoint.mdAndUp" left offset-y>
    <template #activator="{on, attrs}">
      <div v-bind="attrs" v-on="on">
        <div v-if="$vuetify.breakpoint.mdAndUp" class="income-stat">
          <div class="income-stat-item">
            <div class="income-stat-item--title secondary-lite--text">Ваш заработок</div>
            <div class="income-stat-item--content blue--text">{{ formatMoney(incomeSum) }}</div>
          </div>
          <div v-if="showIncomeGoal" class="income-stat-item ml-4">
            <div class="income-stat-item--title secondary-lite--text">{{ nameIncomeGoal }}</div>
            <div :class="`income-stat-item--content ${colorIncomeGoal}--text`">{{ formatMoney(Math.abs(incomeGoal)) }}</div>
          </div>
        </div>
        <span v-else class="mr-n2">
          <btn-icon color="secondary-lite" icon="cash-multiple"/>
        </span>
      </div>
    </template>
    <div class="income-stat income-stat--mobile">
      <div class="income-stat-item">
        <div class="income-stat-item--title secondary-lite--text">Ваш заработок</div>
        <div class="income-stat-item--content blue--text">{{ formatMoney(incomeSum) }}</div>
      </div>
      <div v-if="showIncomeGoal" class="income-stat-item">
        <div class="income-stat-item--title secondary-lite--text">{{ nameIncomeGoal }}</div>
        <div :class="`income-stat-item--content ${colorIncomeGoal}--text`">{{ formatMoney(Math.abs(incomeGoal)) }}</div>
      </div>
    </div>
  </v-menu>
</template>
<script>

import {mapGetters}  from "vuex";
import {formatMoney} from "@/helpers/stringHelpers";
import BtnIcon       from "@/tags/BtnIcon.vue";

export default {
  name:       "IncomeStat",
  components: {BtnIcon},
  data:       () => ({}),
  watch:      {},
  computed:   {
    ...mapGetters(['user', 'isGuest', 'stats']),
    incomeSum() {
      return this.stats?.quarter?.sum || 0;
    },
    incomeGoal() {
      return (this.incomeSum - this.user?.income_goal) || 0;
    },
    showIncomeGoal() {
      return this.user?.income_goal > 0;
    },
    colorIncomeGoal() {
      return this.incomeGoal < 0 ? 'red' : 'green';
    },
    nameIncomeGoal() {
      return this.incomeGoal < 0 ? 'Не хватает до цели' : 'Опережаете цель на';
    }
  },
  methods: {
    formatMoney
  }
}
</script>

<style lang="scss" scoped>
.income-stat {
  line-height: 120%;
  text-align: right;
  font-weight: bold;
  padding: 0 12px;
  display: flex;
  cursor: default;
  white-space: nowrap;

  &.income-stat--mobile {
    flex-direction: column;
    background-color: #fff;
    padding: 16px 0;
    text-align: left;

    & .income-stat-item {
      padding: 8px 16px;
    }
  }


  &-item {
    flex-grow: 1;

    &--title {
      font-size: 12px;
    }
  }
}
</style>
<template>
  <confirm :loading="model.loading" :title="'Вы действительно хотите удалить статус '+name+'?'" color="error" @confirm="model.remove()">
    <btn-icon :title="$t('btn.delete')" color="error" icon="delete"/>
  </confirm>
</template>

<script>
import Confirm      from "@/tags/Confirm";
import BtnIcon      from "@/tags/BtnIcon";
import {DealStatus} from "../../models/DealStatus";

export default {
  name:       "Delete",
  components: {BtnIcon, Confirm},
  props:      ['id', 'name'],
  data() {
    return {
      model: DealStatus.get(this.id, {
        on: {
          remove: () => {
            this.$emit('remove')
          }
        }
      })
    }
  },
}
</script>

<style scoped>

</style>
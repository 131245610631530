<template>
  <v-menu v-model="stat" left offset-y>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" class="mr-2">
        <btn-icon color="secondary-lite" icon="chart-box-outline"/>
      </span>
    </template>
    <div>
      <v-progress-linear v-if="loading" indeterminate/>
      <v-list v-if="!loading" dense min-width="160px">
        <v-list-item v-for="(value,name) in infoParsed" :key="name">
          <span class="mr-2 stat-name">{{ name }}</span>
          <b class="stat-prefix">{{ value.prefix }}</b>
          <b class="stat-value">{{ value.value }}</b>
        </v-list-item>
      </v-list>
      <v-list v-else dense>
        <v-list-item>Загрузка...</v-list-item>
      </v-list>
    </div>
  </v-menu>
</template>

<script>
import BtnIcon      from "../../tags/BtnIcon";
import {mapGetters} from "vuex";

export default {
  name:       "Stat",
  components: {BtnIcon},
  data() {
    return {
      stat:    false,
      loading: false,
      info:    {}
    }
  },
  computed: {
    ...mapGetters(['isGuest']),
    infoParsed() {
      const info = {};
      for (let name in this.info) {
        const [prefix, value] = this.info[name].split(' ');
        info[name] = {prefix, value};
      }
      return info;
    }
  },
  watch: {
    stat(value) {
      if (value) {
        this.loadStat();
      }
    }
  },
  methods: {
    loadStat() {
      if (!this.isGuest) {
        this.loading = true;
        this.axios.get('stat/currency')
            .then(response => {
              this.loading = false;
              this.info = response.data;
            }).catch(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style scoped>
.stat-name {
  width: 60px;
}

.stat-prefix {
  width: 20px;
}

.stat-value {
  text-align: right;
  flex-grow: 1;
}
</style>
interface RequestOptions {
    headers?: object
}

interface SocketOptions extends RequestOptions {
    token?: any
}

interface Response {
    status: number;
    headers: object;
    data: any;
}

export class Socket {

    protected ws: WebSocket;
    protected listeners: Record<string, Function[]> = {};
    protected buffer: string[] = [];
    protected headers: object = {};
    protected token?: any
    protected requests: string[] = [];
    protected retryAfter: number = 300;
    protected subscribes: string[] = [];

    constructor(uri: string, options?: SocketOptions) {
        this.ws = this.connect(uri);
        this.token = options?.token;
        this.headers = options?.headers || {}
    }

    connect(uri: string) {
        let ws = new WebSocket(uri);
        ws.onopen = () => {
            if (this.token) {
                this.sendToken();
            }
            this.sendBuffered();
            this.sendSubscribes();
            this.emit('connect');
        }
        ws.onclose = () => {
            this.subscribes = [];
            this.emit('close');
            setTimeout(() => this.ws = this.connect(uri), this.retryAfter);
        }
        ws.onmessage = (event) => {
            let data = JSON.parse(event.data);
            switch (data.type) {
                case 'event':
                    this.emit(data.event, data.data)
                    break;
                case 'response':
                    this.emit(data.id, data.status, data.headers, data.data)
                    this.off(data.id)
                    delete this.requests[this.requests.indexOf(data.id)];
                    break;
            }
        }
        return ws;
    }


    emit(event: string, ...args: any): this {
        let callbacks = this.listeners[event] || [];
        for (let callback of callbacks) {
            callback(...args)
        }
        return this
    }

    on(event: string, callback: Function): this {
        if (typeof this.listeners[event] === 'undefined') {
            this.listeners[event] = []
        }
        this.listeners[event].push(callback)
        this.subscribe(event);
        return this;
    }

    off(event: string, callback?: Function): this {
        if (typeof this.listeners[event] === 'object') {
            if (callback) {
                let i = this.listeners[event].length;
                while (i--) {
                    if (this.listeners[event][i] == callback) {
                        this.listeners[event].splice(i, 1);
                    }
                }
                if (!this.listeners[event].length) {
                    delete this.listeners[event];
                }
                this.unsubscribe(event);
            } else {
                delete this.listeners[event]
                this.unsubscribe(event);
            }
        }
        return this;
    }

    once(event: string, callback: Function) {
        let fn = (...args: any) => {
            callback(...args)
            this.off(event, fn)
        }
        this.on(event, fn)
        return this;
    }

    canSubscribe(event: string) {
        return ['connect', 'close', 'socket'].indexOf(event) === -1 && this.requests.indexOf(event) === -1 && this.subscribes.indexOf(event) === -1;
    }

    subscribe(event: string) {
        if (this.canSubscribe(event)) {
            this.subscribes.push(event);
            this.send({type: 'subscribe', status: 1, event})
        }
    }

    unsubscribe(event: string) {
        if (this.canSubscribe(event)) {
            this.send({type: 'subscribe', status: 0, event})
        }
    }

    async request(route: string, data?: object, options?: RequestOptions): Promise<Response> {
        let id = btoa(Math.random().toString())
        this.requests.push(id);
        let request = {
            type:    'request',
            id,
            route,
            headers: Object.assign({}, this.headers, options?.headers),
            data:    Object.assign({}, data)
        }

        return new Promise((resolve, reject) => {
            this.on(id, (status: number, headers: object, data: any) => {
                let response: Response = {
                    status,
                    headers,
                    data
                }
                if (Math.floor(status / 100) === 2) {
                    resolve(response);
                } else {
                    reject(response);
                }

            });
            this.send(request);
        });
    }

    sendToken() {
        this.send({type: 'token', data: this.token});
    }

    send(data: any) {
        let message = JSON.stringify(data);
        if (this.ws.readyState == this.ws.OPEN) {
            this.ws.send(JSON.stringify(data));
        } else {
            this.buffer.push(message);
        }
    }

    sendSubscribes() {
        Object.keys(this.listeners).forEach((event) => this.subscribe(event));
    }

    sendBuffered() {
        for (let i = this.buffer.length; i--;) {
            this.buffer.forEach((message) => this.ws.send(message))
            this.buffer = [];
        }
    }

    status() {
        return {
            [this.ws.CLOSED]:     'closed',
            [this.ws.CLOSING]:    'closing',
            [this.ws.CONNECTING]: 'connecting',
            [this.ws.OPEN]:       'open',
        }[this.ws.readyState]
    }



}
<template>
  <v-card-text class="pt-3 text-center">
    <template v-if="isEmptyCurrentQuestion">
      <r-card class="text--disabled pa-4">
        Загрузка данных...
      </r-card>
    </template>
    <template v-else>
      <v-progress-linear :value="progress" class="mb-4" color="primary" height="12" rounded/>
      <h3>{{ currentQuestion.name }}</h3>
      <div class="mb-2 small text--disabled">{{ textInfo }}</div>
      <v-list :disabled="disabled" class="mb-2 transparent text-left" rounded>
        <v-list-item-group v-model="selectedAnswers" :multiple="!!currentQuestion.multiple">
          <v-list-item v-for="answer in currentQuestion.answers" :key="answer.id" :value="answer.id" class="white" color="secondary">
            <template #default="{ active }">
              <v-list-item-content>
                <v-list-item-title class="text-wrap" v-text="answer.name"/>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon :color="active?'primary':'grey'">mdi-check-circle</v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-btn :disabled="disableSelectBtn" color="primary" @click="save">Выбрать</v-btn>
    </template>
  </v-card-text>
</template>
<script>


import RCard from "@/tags/RCard.vue";

export default {
  name:       "MsResultFormQuestions",
  components: {RCard},
  props:      {
    value: {
      type:     Object,
      required: true
    },
    disabled: {
      type:    Boolean,
      default: false
    }
  },
  mounted() {
    this.$emit('mounted');
  },
  data() {
    return {
      questions:       [],
      currentQuestion: {},
      answersList:     [],
      selectedAnswers: undefined
    }
  },
  computed: {
    questionsList() {
      const questions = [];
      const questionMap = new Map();

      for (const question of this.questions) {
        question.questions = [];
        questionMap.set(question.id, question);
      }

      for (const question of this.questions) {
        const parentQuestion = questionMap.get(question.required_question_id);
        if (parentQuestion) {
          parentQuestion.questions.push(question);
        } else if (!question.required_question_id) {
          questions.push(question);
        }
      }
      return questions;
    },
    isEmptyCurrentQuestion() {
      return !this.currentQuestion || Object.keys(this.currentQuestion).length === 0;
    },
    prevQuestion() {
      if (this.answersList.length > 0) {
        const answerIdx = this.answersList.findIndex(question => question.id === this.currentQuestion.id);
        if (answerIdx > 0) {
          return this.answersList[answerIdx - 1];
        }
        if (answerIdx === 0) {
          return false;
        }
        return this.answersList.slice(-1)[0];
      }
      return false;
    },
    textInfo() {
      return this.currentQuestion.multiple ?
             'Выберите один или несколько ответов' :
             'Выберите один из ответов';
    },
    isSelectedAnswer() {
      return (Array.isArray(this.selectedAnswers) ? this.selectedAnswers.length > 0 : !!this.selectedAnswers);
    },
    disableSelectBtn() {
      return !this.isSelectedAnswer
    },
    disableBackBtn() {
      return !this.prevQuestion;
    },
    disableSkipBtn() {
      return !!this.currentQuestion.required;
    },
    progress() {
      const path = this.findIndexPath(this.questionsList, this.currentQuestion);
      let progress = 0;
      let part = 1;
      let prevPath;
      for (let i in path) {
        let curPath = path[i];
        if (prevPath) {
          progress += part / (curPath.length + 1)
        }
        part = part / (curPath.length + (prevPath ? 1 : 0));
        progress += curPath.index * part;
        prevPath = curPath;
      }
      return progress * 100;
    }
  },
  watch:   {},
  methods: {
    save() {
      const selectedAnswers = this.currentQuestion.multiple ? this.selectedAnswers : (this.isSelectedAnswer ? [this.selectedAnswers] : null);
      const value = {...this.value, ...{action: 'add', question_id: this.currentQuestion.id, selected_answers: selectedAnswers}};
      this.$emit('input', value);
    },
    updateQuestions() {
      this.questions = [...this.value.questions];
      this.currentQuestion = {...this.value.currentQuestion};
      this.answersList = [...this.value.answersList];
      this.selectedAnswers = undefined;
    },
    goBack() {
      if (!this.prevQuestion) {
        return;
      }
      this.currentQuestion = this.prevQuestion;
      const selectedAnswers = this.currentQuestion.selected_answers.map(answer => answer.id);
      this.selectedAnswers = this.currentQuestion.multiple ? selectedAnswers : selectedAnswers[0];
    },
    skip() {
      this.selectedAnswers = null;
      this.save();
    },
    findIndexPath(questions, currentQuestion) {
      const idx = questions.findIndex(question => question.id === currentQuestion.id);
      if (idx > -1) {
        return [{index: idx, length: questions.length}];
      }
      for (let idx in questions) {
        const question = questions[idx];
        if (question.questions.length === 0) {
          continue
        }
        let path = this.findIndexPath(question.questions, currentQuestion);
        if (path.length === 0) {
          continue;
        }
        return [{index: +idx, length: questions.length}, ...path];
      }
      return [];
    }
  }
}
</script>
<style scoped>

</style>
<template>
  <confirm :loading="model.loading" :title="'Вы действительно хотите удалить файл ' + name + '?'" color="error" @confirm="model.remove()">
    <v-list-item @click="$emit('click')">{{ $t('btn.delete') }}</v-list-item>
  </confirm>
</template>

<script>
import Confirm        from '@/tags/Confirm.vue';
import {DocumentFile} from "@/models/DocumentFile";

export default {
  name:       'FileDelete',
  components: {Confirm},
  props:      [
    'id',
    'name'
  ],
  data() {
    return {
      model: DocumentFile.get(this.id, {
        on: {
          remove: () => {
            this.$emit('remove');
          }
        }
      })
    };
  }
};
</script>

<style scoped></style>
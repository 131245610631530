<template>
  <v-list-item :inactive="!selectable" :value="file.id" class="pr-2 px-4" color="secondary">
    <template #default="{active}">
      <v-list-item-icon class="mr-2">
        <v-icon color="secondary">{{ icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium">{{ name }}</v-list-item-title>
        <v-list-item-subtitle>{{ created }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action class="flex-row">
        <btn-icon :href="src" color="secondary" icon="download" target="_blank" title="Скачать" @click.native.stop/>
        <btn-icon v-if="selectable" :icon="active?'checkbox-outline':'checkbox-blank-outline'" color="primary"/>
      </v-list-item-action>
    </template>
  </v-list-item>
</template>
<script>
import {apiUri} from "@/helpers/stringHelpers";
import BtnIcon  from "@/tags/BtnIcon.vue";

export default {
  name:       "FileItem",
  components: {BtnIcon},
  props:      {
    file: {
      type:     Object,
      required: true
    },
    selectable: {
      type:    Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    ext() {
      return this.file.path.split('.').pop();
    },
    name() {
      const match = this.file.path.match(/_(\d+)(\.[^.]+)?$/);
      const number = match ? parseInt(match[1]) : 0;
      return `${this.file.name}${number ? ` (${number})` : ``}.${this.ext}`;
    },
    created() {
      return this.$d(this.file.created_at * 1000, 'datetime');
    },
    src() {
      return apiUri(this.file.path);
    },
    icon() {
      const icons = {
        'mdi-image-outline': ['jpg', 'jpeg', 'png']
      }
      for (const icon in icons) {
        if (icons[icon].includes(this.ext)) {
          return icon;
        }
      }
      return 'mdi-file-document-outline';
    }
  },
  methods: {}
}
</script>
<style scoped>

</style>
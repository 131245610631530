import {Model} from "@/models/Model";
import Vue     from "vue";


export class HrResult extends Model {
    modelUri = 'hr-result';
    labels = {
        office_id: 'Офис',
        user_id:   'Менеджер',
        test_id:   'Тест'
    }
    fields = ['user_id', 'test_id']

    questions = {}

    loadQuestions() {
        if (this.attr.id) {
            this.loading = true;
            Vue.axios.get('hr-result/question?id=' + this.attr.id + '&_=' + Math.random())
                .then((response) => {
                    this.questions = response.data;
                    this.loading = false;
                    this.emit('question');
                })
                .catch((error) => {
                    console.log(error);
                    this.questions = {message: 'Что-то пошло не так'}
                    this.loading = false;
                })
        }
    }

    sendAnswer(answer_id: number) {
        if (this.attr.id) {
            this.loading = true;
            Vue.axios.post('hr-result/answer?id=' + this.attr.id, {answer_id})
                .then(() => {
                    this.loading = false;
                    this.emit('answer')
                })
                .catch(() => {
                    this.loading = false
                })
        }
    }
}
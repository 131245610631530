<template>
  <v-list-item>
    <v-list-item-icon class="mr-2">
      <v-tooltip v-model="showTooltip" :open-on-focus="false" :open-on-hover="false" top>
        Скопировано
        <template #activator="{on}">
          <v-icon color="secondary" v-on="on">mdi-content-copy</v-icon>
        </template>
      </v-tooltip>
    </v-list-item-icon>
    <v-list-item-content @click="copy">
      <v-list-item-title class="font-weight-medium" v-text="url"/>
      <v-list-item-subtitle v-text="description"/>
    </v-list-item-content>
    <v-list-item-action class="flex-row">
      <btn-icon v-if="showShare" :disabled="disabled" color="primary" icon="share-outline" title="Поделиться" @click.stop="share"/>
      <btn-icon :disabled="disabled" color="error" icon="delete" title="Удалить" @click.stop="remove"/>
    </v-list-item-action>
  </v-list-item>
</template>
<script>

import {apiUri} from "@/helpers/stringHelpers";
import BtnIcon  from "@/tags/BtnIcon.vue";

export default {
  name:       "MsResultLinkItem",
  components: {BtnIcon},
  props:      {
    id: {
      type:     Number,
      required: true
    },
    link: {
      type:     Object,
      required: true
    },
    disabled: {
      type:    Boolean,
      default: false
    }
  },
  data() {
    return {
      showTooltip: false
    };
  },
  computed: {
    url() {
      return apiUri('ms-result/download?' + new URLSearchParams({id: this.id, key: this.link.key}));
    },
    description() {
      let expired = this.link.expired_at === null ? 'Однорозовая' : this.link.expired_at < 0 ? 'Бессрочная' : this.$d(this.link.expired_at * 1000, 'datetime');
      return `Действительна до: ${expired}; Cкачиваний: ${this.link.counter}`;
    },
    showShare() {
      return navigator.share;
    }
  },
  methods: {
    remove() {
      this.$emit('remove', this.link);
    },
    copy() {
      this.showTooltip = true;
      setTimeout(() => this.showTooltip = false, 2000);
      this.$emit('copy', this.link);
    },
    share() {
      this.$emit('share', this.link);
    }
  }

}
</script>
<style scoped>

</style>
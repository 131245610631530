<template>
    <confirm :loading="model.loading" :title="'Вы действительно хотите восстановить тест ' + name + '?'" color="accent" @confirm="model.restore()">
        <btn-icon :title="$t('btn.restore')" color="accent" icon="delete-off"/>
    </confirm>
</template>

<script>
import Confirm  from '@/tags/Confirm';
import BtnIcon  from '@/tags/BtnIcon';
import {HrTest} from '@/models/HrTest.ts';

export default {
    name:       'Restore',
    components: {BtnIcon, Confirm},
    props:      ['id', 'name'],
    data() {
        return {
            model: HrTest.get(this.id, {
                on: {
                    restore: () => {
                        this.$emit('restore');
                    }
                }
            })
        };
    }
};
</script>

<style scoped></style>
<template>
  <span>
    <confirm :loading="model.loading" :title="$t('user.confirmDelete',{name})" color="error" @confirm="modal = true">
      <btn-icon :title="$t('btn.delete')" color="error" icon="delete"/>
    </confirm>
    <modal v-model="modal" :loading="loading" title="Передача клиентов">
      <v-card-text class="pt-3">
        <v-form :disabled="loading">
          <v-row>
            <v-col cols="12">
              <v-autocomplete v-model="manager" :items="managerList" item-text="username" item-value="id" label="Выберите менеджера"/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <template v-slot:actions>
        <v-btn :disabled="loading" color="primary" @click="moveClients()">Передать</v-btn>
      </template>
    </modal>
  </span>
</template>

<script>
import Confirm      from "@/tags/Confirm";
import BtnIcon      from "@/tags/BtnIcon";
import {User}       from "@/models/User";
import Modal        from "@/tags/Modal";
import {mapGetters} from "vuex";


export default {
  name:       "Delete",
  components: {BtnIcon, Confirm, Modal},
  props:      ['id', 'name'],
  data() {
    return {
      modal:       false,
      loading:     false,
      model:       User.get(this.id, {
        on: {
          remove: () => {
            this.$emit('remove');
            this.loading = false
            this.modal   = false;
          },
          load:   () => {
            this.loading = false;
            this.axios.get('user/list?office_id=' + this.model.attr.office_id)
                .then((response) => {
                  this.managerList = response.data.filter((item) => {
                    return item.id !== this.id
                  })

                  if (this.managerList.length === 0) {
                    this.modal = false;
                    this.model.remove();
                  }
                })
          }
        }
      }),
      manager:     undefined,
      managerList: [],
    }
  },
  watch:    {
    modal(value) {
      if (value) {
        this.model.load();
        this.loading = true
      }
    }
  },
  computed: {
    ...mapGetters(['can'])
  },
  methods:  {
    moveClients() {
      this.loading = true
      this.axios.post('user/move-client?id=' + this.id, {new_id: this.manager})
          .then(() => this.model.remove()).catch(() => this.loading = false);
    }
  }
}
</script>

<style scoped>

</style>
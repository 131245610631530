<template>
  <v-app>
    <auth-service @login="afterLogin" @logout="afterLogout"/>
    <route-service ref="route"/>
    <app-bar/>
    <v-main>
      <v-container ref="container" class="fill-height px-0 pt-4 pb-0 pa-md-6" fluid>
        <div class="fill-width fill-height">
          <router-view/>
        </div>
      </v-container>
    </v-main>
    <foot-bar/>
  </v-app>
</template>

<script>
import AppBar       from "@/layouts/AppBar";
import {mapGetters} from "vuex";
import '@/assets/scrollbar.module.scss'
import '@/assets/style.scss'
import '@/assets/fonts/matefialdesign.css';
import FootBar      from "@/layouts/FootBar";
import AuthService  from "@/services/AuthService.vue";
import RouteService from "@/services/RouteService.vue";
import {debounce}   from "lodash";


export default {
  name:       'App',
  components: {
    RouteService,
    AuthService,
    FootBar,
    AppBar
  },
  mounted() {
    this.setBackground();
    this.calcDvh();
    window.addEventListener('resize', debounce(() => {
      this.calcDvh();
    }, 100));
  },
  watch:    {
    'user.background'() {
      this.setBackground();
    },
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods:  {
    setBackground() {
      this.$refs.container.style.backgroundImage = this.user?.background ? 'url(' + require('@/assets/bg/' + this.user.background) + ')' : 'none';
      this.$refs.container.style.backgroundSize = 'cover';
    },
    afterLogin() {
      this.$refs.route.afterLogin();
    },
    afterLogout() {
      this.$refs.route.afterLogout();
    },
    calcDvh() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }
};
</script>
<style>
main {
  background-color: #fff;
}

.fill-width {
  width: 100%;
  align-self: start;
}

</style>
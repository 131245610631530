<template>
  <v-expansion-panel :readonly="editable || model.isNew" :style="{opacity}" @change="expanded = !expanded">
    <v-expansion-panel-header :hide-actions="model.isNew">
      <v-form ref="form" :disabled="model.loading" class="d-flex align-center" @submit.prevent.stop="save">
        <v-icon :color="model.isNew?'grey':'secondary'" class="mr-2">mdi-chat-question-outline</v-icon>
        <template v-if="!editable">
          <div :class="{'font-weight-bold':expanded}" class="w-100 mr-2 py-2">
            {{ model.attr.name }}
          </div>
          <span class="mr-2">
            <v-badge :content="answersList.length" :value="hasAnswers" bordered color="grey darken-2" offset-x="16px" offset-y="16px">
              <btn-icon
                  :color="answersStatusColor"
                  :title="hasAnswers?'Ответов: '+answersList.length:'Необходимо добавить ответы'"
                  icon="chat-alert-outline"
              />
            </v-badge>
          </span>
          <span v-if="questionsCount" class="mr-2">
            <v-badge :content="questionsCount" bordered color="grey darken-2" offset-x="16px" offset-y="16px">
              <btn-icon
                  :title="'Доп. вопросов: '+questionsCount"
                  color="secondary"
                  icon="chat-question-outline"
              />
            </v-badge>
          </span>
          <span class="mr-6"/>
        </template>
        <v-text-field
            v-else
            ref="name"
            v-model="model.attr.name"
            :label="model.labels.name"
            :rules="model.rule('name')"
            class="mr-2"
        />
        <span class="mr-2">
          <switch-btn-icon
              v-model="model.attr.required"
              :false-value="0"
              :readonly="!editable"
              :true-value="1"
              false-title="Не обязательный"
              icon="asterisk"
              true-title="Обязательный"
          />
        </span>
        <span class="mr-2">
          <switch-btn-icon
              v-model="model.attr.multiple"
              :false-value="0"
              :readonly="!editable"
              :true-value="1"
              false-title="Один ответ"
              icon="list-box-outline"
              true-color="blue"
              true-title="Несколько ответов"
          />
        </span>

        <div class="mr-6"/>

        <template v-if="editable">
          <span class="mr-2">
            <btn-icon :loading="model.loading" color="success" icon="content-save" title="Сохранить" @click.stop="save"/>
          </span>
          <span class="mr-2">
            <btn-icon color="error" icon="cancel" title="Отменить" @click.stop="cancel"/>
          </span>
        </template>
        <template v-else>
          <span class="mr-2">
            <btn-icon v-if="canEdit" :disabled="model.loading" color="primary" icon="pencil" title="Изменить" @click.stop="edit"/>
          </span>
          <span class="mr-2">
            <btn-icon v-if="!model.attr.deleted_at" :loading="model.loading" color="error" icon="delete" title="Удалить" @click.stop="remove"/>
            <btn-icon v-else :loading="model.loading" color="accent" icon="delete-off" title="Восстановить" @click.stop="restore"/>
          </span>
        </template>
      </v-form>
    </v-expansion-panel-header>

    <v-expansion-panel-content v-if="!model.isNew" class="pt-4">
      <div class="d-flex align-center">
        <h4>Ответы</h4>
        <v-spacer/>
        <btn-icon v-if="canEdit" class="mr-5" color="success" icon="plus" title="Добавить ответ" @click="createAnswer()"/>
      </div>
      <v-expansion-panels accordion multiple>
        <template v-if="hasAnswers">
          <ms-answer-item
              v-for="answer in answersList"
              :key="answer._id||answer.id"
              :answer="answer"
              :disabled="!canEdit"
              :questions="questions"
              :show-deleted="showDeleted"
              @update:answer="updateAnswer"
              @remove:answer="removeAnswer"
              @remove:question="removeQuestion"
              @update:question="updateQuestion"
              @create:question="createQuestion"
          />
        </template>
        <v-expansion-panel v-else disabled>
          <v-expansion-panel-header hide-actions>Нет ответов</v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="my-6"/>

      <div class="d-flex align-center">
        <h4>Дополнительные вопросы</h4>
        <v-spacer/>
        <btn-icon v-if="canEdit" class="mr-5" color="success" icon="plus" title="Добавить вопрос" @click="createQuestion()"/>
      </div>
      <v-expansion-panels accordion multiple>
        <template v-if="hasQuestions">
          <ms-question-item
              v-for="question in questionsList"
              :key="question._id||question.id"
              :disabled="!canEdit"
              :question="question"
              :questions="questions"
              :show-deleted="showDeleted"
              @remove:question="removeQuestion"
              @update:question="updateQuestion"
              @create:question="createQuestion"
          />
        </template>
        <v-expansion-panel v-else disabled>
          <v-expansion-panel-header hide-actions>Нет дополнительных вопросов</v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>

import {MsQuestion}  from "@/models/MsQuestion";
import SwitchBtnIcon from "@/tags/SwitchBtnIcon.vue";
import BtnIcon       from "@/tags/BtnIcon.vue";

export default {
  name:       "MsQuestionItem",
  components: {MsAnswerItem: () => import('@/views/msScript/MsAnswerItem'), BtnIcon, SwitchBtnIcon},
  props:      ['question', 'questions', 'disabled', 'showDeleted'],
  mounted() {
    if (this.model.isNew) {
      this.editable = true;
      setTimeout(() => this.$refs.name.focus(), 0);
    }
  },
  data() {
    return {
      model: new MsQuestion(this.question, {
        on: {
          save: () => {
            this.editable = false;
            this.model.fill({
              required: +this.model.attr.required,
              multiple: +this.model.attr.multiple
            })
            this.$emit('update:question', this.model.attr);
            this.oldQuestion = this.model.attr;
          },
          error: () => {
            this.$refs.form.validate();
          },
          remove: () => {
            this.model.fill({deleted_at: 1});
            this.$emit('update:question', this.model.attr);
          },
          restore: () => {
            this.model.fill({deleted_at: null});
            this.$emit('update:question', this.model.attr);
          }
        }
      }),
      expanded:    false,
      editable:    false,
      oldQuestion: this.question
    }
  },
  computed: {
    opacity() {
      return this.model.attr.deleted_at ? 0.6 : 1;
    },
    hasAnswers() {
      this.showDeleted;
      return !!this.answersList.length;
    },
    hasQuestions() {
      this.showDeleted;
      return !!this.questionsList.length;
    },
    answersList() {
      this.showDeleted;
      const answersMap = new Map();
      const answers = this.model.attr.answers
          .filter(answer => this.showDeleted || (!this.showDeleted && !answer.deleted_at))
          .map(answer => {
            answer.questions = [];
            answer.question = this.question;
            if (answer.id) {
              answersMap.set(answer.id, answer);
            }
            return answer;
          });

      for (const question of this.model.attr.questions) {
        if (!this.showDeleted && question.deleted_at) {
          continue;
        }
        const parentAnswer = answersMap.get(question.required_answer_id);
        if (parentAnswer) {
          parentAnswer.questions.push(question);
        }
      }

      return answers;
    },
    questionsList() {
      this.showDeleted;
      return this.model.attr.questions.filter(question => !question.required_answer_id
          && (this.showDeleted || (!this.showDeleted && !question.deleted_at)));
    },
    questionsCount() {
      this.showDeleted;
      return this.questionCountRecursive(this.model.attr)
    },
    answersStatusColor() {
      this.showDeleted;
      const colors = [
        'success',
        'warning',
        'error'
      ]
      return colors[this.answersStatusRecursive(this.model.attr)];
    },
    canEdit() {
      return !this.disabled && !this.model.attr.deleted_at;
    }
  },
  watch: {
    questions() {
      this.model.fill(this.question);
    },
    showDeleted() {
      this.updateQuestion(this.model.attr);
    }
  },
  methods: {
    save() {
      this.model.save();
    },
    cancel() {
      setTimeout(() => this.editable = false, 0);
      this.model.isNew ? this.$emit('remove:question', this.oldQuestion) : this.model.fill(this.oldQuestion);
    },
    edit() {
      this.editable = true;
      setTimeout(() => this.$refs.name.focus(), 0);
    },
    remove() {
      this.model.remove();
    },
    restore() {
      this.model.restore();
    },
    createAnswer() {
      this.model.attr.answers.push({
        question_id: this.model.attr.id,
        filesList:   [],
        _id:         Math.random()
      })
    },
    removeAnswer(answer) {
      const key = answer._id ? '_id' : 'id';
      const idx = this.model.attr.answers.findIndex(item => item[key] === answer[key]);
      if (idx >= 0) {
        this.model.attr.answers.splice(idx, 1);
        this.$emit('update:question', this.model.attr);
      }
    },
    updateAnswer(answer) {
      const key = answer._id ? '_id' : 'id';
      const idx = this.model.attr.answers.findIndex(item => item[key] === answer[key]);
      if (idx >= 0) {
        this.model.attr.answers[idx] = answer;
        this.$emit('update:question', this.model.attr);
      }
    },
    removeQuestion(question) {
      this.$emit('remove:question', question);
    },
    updateQuestion(question) {
      this.$emit('update:question', question);
    },
    createQuestion(question) {
      this.$emit('create:question', question || {required_question_id: this.model.attr.id});
    },
    questionCountRecursive(question) {
      let count = question.questions.length;
      if (count) {
        for (const nestedQuestion of question.questions) {
          count += this.questionCountRecursive(nestedQuestion);
        }
      }
      return count;
    },
    answersStatusRecursive(question) {
      if (!question.answers.length) {
        return 2;
      }
      for (const nestedQuestion of question.questions) {
        if (this.answersStatusRecursive(nestedQuestion)) {
          return 1;
        }
      }
      return 0;
    }
  }
}
</script>
<style scoped>
.w-100 {
  width: 100%;
}
</style>
import {Model} from "@/models/Model";

export class Video extends Model {
    modelUri = 'video';
    labels = {
        name:        'Название',
        url:         'Ссылка',
        public:      'Для всех',
        fixed:       'Закреплено',
        description: 'Описание',
        category_id: 'Категория'
    }

    fields = [
        'name',
        'url',
        'public',
        'category_id',
        'fixed',
        'description',
    ];
}
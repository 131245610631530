import {Model} from '@/models/Model';

export class Bank extends Model {
    modelUri = 'bank';
    labels = {
        name:              'Название банка',
        agent:             'ФИО представителя',
        agent_photo:      'Фото представителя',
        agent_photo_file: 'Фото представителя',
        email:             'Электронная почта',
        phone:             'Телефон',
        extra_email:      'Доп. электронная почта',
        extra_phone:      'Доп. телефон',
        address:           'Адрес',
        url_site:         'Сайт',
        url_pa:            'Личный кабинет',
        description:       'Описание',
        logo:              'Логотип',
        logo_file:         'Логотип',
        view:              'Виден всем',
        commission_reward: 'КВ',
        type_ids:          'Типы лидов'
    };
    fields = [
        'name',
        'agent',
        'agent_photo_file',
        'email',
        'phone',
        'extra_email',
        'extra_phone',
        'address',
        'url_site',
        'url_pa',
        'description',
        'view',
        'logo_file',
        'commission_reward',
        'uploaded_files',
        'action',
        'type_ids',
    ];
}
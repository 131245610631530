<template>
  <section id="SectionFunctional" class="container font-weight-medium pa-0 mt-16">
    <v-row>
      <v-col cols="12" md="5" offset-md="1">
        <div class="screen">
          <img alt="Screenshot" src="@/assets/index/functional-screen.png"/>
        </div>
      </v-col>
      <v-col cols="12" md="5">
        <p class="pa-3 pa-md-0">
          <span class="span-accent">Функционал</span>
        </p>
        <ul class="info-list pa-3 pa-md-0">
          <li>
            Таблица расчета эффективности менеджера
          </li>
          <li>
            Удобная система фильтрации клиентов
          </li>
          <li>
            Сервис по проверке клиентов
          </li>
          <li>
            Встроенный скрипт в карточке
          </li>
          <li>
            Автоматическое формирование документов по услугам для работы с клиентами
          </li>
          <li>
            Встроенный расширенный калькулятор
          </li>
        </ul>
        <div class="px-3 px-md-0 mt-2">
          <v-btn :block="$vuetify.breakpoint.mobile" :to="{name:'Registration'}" color="primary" outlined>Зарегистрироваться</v-btn>
        </div>
      </v-col>
    </v-row>
  </section>
</template>
<script>

export default {
  name: "SectionFunctional"
}
</script>


<style scoped>
.screen img {
  width: 100%;
}
</style>
<template>
  <span>
  <v-dialog
      v-model="show"
      max-width="500px"
      persistent
  >
    <template v-slot:activator="{on,attrs}">
      <span v-if="disabled" v-bind="attrs">
      <slot/>
      </span>
      <span v-else v-bind="attrs" v-on="on">
      <slot/>
      </span>
    </template>
    <v-card>
      <v-card-title class="word-break">{{ title }}</v-card-title>
      <v-progress-linear v-if="loading" class="mb-n1" indeterminate/>
      <v-card-actions>
        <v-spacer/>
        <v-btn :disabled="loading" @click="close()">{{ $t('btn.close') }}</v-btn>
        <v-btn :color="color" :disabled="loading" @click="confirm()">{{ $t('btn.confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </span>
</template>

<script>
export default {
  name:  "Confirm",
  props: {
    title:   {
      type:    String,
      default: 'title'
    },
    color:   {
      type:    String,
      default: 'primary'
    },
    loading: {
      type:    Boolean,
      default: false
    },
    disabled: {
      type:    Boolean,
      default: false,
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    show: {
      get() {
        return this.dialog || this.loading;
      },
      set(value) {
        this.dialog = value;
      }
    }
  },
  methods:  {
    close() {
      this.dialog = false;
    },
    confirm() {
      this.$emit('confirm');
      this.close();
    },
    open() {
      this.dialog = true;
    }
  }
}
</script>

<style scoped>
.word-break {
  word-break: break-word;
}
</style>
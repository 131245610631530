import Vue                  from 'vue';
import Vuex, {StoreOptions} from 'vuex';
import {dateUtcDayOffset}   from "@/helpers/dateHelper";

Vue.use(Vuex);

const states: StoreOptions<any> = {
    state:     {
        authKey:       localStorage.getItem('authKey') || '',
        identity:      JSON.parse(localStorage.getItem('identity') || 'null'),
        notify:        JSON.parse(localStorage.getItem('notify') || '[]'),
        setting:       JSON.parse(localStorage.getItem('setting') || '[]'),
        session:    JSON.parse(localStorage.getItem('session') || 'null'),
        notifyTimeout: 0,
        stats:         JSON.parse(localStorage.getItem('stats') || '[]'),
        keepActive: false,
        lockActive: false,
        clientId:   undefined,
        socketId:   undefined,
        lastRoute:  JSON.parse(localStorage.getItem('lastRoute') || 'null'),
    },
    getters:   {
        user(state: any) {
            return state.identity?.user || {};
        },
        role(state: any) {
            return state.identity?.role || {};
        },
        authKey(state: any) {
            return state.authKey;
        },
        permissions(state: any) {
            return state.identity?.permissions || {};
        },
        office(state: any) {
            return state.identity?.office || {};
        },
        session(state: any) {
            return state.session;
        },
        isGuest(state: any) {
            return !state.identity;
        },
        can(state: any): Function {
            const permissions = state.identity?.permissions || [];
            return (perm: string) => {
                if (perm === '?' && !state.identity) {
                    return true;
                }
                if (perm === '@' && state.identity) {
                    return true;
                }
                return permissions.includes(perm);
            };
        },
        notifyData(state: any) {
            return state.notify;
        },
        notifyCount(state: any) {
            let count = 0;
            for (let category in state.notify) {
                count += parseInt(state.notify[category]?.count);
            }
            return count;
        },
        stats(state: any) {
            return state.stats;
        },
        setting(state: any) {
            return state.setting;
        },
        keepActive(state: any) {
            return state.keepActive;
        },
        lockActive(state: any) {
            return state.lockActive;
        },
        expiredAt(state: any) {
            return state.identity?.expireda_at || 0;
        },
        clientId(state: any) {
            return state.clientId
        },
        socketId(state: any) {
            return state.socketId;
        },
        lastRoute(state: any) {
            return state.lastRoute
        }
    },
    mutations: {
        setUser(state: any, identity: any) {
            state.identity = identity;
            localStorage.setItem('identity', JSON.stringify(identity));
        },
        setAuthKey(state: any, authKey: any) {
            state.authKey = authKey;
            localStorage.setItem('authKey', authKey);
            Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + authKey;
        },
        updateAuthKey(state: any) {
            const authKey = localStorage.getItem('authKey') || state.authKey || '';
            state.authKey = authKey;

            localStorage.setItem('authKey', authKey);
            if (authKey) {
                Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + authKey;
                return true;
            } else {
                delete Vue.axios.defaults.headers.common['Authorization'];
                return false;
            }
        },
        setSession(state: any, session: any) {
            if (!session) return;
            if (!state.session || state.session.id === session.id) {
                state.session = session;
                localStorage.setItem('session', JSON.stringify(session));
            }
        },
        clearUser(state: any) {
            state.identity = null;
            state.authKey = '';
            state.session = null;
            localStorage.setItem('authKey', '');
            localStorage.setItem('identity', 'null');
            localStorage.setItem('session', 'null');
            delete Vue.axios.defaults.headers.common['Authorization'];
        },
        setNotify(state: any, data: any) {
            state.notify = data;
            localStorage.setItem('notify', JSON.stringify(state.notify));
        },
        setNotifyTimeout(state: any, timeout: number) {
            state.notifyTimeout = timeout;
        },
        setStats(state: any, stats: any) {
            state.stats = stats;
            localStorage.setItem('stats', JSON.stringify(stats));
        },
        setSetting(state: any, setting: any) {
            state.setting = setting;
            localStorage.setItem('setting', JSON.stringify(setting));
        },
        setKeepActive(state: any, keepActive: boolean) {
            state.keepActive = keepActive;
        },
        setLockActive(state: any, lockActive: boolean) {
            state.lockActive = lockActive;
        },
        setSocket(state: any, data: { clientId: any, socketId: any }) {
            state.clientId = data.clientId;
            state.socketId = data.socketId;
            Vue.axios.defaults.headers.common['X-Client-Id'] = data.clientId;
            Vue.axios.defaults.headers.common['X-Socket-Id'] = data.socketId;
        },
        setLastRoute(state: any, lastRoute: any) {
            lastRoute = lastRoute ? {name: lastRoute.name} : undefined;
            state.lastRoute = lastRoute;
            localStorage.setItem('lastRoute', JSON.stringify(lastRoute));
        }
    },
    actions:   {
        login({commit}: any, data: { user: object, callback?: Function }) {
            commit('setUser', data.user);
            if (data.callback) data.callback();
        },
        logout({state, commit}: any, callback?: Function) {
            commit('clearUser');
            if (callback) callback();
        },
        async updateNotify({getters}: any, category: string) {
            if (!getters.isGuest) {
                await Vue.axios.post('notify/update', {category: category});
            }
        },
        loadNotify({getters, commit, dispatch}: any) {
            if (getters.notifyTimeout) {
                clearTimeout(getters.notifyTimeout);
            }
            if (!getters.isGuest) {
                Vue.axios.get('notify')
                    .then((response) => {
                        commit('setNotify', response.data);
                        commit('setNotifyTimeout', setTimeout(() => dispatch('loadNotify'), 5000));
                    });
            }
        },
        loadStats({commit}: any) {
            Vue.axios.get('client/stat', {params: {start: dateUtcDayOffset(0)}})
                .then(response => commit('setStats', response.data))
        }
    },
    modules:   {}
};

export default new Vuex.Store(states);
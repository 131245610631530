<template>
  <r-card>
    <v-row class="ma-0 pa-2">
      <v-col v-for="(item, i) in data" :key="item.value" :md="mdCols(i)" class="pa-2" cols="12">
        <chart-card
            :loading="loading"
            :previous="prevValue(item)"
            :title="item.name"
            :type="item.type"
            :value="value(item)"
            prefix="₽"
        />
      </v-col>
    </v-row>
  </r-card>
</template>

<script>


import RCard     from "@/tags/RCard.vue";
import ChartCard from "@/views/stat/ChartCard.vue";

export default {
  name:       "StatIncome",
  components: {ChartCard, RCard},
  props:      {
    stat:    {
      type: Object
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      data: [
        {name: 'Типы лидов', value: 'byType'},
        {name: 'Банки', value: 'byBank'},
        {name: 'Источники клиентов', value: 'bySource'},
        {name: 'Типы услуг', value: 'byService'},
        {name: 'Менеджеры', value: 'byManager'},
      ]
    }
  },
  computed: {},
  methods:  {
    mdCols(i) {
      return this.data.length - 1 === i && this.data.length % 2 ? 12 : 6;
    },
    sum(points) {
      if (!points) return 0;
      return points.reduce((a, v) => a + v.y, 0)
    },
    value(item) {
      return this.stat ? this.stat[item.value] : null;
    },
    prevValue(item) {
      return this.stat ? this.stat.previous[item.value] : null;
    }
  }
}
</script>

<style scoped>

</style>
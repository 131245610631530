import { render, staticRenderFns } from "./FolderRestore.vue?vue&type=template&id=f35f2e80&scoped=true"
import script from "./FolderRestore.vue?vue&type=script&lang=js"
export * from "./FolderRestore.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f35f2e80",
  null
  
)

export default component.exports
<template>
  <v-row justify="center">
    <v-col class="text-center" cols="12" sm="9">
      <v-sheet class="text-h1 font-weight-black py-5 mt-12" color="accent--text" elevation="5">
        <p>404</p>
        <v-card class="text-h6 text--secondary mb-n5" color="grey darken-3">
          {{ $t('error.notFound') }}
        </v-card>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Error404"
}
</script>

<style scoped>

</style>

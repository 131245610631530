import {Model} from "@/models/Model";
import Vue     from "vue";

export class User extends Model {
    modelUri = 'user';
    fields = [
        'username',
        'email',
        'password',
        'phone',
        'avatar_file',
        'is_blocked',
        'office_id',
        'background',
        'income_goal',
        'setting'
    ];

    saveRole() {
        this.loading = true;
        Vue.axios.post('user/role?id=' + this.attr.id, {name: this.attr.role.name})
            .then(() => {
                this.loading = false;
                this.emit('saveRole');
            }).catch(() => this.loading = false);
    }

    block() {
        if (!this.isNew) {
            this.attr.is_blocked = 1;
            this.save();
        }
    }

    unblock() {
        if (!this.isNew) {
            this.attr.is_blocked = 0;
            return this.save();
        }
    }
}
<template>
  <v-list-item :href="href" :target="target">
    <v-list-item-avatar v-if="avatar">
      <v-avatar color="grey lighten-3">
        <v-img v-if="avatarPhoto" :src="apiUri(avatarPhoto)"/>
        <v-icon v-else>mdi-account</v-icon>
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content class="py-0">
      <v-list-item-subtitle class="text-wrap">
        {{ title }}
      </v-list-item-subtitle>
      <v-list-item-title class="text-wrap">
        <slot>{{ text }}</slot>
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <slot name="action"/>
    </v-list-item-action>
    <v-list-item-icon>
      <slot name="icon"/>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import {apiUri} from "@/helpers/stringHelpers";

export default {
  name:  "ListItem",
  props: {
    title: {
      type:    String,
      default: ''
    },
    text: {
      type:    String,
      default: ''
    },
    href:   {
      type: String
    },
    target: {
      type: String
    },
    avatar: {
      type:    Boolean,
      default: false
    },
    avatarPhoto: {
      type: String,
    }
  },
  methods: {
    apiUri
  }
}
</script>

<style scoped>

</style>
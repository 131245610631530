<template>
  <v-card>
    <v-title title="Банки"/>
    <v-card-title>
      <span class="mr-5">Банки</span>
      <v-spacer/>
      <v-autocomplete v-model="types" :items="typesList" class="mr-2" clearable hide-details item-text="name" item-value="id" label="Типы лидов" multiple/>
      <v-select v-model="orderKV" :items="orderKVList" class="mr-2" clearable hide-details item-text="name" item-value="value" label="КВ, %"/>
      <v-text-field v-model="search" :label="$t('btn.search')" append-icon="mdi-magnify" clearable hide-details/>
    </v-card-title>
    <data-list :models="models" class="bank-list" hide-dividers>
      <template v-slot:item="{ item }">
        <bank-item :item="item" :selected-types="types" @type:disable="disableType" @type:enable="enableType"/>
      </template>
    </data-list>
  </v-card>
</template>

<script>
import {Search}     from '@/models/Search';
import {debounce}   from 'lodash/function';
import VTitle       from '@/tags/VTitle';
import DataList     from '@/tags/DataList';
import {mapGetters} from 'vuex';
import BankItem     from "@/views/bankList/BankItem.vue";
import {isBoolean}  from "lodash";

export default {
  name:       'Index',
  components: {BankItem, DataList, VTitle},
  beforeMount() {
    this.axios.get('lead-type/list').then(response => this.typesList = response.data);
  },
  data:     () => ({
    search: '',
    types:       [],
    typesList:   [],
    orderKV:     null,
    orderKVList: [
      {name: 'Меньше', value: true},
      {name: 'Больше', value: false},
    ],
    models: Search.create({
      uri:    'bank',
      fields: [
        'id',
        'logo',
        'document',
        'name',
        'agent',
        'agent_photo',
        'email',
        'extra_email',
        'phone',
        'extra_phone',
        'address',
        'url_site',
        'url_pa',
        'description',
        'agreement',
        'presentation',
        'commission_reward',
        'filesList'
      ],
      expand: ['filesList', 'filesList.file', 'typesList'],
      params: {deleted: 0, types: []},
      filter: {
        or: [
          {name: {like: ''}},
          {agent: {like: ''}},
          {description: {like: ''}}
        ]
      },
    }).load(),
    showDeleted: 0
  }),
  watch:    {
    search(value) {
      this.models.config.filter.or[0].name.like  = value || '';
      this.models.config.filter.or[1].agent.like = value || '';
      this.models.config.filter.or[2].description.like = value || '';
      this.update(this);
    },
    types(value) {
      this.models.params.types = value;
      this.update(this);
    },
    orderKV(value) {
      if (isBoolean(value)) {
        this.models.setSort('commission_reward', value);
      } else {
        this.models.config.sort = ''
      }
      this.update(this);
    }
  },
  computed: {
    ...mapGetters(['can'])
  },
  methods:  {
    enableType(type_id) {
      this.types = [...this.types, type_id];
      this.update(this);
    },
    disableType(type_id) {
      this.types = [...this.types.filter(type => type !== type_id)];
      this.update(this);
    },
    update: debounce((vm) => vm.models.load(), 200)
  }
};
</script>

<style>
.bank-list .v-expansion-panel-content {
  background-color: #3200000b;
}
</style>
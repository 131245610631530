<template>
  <r-card>
    <v-row class="ma-0 pa-2">
      <v-col v-for="item in data" :key="item.value" class="pa-2" cols="12" lg="4" sm="6">
        <info-card
            :loading="loading"
            :prefix="item.prefix"
            :previous="prevValue()"
            :title="item.name"
            :value="value(item)"
            full-number
            funnel
        />
      </v-col>
    </v-row>
  </r-card>
</template>

<script>
import RCard    from "@/tags/RCard.vue";
import InfoCard from "@/views/stat/InfoCard.vue";

export default {
  name:       "StatMain",
  components: {InfoCard, RCard},
  props:      {
    stat:    {
      type: Object
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      data: [
        {name: 'Клиенты', value: 'clients'},
        {name: 'Встречи', value: 'meetings'},
        {name: 'Договора', value: 'signed'},
        {name: 'Подачи', value: 'deals'},
        {name: 'Одобрения', value: 'approved'},
        {name: 'Сделки', value: 'completed'},
      ]
    }
  },
  methods: {
    value(item) {
      return this.stat ? this.stat[item.value] : null;
    },
    prevValue() {
      if (this.stat) {
        return this.stat['clients'];
      }
      return 0;
    }
  }
}
</script>

<style scoped>

</style>
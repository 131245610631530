<template>
  <v-card>
    <v-title title="Типы оплат сделок"/>
    <v-card-title>
      <span class="mr-5">Типы оплат сделок</span>
      <span>
      <deal-payment-type-form @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </deal-payment-type-form>
      </span>
      <toggle-deleted v-model="showDeleted" class="ml-2"/>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
          hide-details
      />
    </v-card-title>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.price_type="{ item }">
        {{ priceType(item) }}
      </template>
      <template v-slot:item.income_type="{ item }">
        {{ incomeType(item) }}
      </template>
      <template v-slot:item.properties="{ item }">
        <btn-icon :disabled="!item.required" :title="item.required?'Обязательный':'Не обязательный'" color="error" icon="asterisk"/>
        <btn-icon :disabled="!item.editable" :title="item.editable?'Редактируемый':'Не редактируемый'" color="error" icon="pencil-circle"/>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <span v-if="!item.deleted_at" class="ml-2">
            <deal-payment-type-form :id="item.id" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </deal-payment-type-form>
          </span>
          <span v-if="!item.deleted_at && !item.permanent" class="ml-2">
            <delete :id="item.id" :name="item.name" @remove="models.load()"/>
          </span>
          <span v-if="item.deleted_at" class="ml-2">
            <restore :id="item.id" :name="item.name" @restore="models.load()"/>
          </span>
        </div>
      </template>
    </data-table>

  </v-card>
</template>

<script>
import {Search}   from "@/models/Search";
import {debounce} from "lodash/function";

import Delete        from "@/views/dealStatuses/Delete";
import ToggleDeleted from "@/views/dealStatuses/ToggleDeleted";
import Restore       from "@/views/dealStatuses/Restore";
import DataTable     from "@/tags/DataTable";
import VTitle        from "@/tags/VTitle";
import BtnIcon       from "@/tags/BtnIcon";

import DealPaymentTypeForm from "@/views/dealPaymentTypes/DealPaymentTypeForm.vue";


export default {
  name:       "Index",
  components: {DealPaymentTypeForm, BtnIcon, VTitle, DataTable, Restore, ToggleDeleted, Delete},
  data:       () => ({
    search:      '',
    models:      Search.create({
      uri:    'deal-payment-type',
      fields: [
        'id',
        'name',
        'required',
        'editable',
        'price_type',
        'price_value',
        'income_type',
        'income_value',
        'deleted_at',
      ],
      params: {deleted: 0},
      filter: {or: [{name: {like: ''}}]},
    }).load(),
    showDeleted: 0,
    priceTypes:  {
      0: 'Ручной ввод',
      1: 'Процент от суммы сделки',
      2: 'Процент от суммы КВ',
      3: 'Фиксированный'
    },
    incomeTypes: {
      0: 'Ручной ввод',
      1: 'Процент от суммы сделки',
      2: 'Процент от дохода компании',
      3: 'Фиксированный'
    }
  }),
  computed:   {
    headers() {
      return [
        {text: 'Название', value: 'name', sortable: true},
        {text: 'Тип дохода компании', value: 'price_type'},
        {text: 'Тип дохода менеджера', value: 'income_type'},
        {text: 'Свойства', value: 'properties'},
        {text: 'Действия', value: 'actions', align: 'end'},
      ]
    }
  },
  watch:      {
    search(value) {
      this.models.config.filter.or[0].name.like = value || '';
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    },
  },
  methods:    {
    update: debounce((vm) => vm.models.load(), 200),
    utcToDatetime(value) {
      return this.$d(value * 1000, 'datetime');
    },
    priceType(item) {
      let name = this.priceTypes[item.price_type];
      switch (item.price_type) {
        case 0:
          return name;
        case 1:
        case 2:
          return name + ' (' + item.price_value + '%)'
        case 3:
          return name + ' (' + item.price_value + ' ₽)'
      }
    },
    incomeType(item) {
      let name = this.incomeTypes[item.income_type];
      switch (item.income_type) {
        case 0:
          return name;
        case 1:
        case 2:
          return name + ' (' + item.income_value + '%)'
        case 3:
          return name + ' (' + item.income_value + ' ₽)'
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-card>
    <v-title :title="title"/>
    <v-card-title>
      <span class="mr-5">{{ title }}</span>
      <span v-if="can('event.manage')">
        <event-form @save="models.load()">
          <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
        </event-form>
        <toggle-deleted v-model="showDeleted" class="ml-2"/>
      </span>
      <v-spacer/>
      <v-text-field v-model="search" :label="$t('btn.search')" append-icon="mdi-magnify" clearable hide-details/>
    </v-card-title>
    <data-list :models="models">
      <template v-slot:item="{ item }">
        <v-col cols="12">
          <div class="d-flex flex-column mb-5" style="gap: 10px">
            <div class="d-flex">
              <h2 class="mt-2 text-start">{{ item.title }}</h2>
              <v-spacer/>
              <v-icon v-if="!isPublished(item)" color="accent">mdi-eye-off</v-icon>
              <v-subheader v-if="item.published_at">{{ utcToDatetime(item.published_at) }}</v-subheader>
            </div>
            <div class="d-flex flex-column" style="gap: 10px">
              <span :class="isExpiredDate(item.ended_at) ? 'secondary--text font-weight-bold' : ''"> <b> Дата начала: </b> {{ isExpiredDate(item.ended_at) ? 'Завершилось' : utcToDatetime(item.started_at) }} </span>
              <span>
                <b> Длительность: {{ timeDuration(item.started_at, item.ended_at) }}</b>
              </span>
            </div>
          </div>
          <v-img v-if="item.image" :src="apiUri(item.image)" max-width="370px"/>
          <p class="mt-5">
            <span v-if="showDescription[item.id]" v-html="item.text"/>
            <span v-else :inner-html.prop="item.text | truncate(100)"/>
            <v-btn color="red" small text @click="toggleDescription(item.id)">
              {{ showDescription[item.id] ? 'Скрыть' : 'Показать' }}
              <v-icon v-if="!showDescription[item.id]">mdi-dots-horizontal-circle-outline</v-icon>
            </v-btn>
          </p>
          <div class="d-flex flex-column">
            <div class="d-flex justify-space-between">
              <v-btn v-if="item.url" :href="item.url" color="primary" small target="_blank">
                <span class="d-none d-sm-block">Перейти к мероприятию</span>
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
              <div v-if="can('event.manage')" class="text-right">
                <span v-if="!item.deleted_at" class="ml-2">
                  <event-form :id="item.id" @save="models.load()">
                    <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
                  </event-form>
                </span>
                <span v-if="!item.deleted_at" class="ml-2">
                  <delete :id="item.id" :name="item.title" @remove="models.load()"/>
                </span>
                <span v-if="item.deleted_at" class="ml-2">
                  <restore :id="item.id" :name="item.title" @restore="models.load()"/>
                </span>
              </div>
            </div>
          </div>
        </v-col>
      </template>
    </data-list>
  </v-card>
</template>

<script>
import {Search}   from '@/models/Search.ts';
import {debounce} from 'lodash/function';
import {apiUri}   from '@/helpers/stringHelpers';

import Delete                   from '@/views/event/Delete.vue';
import {mapActions, mapGetters} from 'vuex';
import ToggleDeleted            from '@/views/event/ToggleDeleted.vue';
import Restore                  from '@/views/event/Restore.vue';
import EventForm                from '@/views/event/EventForm.vue';

import VTitle   from '@/tags/VTitle.vue';
import BtnIcon  from '@/tags/BtnIcon.vue';
import DataList from '@/tags/DataList.vue';

import Vue from 'vue';

Vue.filter('truncate', function (value, length) {
  if (!value) return '';
  value = value.toString();
  if (value.length > length) {
    return value.substring(0, length) + '...';
  } else {
    return value;
  }
});

export default {
  name:       'Index',
  components: {DataList, EventForm, BtnIcon, VTitle, Restore, ToggleDeleted, Delete},
  mounted() {
    this.updateNotify('event');
  },
  beforeRouteUpdate() {
    this.updateNotify('event');
  },
  data:    () => ({
    title:  'Мероприятия',
    search: '',
    models: Search.create({
      uri:    'event',
      fields: [
        'id',
        'title',
        'text',
        'published_at',
        'created_at',
        'deleted_at',
        'started_at',
        'ended_at',
        'url',
        'image'
      ],
      params: {deleted: 0},
      filter: {
        or: [
          {title: {like: ''}},
          {text: {like: ''}}
        ]
      }
    }).load(),
    showDeleted:     0,
    showDescription: {},
    imageSize:       {}
  }),
  computed: {
    ...mapGetters(['can'])
  },
  watch:   {
    search(value) {
      this.models.config.filter.or[0].title.like = value || '';
      this.models.config.filter.or[1].text.like = value || '';
      this.update(this);
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    }
  },
  methods: {
    ...mapActions(['updateNotify']),
    update: debounce((vm) => vm.models.load(), 200),
    utcToDatetime(value) {
      return this.$d(value * 1000, 'datetime');
    },
    toggleDescription(id) {
      this.showDescription = Object.assign({}, this.showDescription, {[id]: !this.showDescription[id]});
    },
    formatDate(date) {
      return date + '000';
    },
    isExpiredDate(date) {
      return Date.now() > date + '000';
    },
    apiUri,
    timeDuration(startDate, endDate) {
      let distance = Math.abs(this.formatDate(startDate) - this.formatDate(endDate));
      const hours  = Math.floor(distance / 3600000);
      distance -= hours * 3600000;
      const minutes = Math.floor(distance / 60000);
      distance -= minutes * 60000;
      const seconds = Math.floor(distance / 1000);
      return `${hours}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
    },
    isPublished(item) {
      return item.published_at > 0 && item.published_at <= Math.floor(new Date().getTime() / 1000);
    },
  }
};
</script>

<style scoped></style>
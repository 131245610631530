<template>
  <modal v-model="modal" :loading="model.loading" :title="id?'Изменение источника клиента':'Создание источника клиента'" max-width="500px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="model.attr.url" :label="model.labels.url" :rules="model.rule('url')"/>
          </v-col>
          <v-col cols="12">
            <v-select v-model="model.attr.category_id" :items="categoryList" :label="model.labels.category_id" :rules="model.rule('category_id')" item-text="name" item-value="id"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal  from "@/tags/Modal";
import {Link} from "../../models/Link";


export default {
  name:       "LinkForm",
  components: {Modal},
  props:      ['id'],
  data() {
    return {
      modal:        false,
      model:        Link.get(this.id, {
        on: {
          save:  () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      categoryList: [],
    }
  },
  computed: {},
  watch:    {
    modal(value) {
      if (value) {
        this.id && this.model.load();
        !this.id && this.model.clear();
        this.axios.get('link-category/list').then((response) => this.categoryList = response.data)
      }
    }
  },
  methods:  {
    close() {
      this.$refs.form.reset();
      this.modal = false
    }
  }
}
</script>

<style scoped>

</style>
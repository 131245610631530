import {Model} from "@/models/Model";

export class Vote extends Model {
    modelUri = 'vote';


    fields = [
        'type',
        'type_id',
        'user_id'
    ]
}
<template>
  <div>
    <v-progress-linear v-if="model.loading" class="mb-n1" indeterminate/>
    <v-form ref="form" :disabled="model.loading" class="pt-4">
      <v-row>
        <template v-if="templates.length > 0">
          <v-col v-for="(item,i) in templates" :key="i" cols="12" lg="4" md="6">
            <v-text-field :key="'f'+i" v-model="templates[i]" :label="'Текст шаблона #'+(i+1)" :loading="model.loading" :rules="model.rule('value')" clearable>
              <template #append-outer>
                <btn-icon color="error" icon="delete" title="Удалить шаблон" @click="removeTemplate(i)"/>
              </template>
            </v-text-field>
          </v-col>
        </template>
        <template v-else>
          <v-col class="text--secondary" cols="12">
            Шаблоны не добавлены
          </v-col>
        </template>
        <v-col cols="12">
          <v-btn :disabled="model.loading" class="mb-2 mr-2" color="primary" @click="sendForm()">{{ $t('btn.save') }}</v-btn>
          <v-btn :disabled="model.loading" class="mb-2 mr-2" color="secondary" @click="addTemplate()">Добавить</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import {Form}  from "@/models/Form";
import BtnIcon from "@/tags/BtnIcon";

export default {
  name:       "ClientDenyTemplatesForm",
  components: {BtnIcon},
  props:      {
    show: {
      type:    Boolean,
      default: false
    }
  },
  created() {
    this.initValue();
  },
  mounted() {
    this.loadSetting();
  },
  data:     () => ({
    model: Form.create({
      uri:   {
        addr:   'setting/set',
        params: {name: 'client'}
      },
      attrs: ['value'],
      on:    {
        error: () => {
          this.$refs.form.validate();
        }
      }
    })
  }),
  watch:    {
    show(value) {
      if (value) {
        this.loadSetting();
      } else {
        this.templates = [];
      }
    }
  },
  computed: {
    templates: {
      get() {
        return this.model.attr.value.denyTemplates;
      },
      set(value) {
        this.model.attr.value.denyTemplates = value;
      }
    }
  },
  methods:  {
    addTemplate() {
      this.templates.push('');
    },
    removeTemplate(i) {
      this.templates.splice(i, 1);
    },
    sendForm() {
      this.templates = this.templates.filter(v => v);
      this.model.send();
    },
    loadSetting() {
      this.initValue();
      this.model.loading = true;
      this.axios.get('setting/get', {params: {name: 'client.denyTemplates'}})
          .then(response => {
            this.model.attr.value.denyTemplates = response.data || [];
            this.model.loading                  = false;
          }).catch(() => {
        this.model.loading = false;
      })
    },
    initValue() {
      this.model.attr = {value: {denyTemplates: []}}
    }
  }
}
</script>

<style scoped>

</style>
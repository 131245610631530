<template>
  <confirm :loading="model.loading" :title="'Вы действительно хотите восстановить файл ' + name + '?'" color="accent" @confirm="model.restore()">
    <v-list-item>{{ $t('btn.restore') }}</v-list-item>
  </confirm>
</template>

<script>
import Confirm        from '@/tags/Confirm.vue';
import {DocumentFile} from "../../models/DocumentFile";

export default {
  name:       'FileRestore',
  components: {Confirm},
  props:      [
    'id',
    'name'
  ],
  data() {
    return {
      model: DocumentFile.get(this.id, {
        on: {
          restore: () => {
            this.$emit('restore');
          }
        }
      })
    };
  }
};
</script>

<style scoped></style>
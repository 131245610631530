<template>
  <v-card>
    <v-title title="Статистика"/>
    <v-card-title>Статистика</v-card-title>
    <v-tabs v-model="tabs" :mobile-breakpoint="0" background-color="transparent" class="nowrap" color="primary" fixed-tabs>
      <v-tab v-if="showMainStat">Основное</v-tab>
      <v-tab>Выручка</v-tab>
      <v-tab>Клиенты</v-tab>
      <v-tab>Партнеры</v-tab>
      <v-tab>Воронка</v-tab>
    </v-tabs>
    <stat-filter-form v-model="form.attr" @input="update()"/>
    <v-progress-linear v-if="form.loading" class="mt-n1" indeterminate/>
    <v-tabs-items v-model="tabs">
      <v-tab-item v-if="showMainStat">
        <stat-main :loading="form.loading" :stat="data.main"/>
      </v-tab-item>
      <v-tab-item>
        <stat-income :loading="form.loading" :stat="data.income"/>
      </v-tab-item>
      <v-tab-item>
        <stat-client :loading="form.loading" :stat="data.client"/>
      </v-tab-item>
      <v-tab-item>
        <stat-deal :loading="form.loading" :stat="data.deal"/>
      </v-tab-item>
      <v-tab-item>
        <stat-funnel :loading="form.loading" :stat="data.funnel"/>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import VTitle         from "@/tags/VTitle";
import StatFilterForm from "./StatFilterForm";
import {Form}         from "@/models/Form";
import StatMain       from "@/views/stat/StatMain.vue";
import StatIncome     from "@/views/stat/StatIncome.vue";
import StatClient     from "@/views/stat/StatClient.vue";
import StatDeal       from "@/views/stat/StatDeal.vue";
import StatFunnel     from "@/views/stat/StatFunnel.vue";
import {mapGetters}   from "vuex";

export default {
  name:       "Index",
  components: {StatIncome, StatMain, StatFilterForm, VTitle, StatClient, StatDeal, StatFunnel},
  data() {
    return {
      tabs:    0,
      data: {},
      form: Form.create({
        attrs: ['start', 'end', 'user_id', 'office_id'],
        on:    {
          send: (data) => {
            this.data[this.idx] = data;
          }
        }
      }),
      loading: false
    }
  },
  computed: {
    ...mapGetters(['role']),
    params() {
      return {
        0: {url: 'stat', idx: 'main'},
        1: {url: 'stat/income', idx: 'income'},
        2: {url: 'stat/client', idx: 'client'},
        3: {url: 'stat/deal', idx: 'deal'},
        4: {url: 'stat/funnel', idx: 'funnel'}
      }
    },
    url() {
      return this.params[this.tabs].url;
    },
    idx() {
      return this.params[this.tabs].idx;
    },
    showMainStat() {
      return this.role?.name !== 'agent';
    }
  },
  watch:    {
    tabs() {
      this.update();
    }
  },
  methods:  {
    update() {
      if (this.url) {
        this.form.setUri(this.url).send();
      }
    }
  }
}
</script>

<style scoped>
.nowrap {
  .v-tab {
    white-space: nowrap;
  }
}
</style>
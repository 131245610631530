<template>
  <v-sheet>
    <v-progress-linear v-if="models.loading" class="mb-n1" indeterminate style="z-index: 1000"/>
    <v-toolbar
        dense
        flat
    >
      <btn-icon class="calendar-toolbar-icon" color="secondary" icon="chevron-left" @click="$refs.calendar.prev()"/>
      <btn-icon class="calendar-toolbar-icon" color="secondary" icon="circle-medium" @click="setToday()"/>
      <btn-icon class="calendar-toolbar-icon" color="secondary" icon="chevron-right" @click="$refs.calendar.next()"/>
      <v-toolbar-title v-if="$refs.calendar" class="mx-2">{{ $refs.calendar.title }}</v-toolbar-title>
      <v-spacer/>
      <v-btn-toggle v-model="type" class="btn-group" color="secondary" dense mandatory rounded>
        <v-btn v-for="v in types" :key="v.value" :input-value="v.value" :value="v.value">{{ v.name }}</v-btn>
      </v-btn-toggle>
    </v-toolbar>
    <v-calendar
        ref="calendar"
        v-model="focus"
        :categories="categories"
        :event-text-color="eventTextColor"
        :events="events"
        :type="type"
        color="primary"
        interval-width="36"
        weekdays="1,2,3,4,5,6,0"
        @change="loadItems"
        @click:date="viewDay"
        @click:more="viewDay"
        @click:event="viewClient"
        @click:day="dblClick"
        @click:time="dblClick"
        @mousedown:event="startDrag"
        @mousemove:time="mouseMove"
        @mousemove:day="mouseMove"
        @mouseleave.native="cancelDrag"
        @mouseup:time="cancelDrag"
        @mouseup:day="cancelDrag"
    >
      <template #category="{category}">
        <div class="v-calendar-category__category">{{ category.split('#')[0] }}</div>
      </template>
      <template #day-body="{date}">
        <div :class="{'d-none':date !== nowDate}" :style="{top:nowY}" class="current-time"/>
      </template>
    </v-calendar>
    <client-view :id="viewID" @update="models.load()">
      <span ref="viewBtn"/>
    </client-view>
  </v-sheet>
</template>

<script>
import {mapGetters}                     from "vuex";
import BtnIcon                          from "../../tags/BtnIcon";
import {Search}                         from "@/models/Search";
import {debounce, findIndex, now, uniq} from "lodash";
import {toDate, toTime}                 from "@/helpers/dateHelper";

export default {
  name:       "ClientCalendar",
  components: {BtnIcon, ClientView: () => import('@/views/clients/ClientView')},
  props:      {
    search: {
      type:    String,
      default: ''
    },
    searchParams: {
      type:    Object,
      default: () => ({filter: {}, params: {}})
    },
    currentItem: {
      type: Object
    }
  },
  mounted() {
    this.$refs.calendar.move(0);
    this.$refs.calendar.checkChange();
    this.models.on('load', this.loadPage);
    const notice_date = this.currentItem ? new Date(this.currentItem.notice_date ? this.currentItem.notice_date * 1000 : now()) : ''
    this.focus = notice_date;
    if (notice_date) {
      this.dragDate = toDate(notice_date.getTime());
      this.dragTime = toTime(notice_date.getTime());
    }
    this.ready = true;
  },
  data: () => ({
    ready:  false,
    focus:  '',
    type:   'week',
    events: [],
    models: Search.create({
      uri:     'client',
      fields:  ['id', 'notice_date', 'status.color', 'status.name', 'status.id', 'manager.id', 'manager.username'],
      expand:  ['status', 'manager'],
      params:  {deleted: 0, search: '', comment: ''},
      filter:  {},
      perPage: 50
    }),
    types: [
      {name: 'Д', value: 'category'},
      {name: 'Н', value: 'week'},
      {name: 'М', value: 'month'}
    ],
    periods: {
      2:  900,
      3:  3600,
      10: 900
    },
    period:    {},
    viewID:    undefined,
    dragEvent: undefined,
    dragDate:  '',
    dragTime:  '',
    clicked:   false
  }),
  computed: {
    ...mapGetters(['can', 'user']),
    categories() {
      let categories = [];
      this.events.forEach(event => {
        const {username, id} = event.input.manager;
        categories.push(username + '#' + id);
      })
      return uniq(categories);
    },
    filterNoticeDate() {
      let {start, end} = this.period
      return {
        gte: (new Date(start.date + 'T00:00:00').getTime()) / 1000,
        lte: (new Date((this.type === 'category' ? start.date : end.date) + 'T23:59:59').getTime()) / 1000
      }
    },
    statusIds() {
      return {in: Object.keys(this.periods)};
    },
    calendarFilter() {
      return {notice_date: this.filterNoticeDate, status_id: this.statusIds}
    },
    nowY() {
      return this.ready ? this.$refs.calendar.timeToY(this.$refs.calendar.times.now) + 'px' : '-10px'
    },
    nowDate() {
      return toDate(now());
    }
  },
  watch: {
    search(value) {
      this.models.params.comment = value || '';
      this.models.params.search = value || '';
      this.models.setPage(1);
      this.update(this);
    },
    searchParams() {
      this.models.setPage(1).setFilter(Object.assign({}, this.searchParams.filter, this.calendarFilter));
      this.models.params = Object.assign({}, this.models.params, this.searchParams.params);
      this.update(this);
    },
    'models.loading'(value) {
      this.$emit('loading', value);
    }
  },
  methods: {
    update: debounce(vm => vm.models.load(), 200),
    setToday() {
      this.focus = '';
    },
    viewDay({date}) {
      this.focus = date;
      this.type = 'category';
    },
    loadItems({start, end}) {
      this.items = [];
      this.period = {start, end};
      this.models.setPage(1).setFilter(Object.assign({}, this.searchParams.filter, this.calendarFilter)).load();
    },
    loadPage(items) {
      let {page, pageCount} = this.models.headers;
      let events = [];
      if (page === 1) {
        this.events = [];
        if (this.currentItem) {
          let item = this.currentItem;
          item.notice_date = new Date(this.dragDate + 'T' + this.dragTime).getTime() / 1000;
          events.push(this.toEvent(item));
        }
      }
      items.forEach(item => {
        if (this.currentItem && item.id === this.currentItem.id) {
          return;
        }
        events.push(this.toEvent(item));
      })
      this.events.push(...events);
      if (pageCount > page) {
        this.models.setPage(page + 1).load();
      }
    },
    viewClient({event}) {
      if (this.currentItem && this.currentItem.id === event.input.id) {
        return;
      }
      this.viewID = event.input.id;
      this.$refs.viewBtn.click();
    },
    eventTextColor(event) {
      return event.input.id === this.currentItem?.id ? 'yellow draggable' : 'white';
    },
    startDrag({event}) {
      if (event.input.id === this.currentItem?.id) {
        this.dragEvent = event;
      }
    },
    mouseMove({date, time}) {
      if (this.dragEvent) {
        if (!Date.parse(date + 'T' + (time || this.dragTime))) {
          return;
        }
        this.dragDate = date ? date : this.dragDate;
        this.dragTime = time ? time : this.dragTime;
        const start = new Date(this.dragDate + 'T' + this.dragTime);
        const end = new Date(start.getTime() + this.periods[this.currentItem.status_id] * 1000);
        this.dragEvent.start = start;
        this.dragEvent.end = end;
      }
    },
    cancelDrag() {
      if (this.dragEvent) {
        const ts = this.dragEvent.start.getTime() / 1000;
        this.$emit('update', ts);
      }
      this.dragEvent = undefined;
    },
    toEvent(item) {
      return {
        name:     item.status.name,
        start:    new Date(item.notice_date * 1000),
        end:      new Date((item.notice_date + this.periods[item.status.id]) * 1000),
        color:    item.status.color,
        timed:    true,
        input:    item,
        category: item.manager.username + '#' + item.manager.id
      }
    },
    dblClick({date, time}) {
      if (!this.currentItem) {
        return;
      }
      if (!this.clicked) {
        this.clicked = true;
        setTimeout(() => this.clicked = false, 200);
      } else {
        if (!Date.parse(date + 'T' + (time || this.dragTime))) {
          this.clicked = false;
          return;
        }

        this.dragDate = date ? date : this.dragDate;
        this.dragTime = time ? time : this.dragTime;
        this.dragEvent = this.events[findIndex(this.events, event => event.input.id === this.currentItem.id)];
        const start = new Date(this.dragDate + 'T' + this.dragTime);
        const end = new Date(start.getTime() + this.periods[this.currentItem.status_id] * 1000);
        this.dragEvent.start = start;
        this.dragEvent.end = end;
        this.$emit('update', this.dragEvent.start.getTime() / 1000);
        this.dragEvent = undefined;
        this.clicked = false;
      }
    }
  }
}
</script>

<style lang="scss">
.v-toolbar__content .v-btn.v-btn--icon.v-size--default {
  width: 36px !important;
  height: 36px !important;
}

.v-btn--fab.v-size--default {
  width: 40px !important;
  height: 40px !important;
}


.current-time {
  height: 2px;
  background-color: #ea433577;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

.text--draggable {
  cursor: move !important;
  user-select: none;
}

.v-calendar.v-calendar-events .v-calendar-weekly__day, .v-calendar.v-calendar-events .v-calendar-weekly__head-weekday {
  margin-right: 0 !important;
}
</style>
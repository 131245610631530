import { render, staticRenderFns } from "./VoteBtn.vue?vue&type=template&id=e8a344c8&scoped=true"
import script from "./VoteBtn.vue?vue&type=script&lang=js"
export * from "./VoteBtn.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8a344c8",
  null
  
)

export default component.exports
import {Model} from "@/models/Model";

export class DealStatus extends Model {
    modelUri = 'deal-status';
    labels = {
        name:                 'Название',
        color:                'Цвет',
        is_default:           'По умолчанию',
        available_status_ids: 'Доступные статусы',
        editable:             'Редактируемый',
        fix_sum:              'Фиксация суммы сделки',
    }

    fields = [
        'name',
        'color',
        'is_default',
        'available_status_ids',
        'editable',
        'fix_sum',
    ];
}

export enum DEAL_STATUSES {
    PROCESSED = 1,
    COMPLETE = 2,
    DENIED = 3,
    APPROVED = 4,
}
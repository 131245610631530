<template>
  <v-card>
    <v-title title="Категории ссылок верификации"/>
    <v-card-title>
      <span class="mr-5">Категории ссылок верификации</span>
      <span>
      <category-form @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </category-form>
      </span>
      <toggle-deleted v-model="showDeleted" class="ml-2"/>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
          hide-details
      />
    </v-card-title>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.created_at="{ item }">
        {{ utcToDatetime(item.created_at) }}
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ utcToDatetime(item.updated_at) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <span v-if="!item.deleted_at" class="ml-2">
            <category-form :id="item.id" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </category-form>
          </span>
          <span v-if="!item.deleted_at" class="ml-2">
            <delete :id="item.id" :name="item.name" @remove="models.load()"/>
          </span>
          <span v-if="item.deleted_at" class="ml-2">
            <restore :id="item.id" :name="item.name" @restore="models.load()"/>
          </span>
        </div>
      </template>
    </data-table>

  </v-card>
</template>

<script>
import {Search}   from "@/models/Search";
import {debounce} from "lodash/function";

import Delete        from "@/views/linkCategory/Delete";
import {mapGetters}  from "vuex";
import ToggleDeleted from "@/views/linkCategory/ToggleDeleted";
import Restore       from "@/views/linkCategory/Restore";
import DataTable     from "@/tags/DataTable";
import VTitle        from "@/tags/VTitle";
import BtnIcon       from "@/tags/BtnIcon";
import CategoryForm  from "./CategoryForm";


export default {
  name:       "Index",
  components: {CategoryForm, BtnIcon, VTitle, DataTable, Restore, ToggleDeleted, Delete},
  data:     () => ({
    search:      '',
    models:      Search.create({
      uri:    'link-category',
      fields: ['id', 'name', 'created_at', 'updated_at', 'deleted_at'],
      params: {deleted: 0},
      filter: {name: {like: ''}},
    }).load(),
    showDeleted: 0,
  }),
  computed: {
    ...mapGetters(['user', 'role']),
    headers() {
      return [
        {text: 'Название', value: 'name', sortable: true},
        {text: 'Создан', value: 'created_at', sortable: true},
        {text: 'Изменен', value: 'updated_at', sortable: true},
        {text: 'Действия', value: 'actions', align: 'end'},
      ]
    }
  },
  watch:    {
    search(value) {
      this.models.config.filter.name.like = value || '';
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    },
  },
  methods:  {
    update: debounce((vm) => vm.models.load(), 200),
    utcToDatetime(value) {
      return this.$d(value * 1000, 'datetime');
    }
  }
}
</script>

<style scoped>

</style>
import {Model} from '@/models/Model';

export class Event extends Model {
    modelUri = 'event';
    labels = {
        id:         'ID',
        title:      'Заголовок',
        text:       'Текст',
        started_at: 'Начало',
        ended_at:   'Окончание',
        url:        'Ссылка',
        image:      'Изображение',
        published_at: 'Опубликовано',
        created_at: 'Создан',
        updated_at: 'Изменен',
        deleted_at: 'Удален'
    };
    fields = [
        'title',
        'text',
        'text',
        'started_at',
        'ended_at',
        'url',
        'image_file',
        'published_at',
    ];
}
<template>
  <v-btn color="primary" v-bind="$attrs" v-on="$listeners">
    <v-icon v-if="iconPrepend" :class="{'mr-1':isDesktop}" class="ml-n1">mdi-{{ iconPrepend }}</v-icon>
    <span v-if="isDesktop">{{ label }}</span>
    <v-icon v-if="iconAppend" :class="{'ml-1': isDesktop}" :color="iconAppendColor" class="mr-n1">mdi-{{ iconAppend }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name:  'AdaptiveBtn',
  props: {
    iconAppend: {
      type:    String,
      default: ''
    },
    iconAppendColor: {
      type:    String,
      default: ''
    },
    iconPrepend: {
      type:    String,
      default: ''
    },
    label: {
      type:     String,
      required: true
    },
    color: {
      type:    String,
      default: 'primary'
    }
  },
  computed: {
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    }
  }
};
</script>
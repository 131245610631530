import { render, staticRenderFns } from "./Restore.vue?vue&type=template&id=a4743790&scoped=true"
import script from "./Restore.vue?vue&type=script&lang=js"
export * from "./Restore.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4743790",
  null
  
)

export default component.exports
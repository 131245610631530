<template>
  <v-menu>
    <template v-slot:activator="{on,attrs}">
      <span v-bind="attrs" v-on="on">
        <slot/>
      </span>
    </template>
    <v-list dense>
      <v-list-item><a :href="'tel:+'+phone" target="_blank">Телефон</a></v-list-item>
      <v-list-item><a :href="'https://t.me/+'+phone" target="_blank">Telegram</a></v-list-item>
      <v-list-item><a :href="'https://wa.me/'+phone" target="_blank">WhatsApp</a></v-list-item>
      <v-list-item><a :href="'viber://chat?number=+'+phone" target="_blank">Viber</a></v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name:  "PhoneList",
  props: ['phone']
}
</script>

<style scoped>

</style>
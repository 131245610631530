<template>
  <div class="mb-3">
    <div class="d-flex">
      <v-spacer/>
      <deal-form v-if="canCreate()" ref="create" :client-id="id" :client-type-id="typeId" :sum="sum" class="mt-n10" @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </deal-form>
    </div>
    <data-table
        :headers="headers"
        :models="models"
        hide-footer
    >
      <template v-slot:item.status="{ item }">
        <v-chip v-if="item.status" :color="item.status.color" dark>{{ item.status.name }}</v-chip>
      </template>
      <template v-slot:item.sum="{item}">
        {{ formatMoney(item.sum) }}
      </template>
      <template v-slot:item.price="{item}">
        {{ formatMoney(item.price) }}
      </template>
      <template v-slot:item.rewarded="{ item }">
        <v-checkbox v-model="item.rewarded" :color="getRewardedColor(item)" class="d-inline-block" readonly/>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <deal-view :id="item.id" @update="models.load()">
            <btn-icon color="info" icon="information" title="Подробности"/>
          </deal-view>
          <deal-form v-if="canUpdate(item)" :id="item.id" ref="update" :client-id="id" @save="models.load()">
            <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
          </deal-form>
        </div>
      </template>
    </data-table>
  </div>
</template>

<script>
import {Search}          from "@/models/Search";
import {mapGetters}      from "vuex";
import DataTable         from "@/tags/DataTable";
import BtnIcon           from "@/tags/BtnIcon";
import DealForm          from "./DealForm";
import {formatMoney}     from "@/helpers/stringHelpers";
import DealView          from "@/views/deals/DealView";
import {CLIENT_STATUSES} from "@/models/ClientStatus";


export default {
  name:       "DealList",
  components: {DealView, DealForm, BtnIcon, DataTable},
  props:      {
    id: {
      type: Number
    },
    typeId: {
      type: Number
    },
    onlyView: {
      type:    Boolean,
      default: false
    },
    client: {
      type: Object
    }
  },
  data: () => ({
    models: Search.create({
      uri:    'deal',
      fields: ['id', 'bank.name', 'status_id', 'status.name', 'status.color', 'status.editable', 'sum', 'price', 'income', 'rewarded', 'price_post', 'income_post', 'rewarded_post'],
      expand: ['bank', 'status'],
      params: {duplicate: 1},
      filter: {client_id: null},
      sort:   {field: 'created_at', sortAsc: false}
    }),
    showDeleted: 0
  }),
  computed: {
    ...mapGetters(['can']),
    headers() {
      let headers = [
        {text: 'Кредитор', value: 'bank.name'},
        {text: 'Сумма кредита', value: 'sum'},
        {text: 'Доход компании', value: 'price'},
        {text: 'Статус', value: 'status', align: 'center'},
        {text: 'Оплачено', value: 'rewarded', align: this.showActions ? 'center' : 'end'}
      ];
      if (this.showActions) headers.push({text: 'Действия', value: 'actions', align: 'end'});
      return headers;
    },
    sum() {
      let sum = 0;
      this.client.fields.forEach(field => {
        if (field.autofill_sum) {
          const value = this.client["___" + field.id];
          sum += value * field.autofill_factor;
        }
      });
      return sum;
    },
    showActions() {
      return !this.onlyView && this.can('deal.manage')
    }
  },
  watch:   {},
  methods: {
    fillParams() {
      this.models.config.filter.client_id = this.id;
      this.models.load();
    },
    canCreate() {
      return !this.onlyView && this.client.status_id === CLIENT_STATUSES.SIGNED_CONTRACT && this.can('deal.manage'); // Подписан договор
    },
    canUpdate(item) {
      return this.can('deal-payment.manage') || item.status.editable;
    },
    getRewardedColor(item) {
      return ['secondary', 'warning', 'primary'][item.rewarded];
    },
    formatMoney
  }
}
</script>

<style scoped>

</style>
<template>
  <modal v-model="modal" :loading="model.loading" :title="id?'Изменение типа услуги':'Создание типа услуги'" max-width="500px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          </v-col>
          <v-col cols="12">
            <file-input v-model="model.attr.avatar_file" :label="model.labels.avatar_file" :rules="model.rule('avatar_file')" accept=".png,.jpg,.svg" icon="mdi-file-image"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="model.attr.direction" :label="model.labels.direction" :rules="model.rule('direction')"/>
          </v-col>
          <v-col cols="12">
            <phone-input v-model="model.attr.phone" :label="model.labels.phone" :rules="model.rule('phone')"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="model.attr.email" :label="model.labels.email" :rules="model.rule('email')"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal            from "@/tags/Modal";
import {SupportContact} from "../../models/SupportContact";
import FileInput        from "@/tags/FileInput";
import PhoneInput       from "@/tags/PhoneInput";


export default {
  name:       "SupportContactForm",
  components: {PhoneInput, FileInput, Modal},
  props:      ['id'],
  data() {
    return {
      modal: false,
      model: SupportContact.get(this.id, {
        on: {
          save:  () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      })
    }
  },
  computed: {},
  watch:    {
    modal(value) {
      if (value) {
        this.id && this.model.load();
        !this.id && this.model.clear();
      }
    }
  },
  methods:  {
    close() {
      this.$refs.form.reset();
      this.modal = false
    },
    open() {
      this.modal = true;
    }
  }
}
</script>

<style scoped>

</style>
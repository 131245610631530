<template>
  <modal v-model="modal" :loading="model.loading" :title="id?'Изменение статуса клиента':'Создание статуса клиента'" max-width="500px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          </v-col>
          <v-col cols="12">
            <v-select v-model="model.attr.color" :items="colorList" :label="model.labels.color" :rules="model.rule('color')">
              <template v-slot:item="{ item }">
                <v-chip :color="item" dark>{{ item }}</v-chip>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip :color="item" dark>{{ item }}</v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-switch v-model="model.attr.is_default" :false-value="0" :label="model.labels.is_default" :rules="model.rule('is_default')" :true-value="1"/>
          </v-col>
          <v-col cols="12">
            <v-switch
                v-model="model.attr.editable" :false-value="0" :label="model.labels.editable" :rules="model.rule('editable')" :true-value="1"
                hint="Для перехода в нередактируемый статус необходимо подтверждение"
            />
          </v-col>
          <v-col cols="12">
            <v-switch v-model="model.attr.fix_sum" :false-value="0" :label="model.labels.fix_sum" :rules="model.rule('fix_sum')" :true-value="1"/>
          </v-col>
          <v-col cols="12">
            <v-select
                v-model="model.attr.available_status_ids"
                :items="availableStatusList"
                :label="model.labels.available_status_ids"
                :menu-props="{offsetY: true}"
                :rules="model.rule('available_status_ids')"
                clearable
                item-text="name"
                item-value="id"
                multiple
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal        from "@/tags/Modal";
import {DealStatus} from "@/models/DealStatus";
import {colorList}  from "@/helpers/lists";


export default {
  name:       "StatusForm",
  components: {Modal},
  props:      ['id'],
  data() {
    return {
      modal:     false,
      model:     DealStatus.get(this.id, {
        on: {
          save:  () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      colorList,
      statusList: [],
    }
  },
  computed: {
    availableStatusList() {
      return this.statusList.filter(item => item.id !== this.model.attr.id);
    }
  },
  watch:    {
    modal(value) {
      if (value) {
        this.id && this.model.load();
        !this.id && this.model.clear();
        this.loadLists();
      }
    }
  },
  methods:  {
    close() {
      this.$refs.form.reset();
      this.modal = false
    },
    loadLists() {
      this.axios.get('deal-status/list').then(response => this.statusList = response.data)
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-alert class="white--text" color="success">
    Регистрация прошла успешно. Проверьте почту для дальнейших инструкций.
  </v-alert>
</template>
<script lang="ts">

export default {
  name: "RegistrationComplete"
}
</script>


<style scoped>

</style>
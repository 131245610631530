import {Model} from "@/models/Model";


export class DocumentFiles extends Model {
    modelUri = 'document-file';
    labels = {
        files:  'Файлы',
        public: 'Для всех'
    }

    fields = [
        'files',
        'folder_id',
        'public'
    ]
}
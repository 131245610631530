<template>
  <modal v-model="modal" :loading="disabled" :title="title" max-width="800">
    <template v-if="!disabled" #activator>
      <slot/>
    </template>
    <v-card-text>
      <h3 class="mt-4 mb-2">Срок жизни ссылки</h3>
      <r-card>
        <v-radio-group v-model="period" class="px-4" row>
          <v-radio v-for="p in periods" :key="p.value" :label="p.name" :value="p.value"/>
        </v-radio-group>
      </r-card>
      <div class="mt-4">
        <v-btn :disabled="disableCopyBtn" color="primary" @click="createLink()">
          <v-icon>mdi-content-copy</v-icon>
          Копировать
        </v-btn>
        <v-btn v-if="showShareBtn" :disabled="disableShareBtn" class="ml-2" color="primary" @click="createLink(true)">
          <v-icon>mdi-share-outline</v-icon>
          Поделиться
        </v-btn>
      </div>
      <div v-if="linksList.length">
        <h3 class="mt-4 mb-2">Ранее созданные ссылки</h3>
        <r-card>
          <v-list-item-group>
            <ms-result-link-item v-for="link in linksList" :id="value.id" :key="link.key" :disabled="disabled" :link="link" @copy="copyLink" @remove="removeLink" @share="shareLink"/>
          </v-list-item-group>
        </r-card>
      </div>
    </v-card-text>
    <template #actions>
      <v-btn color="primary" @click="close">Закрыть</v-btn>
    </template>
  </modal>
</template>
<script>

import {apiUri, copyText} from "@/helpers/stringHelpers";
import Modal              from "@/tags/Modal.vue";
import RCard              from "@/tags/RCard.vue";
import MsResultLinkItem   from "@/views/msScript/MsResultLinkItem.vue";

export default {
  name:       "MsResultLinkForm",
  components: {MsResultLinkItem, RCard, Modal},
  props:      {
    value: {
      type:     Object,
      required: true
    },
    disabled: {
      type:    Boolean,
      default: false
    }
  },
  data() {
    return {
      period:  3600 * 24 * 7,
      periods: [
        {
          name:  'Сутки',
          value: 3600 * 24
        },
        {
          name:  'Неделя',
          value: 3600 * 24 * 7
        },
        {
          name:  'Месяц',
          value: 3600 * 24 * 30
        },
        {
          name:  'Постоянная',
          value: -1
        }
      ],
      waitShare: false,
      waitCopy:  false,
      modal:     false,
      newLink:   undefined
    }
  },
  computed: {
    linksList() {
      if (typeof this.value.linksList !== 'object') {
        return [];
      }
      return Object.values(this.value.linksList);
    },
    title() {
      return `Поделиться ссылкой на файлы: ${this.value?.script?.name}`;
    },
    disableShareBtn() {
      return this.disabled
    },
    disableCopyBtn() {
      return this.disabled;
    },
    showShareBtn() {
      return !!navigator.share;
    }
  },
  watch: {
    linksList(newLinks, oldLinks) {
      if (newLinks.length > oldLinks.length) {
        this.newLink = newLinks.filter(newLink => oldLinks.every(oldLink => oldLink.key !== newLink.key)).pop();
      } else {
        this.newLink = undefined;
      }
    },
    newLink(value) {
      if (value) {
        if (this.waitCopy) {
          this.copyLink(value);
          this.waitCopy = false;
        }
        if (this.waitShare) {
          this.shareLink(value);
          this.waitShare = false;
        }
      }
    },
    modal(value) {
      if (value) {
        this.period = 3600 * 24 * 7;
      }
    }
  },
  methods: {
    createLink(share = false) {
      const value = {...this.value, period: this.period, action: 'add'};
      this.$emit('input', value);
      if (share) {
        this.waitShare = true
      } else {
        this.waitCopy = true;
      }
    },
    removeLink(link) {
      const value = {...this.value, key: link.key, action: 'remove'};
      this.$emit('input', value);
    },
    createUrl(link) {
      return apiUri('ms-result/download?' + (new URLSearchParams({id: this.value.id, key: link.key})));
    },
    copyLink(link) {
      let url = this.createUrl(link);
      copyText(url);
    },
    shareLink(link) {
      navigator.share({url: this.createUrl(link)})
    },
    close() {
      this.modal = false;
    }
  }
}
</script>


<style scoped>

</style>
<template>
  <v-expansion-panels v-if="loading && isEmpty">
    <v-expansion-panel disabled>
      <v-expansion-panel-header>
        Загрузка...
      </v-expansion-panel-header>
    </v-expansion-panel>
  </v-expansion-panels>

  <v-expansion-panels v-else-if="isEmpty">
    <v-expansion-panel disabled>
      <v-expansion-panel-header>
        Каталогов не найдено
      </v-expansion-panel-header>
    </v-expansion-panel>
  </v-expansion-panels>

  <v-expansion-panels v-else multiple>
    <v-expansion-panel v-for="item in items" :key="item.id">
      <v-expansion-panel-header class="folder-header">
        <div class="d-flex">
          <v-icon :color="getColor(item)" class="mr-2">{{ getIcon(item) }}</v-icon>
          <b class="align-self-center">{{ item.name }}</b>
          <v-spacer/>
          <div v-if="can('document.manage')" class="text-right">
            <span v-if="!item.deleted_at">
              <files-form :folder="item" @save="$emit('update')">
                <btn-icon color="success" icon="file-plus" title="Загрузить файлы"/>
              </files-form>
            </span>
            <span v-if="!item.deleted_at" class="ml-2">
              <folder-form :id="item.id" :category="category" @save="$emit('update')">
                <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
              </folder-form>
            </span>
            <span v-if="!item.deleted_at" class="ml-2">
              <folder-delete :id="item.id" :name="item.name" @remove="$emit('update')"/>
            </span>
            <span v-if="item.deleted_at" class="ml-2">
              <folder-restore :id="item.id" :name="item.name" @restore="$emit('update')"/>
            </span>
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <file-list :category="category" :folder="item" :show-deleted="showDeleted" @update="$emit('update')"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {isEmpty}     from "lodash";
import FolderForm    from "@/views/documents/FolderForm";
import BtnIcon       from "@/tags/BtnIcon";
import {mapGetters}  from "vuex";
import FolderDelete  from "@/views/documents/FolderDelete";
import FolderRestore from "@/views/documents/FolderRestore";
import FileList      from "@/views/documents/FileList";
import FilesForm     from "@/views/documents/FilesForm";

export default {
  name:       "FolderList",
  components: {FilesForm, FileList, BtnIcon, FolderForm, FolderDelete, FolderRestore},
  props:      {
    items: {
      type: Array
    },
    loading: {
      type: Boolean
    },
    category: {
      type:     Number,
      required: true
    },
    showDeleted: {
      type: Number
    }
  },
  computed: {
    ...mapGetters(['can']),
    isEmpty() {
      return isEmpty(this.items);
    }
  },
  methods: {
    getType(item) {
      return this.can('document.manage') ? (item.deleted_at ? 'deleted' : (item.public ? 'public' : 'private')) : 'public';
    },
    getIcon(item) {
      return 'mdi-folder' + {
        public:  '',
        private: '-lock',
        deleted: '-remove'
      }[this.getType(item)];
    },
    getColor(item) {
      return {
        public:  'warning',
        private: 'primary',
        deleted: 'secondary'
      }[this.getType(item)];
    }
  }
}
</script>

<style scoped>
.folder-header:hover {
  background-color: #efefef;
}
</style>
import { render, staticRenderFns } from "./ToggleDeleted.vue?vue&type=template&id=49bddf68&scoped=true"
import script from "./ToggleDeleted.vue?vue&type=script&lang=js"
export * from "./ToggleDeleted.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49bddf68",
  null
  
)

export default component.exports
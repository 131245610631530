<template>
  <div>
    <div v-for="(category, index) in categories" :key="index">
      <h3 class="mt-4 mb-n2">{{ category.name }}</h3>
      <div v-for="item in category.list" :key="item.name">
        <v-switch v-model="item.allowed" :disabled="loading" :label="item.description" hide-details @change="change(item.name,item.allowed)"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name:  "PermissionsForm",
  props: ['id', 'categories', 'type'],
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    change(name, allowed) {
      this.loading = true;
      this.axios.post('role/change?id=' + this.id + '&item_type=' + this.type,
          {permission: name, type: allowed ? 'allow' : 'deny'}
      )
          .then(() => {
            this.$emit('update');
            this.loading = false
          });
    }
  }
}
</script>


<style scoped>

</style>
<template>
  <div>
    <v-progress-linear v-if="model.loading" class="mb-n1" indeterminate/>
    <v-form ref="form" :disabled="model.loading" class="pt-4">
      <v-row>
        <v-col cols="12">
          <v-slider
              v-model="userSafety.authKeyExpire.default"
              :hint="hintTime(userSafety.authKeyExpire.default, true)"
              :max="sliderExpireDefault.max"
              :min="sliderExpireDefault.min"
              :step="sliderExpireDefault.step"
              inverse-label
              label="Время сессии по умолчанию"
              persistent-hint
          />
        </v-col>
        <v-col cols="12">
          <v-slider
              v-model="userSafety.authKeyExpire.temp"
              :hint="hintTime(userSafety.authKeyExpire.temp, false)"
              :max="sliderExpireTemp.max"
              :min="sliderExpireTemp.min"
              :step="sliderExpireTemp.step"
              inverse-label
              label="Время короткой сессии"
              persistent-hint
          />
        </v-col>
        <v-col cols="12">
          <v-slider
              v-model="userSafety.passwordMinLength"
              :hint="userSafety.passwordMinLength.toString()"
              :max="sliderPasswordLength.max"
              :min="sliderPasswordLength.min"
              :step="sliderPasswordLength.step"
              inverse-label
              label="Минимальная длина пароля"
              persistent-hint
          />
        </v-col>
        <v-col cols="12">
          <v-slider
              v-model="userSafety.maxSessions"
              :hint="hintSessions(userSafety.maxSessions)"
              :max="sliderMaxSessions.max"
              :min="sliderMaxSessions.min"
              :step="sliderMaxSessions.step"
              inverse-label
              label="Максимальное количество сессий"
              persistent-hint
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-switch
              v-model="userSafety.twoAuth"
              :false-value="0"
              :true-value="1"
              label="Двухфакторная авторизация"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-switch
              v-model="userSafety.ipLink"
              :false-value="0"
              :true-value="1"
              label="Привязка к IP"
          />
        </v-col>
        <v-col cols="12">
          <v-btn :disabled="model.loading" class="mb-2 mr-2" color="primary" @click="sendForm()">{{ $t('btn.save') }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import {Form} from "@/models/Form";

export default {
  name:       "UserSafetyForm",
  components: {},
  props:      {
    show: {
      type:    Boolean,
      default: false
    }
  },
  created() {
    this.initValue();
  },
  mounted() {
    this.loadSetting();
  },
  data: () => ({
    model: Form.create({
      uri: {
        addr:   'setting/set',
        params: {name: 'user'}
      },
      attrs: ['value'],
      on:    {
        error: () => {
          this.$refs.form.validate();
        }
      }
    })
  }),
  watch: {
    show(value) {
      if (value) {
        this.loadSetting();
      }
    }
  },
  computed: {
    userSafety: {
      get() {
        return this.model.attr.value.setting.safety;
      },
      set(value) {
        this.model.attr.value.setting.safety = value;
      }
    },
    sliderExpireDefault() {
      return {
        min:  0,
        max:  7 * 24 * 3600,
        step: 3600
      };
    },
    sliderExpireTemp() {
      return {
        min:  0,
        max:  3600,
        step: 300
      };
    },
    sliderMaxSessions() {
      return {
        min:  0,
        max:  10,
        step: 1
      }
    },
    sliderPasswordLength() {
      return {
        min:  1,
        max:  32,
        step: 1
      }
    }
  },
  methods: {
    sendForm() {
      this.model.send();
    },
    loadSetting() {
      this.initValue();
      this.model.loading = true;
      this.axios.get('setting/get', {params: {name: 'user.setting.safety'}})
          .then(response => {
            this.model.attr.value.setting.safety = response.data || {};
            this.model.loading = false;
          }).catch(() => {
        this.model.loading = false;
      })
    },
    initValue() {
      this.model.attr = {value: {setting: {safety: {authKeyExpire: {default: 0, temp: 0}, passwordMinLength: 0, maxSessions: 0, twoAuth: 0, ipLink: 0}}}}
    },
    hintTime(value, forever) {
      if (value === 0) {
        return forever ? 'Навсегда' : 'Отключено';
      }

      const secondsInMinute = 60;
      const secondsInHour = 60 * secondsInMinute;
      const secondsInDay = 24 * secondsInHour;

      const days = Math.floor(value / secondsInDay);
      const hours = Math.floor((value % secondsInDay) / secondsInHour);
      const minutes = Math.floor((value % secondsInHour) / secondsInMinute);

      let period = '';
      if (days > 0) {
        period += `${days} дней `;
      }
      if (hours > 0) {
        period += `${hours} часов `;
      }
      if (minutes > 0) {
        period += `${minutes} минут `;
      }

      return period.trim();
    },
    hintSessions(value) {
      return value ? value.toString() : 'Неограниченно'
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="login-form-layer d-flex justify-center fill-height align-center">
    <v-title title="Восстановление пароля"/>
    <v-card class="login-form" max-width="500px">
      <v-card-title class="justify-center pt-6">Восстановления пароля</v-card-title>
      <v-progress-linear v-if="model.loading" class="mb-n1" indeterminate/>
      <v-card-text class="px-8 pt-4">
        <v-scroll-y-transition mode="out-in">
          <v-alert v-if="fail" color="error">
            Неверные данные для восстановления пароля.
          </v-alert>
          <v-alert v-if="success && !fail" class="white--text" color="success">
            <p>Пароль успешно изменен. Теперь вы можете</p>
            <div class="text-center">
              <v-btn :to="{name:'Login'}" color="">Авторизоваться</v-btn>
            </div>
          </v-alert>
          <v-form v-if="!success && !fail" ref="form" :disabled="model.loading" @submit.prevent="model.send()">
            <password-input ref="password" v-model="model.attr.password" :rules="model.rule('password')" label="Новый пароль" @keyup.enter="$refs.password.focus()"/>
          </v-form>
        </v-scroll-y-transition>
      </v-card-text>
      <v-card-actions class="justify-center pb-6">
        <v-btn v-if="!success && !fail" :disabled="model.loading" color="primary" @click="model.send()">Сохранить пароль</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {Form}        from "@/models/Form";
import VTitle        from "@/tags/VTitle";
import PasswordInput from "@/tags/PasswordInput";


export default {
  name:       "PasswordReset",
  components: {PasswordInput, VTitle},
  created() {
    this.model.fill({token: this.$route.query.token})
    if (!this.$route.query.token || this.$route.query.token.length !== 43) {
      this.fail = true;
    }
  },
  data() {
    return {
      fail:    false,
      success: false,
      model:   Form.create({
        uri:   'auth/password-reset',
        attrs: ['token', 'password'],
        on:    {
          send: (data) => {
            if (data === true) {
              this.success = true
            }
          },
          error: () => {
            let token = this.model.rule('token');
            if (token[0] !== true) this.fail = true;
            this.$refs.form.validate();
          }
        }
      })
    };
  },
  methods: {
  }
};
</script>

<style scoped>

.login-form {
  width: 100%;
  max-width: 500px;
}
</style>
import {Model} from "@/models/Model";

export class ContractTag extends Model {
    modelUri = 'contract-tag';

    labels = {
        name:           'Название',
        description:    'Описание',
        type:           'Объект',
        property:       'Свойство',
        tag:            'Тег',
        transformation: 'Преобразование',
        editable:       'Редактируемый',
        values: 'Значения',
    }

    fields = [
        'name',
        'description',
        'type',
        'property',
        'transformation',
        'editable',
        'values',
    ];
}
import {Model} from "@/models/Model";

export class LeadType extends Model {
    modelUri = 'lead-type';
    labels = {
        name: 'Название'
    }

    fields = [
        'name',
        'color',
    ]
}
import {Model} from "@/models/Model";
import Vue     from "vue";


export class Client extends Model {
    modelUri = 'client';

    labels = {
        last_name:           'Фамилия',
        first_name:          'Имя',
        patronymic:          'Отчество',
        fullName:            'ФИО',
        city:                'Город',
        email:               'E-mail',
        phone:               'Телефон',
        extra_phone:         'Доп. Телефон',
        birthday:            'День Рождения',
        age:                 'Возраст',
        gender:              'Пол',
        success_chance:      'Вероятность проведения сделки',
        status_id:           'Статус клиента',
        source_id:           'Источник клиента',
        category_id:         'Категория клиента',
        type_id:             'Тип лида',
        notice_date:         'Дата уведомления',
        meeting_success:     'Встреча состоялась',
        rejection_reason_id: 'Причина отказа',
        refund_reason_id:    'Причина возврата',
        manager_id:          'Менеджер',
        office_id:           'Офис',
        description:         'Описание',
        fssp:                'ФССП',
        bci:                 'БКИ',
        sb:                  'СБ',
        fms:                 'ФМС',
        employer:            'Работодатель',

        passport:             'Серия и номер паспорта',
        snils:                'СНИЛС',
        inn:                  'ИНН',
        passport_issued:      'Паспорт выдан (Код, название организации)',
        address_residence:    'Адрес фактического проживания',
        address_registration: 'Адрес регистрации',

        finished_at: 'Завершен',
        created_at:  'Создан',
        updated_at:  'Обновлен',
        deleted_at:  'Удален'
    }

    fields = [
        'last_name',
        'first_name',
        'patronymic',
        'city',
        'email',
        'phone',
        'extra_phone',
        'birthday',
        'gender',
        'success_chance',
        'status_id',
        'source_id',
        'category_id',
        'type_id',
        'notice_date',
        'meeting_success',
        'rejection_reason_id',
        'refund_reason_id',
        'manager_id',
        'description',
        'fssp',
        'bci',
        'sb',
        'fms',
        'employer',
        'passport',
        'snils',
        'inn',
        'passport_issued',
        'address_residence',
        'address_registration',
    ];

    setFields(fields: any[]) {
        this.attr.fields = fields;
        const newFields: string[] = fields.map((field): string => "___" + field.id);
        this.fields = this.fields.filter((field: string) => !field.startsWith("___"));
        this.fields.push(...newFields);
    }

    setFavorite(value: boolean) {
        this.loading = true;
        Vue.axios.post('client/set-favorite?id=' + this.attr.id, {value})
            .then(() => {
                this.loading = false;
                this.emit('setFavorite');
            }).catch(() => this.loading = false);
    }

}

export const CLIENT_FIELDS_COPY: string[] = [
    'last_name',
    'first_name',
    'patronymic',
    'city',
    'birthday',
    'gender',
    'phone',
    'extra_phone',
    'email',
    'passport',
    'snils',
    'inn',
    'passport_issued',
    'address_residence',
    'address_registration',
];
<template>
  <v-card>
    <v-title :title="'Документы: ' + categories[category] "/>
    <v-card-title>
      <span class="mr-5">Документы: {{ categories[category] }}</span>
      <span v-if="can('document.manage')">
        <folder-form :category="category" @save="models.load()">
          <btn-icon color="success" icon="folder-plus" title="Добавить каталог"/>
        </folder-form>
      </span>
      <toggle-deleted v-if="can('document.manage')" v-model="showDeleted" class="ml-2"/>
      <v-spacer/>
      <v-text-field v-model="search" :label="$t('btn.search')" append-icon="mdi-magnify" clearable hide-details/>
    </v-card-title>
    <v-progress-linear v-if="models.loading" class="mb-n1" indeterminate/>
    <folder-list :category="category" :items="models.result" :loading="models.loading" :show-deleted="showDeleted" class="mt-2" @update="models.load()"/>
  </v-card>
</template>

<script>
import {Search}      from '@/models/Search';
import {debounce}    from 'lodash/function';
import VTitle        from '@/tags/VTitle';
import {mapGetters}  from 'vuex';
import ToggleDeleted from "@/views/documents/ToggleDeleted";
import FolderForm    from "@/views/documents/FolderForm";
import BtnIcon       from "@/tags/BtnIcon";
import FolderList    from "@/views/documents/FolderList";

export default {
  name:       'Index',
  components: {FolderList, FolderForm, VTitle, ToggleDeleted, BtnIcon},
  mounted() {
    this.models.params.category = this.category;
    this.models.load();
  },
  props: {
    category: {
      type:     Number,
      required: true
    }
  },
  data: () => ({
    search: '',
    models: Search.create({
      uri:    'document-folder',
      params: {deleted: 0, search: '', category: null}
    }),
    showDeleted: 0,
    categories:  ['Клиенты', 'Обучение']
  }),
  watch: {
    search(value) {
      this.models.params.search = value || '';
      this.update(this);
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    },
    category() {
      this.models.params.category = this.category;
      this.models.load();
    }
  },
  computed: {
    ...mapGetters(['can'])
  },
  methods: {
    update: debounce((vm) => vm.models.load(), 200)
  }
};
</script>

<style scoped></style>
<template>
  <v-expand-transition>
    <v-system-bar v-if="show" app color="primary" dark>
      <marquee>
        {{ showQuote }}
      </marquee>
    </v-system-bar>
  </v-expand-transition>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name:       "QuoteBar",
  components: {},
  mounted() {
    this.setQuotes();
    this.updateCurrentTime();
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  data: () => ({
    currentTime: new Date(),
    quotes:      [],
    showTime:    {
      start: {
        h: 12,
        m: 0
      }, end: {
        h: 12,
        m: 30
      }
    },
    timeout: undefined
  }),
  computed: {
    ...mapGetters(['isGuest', 'setting']),
    show() {
      if (this.isGuest || !this.quotes.length) {
        return false;
      }
      let showMinutesStart = this.showTime.start.h * 60 + this.showTime.start.m;
      let showMinutesEnd = this.showTime.end.h * 60 + this.showTime.end.m;
      let currentMinutes = this.currentTime.getHours() * 60 + this.currentTime.getMinutes();
      return !(currentMinutes < showMinutesStart || currentMinutes > showMinutesEnd);
    },
    showQuote() {
      if (!this.quotes.length) {
        return '';
      }
      let n = Math.floor(this.currentTime.getTime() / (1000 * 3600 * 24)) % this.quotes.length;
      return this.quotes[n];
    }
  },
  watch: {

  },
  methods: {
    setQuotes() {
      this.quotes = this.setting?.content?.daily_quotes || []
    },
    updateCurrentTime() {
      this.timeout = setTimeout(() => {
        this.currentTime = new Date()
        this.updateCurrentTime();
        this.setQuotes();
      }, 1000);
    }
  }

}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-file-input
        ref="inputFile"
        v-model="file"
        :accept="accept"
        :background-color="backgroundColor"
        :hint="hint"
        :label="label"
        :multiple="multiple"
        :persistent-hint="persistentHint"
        :prepend-inner-icon="icon"
        :rules="rules"
        prepend-icon=""
        show-size
        @change="handleFileChange"
    >
      <template #append-outer>
        <slot name="append-outer"/>
      </template>
    </v-file-input>
    <v-row v-if="previewUrls.length">
      <v-col v-for="(url, index) in previewUrls" :key="index" cols="12">
        <v-img :src="url" aspect-ratio="1" contain height="100px"></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name:  'FileInput',
  props: {
    value:          {
      default() {
        return [];
      }
    },
    rules:          {
      type: Array
    },
    icon:           {
      type:    String,
      default: 'mdi-paperclip'
    },
    label:          {
      type: String
    },
    accept:         {
      type: String
    },
    backgroundColor: {
      type: String
    },
    multiple:       {
      type:    Boolean,
      default: false
    },
    hint:           {
      type: String
    },
    persistentHint: {
      type:    Boolean,
      default: false
    }
  },
  data() {
    return {
      file:        this.value || [],
      previewUrls: []
    };
  },
  watch:   {
    file(value) {
      this.$emit('input', value);
    }
  },
  methods: {
    validate() {
      this.$refs.inputFile.validate();
    },
    handleFileChange(files) {
      this.previewUrls = [];
      if (!files) return;

      const fileArray = Array.isArray(files) ? files : [files];
      fileArray.forEach(file => {
        if (file && file.type.startsWith('image/')) {
          const reader  = new FileReader();
          reader.onload = (e) => {
            this.previewUrls.push(e.target.result);
          };
          reader.readAsDataURL(file);
        }
      });
    }
  }
};
</script>

<style scoped></style>
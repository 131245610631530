import {Model} from "@/models/Model";


export class DocumentFile extends Model {
    modelUri = 'document-file';
    labels = {
        name:      'Название',
        public:    'Для всех',
        folder_id: 'Каталог'
    }
    fields = [
        'name',
        'folder_id',
        'public'
    ]
}
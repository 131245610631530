import Vue                 from "vue";
import {Target}            from "@/models/Target";
import {CancelTokenSource} from "axios";

interface SearchOptions {
    filter?: object;
    sort?: SortOptions;
    page?: number;
    perPage?: number;
    fields?: string[];
    expand?: string[];
    params?: object;
    uri?: string;
    on?: Record<string, Function>
}

interface SortOptions {
    field: string;
    sortAsc?: boolean;
}

export class Search extends Target {
    uri: string = ''
    config: any = {
        filter:     {},
        sort:       '',
        page:       1,
        "per-page": 20
    }
    query: any = {
        fields: "",
        expand: ""
    };
    params: any = {}
    headers: any = {
        page:         1,
        pageCount:    1,
        itemsPerPage: 20,
        itemsLength:  0
    }
    result: object[] = []
    loading: boolean = true
    cancelTokenSource: CancelTokenSource

    constructor(options: SearchOptions = {}) {
        super()
        this.set(options)
        this.cancelTokenSource = Vue.axios.CancelToken.source();
    }

    static create(options: SearchOptions = {}) {
        return new this(options);
    }

    set(options: SearchOptions = {}) {
        if (options.filter) {
            this.setFilter(options.filter);
        }
        if (options.sort) {
            this.setSort(options.sort.field, options.sort.sortAsc);
        }
        if (options.page) {
            this.setPage(options.page);
        }
        if (options.perPage) {
            this.setPerPage(options.perPage);
        }
        if (options.fields) {
            this.setFields(options.fields);
        }
        if (options.expand) {
            this.setExpand(options.expand);
        }
        if (options.params) {
            this.setParams(options.params);
        }
        if (options.uri) {
            this.setUri(options.uri);
        }
        if (options.on) {
            for (let event in options.on) {
                this.on(event, options.on[event])
            }
        }
        return this;
    }


    setFilter(value: object) {
        this.config.filter = value;
        return this;
    }

    setSort(field: string, sortAsc: boolean = true) {
        this.config.sort = sortAsc ? field : '-' + field;
        return this;
    }

    setPage(page: number) {
        this.config.page = page;
        return this;
    }

    setPerPage(perPage: number) {
        this.config['per-page'] = perPage;
        return this;
    }

    setFields(fields: string[]) {
        this.query.fields = fields.join(',');
        return this;
    }

    setExpand(expand: string[]) {
        this.query.expand = expand.join(',');
        return this;
    }

    setParams(params: object) {
        this.params = params
        return this;
    }

    setUri(uri: string) {
        this.uri = uri;
        return this;
    }

    load() {
        this.loading = true
        let query = new URLSearchParams();
        for (let i in this.query) query.set(i, this.query[i])
        this.cancelTokenSource.cancel();
        this.cancelTokenSource = Vue.axios.CancelToken.source();
        Vue.axios.post(
            this.uri + '?' + query.toString(),
            Object.assign(this.params, this.config),
            {cancelToken: this.cancelTokenSource.token}
        )
            .then((response) => {
                this.result = response.data
                this.headers.page = parseInt(response.headers['x-pagination-current-page']);
                this.headers.pageCount = parseInt(response.headers['x-pagination-page-count']);
                this.headers.itemsPerPage = parseInt(response.headers['x-pagination-per-page']);
                this.headers.itemsLength = parseInt(response.headers['x-pagination-total-count']);
                this.emit('load', response.data);
                this.loading = false
            })
            .catch((error) => {
                console.error(error)
            });
        return this;
    }


}
<template>
  <modal v-model="modal" :loading="model.loading" :title="id?'Изменение типа лида':'Создание типа лида'" max-width="800px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          </v-col>
          <v-col cols="12">
            <v-select v-model="model.attr.color" :items="colorList" :label="model.labels.color" :rules="model.rule('color')">
              <template v-slot:item="{ item }">
                <v-chip :color="item" dark>{{ item }}</v-chip>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip :color="item" dark>{{ item }}</v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="id" cols="12">
            <v-list-item-subtitle class="text--secondary d-flex">
              Доп. поля
              <v-spacer/>
              <field-form :type-id="id" @save="$refs.list.models.load()">
                <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
              </field-form>
            </v-list-item-subtitle>
            <field-list ref="list" :type-id="id"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal        from "@/tags/Modal";
import {LeadType}   from "@/models/LeadType";
import {mapGetters} from "vuex";
import FieldForm    from "@/views/clientField/FieldForm.vue";
import BtnIcon      from "@/tags/BtnIcon.vue";
import {colorList}  from "@/helpers/lists";


export default {
  name:       "LeadTypeForm",
  components: {BtnIcon, FieldForm, FieldList: () => import('@/views/clientField/FieldList'), Modal},
  props:      ['id'],
  data() {
    return {
      modal: false,
      model: LeadType.get(this.id, {
        on: {
          save: () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      colorList,
    }
  },
  computed: {
    ...mapGetters(['can'])
  },
  watch: {
    modal(value) {
      if (value) {
        this.id && this.model.load();
        !this.id && this.model.clear();
      }
    }
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.modal = false
    },
    canClientField() {
      return !!this.id && this.can('client-field.manage');
    }
  }
}
</script>

<style scoped>

</style>
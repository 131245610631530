<template>
  <v-card>
    <v-title title="Календарь"/>
    <v-card-title>
      <span class="mr-5">Календарь</span>
      <btn-icon v-if="canFilter" :icon="!showFilter?'filter-outline':'filter-off-outline'" :title="showFilter?'Скрыть фильтр':'Показать фильтр'" class="ml-2" color="info" @click="toggleFilter"/>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
      />
    </v-card-title>
    <client-calendar-filter-form v-if="canFilter" v-model="searchParams" :class="showFilter?'mb-2':'d-none'" @input="updateFilter"/>
    <client-calendar
        :search="search"
        :search-params="searchParams"
    />
  </v-card>
</template>

<script>
import VTitle                   from "../../tags/VTitle";
import {mapGetters}             from "vuex";
import ClientCalendar           from "./ClientCalendar";
import ClientCalendarFilterForm from "@/views/clientCalenar/ClientCalendarFilterForm";
import BtnIcon                  from "@/tags/BtnIcon";

export default {
  name:       "Index",
  components: {ClientCalendarFilterForm, ClientCalendar, VTitle, BtnIcon},
  data: () => ({
    search:       '',
    searchParams: {filter: {}, params: {}},
    showFilter:   true
  }),
  computed: {
    ...mapGetters(['can']),
    canFilter() {
      return this.can('user.manage') || this.can('office.manage');
    }
  },
  watch:   {},
  methods: {
    updateFilter(value) {
      this.searchParams = Object.assign({}, value);
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    }
  }

}
</script>

<style scoped>

</style>
<template>
  <v-list class="mb-n4" color="transparent" dense rounded>
    <v-hover v-slot="{ hover }">
      <v-list-item :class="{[colorItem]:true}" class="my-n2">
        <v-list-item-content>
          <v-list-item-title>{{ tag.name }}</v-list-item-title>
          <v-list-item-subtitle v-if="tag.description" class="font-weight-light">{{ tag.description }}</v-list-item-subtitle>
          <v-list-item-subtitle>
            <code class="rounded-pill">{{ '${' + tag.tag + '}' }}</code>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <contract-tag-form v-if="canAdd" :id="tagId" :class="{'d-none':!hover}" :tag="tagData" @save="$emit('update')">
            <btn-icon color="success" icon="plus" title="Создать"/>
          </contract-tag-form>
          <contract-tag-form v-if="canEdit" :id="tagId" :class="{'d-none':!hover}" :tag="tagData" @save="$emit('update')">
            <btn-icon color="primary" icon="pencil" title="Изменить"/>
          </contract-tag-form>
          <delete v-if="canDelete" :id="tagId" :class="{'d-none':!hover}" :name="tag.name" @remove="$emit('update')">
            <btn-icon color="error" icon="trash" title="Удалить"/>
          </delete>
          <btn-icon :title="copyTitle" color="primary" icon="content-copy" @click="copyTag()"/>
        </v-list-item-icon>
      </v-list-item>
    </v-hover>
  </v-list>
</template>
<script>
import BtnIcon         from "@/tags/BtnIcon.vue";
import {copyText}      from "@/helpers/stringHelpers";
import ContractTagForm from "@/views/contractFiles/tags/ContractTagForm.vue";
import Delete          from "./Delete.vue";

export default {
  name:       'ContractTagItem',
  components: {Delete, ContractTagForm, BtnIcon},
  props:      {
    tag: {
      type:     Object,
      required: true,
    },
    defaultTag: {
      type: Object,
    }
  },
  data() {
    return {
      copyTitle: 'Копировать',
    };
  },
  computed: {
    canAdd() {
      return this.tag.type === 'default';
    },
    canEdit() {
      return this.tag.type === 'custom';
    },
    canDelete() {
      return this.tag.type === 'custom';
    },
    tagId() {
      return this.tag.tag.split(':')[2] || null;
    },
    tagData() {
      return this.defaultTag || this.tag;
    },
    colorItem() {
      return this.tag.type === 'default' ? 'white' : 'yellow lighten-4';
    }
  },
  methods:  {
    copyTag() {
      copyText('${' + this.tag.tag + '}');
      this.copyTitle = 'Скопировано';
      setTimeout(() => this.copyTitle = 'Копировать', 500);
    }
  }
}
</script>


<style scoped>

</style>
<template>
  <div class="card">
    <div class="card-title">{{ title }}</div>
    <div class="card-info">
      <div v-if="loading" class="card-loading card-loading-chart">
        <v-skeleton-loader height="400px" type="image, list-item@2"/>
      </div>
      <div v-else class="card-chart">
        <highcharts :options="options"/>
        <div :style="summaryStyle" class="card-chart-summary">
          <div>
            <span v-if="prefix" class="mr-2 secondary--text">{{ prefix }}</span>
            <span class="card-value">{{ summary.value }}</span>
            <span v-if="summary.suffix" class="ml-1">{{ summary.suffix }}</span>
          </div>
          <div v-if="showDiff" class="card-value-prev">
            <v-chip :color="colorDiff" dark small>{{ formattedDiff }}</v-chip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import variablePieModule        from 'highcharts/modules/variable-pie';
import Highcharts               from "highcharts";
import {isArray, isNull, round} from "lodash";
import {shortNumber}            from "@/helpers/stringHelpers";

variablePieModule(Highcharts)

export default {
  name:       "ChartCard",
  components: {},
  props:      {
    value:    {
      type: Array,
    },
    previous: {
      type: Array,
    },
    prefix:   {
      type: String
    },
    title:    {
      type: String
    },
    type:     {
      type: String
    },
    loading:  {
      type: Boolean
    },
    other:    {
      type:    Number,
      default: 0.01,
    },
    inverse:  {
      type: Boolean,
    }
  },
  mounted() {
    this.updateOptions();
  },
  data() {
    return {
      options:  {},
      top:      116,
      excluded: [],
    }
  },
  computed: {
    defaultOptions() {
      return {
        chart:       {
          type:            'variablepie',
          style:           {fontFamily: 'Roboto'},
          backgroundColor: 'transparent',
          events:          {
            render: (e) => {
              this.updateSummary(e);
            }
          }
        },
        title:       {text: ''},
        plotOptions: {
          variablepie: {
            size:         320,
            minSize:      320,
            innerSize:    '120px',
            borderRadius: '10px',
            minPointSize: 10,
            zMin:         0,
            dataLabels:   {
              enabled: false,
            },
            point:        {
              events: {
                legendItemClick: e => this.filterSeries(e.target),
              }
            }
          },
        },
        tooltip:     {pointFormat: this.pointFormat},
        exporting:   {enabled: false},
        credits:     {enabled: false},
        series:      [
          {
            name:         '',
            data:         [],
            colors:       ['#777777'],
            showInLegend: true,
          }
        ]
      }
    },
    summaryStyle() {
      return {top: this.top + 'px'}
    },
    pointFormat() {
      if (!this.showData) {
        return '';
      }
      return '<b>{point.y}</b> ({point.percentage:.1f}%)';
    },
    data() {
      if (!this.showData) {
        return [
          {
            name: 'Нет данных',
            y:    1,
            z:    1,
          }
        ];
      }
      const values = this.value.map(item => ({
        name: item.name,
        y:    item.value,
        z:    item.value + this.sum * 0.3,
      }));
      values.sort((a, b) => a.y - b.y)
      values.reverse();
      this.previous.forEach((item) => {
        if (values.findIndex(vItem => vItem.name === item.name) === -1) {
          values.push({
            name:  item.name,
            y:     0,
            z:     1,
            color: 'grey',
          })
        }
      })
      return values;
    },
    sum() {
      if (!this.showData) {
        return 0;
      }
      let data = this.value;
      if (this.excluded.length > 0) {
        data = data.filter(item => !this.excluded.includes(item.name))
      }
      return data.reduce((start, item) => start + item.value, 0)
    },
    prevSum() {
      if (!this.showData) {
        return 0;
      }
      let data = this.previous;
      if (this.excluded.length > 0) {
        data = data.filter(item => !this.excluded.includes(item.name))
      }
      return data.reduce((start, item) => start + item.value, 0)
    },
    colors() {
      if (!this.showData) {
        return ['#777777'];
      }
      return [
        '#E12435',
        '#E12494',
        '#CF24E1',
        '#7124E1',
        '#2436E1',
        '#2494E1',
        '#24E1CF',
        '#24E171',
        '#94E124',
        '#E1D024',
        '#E17124',
      ];
    },
    showData() {
      return !(!this.value || (isArray(this.value) && !this.value.length));
    },
    summary() {
      let value = this.sum;
      return shortNumber(value, 3);
    },
    diff() {
      if (isNull(this.previous) || isNull(this.value) || this.prevSum <= 0) {
        return null;
      }
      return (this.sum - this.prevSum) / this.prevSum;
    },
    formattedDiff() {
      return (this.diff > 0 ? '+' : '') + round(this.diff * 100, this.diff >= 1 ? 0 : 1) + '%';
    },
    showDiff() {
      return !isNull(this.diff);
    },
    colorDiff() {
      if (this.diff === 0) {
        return 'grey';
      }
      return (this.diff > 0 && !this.inverse) || (this.diff < 0 && this.inverse) ? 'success' : 'error';
    }
  },
  watch:    {
    value() {
      this.updateOptions();
    }
  },
  methods:  {
    updateOptions() {
      const options            = this.defaultOptions;
      options.series[0].data   = this.data;
      options.series[0].colors = this.colors;
      this.options             = options;
    },
    updateSummary(e) {
      this.top = e.target.plotTop + (e.target.plotHeight / 2) - 60;
    },
    filterSeries(series) {
      if (!series.visible) {
        this.excluded = this.excluded.filter(item => item !== series.name)
      } else {
        this.excluded.push(series.name);
      }
    }
  }
};
</script>
<style scoped>

</style>
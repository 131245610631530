<template>
  <v-card>
    <v-title :title="$t('role.title')"/>
    <v-card-title>
      <span class="mr-5">{{ $t('role.title') }}</span>
      <role-form @save="models.load()">
        <btn-icon :title="$t('btn.edit')" color="success" icon="plus"/>
      </role-form>
      <v-spacer/>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          clearable
          hide-details
          :label="$t('btn.search')"
      />

    </v-card-title>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <role-form :id="item.name" @save="models.load()">
            <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
          </role-form>
          <rule-form v-if="canManage(item)" :id="item.name" :name="item.description" class="ml-2" @close="models.load()">
            <btn-icon color="blue-grey" icon="cog" title="Настройка"/>
          </rule-form>
          <span v-if="canDelete(item)" class="ml-2">
            <delete :id="item.name" :name="item.description" @remove="models.load()"/>
          </span>
        </div>
      </template>
    </data-table>
  </v-card>
</template>

<script>
import {Search}   from "@/models/Search";
import {debounce} from "lodash/function";
import Delete     from "@/views/roles/Delete";
import DataTable  from "@/tags/DataTable";
import VTitle     from "@/tags/VTitle";
import RoleForm   from "@/views/roles/RoleForm";
import BtnIcon    from "@/tags/BtnIcon";
import RuleForm   from "@/views/roles/RuleForm";


export default {
  name:       "Index",
  components: {BtnIcon, RoleForm, RuleForm, VTitle, DataTable, Delete},
  computed: {
    headers() {
      return [
        {text: this.$t('role.name'), value: 'description', sortable: true},
        {text: this.$t('role.code'), value: 'name', sortable: true},
        {text: this.$t('role.permissions'), value: 'childrenCount'},
        {text: this.$t('role.users'), value: 'userCount'},
        {text: this.$t('role.actions'), value: 'actions', align: 'end'},
      ];
    }
  },
  data:    () => ({
    search: '',
    models: Search.create({
      uri:    'role',
      fields: ['name', 'description'],
      expand: ['childrenCount', 'userCount', 'permanent'],
      filter: {or: [{name: {like: ''}}, {description: {like: ''}}]}
    }).load(),
  }),
  watch:    {
    search(value) {
      this.models.config.filter.or[0].name.like        = value || '';
      this.models.config.filter.or[1].description.like = value || '';
      this.update(this)
    }
  },
  methods: {
    update: debounce((vm) => vm.models.load(), 200),
    canManage(item) {
      return item.name !== 'admin';
    },
    canDelete(item) {
      return item.name !== 'admin' && !item.permanent;
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-card>
    <v-title title="Категории клиентов"/>
    <v-card-title>
      <span class="mr-5">Категории клиентов</span>
      <span>
      <field-form @save="$refs.list.models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </field-form>
      </span>
      <toggle-deleted v-model="showDeleted" class="ml-2"/>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
          hide-details
      />
    </v-card-title>
    <field-list ref="list" :search="search" :show-deleted="showDeleted"/>
  </v-card>
</template>

<script>
import ToggleDeleted from "./ToggleDeleted";
import VTitle        from "@/tags/VTitle";
import BtnIcon       from "@/tags/BtnIcon";
import FieldForm     from "./FieldForm";
import FieldList     from "@/views/clientField/FieldList.vue";


export default {
  name:       "Index",
  components: {FieldList, FieldForm, BtnIcon, VTitle, ToggleDeleted},
  data: () => ({
    search:      '',
    showDeleted: 0
  })
}
</script>

<style scoped>

</style>
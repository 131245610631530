<template>
  <v-card-text class="pt-3 text-center">
    <h3 class="mb-2">Выберите скрипт для сбора списка документов</h3>
    <v-list class="transparent" rounded>
      <v-list-item-group v-model="selectedScript">
        <v-list-item v-for="script in scripts" :key="script.id" :value="script" class="white" color="secondary">
          <v-list-item-content>
            <v-list-item-title class="text-wrap" v-text="script.button_text"/>
            <v-list-item-subtitle v-if="script.description" class="text-wrap" v-text="script.description"/>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card-text>
</template>
<script>

export default {
  name:       "MsResultFormCreate",
  components: {},
  props:      {
    value: {
      type:     Object,
      required: true
    },
    scripts: {
      type:     Array,
      required: true
    },
    disabled: {
      type:    Boolean,
      default: false
    }
  },
  data() {
    return {
      data:           this.value,
      selectedScript: undefined
    }
  },
  computed: {},
  watch:    {
    selectedScript(value) {
      if (value) {
        this.selectScript(value);
      }
    }
  },
  methods: {
    selectScript(script) {
      const value = {...this.value, script_id: script.id};
      this.$emit('input', value);

    }
  }

};
</script>


<style scoped>

</style>
<template>
  <v-card style="height:100%">
    <v-title title="Импорт клиентов"/>
    <v-card-title>
      <span>Импорт клиентов</span>
    </v-card-title>
    <v-progress-linear v-if="model.loading" class="mb-n1" indeterminate/>
    <v-card-text class="pt-4">
      <v-alert border="top" color="primary" colored-border elevation="2">
        Перед импортом выберите колонки <span class="text--secondary">(A, B, C, D...)</span> в соответствии с указанными в них данными
        <span class="text--secondary">(Фамилия, Имя, Отчество, Телефон...)</span>. <br>
        Телефон вводить в формате: 79876543210 <br/>
        После укажите Источник клиента, тип лида и номер строки с которой начинать импорт клиентов, выберите файл и импортируйте клиентов.<br>
        Колонки кроме телефона не обязательны для импорта.
      </v-alert>
      <v-form ref="form" :disabled="model.loading" class="pt-4" @submit.prevent="model.send()">
        <v-row>
          <v-col cols="12" lg="3" md="4" sm="6">
            <v-select v-model="model.attr.last_name_column" :items="columnList" :rules="model.rule('last_name_column')" clearable label="Фамилия (колонка)"/>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6">
            <v-select v-model="model.attr.first_name_column" :items="columnList" :rules="model.rule('first_name_column')" clearable label="Имя (колонка)"/>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6">
            <v-select v-model="model.attr.patronymic_column" :items="columnList" :rules="model.rule('patronymic_column')" clearable label="Отчество (колонка)"/>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6">
            <v-select v-model="model.attr.phone_column" :items="columnList" :rules="model.rule('phone_column')" clearable label="Телефон (колонка)"/>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6">
            <v-select v-model="model.attr.email_column" :items="columnList" :rules="model.rule('email_column')" clearable label="E-mail (колонка)"/>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6">
            <v-autocomplete v-model="model.attr.source_id" :items="sourceList" :rules="model.rule('source_id')" clearable item-text="name" item-value="id" label="Источник клиента"/>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6">
            <v-autocomplete v-model="model.attr.type_id" :items="typeList" :rules="model.rule('type_id')" clearable item-text="name" item-value="id" label="Тип лида"/>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6">
            <v-select v-model="model.attr.start_row" :items="rowList" :rules="model.rule('start_row')" clearable label="Начинать со строки"/>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6">
            <file-input v-model="model.attr.import_file" :rules="model.rule('import_file')" accept=".xlsx,.csv,.ods" label="Файл импорта (xlsx, csv, ods)"/>
          </v-col>
        </v-row>
        <div>
          <v-btn color="primary" @click="model.send()">Импортировать</v-btn>
        </div>
      </v-form>
      <modal v-model="modal" title="Клиенты успешно импортированы">
        <v-card-text>Импортировано {{ count }} клиентов.</v-card-text>
      </modal>
    </v-card-text>
  </v-card>
</template>

<script>
import VTitle    from "../../tags/VTitle";
import {Form}    from "@/models/Form";
import FileInput from "@/tags/FileInput";
import Modal     from "@/tags/Modal";

export default {
  name:       "Import",
  components: {FileInput, VTitle, Modal},
  mounted() {
    this.axios.get('client-source/list').then((response) => this.sourceList = response.data)
    this.axios.get('lead-type/list').then((response) => this.typeList = response.data)
  },
  data() {
    return {
      model:      Form.create({
        uri:   'client/import',
        attrs: ['last_name_column', 'first_name_column', 'patronymic_column', 'phone_column', 'email_column', 'source_id', 'type_id', 'start_row', 'import_file'],
        on:    {
          send:  (data) => {
            this.count = data;
            this.modal = true;
            this.$refs.form.reset();
          },
          error: () => {
            this.$refs.form.validate()
          }
        }
      }),
      columnList: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
      sourceList: [],
      typeList:   [],
      rowList:    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      modal:      false,
      count:      0
    }
  }
}
</script>

<style scoped>

</style>
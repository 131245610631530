import {Model} from "@/models/Model";

export class Link extends Model {
    modelUri = 'link';
    labels = {
        name:        'Название',
        url:         'Ссылка',
        category_id: 'Категория'
    }

    fields = [
        'name',
        'url',
        'category_id'
    ];
}
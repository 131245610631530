import {Model} from "@/models/Model";

export class ClientField extends Model {
    modelUri = 'client-field';
    labels = {
        type_id:         'Тип лида',
        name:            'Название',
        hint:            'Подпись',
        type:            'Тип данных',
        required:        'Обязательный',
        params:          'Параметры',
        values:          'Значения',
        multiple:        'Множественный выбор',
        autofill_sum:    'Автозаполнение суммы',
        autofill_factor: 'Множитель'
    }

    fields = [
        'type_id',
        'name',
        'hint',
        'type',
        'required',
        'params',
        'values',
        'multiple',
        'autofill_sum',
        'autofill_factor'
    ];
}
<template>
  <v-card>
    <v-title title="Ссылки верификации"/>
    <v-card-title>
      <span class="mr-5">Ссылки верификации</span>
      <span>
      <link-form v-if="can('link.manage')" @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </link-form>
      </span>
      <toggle-deleted v-if="can('link.manage')" v-model="showDeleted" class="ml-2"/>
      <v-spacer/>
      <v-select
          v-model="category"
          :items="categoryList"
          class="ml-2"
          clearable
          hide-details
          item-text="name"
          item-value="id"
          label="Категория"
      />
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          class="ml-2"
          clearable
          hide-details
      />
    </v-card-title>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.created_at="{ item }">
        {{ utcToDatetime(item.created_at) }}
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ utcToDatetime(item.updated_at) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <span v-if="!item.deleted_at">
            <a :href="item.url" class="text-decoration-none" target="_blank">
              <btn-icon color="info" icon="arrow-top-right-thick" title="Открыть"/>
            </a>
          </span>
          <span v-if="!item.deleted_at && can('link.manage')" class="ml-2">
            <link-form :id="item.id" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </link-form>
          </span>
          <span v-if="!item.deleted_at && can('link.manage')" class="ml-2">
            <delete :id="item.id" :name="item.name" @remove="models.load()"/>
          </span>
          <span v-if="item.deleted_at && can('link.manage')" class="ml-2">
            <restore :id="item.id" :name="item.name" @restore="models.load()"/>
          </span>
        </div>
      </template>
    </data-table>

  </v-card>
</template>

<script>
import {Search}   from "@/models/Search";
import {debounce} from "lodash/function";

import Delete        from "@/views/links/Delete";
import {mapGetters}  from "vuex";
import ToggleDeleted from "@/views/links/ToggleDeleted";
import Restore       from "@/views/links/Restore";
import DataTable     from "@/tags/DataTable";
import VTitle        from "@/tags/VTitle";
import BtnIcon       from "@/tags/BtnIcon";
import LinkForm      from "./LinkForm";


export default {
  name:       "Index",
  components: {LinkForm, BtnIcon, VTitle, DataTable, Restore, ToggleDeleted, Delete},
  mounted() {
    this.axios.get('link-category/list').then((response) => this.categoryList = response.data)
  },
  data:     () => ({
    search:       '',
    category:     null,
    categoryList: [],
    models:       Search.create({
      uri:    'link',
      fields: ['id', 'name', 'url', 'category.name', 'created_at', 'updated_at', 'deleted_at'],
      expand: ['category'],
      params: {deleted: 0},
      filter: {
        name: {like: ''},
      },
    }).load(),
    showDeleted:  0,
  }),
  computed: {
    ...mapGetters(['can']),
    headers() {
      return [
        {text: 'Название', value: 'name', sortable: true},
        {text: 'Категория', value: 'category.name'},
        {text: 'Действия', value: 'actions', align: 'end'},
      ]
    }
  },
  watch:    {
    search(value) {
      this.models.config.filter.name.like = value || '';
      this.update(this)
    },
    category(value) {
      if (value) this.models.config.filter.category_id = value;
      else delete this.models.config.filter.category_id
      this.update(this);
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    },
  },
  methods:  {
    update: debounce((vm) => vm.models.load(), 200),
    utcToDatetime(value) {
      return this.$d(value * 1000, 'datetime');
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-card>
    <v-title title="Шаблоны договоров"/>
    <v-card-title>
      <span class="mr-5">Шаблоны договоров</span>
      <span>
      <contract-file-form @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </contract-file-form>
      </span>
      <toggle-deleted v-model="showDeleted" class="ml-2"/>
      <contract-tag-list class="ml-2">
        <btn-icon color="blue-gray" icon="cog" title="Настройка тегов"/>
      </contract-tag-list>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
          hide-details
      />
    </v-card-title>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.tags="{ item }">
        {{ countTags(item.tags) }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ utcToDatetime(item.created_at) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <span v-if="!item.deleted_at" class="ml-2">
            <contract-file-form :id="item.id" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </contract-file-form>
          </span>
          <span v-if="!item.deleted_at" class="ml-2">
            <delete :id="item.id" :name="item.name" @remove="models.load()"/>
          </span>
          <span v-if="item.deleted_at" class="ml-2">
            <restore :id="item.id" :name="item.name" @restore="models.load()"/>
          </span>
        </div>
      </template>
    </data-table>

  </v-card>
</template>

<script>
import {Search}   from "@/models/Search";
import {debounce} from "lodash/function";

import Delete           from "./Delete";
import {mapGetters}     from "vuex";
import ToggleDeleted    from "./ToggleDeleted";
import Restore          from "./Restore";
import DataTable        from "@/tags/DataTable";
import VTitle           from "@/tags/VTitle";
import BtnIcon          from "@/tags/BtnIcon";
import ContractFileForm from "./ContractFileForm.vue";
import ContractTagList  from "@/views/contractFiles/tags/ContractTagList.vue";


export default {
  name:       "Index",
  components: {ContractTagList, ContractFileForm, BtnIcon, VTitle, DataTable, Restore, ToggleDeleted, Delete},
  data:       () => ({
    search:      '',
    models:      Search.create({
      uri:    'contract-file',
      fields: ['id', 'name', 'type.name', 'tags', 'created_at', 'deleted_at'],
      expand: ['type'],
      params: {deleted: 0},
      filter: {or: [{name: {like: ''}}, {description: {like: ''}}]},
    }).load(),
    showDeleted: 0,
  }),
  computed:   {
    ...mapGetters(['user', 'role']),
    headers() {
      return [
        {text: 'Название', value: 'name', sortable: true},
        {text: 'Тип лида', value: 'type.name'},
        {text: 'Теги (тип./исп.)', value: 'tags'},
        {text: 'Создано', value: 'created_at'},
        {text: 'Действия', value: 'actions', align: 'end'},
      ]
    }
  },
  watch:      {
    search(value) {
      this.models.config.filter.or[0].name.like        = value || '';
      this.models.config.filter.or[1].description.like = value || '';
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    },
  },
  methods:    {
    update: debounce((vm) => vm.models.load(), 200),
    utcToDatetime(value) {
      return this.$d(value * 1000, 'datetime');
    },
    countTags(tags) {
      let types = 0;
      let uses  = 0;
      if (typeof tags == 'object') {
        for (let [, count] of Object.entries(tags)) {
          types++;
          uses += count;
        }
      }
      return types + ' / ' + uses;
    }
  }
}
</script>

<style scoped>

</style>
import {Model} from "@/models/Model";

export class RefundReason extends Model {
    modelUri = 'refund-reason';
    labels = {
        name: 'Название'
    }

    fields = [
        'name'
    ]
}
<template>
  <modal v-model="modal" :loading="model.loading" :title="id?'Изменение типа оплаты сделок':'Создание типа оплаты сделок'" max-width="600px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          </v-col>
          <v-col cols="12">
            <v-select v-model="model.attr.price_type" :items="priceTypes" :label="model.labels.price_type" :rules="model.rule('price_type')"/>
          </v-col>
          <v-col v-if="showPriceValue" cols="12">
            <mask-input v-model="model.attr.price_value" :label="model.labels.price_value" :mask="mask" :rules="model.rule('price_value')" :suffix="suffixPriceValue" unmask/>
          </v-col>
          <v-col cols="12">
            <v-select v-model="model.attr.income_type" :items="incomeTypes" :label="model.labels.income_type" :rules="model.rule('income_type')"/>
          </v-col>
          <v-col v-if="showIncomeValue" cols="12">
            <mask-input v-model="model.attr.income_value" :label="model.labels.income_value" :mask="mask" :rules="model.rule('income_value')" :suffix="suffixIncomeValue" unmask/>
          </v-col>
          <v-col cols="12" md="6">
            <v-switch
                v-model="model.attr.required"
                :false-value="0"
                :label="model.labels.required"
                :rules="model.rule('required')"
                :true-value="1"
                hint="Данный тип оплаты обязателен для заполнения"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-switch
                v-model="model.attr.editable"
                :false-value="0"
                :label="model.labels.editable"
                :rules="model.rule('editable')"
                :true-value="1"
                hint="После расчета доходов менеджер может редактировать сумму"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal                               from "@/tags/Modal";
import {DEAL_PRICE_TYPES, DealPaymentType} from "@/models/DealPaymentType";
import MaskInput                           from "@/tags/MaskInput.vue";


export default {
  name:       "DealPaymentTypeForm",
  components: {MaskInput, Modal},
  props:      ['id'],
  data() {
    return {
      modal:       false,
      model:       DealPaymentType.get(this.id, {
        on: {
          save:  () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      priceTypes:  [
        {
          value: DEAL_PRICE_TYPES.MANUAL,
          text:  'Ручной ввод'
        },
        {
          value: DEAL_PRICE_TYPES.PERCENTAGE,
          text:  'Процент от суммы сделки'
        },
        {
          value: DEAL_PRICE_TYPES.KV_PERCENTAGE,
          text:  'Процент от суммы КВ'
        },
        {
          value: DEAL_PRICE_TYPES.FIXED,
          text:  'Фиксированный'
        },

      ],
      incomeTypes: [
        {
          value: DEAL_PRICE_TYPES.MANUAL,
          text:  'Ручной ввод'
        },
        {
          value: DEAL_PRICE_TYPES.PERCENTAGE,
          text:  'Процент от суммы сделки'
        },
        {
          value: DEAL_PRICE_TYPES.KV_PERCENTAGE,
          text:  'Процент от суммы КВ'
        },
        {
          value: DEAL_PRICE_TYPES.FIXED,
          text:  'Фиксированный'
        },
        {
          value: DEAL_PRICE_TYPES.PRICE_PERCENTAGE,
          text:  'Процент от дохода компании'
        },


      ],
      mask:        {
        alias:          'numeric',
        digits:         2,
        min:            0,
        groupSeparator: ' '
      }
    }
  },
  computed: {
    showPriceValue() {
      return this.model.attr.price_type !== DEAL_PRICE_TYPES.MANUAL;
    },
    showIncomeValue() {
      return this.model.attr.income_type !== DEAL_PRICE_TYPES.MANUAL;
    },
    suffixPriceValue() {
      switch (this.model.attr.price_type) {
        case DEAL_PRICE_TYPES.PERCENTAGE:
        case DEAL_PRICE_TYPES.KV_PERCENTAGE:
          return '%';
        case DEAL_PRICE_TYPES.FIXED:
          return '₽'
        default:
          return '';
      }
    },
    suffixIncomeValue() {
      switch (this.model.attr.income_type) {
        case DEAL_PRICE_TYPES.PERCENTAGE:
        case DEAL_PRICE_TYPES.KV_PERCENTAGE:
        case DEAL_PRICE_TYPES.PRICE_PERCENTAGE:
          return '%';
        case DEAL_PRICE_TYPES.FIXED:
          return '₽'
        default:
          return '';
      }
    }
  },
  watch:    {
    modal(value) {
      if (value) {
        if (this.id) {
          this.model.load();
        } else {
          this.model.clear();
          this.model.fill({editable: 0})
        }
      }
    }
  },
  methods:  {
    close() {
      this.$refs.form.reset();
      this.modal = false
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <confirm :loading="model.loading" :title="'Вы действительно хотите восстановить каталог ' + name + '?'" color="accent" @confirm="model.restore()">
    <btn-icon :title="$t('btn.restore')" color="accent" icon="delete-off" @click="$emit('click')"/>
  </confirm>
</template>

<script>
import Confirm          from '@/tags/Confirm.vue';
import BtnIcon          from '@/tags/BtnIcon.vue';
import {DocumentFolder} from "@/models/DocumentFolder";

export default {
  name:       'Restore',
  components: {BtnIcon, Confirm},
  props:      [
    'id',
    'name'
  ],
  data() {
    return {
      model: DocumentFolder.get(this.id, {
        on: {
          restore: () => {
            this.$emit('restore');
          }
        }
      })
    };
  }
};
</script>

<style scoped></style>
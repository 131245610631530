import {Model} from "@/models/Model";

export class MsScript extends Model {
    modelUri = 'ms-script';

    labels = {
        id:          'ID',
        name:        'Название',
        description: 'Описание',
        type_id:     'Тип лида',
        button_text: 'Текст кнопки',
        created_at:  'Создано',
        updated_at:  'Обновлено',
        deleted_at:  'Удалено'
    }

    fields = [
        'name',
        'description',
        'type_id',
        'button_text'
    ];
}
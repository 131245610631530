<template>
  <v-card>
    <v-title :title="$t(`hrTest.titleManage`)"/>
    <v-card-title>
      <span v-t="`hrTest.titleManage`" class="mr-5"/>
      <hr-test-form @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </hr-test-form>
      <toggle-deleted v-model="showDeleted" class="ml-2"/>
      <v-spacer/>
      <v-text-field v-model="search" :label="$t('btn.search')" append-icon="mdi-magnify" clearable hide-details/>
    </v-card-title>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.timeout="{item}">
        {{ item.timeout / 60 }} мин
      </template>
      <template v-slot:item.actions="{item}">
        <span v-if="!item.deleted_at" class="ml-2">
          <hr-test-form :id="item.id" @save="models.load()">
            <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
          </hr-test-form>
        </span>
        <span v-if="!item.deleted_at" class="ml-2">
          <hr-question-list :id="item.id" :name="item.name" @save="models.load()">
            <btn-icon :title="$t('hrTest.editQuestions')" color="primary" icon="book-edit"/>
          </hr-question-list>
        </span>
        <span v-if="!item.deleted_at" class="ml-2">
          <delete :id="item.id" :name="item.name" @remove="models.load()"/>
        </span>
        <span v-if="item.deleted_at" class="ml-2">
          <restore :id="item.id" :name="item.name" @restore="models.load()"/>
        </span>
      </template>
    </data-table>
  </v-card>
</template>

<script>
import {debounce}   from 'lodash/function';
import {mapGetters} from 'vuex';

import {Search} from '@/models/Search.ts';

import Delete         from '@/views/hrTestManage/Delete.vue';
import ToggleDeleted  from '@/views/hrTestManage/ToggleDeleted.vue';
import Restore        from '@/views/hrTestManage/Restore';
import HrQuestionList from '@/views/hrTestManage/HrQuestionList.vue';
import HrTestForm     from '@/views/hrTestManage/HrTestForm.vue';
import VTitle         from '@/tags/VTitle.vue';
import BtnIcon        from '@/tags/BtnIcon.vue';
import DataTable      from '@/tags/DataTable.vue';

export default {
  name:       'Index',
  components: {DataTable, HrTestForm, HrQuestionList, BtnIcon, VTitle, Restore, ToggleDeleted, Delete},
  data: () => ({
    search: '',
    models: Search.create({
      uri:    'hr-test',
      fields: ['id', 'name', 'description', 'timeout'],

      params: {deleted: 0},
      filter: {or: [{name: {like: ''}}]}
    }).load(),
    showDeleted:     0,
    showDescription: {}
  }),
  computed: {
    ...mapGetters(['can']),
    headers() {
      return [
        {text: 'Название', value: 'name', sortable: true},
        {text: 'Описание', value: 'description', sortable: true},
        {text: 'Время прохождения', value: 'timeout', sortable: true},
        {text: 'Действия', value: 'actions', align: 'end'}
      ]
    }
  },
  watch: {
    search(value) {
      this.models.config.filter.or[0].name.like = value || '';
      this.update(this);
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    }
  },
  methods: {
    update: debounce((vm) => vm.models.load(), 200)
  }
};
</script>

<style scoped></style>
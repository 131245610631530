<template>
  <v-card>
    <v-title title="Мини-скрипты"/>
    <v-card-title>
      <span class="mr-5">Мини-скрипты</span>
      <span v-if="can('book.manage')">
      <ms-script-form @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </ms-script-form>
      <toggle-deleted v-model="showDeleted" class="ml-2"/>
      </span>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
          hide-details
      />
    </v-card-title>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.actions="{item}">
        <span v-if="!item.deleted_at" class="ml-2">
          <ms-script-form :id="item.id" @save="models.load()">
            <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
          </ms-script-form>
        </span>
        <span v-if="!item.deleted_at" class="ml-2">
          <ms-question-list :id="item.id" @save="models.load()">
            <btn-icon color="primary" icon="book-edit" title="Изменить вопросы"/>
          </ms-question-list>
        </span>
        <span v-if="!item.deleted_at" class="ml-2">
          <delete :id="item.id" :name="item.name" @remove="models.load()"/>
        </span>
        <span v-if="item.deleted_at" class="ml-2 text-w">
          <restore :id="item.id" :name="item.name" @restore="models.load()"/>
        </span>
      </template>
    </data-table>
  </v-card>
</template>

<script>
import {Search}   from "@/models/Search";
import {debounce} from "lodash/function";

import Delete         from "@/views/news/Delete";
import {mapGetters}   from "vuex";
import ToggleDeleted  from "@/views/news/ToggleDeleted";
import Restore        from "@/views/news/Restore";
import VTitle         from "@/tags/VTitle";
import BtnIcon        from "@/tags/BtnIcon";
import DataTable      from "@/tags/DataTable.vue";
import MsScriptForm   from "@/views/msScript/MsScriptForm.vue";
import MsQuestionList from "@/views/msScript/MsQuestionList.vue";


export default {
  name:       "Index",
  components: {MsQuestionList, MsScriptForm, DataTable, BtnIcon, VTitle, Restore, ToggleDeleted, Delete},
  data: () => ({
    search: '',
    models: Search.create({
      uri:    'ms-script',
      fields: ['id', 'name', 'description', 'type.name', 'created_at', 'deleted_at'],
      expand: ['type'],
      params: {deleted: 0},
      filter: {or: [{name: {like: ''}}, {description: {like: ''}}]}
    }).load(),
    showDeleted: 0
  }),
  computed: {
    ...mapGetters(['can']),
    headers() {
      return [
        {text: 'Название', value: 'name', sortable: true},
        {text: 'Описание', value: 'description', sortable: true},
        {text: 'Тип лида', value: 'type.name', cellClass: 'text-no-wrap'},
        {text: 'Действия', value: 'actions', align: 'end'}
      ]
    }
  },
  watch: {
    search(value) {
      this.models.config.filter.or[0].name.like = value || '';
      this.models.config.filter.or[1].description.like = value || '';
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    }
  },
  methods: {
    update: debounce((vm) => vm.models.load(), 200)
  }
}
</script>

<style scoped>

</style>
<template>
  <modal v-model="modal" :loading="model.loading" :title="id ? 'Изменение мероприятия' : 'Создание мероприятия'" max-width="1000px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="model.attr.title" :label="model.labels.title" :rules="model.rule('title')"/>
          </v-col>
          <v-col col="12">
            <tiny-editor v-if="editor" v-model="model.attr.text"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <datetime-utc-input v-model="model.attr.started_at" :label="model.labels.started_at" :rules="model.rule('started_at')"/>
          </v-col>
          <v-col cols="12" md="6">
            <datetime-utc-input v-model="model.attr.ended_at" :label="model.labels.ended_at" :rules="model.rule('ended_at')"/>
          </v-col>
          <v-col cols="12" md="6">
            <file-input v-model="model.attr.image_file" :label="model.labels.image" :rules="model.rule('image_file')" accept=".jpg,.jpeg,.png,.svg" icon="mdi-file-image"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.url" :label="model.labels.url" :rules="model.rule('url')"/>
          </v-col>
          <v-col cols="12">
            <datetime-utc-input v-model="model.attr.published_at" :label="model.labels.published_at" :rules="model.rule('published_at')" mask>
              <template #append-outer>
                <btn-icon color="secondary" icon="clock-time-four-outline" title="Сейчас" @click="publishNow()"/>
              </template>
            </datetime-utc-input>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal            from '@/tags/Modal.vue';
import {Event}          from '@/models/Event.ts';
import TinyEditor       from '@/tags/TinyEditor.vue';
import DatetimeUtcInput from '@/tags/DatetimeUtcInput.vue';
import FileInput        from '@/tags/FileInput.vue';
import BtnIcon          from "@/tags/BtnIcon.vue";

export default {
  name:       'Event',
  components: {BtnIcon, TinyEditor, Modal, DatetimeUtcInput, FileInput},
  props:      ['id'],
  data() {
    return {
      modal:  false,
      editor: false,
      model:  Event.get(this.id, {
        on: {
          save: () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      })
    };
  },
  computed: {},
  watch:    {
    modal(value) {
      if (value) {
        setTimeout(() => (this.editor = true), 0);
        if (this.id) {
          this.model.load();
        } else {
          this.model.clear();
          this.model.fill({published_at: null})
        }
      } else {
        setTimeout(() => (this.editor = false), 0);
      }
    }
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.modal = false;
    },
    publishNow() {
      this.model.attr.published_at = Math.floor(new Date().getTime() / 1000);
    }
  }
};
</script>

<style scoped></style>
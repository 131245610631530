<template>
  <modal v-model="modal" max-width="1000px" :title="$t(step === 1 ? 'service.title' : `service.${service}`)">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-stepper v-model="step">
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <v-card-text class="pt-2">
            <v-list class="pa-0">
              <v-list-item-group v-model="service">
                <v-list-item v-for="(service, key) in services" :key="key" :value="key" @click="nextStep()">
                  <v-list-item-icon>
                    <v-icon v-text="`mdi-form-select`"/>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap" v-text="$t(`service.${key}`)"/>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-form v-if="service" ref="form" :disabled="model.loading" @submit.prevent="model.send()">
            <v-row>
              <template v-for="fileId in services[service].fileIds">
                <v-col v-if="category[fileId]" cols="12" :key="`${fileId}-category`">
                  <h3 v-t="category[fileId]"/>
                </v-col>
                <v-col cols="12" md="6" :key="fileId">
                  <file-input :background-color="requiredColor(fileId)" v-model="model.attr[`file${fileId}`]" :label="$t(`file.${fileId}`)" :rules="model.rule(`file${fileId}`)" accept=".jpeg,.jpg,.pdf,.docx,.doc" icon="mdi-file"/>
                </v-col>
              </template>
              <v-col cols="12">
                <v-textarea v-model="model.attr.comment" :label="$t('label.comment')" :rules="model.rule('comment')" counter="500"/>
                <p class="d-flex flex-column text-caption grey--text">
                  {{ $t(`file.requirements.title`) }}:
                  <span v-for="requirement in fileRequirements" :key="requirement">• {{ $t(`file.requirements.${requirement}`) }} </span>
                </p>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>
        <v-btn v-show="step !== 1" style="position: fixed; bottom: 10px; left: 10px" color="secondary" fab @click="previousStep()"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
      </v-stepper-items>
    </v-stepper>

    <template #actions>
      <div class="d-flex justify-end" style="width: 100%">
        <v-btn v-show="step === 2" color="primary" @click="model.send()" :loading="model.loading" :disabled="sended">
          <span v-if="!sended"> Отправить </span>
          <span v-if="sended">
            <v-icon class="">mdi-chevron-down-circle</v-icon>
            Отправлено
          </span>
        </v-btn>
      </div>
    </template>
  </modal>
</template>

<script>
import {Form} from '@/models/Form.ts';

import Modal     from '@/tags/Modal.vue';
import FileInput from '@/tags/FileInput.vue';

export default {
  name:       'ClientDocumentForm',
  components: {Modal, FileInput},
  props:      ['id'],
  data:       function () {
    return {
      model: Form.create({
        uri:   'client/document',
        attrs: ['comment'],
        fill:  {client_id: this.id},
        on:    {
          send: (data) => {
            if (data) {
              this.sended = true;
              this.$emit('submit');
              setTimeout(() => {
                this.close();
              }, 2000);
            }
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      sended:   false,
      step:     1,
      modal:    false,
      service:  null,
      services: {
        1: {fileIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]},
        2: {fileIds: [14, 15, 16, 17, 18]},
        3: {fileIds: [19, 20, 21, 22, 23, 24]},
        4: {fileIds: [25, 26, 27, 28, 29]},
        5: {fileIds: [30, 31, 32, 33]},
        6: {fileIds: [34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]}
      },
      fileRequiredIds:  [1, 2, 5, 14, 15, 16, 17, 19, 20, 21, 25, 26, 27, 30, 31, 32, 34, 56, 57],
      category:         {34: 'file.category.1', 47: 'file.category.2', 50: 'file.category.3', 54: 'file.category.4', 56: 'file.category.5'},
      fileRequirements: [1, 2, 3, 4]
    };
  },
  watch: {
    modal(value) {
      if (!value) {
        this.close();
      }
    },
    step(value) {
      if (value === 1) {
        this.resetForm();
      }
    },
    service(value) {
      this.model.attr.type = value;
    }
  },
  methods: {
    previousStep() {
      this.step = 1;
    },
    nextStep() {
      this.step = 2;
    },
    async close() {
      await this.resetForm();
      this.step = 1;
      this.modal = false;
    },
    async resetForm() {
      this.$refs['form'] && (await this.$refs['form'].reset());
      this.service = null;
      this.sended = false;
    },
    requiredColor(fileId) {
      return this.fileRequiredIds.some((id) => id === fileId) ? 'primary-lite' : '';
    }
  }
};
</script>
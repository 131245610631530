<template>
  <modal v-model="modal" :loading="model.loading" :title="id?'Изменение новости':'Создание новости'" max-width="1000px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="model.attr.author" :label="model.labels.author" :rules="model.rule('author')"/>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="model.attr.description" :label="model.labels.description" :rules="model.rule('description')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.url" :label="model.labels.url" :rules="model.rule('url')"/>
          </v-col>
          <v-col cols="12" md="6">
            <file-input v-model="model.attr.image_file" :label="model.labels.image" :rules="model.rule('image_file')" accept=".jpg,.jpeg,.png" icon="mdi-file-image"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal     from "@/tags/Modal";
import FileInput from "@/tags/FileInput";
import {Book}    from "@/models/Book";


export default {
  name:       "BookForm",
  components: {Modal, FileInput},
  props:      ['id'],
  data() {
    return {
      modal:  false,
      editor: false,
      model:  Book.get(this.id, {
        on: {
          save: () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      })
    }
  },
  computed: {},
  watch:    {
    modal(value) {
      if (value) {
        setTimeout(() => this.editor = true, 0)
        this.id && this.model.load();
        !this.id && this.model.clear();
      } else {
        setTimeout(() => this.editor = false, 0)
      }
    }
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.modal = false
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <div class="d-flex ma-2 flex-wrap">
      <div class="pa-2">
        <v-btn-toggle v-model="date" :mandatory="!!date" class="btn-group" color="secondary" dense rounded>
          <v-btn v-for="item in dateList" :key="item.value" :value="item.value">
            {{ datePeriodLabel(item) }}
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="pa-2">
        <v-menu
            ref="menu"
            v-model="menuDate"
            :close-on-content-click="false"
            min-width="auto"
            offset-y
            transition="scale-transition"
        >
          <template #activator="{  on, attrs }">
            <v-btn class="filter-btn" color="secondary lighten-2" outlined v-bind="attrs" v-on="on">
              <v-icon v-if="!$vuetify.breakpoint.mobile" class="ml-n1 mr-2">
                mdi-calendar-outline
              </v-icon>
              <span class="text-truncate">{{ datePeriod }}</span>
            </v-btn>
          </template>
          <v-date-picker
              v-model="dates"
              :allowed-dates="allowedDates"
              color="primary"
              first-day-of-week="1"
              no-title
              range
              @input="setDate()"
          />
        </v-menu>
      </div>
      <div v-if="showFilter" class="pa-2">
        <modal v-model="modalFilter" :title="textModal" max-width="600px">
          <template #activator="{  on, attrs }">
            <v-btn class="filter-btn" color="secondary lighten-2" outlined rounded v-bind="attrs" v-on="on">
              <v-icon v-if="!$vuetify.breakpoint.mobile" class="ml-n1 mr-2">
                {{ filterIcon }}
              </v-icon>
              <span class="text-truncate">{{ textFilter }}</span>
            </v-btn>
          </template>
          <v-card-text class="pt-3">
            <v-row>
              <v-col v-if="can('office.manage')" cols="12">
                <v-autocomplete
                    v-model="office_id"
                    :items="officeList"
                    clearable
                    item-text="name"
                    item-value="id"
                    label="Офис"
                    @change="updateManagerList"
                />
              </v-col>
              <v-col v-if="can('user.manage')" cols="12">
                <v-autocomplete
                    v-model="user_id"
                    :items="managerList"
                    :loading="loadingFilter"
                    :readonly="loadingFilter"
                    clearable
                    item-text="username"
                    item-value="id"
                    label="Менеджер"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <template #actions>
            <v-btn color="primary" @click="setFilter()">Применить</v-btn>
            <v-btn class="ml-2" @click="clearFilter()">Отменить</v-btn>
          </template>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import {dateUtcDayOffset} from "@/helpers/dateHelper";
import {mapGetters}       from "vuex";
import Modal              from "@/tags/Modal.vue";

export default {
  name:       "StatFilterForm",
  components: {Modal},
  props:      {
    value: {
      type: Object,
    },
  },
  mounted() {
    if (this.can('office.manage')) {
      this.axios.get('office/list').then((response) => this.officeList = response.data);
      this.office_id = this.search.office_id = this.user.office_id;
    }
    if (this.can('user.manage')) {
      this.axios.get('user/list').then((response) => this.managerList = response.data);
      this.user_id = this.search.user_id = this.user.id;
    }
    this.date = 'month';
  },
  data() {
    return {
      search:        this.value,
      start:         undefined,
      end:           undefined,
      date:          undefined,
      dates:         [],
      menuDate:      false,
      modalFilter:   false,
      dateList:      [
        {
          label: 'Сегодня',
          value: 'today'
        },
        {
          label: 'Вчера',
          value: 'yesterday'
        },
        {
          label: 'Неделя',
          value: 'week'
        },
        {
          label: 'Месяц',
          value: 'month'
        },
        {
          label: 'Квартал',
          value: 'quarter'
        }

      ],
      officeList:  [],
      managerList:   [],
      loadingFilter: false,
      office_id:     this.value.office_id,
      user_id:       this.value.user_id,
    }
  },
  watch:    {
    date(value) {
      if (!value) {
        return;
      }
      let start = this.search.start;
      let end   = this.search.end;
      switch (value) {
        case 'today':
          start = dateUtcDayOffset();
          end   = dateUtcDayOffset();
          break;
        case 'yesterday':
          start = dateUtcDayOffset(-1);
          end   = dateUtcDayOffset(-1);
          break;
        case 'week':
          start = dateUtcDayOffset(-7);
          end   = dateUtcDayOffset(-1);
          break;
        case 'month':
          start = dateUtcDayOffset(-30);
          end   = dateUtcDayOffset(-1);
          break;
        case 'quarter':
          start = dateUtcDayOffset(-90);
          end   = dateUtcDayOffset(-1);
          break;
      }
      if (start && end) {
        this.search.start = start;
        this.search.end   = end + 86400;
        let offset        = new Date().getTimezoneOffset() * 60 * 1000;
        let dateStart     = new Date(start * 1000 - offset).toISOString().substring(0, 10);
        let dateEnd       = new Date(end * 1000 - offset).toISOString().substring(0, 10)
        this.dates        = [dateStart, dateEnd];
        this.update();
      }
    },
    office_id() {
      this.updateManagerList();
    }
  },
  computed: {
    ...mapGetters(['can', 'user']),
    datePeriod() {
      let start = this.search.start ? this.$d(this.search.start * 1000, 'date') : false;
      let end   = this.search.end ? this.$d((this.search.end - 86400) * 1000, 'date') : false;
      let dates = [];
      if (start) dates.push(start);
      if (end && end !== start) dates.push(end);
      return dates.join(' - ')
    },
    showFilter() {
      return this.can('user.manage') || this.can('office.manage');
    },
    currentManager() {
      return this.managerList.find(user => user.id === this.user_id);
    },
    currentOffice() {
      return this.officeList.find(office => office.id === this.office_id);
    },
    textFilter() {
      if (this.currentManager) {
        return this.currentManager.username;
      }
      if (this.currentOffice) {
        return this.currentOffice.name;
      }
      return 'Не выбрано';
    },
    textModal() {
      let modalText = [];
      if (this.can('office.manage')) {
        modalText.push('офис')
      }
      if (this.can('user.manage')) {
        modalText.push('менеджера');
      }
      return 'Выберите ' + modalText.join(' и ');
    },
    filterIcon() {
      return this.search.office_id || this.search.user_id ? 'mdi-filter' : 'mdi-filter-outline'
    }
  },
  methods:  {
    update() {
      this.$emit('input', this.search);
    },
    updateManagerList() {
      this.loadingFilter = true;
      this.axios.get('user/list', {
        params: {office_id: this.office_id || ''}
      })
          .then((response) => {
            this.managerList   = response.data;
            this.loadingFilter = false;
          })
    },
    datePeriodLabel(item) {
      return this.$vuetify.breakpoint.mobile ? item.label.substring(0, 1) : item.label;
    },
    allowedDates(value) {
      return new Date().getTime() >= new Date(value).getTime();
    },
    setDate() {
      if (this.dates.length === 2) {
        let [start, end]  = this.dates.sort();
        this.search.start = Math.floor(new Date(start).getTime() / 1000)
        this.search.end   = Math.floor(new Date(end).getTime() / 1000) + 86400;
        this.date         = false;
        this.menuDate     = false;
        this.update();
      }
    },
    setFilter() {
      this.modalFilter      = false;
      this.search.user_id   = this.user_id;
      this.search.office_id = this.office_id;
      this.update();
    },
    clearFilter() {
      this.modalFilter = false;
      this.user_id     = this.search.user_id;
      this.office_id   = this.search.office_id;
    }
  }
}
</script>

<style scoped>
.filter-btn {
  max-width: calc(100vw - 32px);

  span {
    max-width: calc(100vw - 72px);

  }
}
</style>
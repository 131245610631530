<template>
  <span>
  <v-dialog
      v-model="dialog"
      :fullscreen="isFullscreen"
      :max-width="maxWidth"
      persistent
  >
    <template v-slot:activator="{on,attrs}">
      <span v-bind="attrs" v-on="on">
      <slot name="activator"/>
      </span>
    </template>
    <v-card class="fill-height">
      <v-card-title ref="modalTitle" class="flex-nowrap">
        <div ref="titleBlock" class="modal-title" @resize="checkTitleOverflow">
          <marquee v-if="isTitleOverflow" behavior="alternate" class="d-inline">
            <div ref="titleContent" class="d-inline">{{ title }}</div>
          </marquee>
          <div v-else ref="titleContent" class="d-inline">{{ title }}</div>
        </div>
        <v-spacer/>
        <v-btn v-if="closable" class="align-self-start mr-n3" icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-progress-linear v-if="loading" class="mb-n1 z-index-100" indeterminate/>
      <div class="h-contain overflow-y-auto">
        <slot/>
      </div>
      <v-card-actions class="py-4">
        <slot name="actions"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </span>
</template>

<script>
export default {
  name:  "Modal",
  props: {
    maxWidth: {
      default: '500px'
    },
    persistent: {
      default: true
    },
    title: {
      default: ''
    },
    loading: {
      default: false
    },
    value: {
      default: false
    },
    closable: {
      default: true
    },
    fullscreen: {
      type:    Boolean,
      default: false
    },
    fullscreenMobile: {
      type:    Boolean,
      default: true
    }
  },
  mounted() {
    this.interval = setInterval(() => this.checkTitleOverflow(), 1000);
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  data() {
    return {
      dialog:          this.value,
      isTitleOverflow: false,
      interval:        undefined
    }
  },
  computed: {
    isFullscreen() {
      return this.fullscreen || (this.fullscreenMobile && this.$vuetify.breakpoint.xs);
    }
  },
  watch: {
    dialog(value) {
      this.$emit('input', value);
    },
    value() {
      this.dialog = this.value;
    }
  },
  methods: {
    close() {
      this.dialog = false;
    },
    checkTitleOverflow() {
      const block = this.$refs.titleBlock;
      const content = this.$refs.titleContent;
      if (block && content) {
        this.isTitleOverflow = content.offsetWidth > block.offsetWidth;
      }
    },

  }
}
</script>

<style scoped>
.h-contain {
  max-height: calc(var(--vh, 1svh) * 100 - 130px);
}

.z-index-100 {
  z-index: 100;
}
</style>
<style>

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100% !important;
}

.modal-title {
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
  width: 100%;
}
</style>
<template>
  <div class="card">
    <div class="card-title">{{ title }}</div>
    <div class="card-info">
      <div v-if="loading" class="card-loading">
        <v-skeleton-loader type="card-heading"/>
      </div>
      <div v-else-if="noData" class="card-no-info">
        нет данных
      </div>
      <div v-else class="card-data d-flex justify-space-between align-center">
        <div>
          <span v-if="prefix" class="mr-2 secondary--text">{{ prefix }}</span>
          <span class="card-value">{{ data.value }}</span>
          <span v-if="data.suffix" class="ml-1">{{ data.suffix }}</span>
        </div>
        <div v-if="showDiff" class="card-value-prev">
          <v-chip :color="colorDiff" dark>{{ formattedDiff }}</v-chip>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {isNull, round}       from "lodash";
import {plural, shortNumber} from "@/helpers/stringHelpers";

export default {
  name:       "InfoCard",
  components: {},
  props:      {
    value:      {
      type: Number,
    },
    previous:   {
      type: Number,
    },
    prefix:     {
      type: String
    },
    title:      {
      type: String
    },
    type:       {
      type: String
    },
    loading:    {
      type: Boolean
    },
    inverse:    {
      type: Boolean,
    },
    funnel:     {
      type: Boolean
    },
    fullNumber: {
      type:    Boolean,
      default: false
    }
  },
  computed:   {
    noData() {
      return typeof this.value === 'undefined' || isNull(this.value);
    },
    data() {
      let value  = +this.value;
      let suffix = undefined;
      {
        if (isNaN(value)) {
          return {value: this.value, suffix};
        }
        if (this.type === 'period') {
          value  = round(value / 86400, 1)
          suffix = plural(Math.floor(value), ['день', 'дня', 'дней']);
          return {value: value.toLocaleString(), suffix}
        }
        return this.fullNumber ? {value: value.toLocaleString(), suffix: ''} : shortNumber(value);
      }
    },
    diff() {
      if (isNull(this.previous) || isNull(this.value) || this.previous <= 0) {
        return null;
      }
      return this.funnel ? this.value / this.previous : (this.value - this.previous) / this.previous;
    },
    formattedDiff() {
      return (this.diff > 0 ? (this.funnel ? '' : '+') : '') + round(this.diff * 100, this.diff >= 1 ? 0 : 1) + '%';
    },
    showDiff() {
      return !isNull(this.diff);
    },
    colorDiff() {
      if (this.diff === 0) {
        return 'grey';
      }
      return (this.diff > 0 && !this.inverse) || (this.diff < 0 && this.inverse) ? 'success' : 'error';
    }
  }
}
</script>
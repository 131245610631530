<template>
  <v-sheet color="transparent">
    <v-toolbar v-if="$vuetify.breakpoint.mdAndUp" color="transparent" flat>
      <v-scale-transition>
        <v-img v-if="drawer" :src="logoSrc" contain max-height="48px" position="center"/>
      </v-scale-transition>
    </v-toolbar>
    <div class="d-flex justify-center pt-6">

      <v-badge id="user-place-badge" :value="showBadge" bordered offset-x="20px" overlap>
        <v-avatar color="grey lighten-3">
          <v-img v-if="user.avatar" :src="apiUri(user.avatar)"/>
          <v-icon v-else>mdi-account</v-icon>
        </v-avatar>
        <template #badge>
          #{{ showPlace }}
          <v-icon v-if="showPlaceIcon" style="top:-1px;">{{ showPlaceIcon }}</v-icon>
        </template>
      </v-badge>
      <v-tooltip activator="#user-place-badge" top>
        {{ nextPlaceText }}
      </v-tooltip>
    </div>
    <div class="account-info">
      <div class="v-list-item">
        <v-list-item-title class="account-username">{{ user.username }}</v-list-item-title>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon class="ma-0 align-self-center" v-bind="attrs" v-on="on">
              <btn-icon icon="dots-vertical"/>
            </v-list-item-icon>
          </template>
          <v-list dense>
            <v-list-item-group>
              <user-self-form :id="user.id">
                <v-list-item>Профиль</v-list-item>
              </user-self-form>
              <session-form>
                <v-list-item>Сеансы</v-list-item>
              </session-form>
              <office-self-form v-if="can('office.manage') || can('office.self')" :id="user.office_id">
                <v-list-item>Офис</v-list-item>
              </office-self-form>
              <v-list-item link @click="logout">{{ $t('login.signOut') }}</v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
      <v-list-item-subtitle class="text-center pr-7 secondary-lite--text">{{ user.email }}</v-list-item-subtitle>
    </div>
  </v-sheet>
</template>
<script>
import {mapGetters}     from "vuex";
import {apiUri, plural} from "@/helpers/stringHelpers";
import {isEmpty}        from "lodash";
import BtnIcon          from "@/tags/BtnIcon.vue";
import OfficeSelfForm   from "@/views/office/OfficeSelfForm.vue";
import UserSelfForm     from "@/views/users/UserSelfForm.vue";
import SessionForm      from "@/views/users/SessionForm.vue";

export default {
  name:       "Account",
  components: {SessionForm, UserSelfForm, OfficeSelfForm, BtnIcon},
  props:      ['drawer'],
  data:       () => ({}),
  computed:   {
    ...mapGetters(['user', 'office', 'can', 'stats']),
    logoSrc() {
      let logo = require('@/assets/logo.svg');
      if (this.can('office.logo') && this.office?.logo) {
        logo = this.apiUri(this.office.logo);
      }
      return logo;
    },
    showBadge() {
      return !isEmpty(this.stats);
    },
    showPlace() {
      return this.user?.place;
    },
    showPlaceIcon() {
      const icons = {
        1: 'mdi-diamond-stone',
        2: 'mdi-gold'
      }
      return icons[this.showPlace];
    },
    nextPlaceText() {
      switch (this.showPlace) {
        case 1:
          return 'Вы на первом месте. Красавчик!';
        case 2:
          return 'Вы на втором месте, еще чуток и Вы №1. Большой молодец!';
        case 3:
          return 'Вы на третьем месте, поднажмите и станьте №1. Вы молодцы!';
        default:
          return `До следующего места в рейтинге
          осталось ${this.stats.quarter.next_place_points.toLocaleString()}
          ${plural(this.stats.quarter.next_place_points, ['балл', 'балла', 'баллов'])},
           поднажмите!`
      }
    }
  },
  watch:      {},
  methods:    {
    apiUri,
    logout() {
      this.axios.post(apiUri('auth/logout'))
    }
  }
};
</script>
<style scoped>
.account-info {
  padding: 0 4px 8px 32px;
}

.account-username {
  font-weight: bold;
  white-space: normal;
  text-align: center;
}
</style>
<script>
export default {
  name:  "VTitle",
  props: ['title'],
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title ? this.title + ' | ' + process.env.VUE_APP_NAME : process.env.VUE_APP_NAME;
      }
    }
  },
  render() {
    return '';
  }
}
</script>
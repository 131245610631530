<template>
  <v-card class="fill-height">
    <v-title title="Экспорт клиентов"/>
    <v-card-title>
      <span>Экспорт клиентов</span>
    </v-card-title>
    <v-progress-linear v-if="model.loading" class="mb-n1" indeterminate/>
    <v-card-text class="pt-4">
      <v-alert border="top" color="primary" colored-border elevation="2">
        Выберите дату с которой начнется экспорт клиентов
      </v-alert>
      <v-form ref="form" :disabled="model.loading" class="pt-4" @submit.prevent="model.send()">
        <v-row>
          <v-col cols="12" lg="3" md="4" sm="6">
            <datetime-utc-input v-model="model.attr.created_at" :rules="model.rule('created_at')" label="Дата экспорта от" only-date/>
          </v-col>
          <v-col v-if="can('office.manage')" lg="3" md="4" sm="6">
            <v-autocomplete v-model="model.attr.office_id" :items="officeList" :rules="model.rule('office_id')" item-text="name" item-value="id" label="Офис"/>
          </v-col>
        </v-row>
        <div>
          <v-btn :disabled="model.loading" color="primary" @click="model.send()">Экспортировать</v-btn>
        </div>
      </v-form>
      <modal v-model="modal" title="Клиенты успешно экспортированы">
        <!--        <v-card-text>Клиенты успешно экспортированы</v-card-text>-->
      </modal>
    </v-card-text>
  </v-card>
</template>

<script>
import VTitle           from "../../tags/VTitle";
import {Form}           from "@/models/Form";
import Modal            from "@/tags/Modal";
import {mapGetters}     from "vuex";
import {apiUri}         from "@/helpers/stringHelpers";
import DatetimeUtcInput from "@/tags/DatetimeUtcInput.vue";

export default {
  name:       "Export",
  components: {DatetimeUtcInput, VTitle, Modal},
  mounted() {
    if (this.can('office.manage')) this.axios.get('office/list').then((response) => this.officeList = response.data)
  },
  computed: {
    ...mapGetters(['can'])
  },
  data() {
    return {
      model:      Form.create({
        uri:   'client/export',
        attrs: ['office_id', 'created_at'],
        on:    {
          send:  (data) => {
            window.open(apiUri(data), '_blank');
            this.modal = true;
          },
          error: () => {
            this.$refs.form.validate()
          }
        }
      }),
      officeList: [],
      modal:      false,
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-row class="mx-1 mb-2">
      <v-col cols="12" lg="3" md="4" sm="6">
        <datetime-utc-input v-model="dateCreatedStart" hide-details label="Создано: начало периода" only-date/>
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6">
        <datetime-utc-input v-model="dateCreatedEnd" hide-details label="Создано: конец периода" only-date/>
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6">
        <v-select v-model="statuses" :items="statusList" clearable hide-details item-text="name" item-value="id" label="Статус" multiple/>
      </v-col>
      <v-col v-if="can('office.manage')" cols="12" lg="3" md="4" sm="6">
        <v-autocomplete v-model="office" :items="officeList" clearable hide-details item-text="name" item-value="id" label="Офис" @change="updateManagerList"/>
      </v-col>
      <v-col v-if="can('user.manage')" cols="12" lg="3" md="4" sm="6">
        <v-autocomplete v-model="manager" :items="managerList" clearable hide-details item-text="username" item-value="id" label="Менеджер"/>
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6">
        <v-autocomplete v-model="source" :items="sourceList" clearable hide-details item-text="name" item-value="id" label="Источник клиента"/>
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6">
        <v-autocomplete v-model="type" :items="typeList" clearable hide-details item-text="name" item-value="id" label="Тип лида"/>
      </v-col>
      <v-col class="d-flex justify-space-between flex-wrap" cols="12" lg="3" md="4" sm="6">
        <v-switch v-model="rewarded" hide-details label="Оплачено"/>
        <v-switch v-model="fullRewarded" :disabled="!rewarded" hide-details label="Частично"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {isEmpty}        from "lodash";
import {mapGetters}     from "vuex";
import DatetimeUtcInput from "@/tags/DatetimeUtcInput.vue";

export default {
  name:       "DealFilterForm",
  components: {DatetimeUtcInput},
  props:      ['value'],
  mounted() {
    this.axios.get('deal-status/list').then((response) => this.statusList = response.data);
    this.axios.get('client-source/list').then((response) => this.sourceList = response.data)
    this.axios.get('lead-type/list').then((response) => this.typeList = response.data)
    if (this.can('office.manage')) {
      this.axios.get('office/list').then((response) => this.officeList = response.data);
      this.office = this.user.office_id;
    }
    if (this.can('user.manage')) {
      this.axios.get('user/list').then((response) => this.managerList = response.data);
      this.manager = this.user.id;
    }
    this.update();
  },
  data() {
    return {
      search:           this.value,
      dateCreatedStart: undefined,
      dateCreatedEnd:   undefined,
      statuses:         [],
      statusList:       [],
      office:           undefined,
      manager:          undefined,
      officeList:       [],
      managerList:      [],
      source:           undefined,
      type:             undefined,
      sourceList:       [],
      typeList:         [],
      rewarded:     false,
      fullRewarded: false,
    }
  },
  watch:    {
    dateCreatedStart(value) {
      this.search.filter.created_at ||= {}
      if (value) this.search.filter.created_at.gte = value
      else delete this.search.filter.created_at.gte;
      if (isEmpty(this.search.filter.created_at)) delete this.search.filter.created_at;
      this.update();
    },
    dateCreatedEnd(value) {
      this.search.filter.created_at ||= {}
      if (value) this.search.filter.created_at.lt = value + 3600 * 24
      else delete this.search.filter.created_at.lt;
      if (isEmpty(this.search.filter.created_at)) delete this.search.filter.created_at;
      this.update();
    },
    statuses(value) {
      if (isEmpty(value)) delete this.search.filter.status_id;
      else this.search.filter.status_id = {in: value};
      this.update();
    },
    office(value) {
      this.search.params.office = value;
      this.update();
    },
    manager(value) {
      if (value) this.search.filter.manager_id = value;
      else delete this.search.filter.manager_id;
      this.update();
    },
    source(value) {
      this.search.params.source = value;
      this.update();
    },
    type(value) {
      this.search.params.type = value;
      this.update();
    },
    rewarded(value) {
      if (value) {
        this.search.params.rewarded = 1;
      } else {
        this.fullRewarded           = false;
        this.search.params.rewarded = 0;
      }
      this.update();
    },
    fullRewarded(value) {
      if (value) {
        this.search.params.rewarded = 2;
      } else {
        if (this.rewarded) {
          this.search.params.rewarded = 1;
        } else {
          this.search.params.rewarded = 0;
        }
      }
      this.update();
    }
  },
  computed: {
    ...mapGetters(['can', 'user']),
  },
  methods:  {
    update() {
      this.$emit('input', this.search);
    },
    updateManagerList() {
      this.axios.get('user/list' + (this.office ? '?office_id=' + this.office : '')).then((response) => this.managerList = response.data)
    }
  }
}
</script>

<style scoped>

</style>
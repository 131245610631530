import {Model} from "@/models/Model";

export class Faq extends Model {
    modelUri = 'faq';
    labels = {
        title: 'Заголовок',
        text:  'Текст',
        published_at: 'Опубликовано',
    }
    fields = [
        'title',
        'text',
        'published_at',
    ];
}
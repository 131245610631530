<template>
  <modal v-model="modal" :title="'Список вопросов для теста - ' + name" max-width="1000px">
    <template v-slot:activator>
      <slot/>
    </template>
    <hr-question-form :testId="id" @save="models.load()">
      <v-btn color="primary" rounded text>
        <v-icon class="pr-2">mdi-plus-circle</v-icon>
        {{ $t('hrTest.addQuestion') }}
      </v-btn>
    </hr-question-form>
    <data-list :models="models">
      <template v-slot:item="{ item }">
        <v-row class="ma-0">
          <v-col class="font-weight-bold" cols="6">{{ item.name }}</v-col>
          <v-col class="text--secondary" cols="4">{{ item.category || 'Общие вопросы' }}</v-col>
          <v-col class="text-right" cols="2">
          <span v-if="!item.deleted_at" class="ml-2">
            <hr-question-form :id="item.id" :test-id="id" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </hr-question-form>
          </span>
            <span v-if="!item.deleted_at" class="ml-2">
            <question-delete :id="item.id" :name="item.name" @remove="models.load()"/>
          </span>
          </v-col>
        </v-row>
      </template>
    </data-list>
    <v-btn v-t="`btn.close`" color="primary" rounded text @click="close"/>
  </modal>
</template>

<script>
import {Search} from '@/models/Search.ts';

import Modal          from '@/tags/Modal.vue';
import DataList       from '@/tags/DataList.vue';
import HrQuestionForm from '@/views/hrTestManage/HrQuestionForm.vue';
import QuestionDelete from '@/views/hrTestManage/QuestionDelete.vue';
import BtnIcon        from '@/tags/BtnIcon.vue';

export default {
  name:       'HrQuestionList',
  components: {Modal, DataList, HrQuestionForm, QuestionDelete, BtnIcon},
  props:      ['id', 'name'],
  data() {
    return {
      modal:  false,
      editor: false,
      models: Search.create({
        uri:    'hr-question',
        fields: ['id', 'name', 'category'],
        params: {deleted: 0},
        filter: {test_id: null}
      })
    };
  },

  watch: {
    modal(value) {
      if (value) {
        this.models.result = [];
        this.models.config.filter.test_id = this.id;
        this.models.load();
      }
    }
  },
  methods: {
    close() {
      this.modal = false;
    }
  }
};
</script>

<style scoped></style>
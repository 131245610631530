<template>
  <modal v-model="modal" :loading="model.loading" :title="currentId ? 'Редактирование вопроса' : 'Создание вопроса'" max-width="1000px">
    <template v-slot:activator>
      <slot/>
    </template>

    <v-form ref="form" :disabled="model.loading" class="mx-4" @submit.prevent="model.save()">
      <v-row>
        <v-col cols="12">
          <v-textarea v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')" auto-grow prepend-icon="mdi-help" rows="1"/>
        </v-col>
        <v-col cols="12">
          <v-combobox v-model="model.attr.category" :items="categoryList" :label="model.labels.category" :rules="model.rule('category')" clearable prepend-icon="mdi-folder"/>
        </v-col>
      </v-row>
    </v-form>
    <HrAnswerList :questionId="currentId" @save="save()"/>
  </modal>
</template>

<script>
import {HrQuestion} from '@/models/HrQuestion.ts';

import Modal        from '@/tags/Modal.vue';
import HrAnswerList from '@/views/hrTestManage/HrAnswerList.vue';

export default {
  name:       'HrQuestionForm',
  components: {Modal, HrAnswerList},
  props:      {
    id: {
      type:    Number,
      default: null
    },
    testId: {
      type:    Number,
      default: null
    }
  },
  data() {
    return {
      modal: false,
      model: HrQuestion.get(this.id, {
        on: {
          save: () => {
            this.$emit('save');
            if (this.model.attr.id) {
              this.close();
            }
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      categoryList: []
    };
  },
  computed: {
    currentId() {
      return this.id || this.model.attr.id;
    }
  },
  watch: {
    modal(value) {
      if (value) {
        this.id && this.model.load();
        !this.id && this.model.clear();
        this.model.attr.test_id = this.testId;
        this.updateCategoryList();
      }
    }
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.modal = false;
    },
    save() {
      setTimeout(() => this.model.save(), 0);
    },
    updateCategoryList() {
      this.axios.get('hr-question/list?test_id=' + this.testId)
          .then((response) => this.categoryList = response.data)
    }
  }
};
</script>

<style scoped></style>
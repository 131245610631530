<template>
  <v-card class="fill-height">
    <v-title title="HR SF Test"/>
    <v-card-title>
      <span class="mr-5">HR SF Test</span>
      <hr-result-form v-if="can('hr-result.manage')" @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </hr-result-form>
      <toggle-deleted v-if="can('hr-result.manage')" v-model="showDeleted" class="ml-2"/>
      <btn-icon :icon="!showFilter?'filter-outline':'filter-off-outline'" :title="showFilter?'Скрыть фильтр':'Показать фильтр'" class="ml-2" color="info" @click="toggleFilter"/>
      <hr-result-stat v-if="can('hr-result.manage')">
        <btn-icon class="ml-2" color="warning" icon="chart-box-outline" title="Статистика"/>
      </hr-result-stat>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
      />
    </v-card-title>
    <v-expand-transition>
      <hr-test-filter-form v-model="searchParams" :class="showFilter?'':'d-none'" @input="updateFilter"/>
    </v-expand-transition>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.status="{ item }">
        <v-chip :color="statuses[item.status].color" dark>{{ statuses[item.status].name }}</v-chip>
      </template>
      <!--
      <template v-slot:item.correct="{item}">
        {{ item.status === 2 ? Math.round(item.correct * 100) + '%' : '-' }}
      </template>
      -->
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <span v-if="canPlay(item)" class="ml-2">
            <hr-result-play :id="item.id" @close="models.load()">
              <btn-icon color="blue" icon="play" title="Начать прохождение"/>
            </hr-result-play>
          </span>
          <a v-if="canResult(item)" :href="apiUri('hr-result/result?id='+item.id)" class="result-link" target="_blank">
            <btn-icon color="warning" icon="file-download" title="Скачать результат"/>
          </a>
          <span v-if="canDelete(item)" class="ml-2">
            <delete :id="item.id" :name="item.name" @remove="models.load()"/>
          </span>
          <span v-if="item.deleted_at" class="ml-2">
            <restore :id="item.id" :name="item.name" @restore="models.load()"/>
          </span>
        </div>
      </template>
    </data-table>
    <div v-if="can('hr-result.manage')" class="d-flex justify-center flex-wrap">
      <v-btn :href="apiUri('/hr_test/Анкета_кандидата.pdf')" class="ma-2" color="primary" target="_blank">Анкета кандидата
        <v-icon>mdi-file-download</v-icon>
      </v-btn>
      <v-btn :href="apiUri('/hr_test/Вопросы_для_собеседования.pdf')" class="ma-2" color="primary" target="_blank">Вопросы для собеседования
        <v-icon>mdi-file-download</v-icon>
      </v-btn>
      <v-btn :href="apiUri('/hr_test/Вопросы_после_испытательного_срока.pdf')" class="ma-2" color="primary" target="_blank">Вопросы после испытательного срока
        <v-icon>mdi-file-download</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import VTitle           from "../../tags/VTitle";
import {mapGetters}     from "vuex";
import {Search}         from "@/models/Search";
import DataTable        from "@/tags/DataTable";
import {debounce}       from "lodash/function";
import HrTestFilterForm from "@/views/hrTest/HrTestFilterForm";
import ToggleDeleted    from "@/views/hrTest/ToggleDeleted";
import BtnIcon          from "@/tags/BtnIcon";
import Delete           from "@/views/hrTest/Delete";
import Restore          from "@/views/hrTest/Restore";
import HrResultForm     from "@/views/hrTest/HrResultForm";
import HrResultPlay     from "@/views/hrTest/HrResultPlay";
import {apiUri}         from "@/helpers/stringHelpers";
import HrResultStat     from "@/views/hrTest/HrResultStat";

export default {
  name:       "Index",
  components: {HrResultStat, HrResultPlay, HrResultForm, ToggleDeleted, HrTestFilterForm, DataTable, VTitle, BtnIcon, Delete, Restore},
  data() {
    return {
      search: '',
      models: Search.create({
        uri:    'hr-result',
        fields: ['id', 'status', 'user.id', 'user.username', 'test.id', 'test.name', 'created_at', 'deleted_at', 'correct'],
        expand: ['test', 'user'],
        params: {deleted: 0, search: ''},
        filter: {},
        sort:   {field: 'id', sortAsc: false}
      }),
      showDeleted:  0,
      showFilter:   true,
      searchParams: {filter: {}, params: {}},
      statuses:     [
        {name: 'Новый', color: 'blue'},
        {name: 'В процессе', color: 'cyan'},
        {name: 'Пройден', color: 'green'},
        {name: 'Просрочен', color: 'orange'}

      ]
    }
  },
  computed: {
    ...mapGetters(['can', 'office', 'user']),
    headers() {
      return [
        {text: 'Менеджер', value: 'user.username'},
        {text: 'Тест', value: 'test.name'},
        {text: 'Статус', value: 'status', align: 'center'},
        // {text: 'Результат', value: 'correct', align: 'center'},
        {text: 'Действия', value: 'actions', align: 'end'}
      ];
    }
  },
  watch: {
    search(value) {
      this.models.params.search = value || '';
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    }
  },
  methods: {
    update: debounce((vm) => vm.models.load(), 200),
    updateFilter() {
      this.models.config.filter = Object.assign({}, this.searchParams.filter);
      this.models.params = Object.assign({}, this.models.params, this.searchParams.params)
      this.update(this);
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    canDelete(item) {
      return !item.deleted_at && this.can('hr-result.manage');
    },
    canPlay(item) {
      return item.user.id === this.user.id && item.status < 2;
    },
    canResult(item) {
      return item.status === 2 && this.can('hr-result.manage');
    },
    apiUri
  }
}
</script>

<style scoped>
.b-0 {
  border: 0;
}

.result-link {
  color: transparent;
}
</style>
<template>
  <div class="mb-3">
    <div class="d-flex">
      <v-spacer/>
      <ms-result-form v-if="canCreate()" :client-id="id" :scripts="scripts" class="mt-n10" @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </ms-result-form>
    </div>
    <data-table
        :headers="headers"
        :models="models"
        hide-footer
    >
      <template v-slot:item.created_at="{ item }">
        {{ utcToDatetime(item.created_at) }}
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ utcToDatetime(item.updated_at) }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="status(item.status).color" dark>{{ status(item.status).name }}</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <ms-result-form v-if="canUpdate(item)" :id="item.id" @save="models.load()">
          <btn-icon color="blue" icon="play" title="Продолжить"/>
        </ms-result-form>
        <ms-result-view v-if="canView(item)" :id="item.id" @update="models.load()">
          <btn-icon color="info" icon="information" title="Посмотреть результаты"/>
        </ms-result-view>
      </template>
    </data-table>
  </div>
</template>
<script>
import MsResultForm from "@/views/msScript/MsResultForm.vue";
import BtnIcon      from "@/tags/BtnIcon.vue";
import DataTable    from "@/tags/DataTable.vue";
import {Search}     from "@/models/Search";
import MsResultView from "@/views/msScript/MsResultView.vue";
import {mapGetters} from "vuex";

export default {
  name:       "MsResultList",
  components: {MsResultView, DataTable, BtnIcon, MsResultForm},
  props:      {
    id: {
      type:     Number,
      required: true
    },
    onlyView: {
      type:    Boolean,
      default: false
    },
    scripts: {
      type:     Array,
      required: true
    }
  },
  data() {
    return {
      models: Search.create({
        uri:     'ms-result',
        fields:  ['id', 'name', 'status', 'script.name', 'created_at', 'updated_at'],
        expand:  ['script'],
        params:  {deleted: 0},
        filter:  {client_id: null},
        sort:    {field: 'created_at', sortAsc: false},
        perPage: 10
      }),
      showDeleted: 0
    }
  },
  computed: {
    ...mapGetters(['can']),
    headers() {
      let headers = [
        {text: 'Название', value: 'script.name'},
        {text: 'Создано', value: 'created_at', sortable: true, cellClass: 'table-datetime'},
        {text: 'Изменено', value: 'updated_at', sortable: true, cellClass: 'table-datetime'},
        {text: 'Статус', value: 'status', align: this.showActions ? 'center' : 'end'}
      ];
      if (this.showActions) {
        headers.push({text: 'Действия', value: 'actions', align: 'end'})
      }
      return headers;
    },
    showActions() {
      return !this.onlyView && this.can('ms-result.manage');
    }
  },
  watch:    {},
  methods:  {
    status(status) {
      return [
        {name: 'Анкета', color: 'grey'},
        {name: 'Документы', color: 'warning'},
        {name: 'Готово', color: 'success'}
      ][status] || {name: 'Неизвестно', color: 'error'};
    },
    fillParams() {
      this.models.config.filter.client_id = this.id;
      this.models.load();
    },
    utcToDatetime(value) {
      if (!value) {
        return 'Не указано';
      }
      return this.$d(value * 1000, 'datetime');
    },
    canCreate() {
      return !this.onlyView && this.can('ms-result.manage');
    },
    canUpdate(item) {
      return item.status < 2;
    },
    canView(item) {
      return item.status > 0;
    }
  }
}
</script>
<style>

</style>
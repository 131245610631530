<template>
  <v-data-table
      :footer-props="{
        pagination:computedPagination,
        'items-per-page-options':[10,20,50],
        'show-current-page':true,
        'show-first-last-page': true
      }"
      :headers="computedHeaders"
      :items="computedItems"
      :loading="models.loading"
      :options="computedOptions"
      :page="2"
      :hide-default-footer="hideDefaultFooter()"
      disable-filtering
      @update:items-per-page="updatePerPage"
      @update:page="updatePage"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
  >
    <template v-for="slot in computedSlots" v-slot:[slot.name]="{ item }">
      <slot :item="item" :name="slot.name">{{ itemValue(item, slot.value) }}</slot>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name:  "DataTable",
  props: {
    headers: {
      default: []
    },
    models: {
      default() {
        return {
          result:  [],
          loading: false,
          headers: {
            page:         1,
            pageCount:    1,
            itemsPerPage: 20,
            itemsLength:  0
          }
        }
      }
    },
    hideFooter: {
      type:    Boolean,
      default: false
    }
  },
  computed: {
    computedHeaders() {
      const defaultHeader = {sortable: false, sort: () => 0};
      let headers = [];
      this.headers.forEach((header, i) => headers[i] = Object.assign({}, defaultHeader, header));
      return headers;
    },
    computedItems() {
      let {itemsPerPage, itemsLength} = this.computedPagination;
      let items = itemsLength > 0 ? (new Array(itemsLength)).fill({}) : [];
      items.splice((this.page - 1) * itemsPerPage, itemsPerPage, ...this.models.result)
      return items;
    },
    computedPagination() {
      let options = this.models.headers;
      return {
        pageStart: (options.page - 1) * options.itemsPerPage,
        pageStop:  Math.min(options.page * options.itemsPerPage, options.itemsLength),
        ...options
      }
    },
    computedOptions() {
      return {
        page:         this.models.headers.page,
        itemsPerPage: this.models.headers.itemsPerPage
      }
    },
    computedSlots() {
      let slots = [];
      this.headers.forEach((header, i) => slots[i] = {
        name:  'item.' + header.value,
        value: header.value.split('.')
      });
      return slots;
    },
    sort() {
      return {
        field: this.sortBy,
        asc:   this.sortAsc
      }
    }
  },
  data() {
    return {
      sortBy:  '',
      sortAsc: true,
      page:    this.models.headers.page
    }
  },
  watch: {
    sort(value) {
      if (!(value.field === '' && value.asc === true)) {
        // this.$emit('update:sort', value);
        this.models.setSort(value.field, value.asc).load()

      }
    }
  },
  methods: {
    updatePerPage(perPage) {
      // this.$emit('update:per-page', perPage);
      this.models.setPerPage(perPage).load()
    },
    updatePage(page) {
      // this.$emit('update:page', page);
      this.models.setPage(page).load();
      this.page = page
    },
    updateSortBy(sort) {
      this.sortBy = sort ? sort : ''
    },
    updateSortDesc(desc) {
      this.sortAsc = desc ? !desc : true;
    },
    itemValue(item, value) {
      for (let idx of value) {
        if (item) {
          item = item[idx];
        }
      }
      return item;
    },
    hideDefaultFooter() {
      return this.hideFooter && this.models.headers.pageCount < 2
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <modal v-model="modal" :loading="loading" :title="$t('role.manage')+' '+name">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text>
      <permissions-form :id="id" :categories="categories" type="user" @update="updatePermissions"/>
    </v-card-text>
    <template v-slot:actions>
      <v-btn @click="close()">{{ $t('btn.close') }}</v-btn>
    </template>
  </modal>
</template>
<script>

import Modal           from "@/tags/Modal.vue";
import PermissionsForm from "@/views/roles/PermissionsForm.vue";
import {mapGetters}    from "vuex";

export default {
  name:       "UserRuleForm",
  props:      ['id', 'name', 'role'],
  components: {PermissionsForm, Modal},
  data() {
    return {
      modal:       false,
      loading:     false,
      permissions: [],
      categories:  [],
    }
  },
  computed: {
    ...mapGetters(['user', 'can'])
  },
  watch:    {
    modal(value) {
      if (value) {
        this.updatePermissions();
      }
    },
  },
  methods:  {
    close() {
      this.modal = false;
      this.$emit('close')
    },
    updatePermissions() {
      this.loading = true;
      Promise.all([
        this.axios.get('role/permissions', {params: {id: this.role, user_id: this.id}}),
        this.axios.get('role/permissions-category')
      ])
          .then(([permissionsResponse, categoriesResponse]) => {
            this.permissions = permissionsResponse.data;
            this.categories  = categoriesResponse.data.map((category => {
              return {
                name: category.name,
                list: category.list.map((item) => {
                  return this.permissions.find((permission) => permission.name === item);
                })
              }
            }));
            console.log(this.categories);
            this.categories.forEach((category) => {
              category.list = category.list.filter((permission) => {
                return permission && this.can(permission.name);
              });
            });
            this.categories = this.categories.filter((category) => category.list.length > 0);
          })
          .finally(() => {
            this.loading = false;
          });
    }
  },
}
</script>


<style scoped>

</style>
<template>
  <confirm :loading="model.loading" :title="'Вы действительно хотите удалить категорию ' + name + '?'" color="error" @confirm="model.remove()">
    <btn-icon :title="$t('btn.delete')" color="error" icon="delete"/>
  </confirm>
</template>

<script>
import Confirm        from '@/tags/Confirm.vue';
import BtnIcon        from '@/tags/BtnIcon.vue';
import {TaskCategory} from '@/models/TaskCategory.ts';

export default {
  name:       'Delete',
  components: {BtnIcon, Confirm},
  props:      [
'id',
'name'
],
  data() {
    return {
      model: TaskCategory.get(this.id, {
        on: {
          remove: () => {
            this.$emit('remove');
          }
        }
      })
    };
  }
};
</script>

<style scoped></style>
import {Model} from "@/models/Model";

export class Book extends Model {
    modelUri = 'book';
    labels = {
        name:        'Название',
        author:      'Автор',
        description: 'Описание',
        image:       'Изображение',
        url:         'Ссылка'
    }
    fields = [
        'name',
        'author',
        'description',
        'url',
        'image_file'
    ];
}
import {Model} from "@/models/Model";


export class DealPaymentType extends Model {
    modelUri = 'deal-payment-type';
    labels = {
        name:         'Название',
        required:     'Обязательный',
        editable:     'Редактируемый',
        price_type:   'Тип дохода компании',
        price_value:  'Доход компании',
        income_type:  'Тип дохода менеджера',
        income_value: 'Доход менеджера'
    }

    fields = [
        'name',
        'required',
        'editable',
        'price_type',
        'price_value',
        'income_type',
        'income_value',
    ]
}

export enum DEAL_PRICE_TYPES {
    MANUAL = 0,
    PERCENTAGE = 1,
    KV_PERCENTAGE = 2,
    FIXED = 3,
    PRICE_PERCENTAGE = 4,
}
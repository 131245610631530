<template>
  <modal v-model="modal" max-width="600px" title="Активные сеансы">
    <template #activator>
      <slot/>
    </template>
    <v-card-text>
      <h3 class="mb-2">Это устройство</h3>
      <r-card>
        <session-item :item="session" current/>
      </r-card>
      <h3 class="mt-4 mb-2">Активные сеансы</h3>
      <r-card>
        <data-list :models="models" hide-dividers hide-footer>
          <template v-slot:item="{ item }">
            <session-item :disabled="models.loading" :item="item" @update="refresh"/>
          </template>
        </data-list>
      </r-card>
    </v-card-text>
    <template #actions>
      <v-btn color="primary" @click="close">Закрыть</v-btn>
      <v-spacer/>
      <v-btn :disabled="disableDisableBtn" color="primary" @click="disableAll">Завершить другие сессии</v-btn>
    </template>
  </modal>
</template>
<script>

import Modal        from "@/tags/Modal.vue";
import {Search}     from "@/models/Search";
import {mapGetters} from "vuex";
import DataList     from "@/tags/DataList.vue";
import SessionItem  from "@/views/users/SessionItem.vue";
import RCard        from "@/tags/RCard.vue";

export default {
  name:       "SessionForm",
  components: {RCard, SessionItem, DataList, Modal},
  data() {
    return {
      modal:  false,
      models: Search.create({
        uri:     'session',
        fields:  ['id', 'active', 'last_activity', 'expired_at', 'user_agent', 'ip_addr', 'created_at'],
        perPage: 10
      })
    }
  },
  computed: {
    ...mapGetters(['session']),
    disableDisableBtn() {
      return !this.models.result || !this.models.result.length;
    }
  },
  watch: {
    modal(value) {
      if (value) {
        this.models.load();
      }
    }
  },
  methods: {
    close() {
      this.modal = false;
    },
    refresh() {
      this.models.load();
    },
    disableAll() {
      this.axios.post('session/disable?id=all').finally(() => this.refresh());
    }
  }
}

</script>
<style scoped>

</style>
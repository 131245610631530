<template>
  <v-card>
    <v-title title="Список задач"/>
    <v-card-title>
      <span class="mr-5">Список задач</span>
      <span>
        <task-form @save="models.load()">
          <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
        </task-form>
      </span>
      <toggle-deleted v-if="can('task.delete')" v-model="showDeleted" class="ml-2"/>
      <btn-icon :icon="!showFilter ? 'filter-outline' : 'filter-off-outline'" :title="showFilter ? 'Скрыть фильтр' : 'Показать фильтр'" class="ml-2" color="info" @click="toggleFilter"/>
      <v-spacer/>
      <v-text-field v-model="search" :label="$t('btn.search')" append-icon="mdi-magnify" clearable/>
    </v-card-title>
    <v-expand-transition>
      <task-filter-form v-model="searchParams" :class="showFilter ? '' : 'd-none'" @input="updateFilter"/>
    </v-expand-transition>
    <data-table :headers="headers" :models="models">
      <template v-slot:item.status="{ item }">
        <v-chip v-if="item.status" :color="item.status.color" dark>{{ item.status.name }}</v-chip>
      </template>
      <template v-slot:item.category="{ item }">
        <v-chip v-if="item.category" :color="item.category.color" dark>{{ item.category.name }}</v-chip>
      </template>
      <template v-slot:item.notice_date="{ item }">
        {{ utcToDatetime(item.notice_date) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <span v-if="!item.deleted_at" class="ml-2">
            <task-form :id="item.id" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </task-form>
          </span>
          <span v-if="!item.deleted_at" class="ml-2">
            <favorite :id="item.id" :favorite="item.isFavorite" @update="models.load()"/>
          </span>
          <span v-if="canDelete(item)" class="ml-2">
            <delete :id="item.id" :name="item.name" @remove="models.load()"/>
          </span>
          <span v-if="item.deleted_at" class="ml-2">
            <restore :id="item.id" :name="item.name" @restore="models.load()"/>
          </span>
        </div>
      </template>
    </data-table>
  </v-card>
</template>

<script>
import {Search}   from '@/models/Search';
import {debounce} from 'lodash/function';

import Delete                   from '@/views/tasks/Delete';
import {mapActions, mapGetters} from 'vuex';
import ToggleDeleted            from '@/views/tasks/ToggleDeleted';
import Restore                  from '@/views/tasks/Restore';
import DataTable                from '@/tags/DataTable';
import VTitle                   from '@/tags/VTitle';
import BtnIcon                  from '@/tags/BtnIcon';
import {includes}               from 'lodash';
import TaskFilterForm           from '@/views/tasks/TaskFilterForm';
import TaskForm                 from './TaskForm';
import Favorite                 from '@/views/tasks/Favorite';

export default {
  name:       'Index',
  components: {Favorite, TaskForm, TaskFilterForm, BtnIcon, VTitle, DataTable, Restore, ToggleDeleted, Delete},
  mounted() {
    this.updateNotify('task')
  },
  beforeRouteUpdate() {
    this.updateNotify('task');
  },
  data: () => ({
    search: '',
    models: Search.create({
      uri:    'task',
      fields: [
        'id',
        'name',
        'notice_date',
        'isFavorite',
        'status_id',
        'status.name',
        'status.color',
        'user.username',
        'deleted_at',
        'category_id'
      ],
      expand: [
        'status',
        'user',
        'category'
      ],
      params: {deleted: 0, search: ''},
      filter: {}
    }),
    showDeleted:  0,
    showFilter:   true,
    searchFilter: {
      or: [
        {name: {like: ''}},
        {description: {like: ''}}
      ]
    },
    searchParams:     {filter: {}, params: {}},
    createdID:        undefined,
    dateCreatedStart: undefined,
    dateCreatedEnd:   undefined
  }),
  computed: {
    ...mapGetters(['can']),
    headers() {
      return [
        {text: 'Название', value: 'name'},
        {text: 'Дата выполнения', value: 'notice_date'},
        {text: 'Статус', value: 'status'},
        {text: 'Категория', value: 'category'},
        {text: 'Исполнитель', value: 'user.username'},
        {text: 'Действия', value: 'actions', align: 'end'}
      ];
    }
  },
  watch: {
    search(value) {
      this.searchFilter.or[0].name.like = value || '';
      this.searchFilter.or[1].description.like = value || '';
      this.models.config.filter = Object.assign({}, this.searchFilter, this.searchParams.filter);
      this.update(this);
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    }
  },
  methods: {
    ...mapActions(['updateNotify']),
    update: debounce((vm) => vm.models.load(), 200),
    utcToDatetime(value) {
      if (!value) {
        return 'Не указано';
      }
      return this.$d(value * 1000, 'datetime');
    },
    canDelete(item) {
      return !item.deleted_at && this.can('task.delete');
    },
    canEdit(item) {
      return !item.deleted_at && !includes([
        2,
        3
      ], item.status_id); // Выполнено, Отказано
    },
    onCreate(id) {
      this.models.load();
      this.createdID = id;
      this.$refs.createdBtn.click();
    },
    updateFilter() {
      this.models.config.filter = Object.assign({}, this.searchFilter, this.searchParams.filter);
      this.models.params = Object.assign({}, this.models.params, this.searchParams.params);
      this.update(this);
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    }
  }
};
</script>

<style scoped></style>
<template>
  <btn-icon :icon="value ? 'eye-off' : 'eye'" :title="$t(value ? 'btn.hideDeleted' : 'btn.showDeleted')" color="accent" @click="click"/>
</template>

<script>
import BtnIcon from '@/tags/BtnIcon.vue';

export default {
  name:       'ToggleDeleted',
  components: {BtnIcon},
  props:      ['value'],
  methods:    {
    click() {
      let value = this.value ? 0 : 1;
      this.$emit('input', value);
    }
  }
};
</script>

<style scoped></style>
<template>
  <section id="SectionIntegration" class="container font-weight-medium pa-0 mt-16">
    <v-row>
      <v-col cols="12" md="5" offset-md="1" order="2" order-md="0">
        <p class="pa-3 pa-md-0">
          <span class="span-accent">Встроенные проекты</span>
        </p>
        <ul class="info-list pa-3 pa-md-0">
          <li>
            Встроенная платформа <b>Школа брокера SF</b>. Самостоятельное обучение персонала: видеоматериалы, документы и сертификат
          </li>
          <li>
            Встроенная система тестирования персонала <b>HR SF Test</b>
          </li>
          <li>
            Встроенная платформа подбора кредитных и ипотечных программ <b>ФинСервисSF</b>
          </li>
          <li>
            Планировщик задач
          </li>
        </ul>
        <div class="px-3 px-md-0 mt-2">
          <v-btn :block="$vuetify.breakpoint.mobile" :to="{name:'Registration'}" color="primary" outlined>Зарегистрироваться</v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="5">
        <div class="screen">
          <img alt="Screenshot" src="@/assets/index/integration-screen.png"/>
        </div>
      </v-col>
    </v-row>
  </section>
</template>
<script>

export default {
  name: "SectionIntegration"
}
</script>


<style scoped>
.screen img {
  width: 100%;
}
</style>
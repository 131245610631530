<template>
  <modal v-model="modal" :loading="loading" max-width="600px" title="Статистика">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-autocomplete v-if="can('office.manage')" v-model="office_id" :items="officeList" clearable item-text="name" item-value="id" label="Офис" @change="loadStat()"/>
      <div v-if="!data">
        Загрузка...
      </div>
      <div v-else>
        <v-simple-table>
          <thead>
          <tr>
            <th>Тест</th>
            <th class="text-center">Количество</th>
            <th class="text-right">Результат</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in data" :key="i">
            <td>{{ item.name }}</td>
            <template v-if="item.data">
              <td class="text-center">
                {{ item.data.count }}
              </td>
              <td class="text-right">
                {{ Math.round(item.data.correct * 1000) / 10 }}%
              </td>
            </template>
            <template v-else>
              <td class="text-right" colspan="2">
                Нет данных
              </td>
            </template>
          </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-card-text>
  </modal>
</template>

<script>
import Modal        from "../../tags/Modal";
import {mapGetters} from "vuex";

export default {
  name:       "HrResultStat",
  components: {Modal},
  data() {
    return {
      modal:      false,
      loading:    false,
      office_id:  null,
      data:       null,
      officeList: []
    }
  },
  computed: {
    ...mapGetters(['can', 'office'])
  },
  watch: {
    modal(value) {
      if (value) {
        this.loadStat();
        if (this.can('office.manage')) this.axios.get('office/list').then((response) => this.officeList = response.data);
      } else {
        this.loading = false;
        this.data = null;
        this.office_id = null;
      }
    }
  },
  methods: {
    loadStat() {
      this.loading = true;
      this.data = null;
      this.axios.get('hr-result/stat' + (this.office_id ? '?office_id=' + this.office_id : ''))
          .then((response) => {
            this.data = response.data;
            this.loading = false;
          })
          .catch(() => {
            this.data = null;
            this.loading = false;
          })
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-data-table
        :class="{'data-list-hide-dividers':hideDividers}"
        :footer-props="{
                pagination: computedPagination,
                'items-per-page-options': [12, 24, 48],
                'show-current-page': true,
                'show-first-last-page': true
            }"
        :headers="computedHeader"
        :hide-default-footer="hideDefaultFooter()"
        :items="computedItems"
        :loading="models.loading"
        :options="computedOptions"
        :page="2"
        class="data-list"
        disable-filtering
        mobile-breakpoint="0"
        @update:items-per-page="updatePerPage"
        @update:page="updatePage"
        @update:sort-by="updateSortBy"
        @update:sort-desc="updateSortDesc"
    >
      <template #body="{ items }">
        <v-row :class="rowClass">
          <v-col v-for="item in items" :key="JSON.stringify(item)" :class="colClass" :cols="cols" :lg="lg" :md="md" :sm="sm" :xl="xl">
            <slot :item="item" name="item">{{ item }} }}</slot>
          </v-col>
        </v-row>
      </template>
      <template v-slot:body.append>
        <slot name="append"/>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name:     'DataGrid',
  props:    {
    models:       {
      default() {
        return {
          result:  [],
          loading: false,
          headers: {
            page:         1,
            pageCount:    1,
            itemsPerPage: 24,
            itemsLength:  0
          }
        };
      }
    },
    hideFooter:   {
      type:    Boolean,
      default: false
    },
    hideDividers: {
      type:    Boolean,
      default: false
    },
    cols:         {
      type: Number,
    },
    sm:           {
      type: Number,
    },
    md:           {
      type: Number,
    },
    lg:           {
      type: Number
    },
    xl:           {
      type: Number
    },
    rowClass:     {
      type:    String,
      default: 'ma-0 pa-2',
    },
    colClass:     {
      type:    String,
      default: 'pa-2'
    }
  },
  computed: {
    computedHeader() {
      return [{value: 'value', sortable: false, sort: () => 0, align: 'start', class: 'd-none', cellClass: 'pa-0 w-0'}];
    },
    computedItems() {
      let {itemsPerPage, itemsLength} = this.computedPagination;
      let items                       = new Array(itemsLength).fill({});
      items.splice((this.page - 1) * itemsPerPage, itemsPerPage, ...this.models.result);
      return items;
    },
    computedPagination() {
      let options = this.models.headers;
      return {
        pageStart: (options.page - 1) * options.itemsPerPage,
        pageStop:  Math.min(options.page * options.itemsPerPage, options.itemsLength),
        ...options
      };
    },
    computedOptions() {
      return {
        page:         this.models.headers.page,
        itemsPerPage: this.models.headers.itemsPerPage
      };
    },
    computedSlots() {
      let slots = [];
      this.headers.forEach(
          (header, i) =>
              (slots[i] = {
                name:  'item.' + header.value,
                value: header.value.split('.')
              })
      );
      return slots;
    },
    sort() {
      return {
        field: this.sortBy,
        asc:   this.sortAsc
      };
    }
  },
  data() {
    return {
      sortBy:  '',
      sortAsc: true,
      page:    this.models.headers.page
    };
  },
  watch:   {
    sort(value) {
      if (!(value.field === '' && value.asc === true)) {
        // this.$emit('update:sort', value);
        this.models.setSort(value.field, value.asc).load();
      }
    }
  },
  methods: {
    updatePerPage(perPage) {
      // this.$emit('update:per-page', perPage);
      this.models.setPerPage(perPage).load();
    },
    updatePage(page) {
      // this.$emit('update:page', page);
      this.models.setPage(page).load();
      this.page = page;
    },
    updateSortBy(sort) {
      this.sortBy = sort[0] || '';
    },
    updateSortDesc(desc) {
      this.sortAsc = !desc[0];
    },
    itemValue(item, value) {
      for (let idx of value) {
        if (item) {
          item = item[idx];
        }
      }
      return item;
    },
    hideDefaultFooter() {
      return this.hideFooter && this.models.headers.pageCount < 2;
    }
  }
};
</script>
<style lang="scss">
.data-list tbody {
  tr:hover {
    background-color: transparent !important;
  }
}

.w-0 {
  max-width: 0;
}
</style>
<template>
  <v-list class="px-0" expand>
    <div v-for="msFile in files" :key="msFile.id">
      <template v-if="msFile.files.length">
        <v-list-group color="primary">
          <template #activator>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium text-wrap">
                {{ msFile.name }}
                <v-icon v-if="msFile.required && !msFile.files.length" color="error" size="16">mdi-asterisk</v-icon>
                <v-icon v-if="msFile.files.length" color="success" size="16">mdi-file-document-check-outline</v-icon>
                <v-icon v-if="!msFile.files.length && completed" color="grey" size="16">mdi-cancel</v-icon>
              </v-list-item-title>
              <v-list-item-subtitle v-if="msFile.description" class="text-wrap">{{ msFile.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <v-list-item-group readonly>
            <file-item v-for="file in msFile.files" :key="file.id" :file="file" class="pr-3 pl-8"/>
          </v-list-item-group>
          <v-divider/>
        </v-list-group>
      </template>
      <template v-else>
        <v-list-item :disabled="completed && !msFile.files.length">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium text-wrap">
              {{ msFile.name }}
              <v-icon v-if="msFile.required && !msFile.files.length" color="error" size="16">mdi-asterisk</v-icon>
              <v-icon v-if="msFile.files.length" color="success" size="16">mdi-file-document-check-outline</v-icon>
              <v-icon v-if="!msFile.files.length && completed" color="grey" size="16">mdi-cancel</v-icon>
            </v-list-item-title>
            <v-list-item-subtitle v-if="msFile.description" class="text-wrap">{{ msFile.description }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </div>
  </v-list>
</template>
<script>
import FileItem from "@/views/msScript/FileItem.vue";

export default {
  name:       "MsResultFileItem",
  components: {FileItem},
  props:      {
    files: {
      type:     Array,
      required: true
    },
    completed: {
      type:    Boolean,
      default: false
    }
  }
}
</script>
<style scoped>

</style>
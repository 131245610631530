<template>
  <modal v-model="modal" :loading="loading" max-width="1000px" title="Список тегов">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          class="mb-4"
          clearable
          hide-details
      />
      <div v-for="(tags, category) in tagsList" :key="category" class="mb-8">
        <h3 class="mb-2">{{ category }}</h3>
        <v-row>
          <v-col v-for="tag in tags" :key="tag.tag" cols="12" md="6">
            <contract-tag-item :default-tag="getDefaultTag(tag)" :tag="tag" @update="loadList()"/>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <template #actions>
      <v-btn color="primary" @click="close()">Закрыть</v-btn>
      <v-spacer/>
      <contract-tag-form :default-tag="contractTag" :tag="contractTag" @save="loadList()">
        <adaptive-btn icon-prepend="plus" label="Добавить тег"/>
      </contract-tag-form>
    </template>
  </modal>
</template>
<script>

import Modal           from "@/tags/Modal.vue";
import ContractTagItem from "./ContractTagItem.vue";
import {groupBy}       from "lodash";
import AdaptiveBtn     from "@/tags/AdaptiveBtn.vue";
import ContractTagForm from "@/views/contractFiles/tags/ContractTagForm.vue";

export default {
  name:       "ContractTagList",
  components: {ContractTagForm, AdaptiveBtn, ContractTagItem, Modal},
  data() {
    return {
      modal:   false,
      loading: false,
      tags:    [],
      search:  '',
    }
  },
  computed: {
    filteredTags() {
      return this.tags.filter((tag) => {
        if (!this.search) {
          return true;
        }
        let search = this.search.toLowerCase();
        if (tag.category.toLowerCase().indexOf(search) > -1) {
          return true;
        }
        if (tag.tag.toLowerCase().indexOf(search) > -1) {
          return true;
        }
        if (tag.name.toLowerCase().indexOf(search) > -1) {
          return true;
        }
      });
    },
    tagsList() {
      return groupBy(this.filteredTags, 'category');
    },
    contractTag() {
      return {
        category:   "Доп. теги",
        name:       "Значения",
        path:       "values",
        tag:        "contract_tag:values",
        type:       "default",
        value_type: "const"
      };
    }
  },
  watch:    {
    modal(value) {
      if (value) {
        this.loadList();
      }
    }
  },
  methods:  {
    loadList() {
      this.loading = true;
      this.axios.get('contract-tag/list').then(response => {
        this.tags    = response.data;
        this.loading = false;
      });
    },
    getDefaultTag(tag) {
      if (tag.type === 'custom') {
        const tagValue = tag.tag.split(':').slice(0, 2).join(':')
        return this.tags.find(item => item.tag === tagValue);
      }
      return tag;
    },
    close() {
      this.modal = false;
    }
  }
}
</script>


<style scoped>

</style>
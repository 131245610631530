<template>
  <v-form @submit.prevent.stop="selectOfficeType">
    <v-list class="mb-2 transparent text-left" rounded>
      <v-list-item-group v-model="officeType">
        <v-list-item v-for="type in officeTypesList" :key="type.value" :value="type.value" class="white" color="secondary">
          <template #default="{ active }">
            <v-list-item-content>
              <v-list-item-title class="text-wrap" v-text="type.name"/>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon :color="active?'primary':'grey'">mdi-check-circle</v-icon>
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div class="d-flex my-2">
      <v-btn color="primary" outlined @click="$emit('back')">Назад</v-btn>
      <v-spacer/>
      <v-btn color="primary" type="submit" @click="selectOfficeType">Далее</v-btn>
    </div>
  </v-form>
</template>
<script>

import {OFFICE_TYPE, OFFICE_TYPE_NAME} from "@/models/Office";

export default {
  name: "RegistrationOfficeType",
  data() {
    return {
      officeType:      null,
      officeTypesList: [
        {
          name:  OFFICE_TYPE_NAME.LLC,
          value: OFFICE_TYPE.LLC
        },
        {
          name:  OFFICE_TYPE_NAME.IP,
          value: OFFICE_TYPE.IP
        },
        {
          name:  OFFICE_TYPE_NAME.PERSONAL,
          value: OFFICE_TYPE.PERSONAL
        },
      ],
    };
  },
  methods: {
    selectOfficeType() {
      this.$emit('complete', this.officeType)
    }
  }
}
</script>


<style scoped>

</style>
<template>
  <section id="SectionInfo" class="container font-weight-medium pa-0 mt-16">
    <v-row>
      <v-col cols="12" md="5" offset-md="1">
        <div class="screen">
          <img alt="Screenshot" src="@/assets/index/info-screen.png"/>
        </div>
      </v-col>
      <v-col cols="12" md="5">
        <p class="pa-3 pa-md-0">
          <span class="span-accent">pro CRM SF</span>
          - платформа для ведения клиентов,
          агентского сотрудничества и расширения бизнеса для кредитных и ипотечных брокеров,
          юристов по банкротству и риелторов.
          Платформа позволяет зарабатывать на совместных сделках по кредитам,
          ипотеке, банкротству, страховании и недвижимости.
        </p>
        <ul class="info-list pa-3 pa-md-0">
          <li>
            Бесплатный сокращенный доступ
          </li>
          <li>
            Все контакты партнеров: банки, инвесторы, арбитражные управляющие, страховые компании и другие
          </li>
          <li>
            Постоянное обновление pro CRM SF
          </li>
          <li>
            Возможность импорта и экспорта базы
          </li>
          <li>
            Максимальная автоматизация
          </li>
        </ul>
        <div class="px-3 px-md-0 mt-2">
          <v-btn :block="$vuetify.breakpoint.mobile" :to="{name:'Registration'}" color="primary" outlined>Зарегистрироваться</v-btn>
        </div>
      </v-col>
    </v-row>
  </section>
</template>
<script>

export default {
  name: "SectionInfo"
}
</script>

<style lang="scss" scoped>
.screen img {
  width: 100%;
}


</style>
<template>
  <v-card>
    <v-title title="Контакты поддержки"/>
    <v-card-title>
      <span class="mr-5">Контакты поддержки</span>
      <span>
      <support-contact-form v-if="can('support-contact.manage')" @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </support-contact-form>
      </span>
      <toggle-deleted v-if="can('support-contact.manage')" v-model="showDeleted" class="ml-2"/>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
          hide-details
      />
    </v-card-title>
    <data-grid
        :cols="12"
        :headers="headers"
        :lg="3"
        :md="4"
        :models="models"
        :sm="6"
        hide-footer
    >
      <template #item="{ item }">
        <support-contact-item :item="item" @update="models.load()"/>
      </template>
    </data-grid>
  </v-card>
</template>

<script>
import {Search}           from "@/models/Search";
import {debounce}         from "lodash/function";
import {mapGetters}       from "vuex";
import ToggleDeleted      from "./ToggleDeleted";
import VTitle             from "@/tags/VTitle";
import BtnIcon            from "@/tags/BtnIcon";
import SupportContactForm from "./SupportContactForm";
import DataGrid           from "@/tags/DataGrid.vue";
import SupportContactItem from "@/views/supportContact/SupportContactItem.vue";


export default {
  name:       "Index",
  components: {SupportContactItem, DataGrid, SupportContactForm, BtnIcon, VTitle, ToggleDeleted},
  data:       () => ({
    search: '',
    models: Search.create({
      uri:    'support-contact',
      fields: ['id', 'avatar', 'name', 'direction', 'phone', 'email', 'created_at', 'updated_at', 'deleted_at'],
      params: {deleted: 0},
      filter: {or: [{name: {like: ''}}]}
    }).load(),
    showDeleted: 0
  }),
  computed:   {
    ...mapGetters(['can']),
    headers() {
      let headers = [
        {text: 'Фото', value: 'avatar'},
        {text: 'ФИО', value: 'name', sortable: true},
        {text: 'Направление', value: 'direction', sortable: true},
        {text: 'Телефон', value: 'phone'},
        {text: 'Электронная почта', value: 'email'}
      ]
      if (this.can('support-contact.manage')) {
        headers.push({text: 'Действия', value: 'actions', align: 'end'});
      }
      return headers;
    }
  },
  watch:      {
    search(value) {
      this.models.config.filter.or[0].name.like = value || '';
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    }
  },
  methods:    {
    update: debounce((vm) => vm.models.load(), 200),
    utcToDatetime(value) {
      return this.$d(value * 1000, 'datetime');
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <v-expansion-panels v-model="active" focusable>
    <v-expansion-panel>
      <v-expansion-panel-header class="py-0">
        <div class="d-flex align-center align-start">
          <div>
            <v-img :src="logoSrc" :width="$vuetify.breakpoint.smAndDown?80:230" class="my-2" contain height="64"/>
          </div>
          <h3 class="ml-4 ml-md-8">{{ item.name }}</h3>
          <v-spacer/>
          <v-chip v-if="item.url_site" :href="item.url_site" class="mr-4" color="white" target="_blank" @click.stop>
            <span class="mr-2">Сайт</span>
            <v-icon color="light-blue" small>mdi-open-in-new</v-icon>
          </v-chip>
          <v-chip v-if="item.url_pa" :href="item.url_pa" class="mr-4" color="white" target="_blank" @click.stop>
            <span class="mr-2">ЛК</span>
            <v-icon color="light-blue" small>mdi-open-in-new</v-icon>
          </v-chip>
          <v-chip class="mr-4" color="white">КВ: {{ item.commission_reward }}%</v-chip>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <h3 class="mt-4 mb-2">Контакты</h3>
        <r-card class="py-4">
          <v-row>
            <v-col cols="12" md="3" sm="6">
              <list-item :avatar-photo="item.agent_photo" :text="item.agent" avatar title="Представитель"/>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <list-item :href="`tel:+${item.phone}`" target="_blank" title="Телефон">
                {{ formatPhone(item.phone) }}
                <btn-icon color="primary" icon="phone" title="Позвонить"/>
              </list-item>
            </v-col>
            <v-col v-if="item.extra_phone" cols="12" md="3" sm="6">
              <list-item :href="`tel:+${item.extra_phone}`" target="_blank" title="Доп. телефон">
                {{ formatPhone(item.extra_phone) }}
                <btn-icon color="primary" icon="phone" title="Позвонить"/>
              </list-item>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <list-item :href="`mailto:${item.email}`" target="_blank" title="Почта">
                {{ item.email }}
                <btn-icon color="primary" icon="email-outline" title="Написать"/>
              </list-item>
            </v-col>
            <v-col v-if="item.extra_email" cols="12" md="3" sm="6">
              <list-item :href="`mailto:${item.extra_email}`" target="_blank" title="Доп. почта">
                {{ item.extra_email }}
                <btn-icon color="primary" icon="email-outline" title="Написать"/>
              </list-item>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <list-item :text="item.address" title="Адрес"/>
            </v-col>
            <v-col v-if="showTypesList" cols="12">
              <list-item title="Типы лидов">
                <v-chip v-for="type in filteredTypesList"
                        :key="type.id"
                        :color="type.color"
                        :input-value="type.checked"
                        class="mr-2 mb-2"
                        dark
                        filter
                        @click="switchType(type.id)">

                  {{ type.name }}
                </v-chip>
                <v-chip v-if="showMoreBtn" class="mr-2 mb-2" color="grey lighten-2" @click="showFullTypesList">Еще {{ countMore }}...</v-chip>
              </list-item>
            </v-col>
          </v-row>
        </r-card>

        <template v-if="hasDocuments">
          <h3 class="mt-4 mb-2">Документы</h3>
          <r-card class="pa-4">
            <v-btn v-for="file in item.filesList" :key="file.id" :href="documentSrc(file)" class="mr-4 mb-2" color="primary" target="_blank">
              {{ file.name }}
              <v-icon class="ml-2">mdi-download</v-icon>
            </v-btn>
          </r-card>
        </template>

        <template v-if="item.description">
          <h3 class="mt-4 mb-2">Описание</h3>
          <r-card :height="200" expandable>
            <div class="ma-4 text-pre-wrap">{{ item.description }}</div>
          </r-card>
        </template>

        <comments :id="item.id" :autoload="true" :form-bottom="true" :only-view="!can('comment.manage')" top type="bank"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>

import {apiUri, formatPhone} from "@/helpers/stringHelpers";
import RCard                 from "@/tags/RCard.vue";
import ListItem              from "@/tags/ListItem.vue";
import Comments              from "@/views/comments/Comments.vue";
import {mapGetters}          from "vuex";
import BtnIcon               from "@/tags/BtnIcon.vue";

export default {
  name:       "BankItem",
  components: {BtnIcon, Comments, ListItem, RCard},
  props:      {
    item:          {
      type:     Object,
      required: true
    },
    selectedTypes: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  beforeMount() {
    this.setCheckedTypes();
  },
  data() {
    return {
      active:         false,
      shortTypesList: true,
      typesList: [],
    }
  },
  computed: {
    ...mapGetters(['can']),
    logoSrc() {
      return apiUri(this.item.logo);
    },
    hasDocuments() {
      return this.item.filesList?.length > 0;
    },
    showTypesList() {
      return this.item.typesList?.length > 0;
    },
    showMoreBtn() {
      return this.item.typesList?.length > 5 && this.shortTypesList;
    },
    countMore() {
      return this.item.typesList?.length - 5;
    },
    filteredTypesList() {
      let typesList = [...this.typesList];
      typesList     = typesList.sort((a, b) => !a.checked - !b.checked)
      if (this.shortTypesList) {
        typesList = typesList.slice(0, 5);
      }
      return typesList;
    }
  },
  watch:    {
    selectedTypes() {
      this.setCheckedTypes();
    },
    item() {
      this.setCheckedTypes();
    },
  },
  methods:  {
    documentSrc(file) {
      return apiUri(file.file.path);
    },
    showFullTypesList() {
      this.shortTypesList = false;
    },
    setCheckedTypes() {
      if (this.showTypesList) {
        this.typesList = this.item.typesList;
        for (let i in this.typesList) {
          this.typesList[i].checked = this.selectedTypes.includes(this.typesList[i].id);
        }
      }
    },
    switchType(type_id) {
      let result;
      this.typesList = [
        ...this.typesList.map(type => {
          if (type.id === type_id) {
            type.checked = !type.checked;
            result       = type.checked;
          }
          return type;
        })
      ];
      this.$emit(result ? 'type:enable' : 'type:disable', type_id);
    },
    formatPhone
  }
}
</script>


<style scoped>

</style>
<template>
  <btn-icon :color="favorite?'warning':'grey'" :icon="model.loading?'progress-star':'star'" :title="favorite?'Из избранного':'В избранное'" @click="model.setFavorite(!favorite)"/>
</template>

<script>
import BtnIcon from "@/tags/BtnIcon";
import {Task}  from "../../models/Task";

export default {
  name:       "Favorite",
  components: {BtnIcon},
  props:      ['id', 'favorite'],
  data() {
    return {
      model: Task.get(this.id, {
        on: {
          setFavorite: () => {
            this.$emit('update')
          }
        }
      })
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <modal v-model="modal" :loading="model.loading" :title="'Клиент: ' + (this.model.attr.num || '...')" max-width="1000px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-row class="ma-0">
      <v-col class="pa-0" cols="12">
        <v-card-text>
          <h3 class="mb-2">
            Информация о клиентe
            <span>{{ fill_percent }}</span>
          </h3>
          <r-card class="py-4">
            <v-row>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.fullName">{{ model.attr.fullName }}</list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.age">{{ model.attr.age || 'Не указано' }}</list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.gender">{{ formatGender(model.attr.gender) }}</list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.city">{{ model.attr.city || 'Не указано' }}</list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.phone">
                  <div v-if="showContacts">
                    {{ formatPhone(model.attr.phone) }}
                    <client-duplicate :id="model.attr.id" :search="model.attr.phone" @load="updatePhoneDuplicate">
                      <btn-icon v-if="havePhoneDuplicate" color="warning" icon="account-multiple" title="Показать дубликаты"/>
                    </client-duplicate>
                    <phone-list :phone="model.attr.phone">
                      <btn-icon color="primary" icon="phone" title="Позвонить"/>
                    </phone-list>
                  </div>
                  <div v-else>Скрыто</div>
                </list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.extra_phone">
                  <div v-if="showContacts">
                    {{ formatPhone(model.attr.extra_phone) || 'Не указано' }}
                    <client-duplicate :id="model.attr.id" :search="model.attr.extra_phone" @load="updateExtraPhoneDuplicate">
                      <btn-icon v-if="haveExtraPhoneDuplicate" color="warning" icon="account-multiple" title="Показать дубликаты"/>
                    </client-duplicate>
                    <phone-list v-if="model.attr.extra_phone" :phone="model.attr.extra_phone">
                      <btn-icon color="primary" icon="phone" title="Позвонить"/>
                    </phone-list>
                  </div>
                  <div v-else>Скрыто</div>
                </list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.email">
                  <div v-if="showContacts">
                    {{ model.attr.email || 'Не указано' }}
                    <client-duplicate :id="model.attr.id" :search="model.attr.email" @load="updateEmailDuplicate">
                      <btn-icon v-if="haveEmailDuplicate" color="warning" icon="account-multiple" title="Показать дубликаты"/>
                    </client-duplicate>
                  </div>
                  <div v-else>Скрыто</div>
                </list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.success_chance">
                  <v-chip :color="successChanceColor" dark>{{ model.attr.success_chance * 10 }}%</v-chip>
                </list-item>
              </v-col>
            </v-row>
          </r-card>

          <template v-if="showContacts">
            <h3 class="mt-4 mb-2">Дополнительная информация</h3>
            <r-card class="py-4">
              <v-row>
                <v-col cols="12" md="4">
                  <list-item :title="model.labels.passport">{{ model.attr.passport || 'Не указано' }}</list-item>
                </v-col>
                <v-col cols="12" md="4">
                  <list-item :title="model.labels.snils">{{ model.attr.snils || 'Не указано' }}</list-item>
                </v-col>
                <v-col cols="12" md="4">
                  <list-item :title="model.labels.inn">{{ model.attr.inn || 'Не указано' }}</list-item>
                </v-col>
                <v-col cols="12">
                  <list-item :title="model.labels.passport_issued">{{ model.attr.passport_issued || 'Не указано' }}</list-item>
                </v-col>
                <v-col cols="12" md="6">
                  <list-item :title="model.labels.address_registration">{{ model.attr.address_registration || 'Не указано' }}</list-item>
                </v-col>
                <v-col cols="12" md="6">
                  <list-item :title="model.labels.address_residence">{{ model.attr.address_residence || 'Не указано' }}</list-item>
                </v-col>
              </v-row>
            </r-card>
          </template>

          <h3 class="mt-4 mb-2">Статус клиента</h3>
          <r-card class="py-4">
            <v-row>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.status_id">
                  <v-chip :color="statusColor" dark>{{ statusName }}</v-chip>
                </list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.category_id">
                  <v-chip :color="categoryColor" dark>{{ categoryName }}</v-chip>
                </list-item>
              </v-col>
              <v-col v-if="showRejectionReason" cols="12" md="4">
                <list-item :title="model.labels.rejection_reason_id">{{ rejectionReasonName }}</list-item>
              </v-col>
              <v-col v-if="showRefundReason" cols="12" md="4">
                <list-item :title="model.labels.refund_reason_id">{{ refundReasonName }}</list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.notice_date">
                  {{ utcToDatetime(model.attr.notice_date) }}
                  <client-calendar-modal v-if="canViewCalendar" :current-item="model.attr" @update="updateNoticeDate">
                    <btn-icon color="warning" icon="calendar" title="Календарь"/>
                  </client-calendar-modal>
                </list-item>
              </v-col>
              <v-col v-if="showFinished " cols="12" md="4">
                <list-item :title="model.labels.finished_at">{{ utcToDatetime(model.attr.finishedAt) }}</list-item>
              </v-col>
              <v-col cols="12" md="4">
                <list-item :title="model.labels.created_at">{{ utcToDatetime(model.attr.created_at) }}</list-item>
              </v-col>
            </v-row>
          </r-card>

          <div>
            <h3 class="mt-4 mb-2">Дополнительные услуги</h3>
            <service-list :id="model.attr.id" ref="service" :only-view="onlyView"/>
          </div>

          <div>
            <h3 class="mt-4 mb-2">Сделки</h3>
            <deal-list :id="model.attr.id" ref="deal" :client="model.attr" :only-view="onlyView" :type-id="model.attr.type_id"/>
          </div>

          <div v-if="showMsResult">
            <h3 class="mt-4 mb-2">Документы</h3>
            <ms-result-list :id="model.attr.id" ref="script" :only-view="onlyView" :scripts="scriptsList"/>
          </div>

          <h3 class="mt-4 mb-2">Остальное</h3>
          <r-card class="py-4">
            <v-row>
              <v-col cols="12" md="6">
                <list-item :title="model.labels.source_id">{{ sourceName }}</list-item>
              </v-col>
              <v-col cols="12" md="6">
                <list-item :title="model.labels.type_id">
                  <v-chip :color="typeColor" dark>{{ typeName }}</v-chip>
                </list-item>
              </v-col>
            </v-row>

            <v-row>
              <v-col v-for="field in model.attr.fields" :key="field.id" cols="12" md="6">
                <list-item :title="field.name">
                  {{ formatFieldValue(model.attr['___' + field.id], field) }}
                  <template v-if="showCalculatorView(field)" #action>
                    <calculator-view :initial="autofillInitialSum" :sum="autofillSum" :type="typeCalculator">
                      <btn-icon color="secondary" icon="calculator" title="Калькулятор"/>
                    </calculator-view>
                  </template>
                </list-item>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <list-item :title="model.labels.office_id">{{ officeName }}</list-item>
              </v-col>
              <v-col cols="12" md="6">
                <list-item :title="model.labels.manager_id">{{ managerName }}</list-item>
              </v-col>
            </v-row>
          </r-card>

          <r-card class="py-4 mt-2">
            <v-row>
              <v-col>
                <list-item :title="model.labels.fssp">
                  <v-checkbox v-model="model.attr.fssp" class="ml-2" readonly/>
                </list-item>
              </v-col>
              <v-col>
                <list-item :title="model.labels.bci">
                  <v-checkbox v-model="model.attr.bci" class="ml-2" readonly/>
                </list-item>
              </v-col>
              <v-col>
                <list-item :title="model.labels.sb">
                  <v-checkbox v-model="model.attr.sb" class="ml-2" readonly/>
                </list-item>
              </v-col>
              <v-col>
                <list-item :title="model.labels.fms">
                  <v-checkbox v-model="model.attr.fms" class="ml-2" readonly/>
                </list-item>
              </v-col>
              <v-col>
                <list-item :title="model.labels.employer">
                  <v-checkbox v-model="model.attr.employer" class="ml-2" readonly/>
                </list-item>
              </v-col>
            </v-row>
          </r-card>

          <r-card class="mt-2 py-4">
            <v-row>
              <v-col cols="12">
                <list-item :title="model.labels.description">
                  <div class="text-pre-wrap">{{ model.attr.description || 'Нет описания' }}</div>
                </list-item>
              </v-col>
            </v-row>
          </r-card>
        </v-card-text>
      </v-col>
      <v-col class="pa-0" cols="12">
        <v-card-text>
          <comments :id="model.attr.id" ref="comments" :only-view="onlyView || !can('comment.manage')" top type="client"/>
        </v-card-text>
      </v-col>
    </v-row>
    <template v-slot:actions>
      <client-form v-if="canEdit" :id="model.attr.id" @save="update()">
        <v-btn color="primary">{{ $t('btn.edit') }}</v-btn>
      </client-form>
      <client-document-form v-if="canEdit" :id="model.attr.id" @submit="update()">
        <adaptive-btn v-if="contractSign" :icon-append="documentSent ? 'check-circle' : ''" :label="$t('service.sendDocuments')" class="ml-2" icon-append-color="green lighten-2" icon-prepend="file-send"/>
      </client-document-form>
      <adaptive-btn v-if="!contractSign" :label="$t('service.sendDocuments')" class="ml-2" disabled icon-prepend="file-send"/>
      <adaptive-btn v-if="canDuplicate" :disabled="model.loading" class="ml-2" icon-prepend="account-multiple-plus" label="Дублировать" @click="copyProfile()"/>
    </template>
  </modal>
</template>
<script>
import {Client, CLIENT_FIELDS_COPY} from '@/models/Client';
import Modal                        from '@/tags/Modal';
import ClientForm                   from '@/views/clients/ClientForm';
import ListItem                     from '@/tags/ListItem';
import {formatGender, formatPhone}  from '@/helpers/stringHelpers';
import {mapGetters}                 from 'vuex';
import Comments                     from '@/views/comments/Comments';
import ServiceList                  from '@/views/services/ServiceList';
import DealList                     from '@/views/deals/DealList';
import ClientDuplicate              from '@/views/clients/ClientDuplicate';
import BtnIcon                      from '@/tags/BtnIcon';
import PhoneList                    from '@/views/clients/PhoneList';
import ClientDocumentForm           from '@/views/clients/ClientDocumentForm.vue';
import AdaptiveBtn                  from '@/tags/AdaptiveBtn.vue';
import {includes, isEmpty, isNull}  from "lodash";
import ClientCalendarModal          from "@/views/clientCalenar/ClientCalendarModal";
import {isArray}                    from "highcharts";
import Inputmask                    from 'inputmask'
import MsResultList                 from "@/views/msScript/MsResultList.vue";
import RCard                        from "@/tags/RCard.vue";
import {CLIENT_STATUSES}            from "@/models/ClientStatus";
import CalculatorView               from "@/views/calculator/CalculatorView.vue";


export default {
  name:       'ClientView',
  components: {CalculatorView, RCard, MsResultList, ClientCalendarModal, PhoneList, BtnIcon, ClientDuplicate, DealList, ServiceList, Comments, ClientForm, ListItem, Modal, ClientDocumentForm, AdaptiveBtn},
  props:      ['id', 'onlyView', 'canDuplicate'],
  data() {
    return {
      modal: false,
      model: Client.get(this.id, {
        with: ['category', 'manager', 'manager.office', 'refundReason', 'rejectionReason', 'source', 'status', 'type', 'type.scripts', 'document_sent', 'fill_percent', 'fields', 'finishedAt'],
        on:   {
          load: () => {
            this.canEdit = !this.onlyView && !this.can('client.agent');
            this.$refs.comments.fillParams();
            this.$refs.service.fillParams();
            this.$refs.deal.fillParams();
            if (this.scriptsList.length) {
              setTimeout(() => this.$refs.script.fillParams(), 0);
            }
          },
          save: () => {
            this.model.attr.notice_date = parseInt(this.model.attr.notice_date);
            this.$emit('update');
          }
        }
      }),
      canEdit:                 false,
      havePhoneDuplicate:      false,
      haveExtraPhoneDuplicate: false,
      haveEmailDuplicate:      false,
      updated:                 false
    };
  },
  computed: {
    ...mapGetters(['can']),
    successChanceColor() {
      let n = this.model.attr.success_chance;
      if (n < 3) {
        return 'error';
      }
      if (n < 7) {
        return 'warning';
      }
      return 'success';
    },
    statusName() {
      return this.model.attr.status?.name;
    },
    statusColor() {
      return this.model.attr.status?.color;
    },
    categoryName() {
      return this.model.attr.category?.name;
    },
    categoryColor() {
      return this.model.attr.category?.color;
    },
    typeName() {
      return this.model.attr.type?.name;
    },
    typeColor() {
      return this.model.attr.type?.color;
    },
    rejectionReasonName() {
      return this.model.attr.rejectionReason?.name;
    },
    refundReasonName() {
      return this.model.attr.refundReason?.name;
    },
    sourceName() {
      return this.model.attr.source?.name;
    },
    officeName() {
      return this.model.attr.manager?.office?.name;
    },
    managerName() {
      return this.model.attr.manager?.username;
    },
    showMsResult() {
      return this.scriptsList.length && this.can('ms-result.manage')
    },
    contractSign() {
      return this.model.attr.status_id === 5;
    },
    documentSent() {
      return this.model.attr.document_sent > 0;
    },
    fill_percent() {
      return `${Math.round(this.model.attr.fill_percent * 100)}%`;
    },
    canViewCalendar() {
      return includes([2, 3, 10], this.model.attr.status_id);
    },
    scriptsList() {
      return this.model.attr?.type?.scripts || [];
    },
    showFinished() {
      return this.model.attr.status_id === CLIENT_STATUSES.COMPLETE
    },
    showRejectionReason() {
      return this.model.attr.status_id === CLIENT_STATUSES.DENIED;
    },
    showRefundReason() {
      return this.model.attr.status_id === CLIENT_STATUSES.REFUND;
    },
    showContacts() {
      const isCompletedOrDenied = [CLIENT_STATUSES.COMPLETE, CLIENT_STATUSES.DENIED].includes(this.model.attr.status_id);
      const canManageCompleted  = this.can('client.manage.completed');
      return !isCompletedOrDenied || canManageCompleted;
    },
    autofillSum() {
      let sum = 0;
      this.model.attr.fields.forEach(field => {
        if (field.autofill_sum) {
          if (field.autofill_factor > 0) {
            const value = this.model.attr["___" + field.id];
            sum += value * field.autofill_factor;
          }
        }
      });
      return sum;
    },
    autofillInitialSum() {
      let sum = 0;
      this.model.attr.fields.forEach(field => {
        if (field.autofill_sum) {
          if (field.autofill_factor < 0) {
            const value = this.model.attr["___" + field.id];
            sum += value * -field.autofill_factor;
          }
        }
      });
      return sum;
    },
    typeCalculator() {
      return this.model.attr.type_id === 1 ? 'ipoteka' : 'potreb';
    }
  },
  watch:    {
    modal(value) {
      if (value) {
        this.model.attr.id = this.id;
        this.model.load();
      } else if (this.updated) {
        this.$emit('update');
      }
    }
  },
  methods:  {
    formatGender,
    formatPhone,
    utcToDatetime(value) {
      if (!value) {
        return 'Не указано';
      }
      return this.$d(value * 1000, 'datetime');
    },
    close() {
      this.modal = false;
    },
    update() {
      this.model.load();
      this.$emit('update');
    },
    updatePhoneDuplicate(count) {
      this.havePhoneDuplicate = !!count;
    },
    updateExtraPhoneDuplicate(count) {
      this.haveExtraPhoneDuplicate = !!count;
    },
    updateEmailDuplicate(count) {
      this.haveEmailDuplicate = !!count;
    },
    updateNoticeDate(date) {
      this.model.attr.notice_date = date;
      this.model.save(['notice_date']);
    },
    copyProfile() {
      const data = {};
      CLIENT_FIELDS_COPY.forEach(field => {
        let value = this.model.attr[field];
        if (![null, undefined, NaN, ''].includes(value)) {
          data[field] = value;
        }
      })
      this.$emit('copy-profile', data);
      this.close();
    },
    formatFieldValue(value, field) {
      switch (field.type) {
        case 0:
        case 1:
          try {
            if (!isEmpty(field.mask) && !isNull(value) && typeof value !== 'undefined') {
              let mask = this.filterMask(field.mask);
              let im   = Inputmask(mask);
              let el   = document.createElement('input');
              el.value = value;
              im.mask(el);
              value = el.value;
            }
          }
          catch (e) {
            console.warn('Значение поля ' + field.name + ' (' + field.id + ') не совместимо с указанным для него типом данных')
          }
          return isNull(value) || value === '' ? 'Не указано' : field.params.prefix + value + field.params.suffix;
        case 2:
          return isNull(value) ? field.values[0] || 'Не указано' : value;
        case 3:
          return isNull(value) ? 'Не указано' : isArray(value) ? value.join(', ') : value || 'Не указано';
        case 4:
          return !value ? 'Не указано' : this.$d(new Date(field.params.local ? +value * 1000 : value).getTime(), field.params.only_date ? 'date' : 'datetime');
      }
    },
    filterMask(mask) {
      return !isEmpty(mask) ? Object.fromEntries(Object.entries(mask).filter(([key]) => !['suffix', 'prefix'].includes(key))) : '';
    },
    showCalculatorView(field) {
      return field.autofill_sum && field.autofill_factor > 0;
    }
  }
};
</script>

<style scoped></style>
<template>
  <modal v-model="modal" :loading="model.loading" :title="'Изменение файла '+model.attr.name ">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          </v-col>
          <v-col cols="12">
            <v-autocomplete v-model="model.attr.folder_id" :items="folderList" :label="model.labels.folder_id" :rules="model.rule('folder_id')" item-text="name" item-value="id"/>
          </v-col>
          <v-col cols="12">
            <v-switch v-model="model.attr.public" :false-value="0" :label="model.labels.public" :rules="model.rule('public')" :true-value="1"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal          from "../../tags/Modal";
import {DocumentFile} from "../../models/DocumentFile";

export default {
  name:       "FileForm",
  components: {Modal},
  props:      {
    category: {
      type:     Number,
      required: true
    },
    id: {
      type: Number
    },
    showDeleted: {
      type: Number
    }
  },
  data() {
    return {
      modal: false,
      model: DocumentFile.get(this.id, {
        on: {
          save: () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      folderList: []
    }
  },
  computed: {},
  watch:    {
    modal(value) {
      if (value) {
        this.id && this.model.load();
        this.axios.get('document-folder/list', {params: {category: this.category, deleted: this.showDeleted}}).then(response => this.folderList = response.data);
      }
    }
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.modal = false;
    }
  }
}
</script>

<style scoped>

</style>
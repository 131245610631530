<template>
  <v-card-text class="pt-3 text-center">
    <template v-if="isEmptyCurrentFile">
      <r-card class="text--disabled pa-4">
        Загрузка данных...
      </r-card>
    </template>
    <template v-else>
      <v-progress-linear :value="progress" class="mb-4" color="primary" height="12" rounded/>
      <h3>
        {{ currentFile.name }}
        <v-icon v-if="currentFile.required" color="error" size="16">mdi-asterisk</v-icon>
      </h3>
      <div class="mb-2 small text--disabled">{{ currentFile.description }}</div>

      <v-form ref="form" :disabled="disabled" @submit.prevent.stop="!disableLoadBtn && save">
        <file-input
            v-model="selectedFiles"
            :hint="hint"
            :label="label"
            :multiple="currentFile.multiple"
            :rules="rules"
            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
            class="mx-4"
            persistent-hint
            ref="inputFile"
        />
        <v-btn :disabled="disableLoadBtn" class="mt-4" color="primary" @click="save">Загрузить</v-btn>
      </v-form>

      <r-card class="small text--disabled pa-4 text-left mt-8">
        Рекомендации по отправке для ускорения обработки данных:<br/>
        - Размер файла не более 3 Мб;<br/>
        - Форматы: PDF, DOC, DOCX, JPG, JPEG и PNG;
        <template v-if="currentFile.files.length">
          <br/>
          <template v-if="currentFile.multiple">
            - Выбранные файлы будут добавлены к загруженным;
          </template>
          <template v-else>
            - Ранее загруженный файл будет заменен;
          </template>
        </template>
      </r-card>

      <div v-if="currentFile.files.length" class="text-left">
        <h3 class="mt-4 mb-2">Загруженные файлы</h3>
        <r-card>
          <v-list>
            <v-list-item-group v-model="removedFiles" multiple>
              <file-item v-for="file in currentFile.files" :key="file.id" :file="file" selectable/>
            </v-list-item-group>
          </v-list>
        </r-card>
        <div class="text-center">
          <v-btn :disabled="disableRemoveBtn" class="mt-4" color="primary" @click="remove">Удалить выбранные файлы</v-btn>
        </div>
      </div>

      <div v-if="currentFile.relatedFiles.length" class="text-left">
        <h3 class="mt-4 mb-2">Список подходящих файлов</h3>
        <r-card>
          <v-list>
            <v-list-item-group v-model="replacedFiles" :multiple="currentFile.multiple">
              <file-item v-for="file in currentFile.relatedFiles" :key="file.id" :file="file" selectable/>
            </v-list-item-group>
          </v-list>
        </r-card>
        <div class="text-center">
          <v-btn :disabled="disableReplaceBtn" class="mt-4 mx-auto" color="primary" @click="replace">{{ replaceBtnName }}</v-btn>
        </div>
      </div>
    </template>
  </v-card-text>
</template>
<script>

import FileInput from "@/tags/FileInput.vue";
import RCard     from "@/tags/RCard.vue";
import FileItem  from "@/views/msScript/FileItem.vue";
import {isArray} from "highcharts";

export default {
  name:       "MsResultFormFiles",
  components: {FileItem, RCard, FileInput},
  props:      {
    value: {
      type:     Object,
      required: true
    },
    disabled: {
      type:    Boolean,
      default: false
    },
    rules: {
      type: Array
    }
  },
  mounted() {
    this.$emit('mounted');
  },
  data() {
    return {
      requiredFilesList: [],
      filesList:         [],
      currentFile:       {},
      relatedFiles:      [],
      selectedFiles:     undefined,
      removedFiles:      undefined,
      replacedFiles:     undefined
    };
  },
  computed: {
    progress() {
      let progress = this.requiredFilesList.length > 0 ?
                     Math.max(0, this.requiredFilesList.findIndex(file => file.id === this.currentFile.id) / this.requiredFilesList.length) : 0
      return 100 * progress;
    },
    label() {
      return this.currentFile.multiple ? 'Файлы' : 'Файл';
    },
    hint() {
      return this.currentFile.multiple ? 'Выберите один или несколько файлов для загрузки' : 'Выберите файл для загрузки';
    },
    isSelectedFiles() {
      return !!this.selectedFiles && (this.selectedFiles?.length || this.selectedFiles instanceof File);
    },
    disableLoadBtn() {
      return !this.isSelectedFiles || this.disabled;
    },
    disableBackBtn() {
      return !Object.keys(this.prevFile).length || this.disabled;
    },
    disableSkipBtn() {
      return this.isEmptyCurrentFile || (this.currentFile.required && !this.currentFile.files.length) || this.disabled;
    },
    disableRemoveBtn() {
      return !this.removedFiles || !this.removedFiles.length || this.disabled;
    },
    disableReplaceBtn() {
      return !this.replacedFiles || (isArray(this.replacedFiles) && !this.replacedFiles.length) || this.disabled;
    },
    files() {
      const files = [];
      for (const requiredFile of this.requiredFilesList) {
        const file = this.filesList.find(file => file.id === requiredFile.id);
        requiredFile.files = file ? file.files : [];
        const relatedFile = this.relatedFiles.find(file => file.id === requiredFile.id)
        requiredFile.relatedFiles = relatedFile ? relatedFile.files.filter(rFile => requiredFile.files.findIndex(file => file.id === rFile.id) < 0) : [];
        files.push(requiredFile);
      }
      return files;
    },
    isEmptyCurrentFile() {
      return Object.keys(this.currentFile).length === 0;
    },
    isLoadedCurrentFile() {
      return this.filesList.findIndex(file => file.id === this.currentFile.id) > -1;
    },
    prevFile() {
      if (!this.isEmptyCurrentFile) {
        let idx = this.files.findIndex(file => file.id === this.currentFile.id);
        return this.files[idx - 1] || {};
      }
      return {};
    },
    nextFile() {
      if (!this.isEmptyCurrentFile) {

        let idx = this.files.findIndex(file => file.id === this.currentFile.id);
        return this.files[idx + 1] || {};
      }
      return this.files[0] || {};
    },
    replaceBtnName() {
      if (this.currentFile.multiple || !this.currentFile.files.length) {
        return 'Присоединить к документам';
      }
      return 'Заменить текущий файл';
    }
  },
  watch: {
    rules() {
      this.$refs.form.validate();
    }
  },
  methods: {
    updateFiles() {
      this.requiredFilesList = this.value.requiredFilesList;
      this.filesList = this.value.filesList;
      this.relatedFiles = this.value.relatedFiles;
      if (this.isEmptyCurrentFile || !this.isLoadedCurrentFile) {
        this.currentFile = this.value.currentFile;
      } else {
        this.currentFile = this.nextFile;
      }
      this.updateCurrentFile();
      this.clearValues();
    },
    updateCurrentFile() {
      const file = this.files.find(file => file.id === this.currentFile.id);
      if (file) {
        this.currentFile.files = file.files;
        this.currentFile.relatedFiles = file.relatedFiles.filter(rFile => file.files.findIndex(file => file.id === rFile.id) < 0);
      } else {
        this.currentFile.files = [];
        this.currentFile.relatedFiles = [];
      }
    },
    clearValues() {
      this.selectedFiles = undefined;
      this.removedFiles = undefined;
      this.replacedFiles = undefined;
      this.$refs.form?.reset();
    },
    save() {
      const selectedFiles = this.selectedFiles ? this.selectedFiles : null;
      const value = {...this.value, action: 'add', file_id: this.currentFile.id, selected_files: selectedFiles};
      this.$emit('input', value);
      this.clearValues();
    },
    goBack() {
      if (this.prevFile) {
        this.currentFile = this.prevFile;
        this.clearValues();
      }
    },
    skip() {
      if (!this.disableSkipBtn) {
        if (this.isLoadedCurrentFile) {
          this.currentFile = this.nextFile;
          this.clearValues();
        } else {
          const value = {...this.value, action: 'add', file_id: this.currentFile.id, selected_files: null};
          this.$emit('input', value);
          this.clearValues();
        }

      }
    },
    remove() {
      if (!this.disableRemoveBtn) {
        const value = {...this.value, action: 'remove', file_id: this.currentFile.id, selected_files: this.removedFiles}
        this.$emit('input', value);
        this.clearValues();
      }
    },
    replace() {
      const replacedFiles = isArray(this.replacedFiles) ? this.replacedFiles : [this.replacedFiles];
      if (!this.disableReplaceBtn) {
        const value = {...this.value, action: 'add_exist', file_id: this.currentFile.id, selected_files: replacedFiles}
        this.$emit('input', value);
        this.clearValues();
      }
    }
  }
}
</script>
<style scoped>

</style>
<template>
  <div class="main">
    <v-title title="pro CRM SF"/>
    <section-banner/>
    <section-links/>
    <section-info/>
    <section-agent-s-f/>
    <section-functional/>
    <section-integration/>
    <section-team/>
    <section-partners/>
  </div>
</template>

<script>
import VTitle             from "@/tags/VTitle.vue";
import {mapGetters}       from "vuex";
import SectionBanner      from "@/views/index/SectionBanner.vue";
import SectionInfo        from "@/views/index/SectionInfo.vue";
import SectionAgentSF     from "@/views/index/SectionAgentSF.vue";
import SectionFunctional  from "@/views/index/SectionFunctional.vue";
import SectionIntegration from "@/views/index/SectionIntegration.vue";
import SectionTeam        from "@/views/index/SectionTeam.vue";
import SectionPartners    from "@/views/index/SectionPartners.vue";
import SectionLinks       from "@/views/index/SectionLinks.vue";

export default {
  name:       'Index',
  components: {SectionLinks, SectionPartners, SectionTeam, SectionIntegration, SectionFunctional, SectionAgentSF, SectionInfo, SectionBanner, VTitle},
  beforeRouteUpdate() {
    if (!this.isGuest) {
      this.$router.push({name: 'Dashboard'});
    }
  },
  watch:    {
    isGuest(value) {
      if (!value) {
        this.$router.push({name: 'Dashboard'});
      }
    }
  },
  computed: {
    ...mapGetters(['isGuest'])
  },
}
</script>

<style>
.bd {
  border: 1px solid #ddd;
}
</style>
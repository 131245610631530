<template>
  <confirm :loading="model.loading" :title="'Вы действительно хотите удалить поле '+name+'?'" color="error" @confirm="model.remove()">
    <btn-icon :title="$t('btn.delete')" color="error" icon="delete"/>
  </confirm>
</template>

<script>
import Confirm       from "@/tags/Confirm";
import BtnIcon       from "@/tags/BtnIcon";
import {ClientField} from "@/models/ClientField";

export default {
  name:       "Delete",
  components: {BtnIcon, Confirm},
  props:      ['id', 'name'],
  data() {
    return {
      model: ClientField.get(this.id, {
        on: {
          remove: () => {
            this.$emit('remove')
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
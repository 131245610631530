import {Model} from "@/models/Model";

export class DealPayment extends Model {
    modelUri = 'deal-payment';

    labels = {
        id:         'ID',
        deal_id:    'Сделка',
        type_id:    'Тип оплаты',
        price:      'Доход компании',
        income:     'Доход менеджера',
        rewarded:   'Оплачено',
        created_at: 'Создано',
        updated_at: 'Изменено',
        deleted_at: 'Удалено',
    }

    fields = [
        'deal_id',
        'type_id',
        'price',
        'income',
        'rewarded',
    ];
}
<template>
  <v-text-field
      v-model="password"
      :label="label"
      :rules="rules"
      :type="show?'text':'password'"
      v-bind="this.$attrs"
      @keyup="keyUp"
  >
    <template #append>
      <v-icon :color="color" class="pointer" title="Показать пароль" @click="show=!show">{{ show ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
    </template>
  </v-text-field>

</template>

<script>
export default {
  name:  "PasswordInput",
  props: {
    value: {
      default: null
    },
    label: {
      type:    String,
      default: ''
    },
    rules: {
      type: Array
    },
    color: {
      type:    String,
      default: 'primary'
    }
  },
  data() {
    return {
      password: null,
      show:     false
    }
  },
  watch: {
    password(value) {
      this.$emit('input', value);
    }
  },
  methods: {
    keyUp(event) {
      this.$emit(event.type, event);
    }
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
<template>
  <v-card class="fill-height pa-8">
    {{ $d(183267446400000, 'date') }}
    <datetime-utc-input v-model="datetimeUtc"/>
    "{{ datetimeUtc }}": {{ new Date(datetimeUtc * 1000) }}
    <datetime-utc-input v-model="dateUtc" only-date/>
    "{{ dateUtc }}": {{ new Date(dateUtc * 1000) }}
    <v-divider class="my-16"/>
    <datetime-utc-input v-model="datetime" as-string/>
    "{{ datetime }}": {{ new Date(datetime) }}
    <datetime-utc-input v-model="date" as-string only-date/>
    "{{ date }}": {{ new Date(date) }}
    <v-divider class="my-16"/>
    <datetime-utc-input v-model="datetimeUtc" mask/>
    "{{ datetimeUtc }}": {{ new Date(datetimeUtc * 1000) }}
    <datetime-utc-input v-model="dateUtc" mask only-date/>
    "{{ dateUtc }}": {{ new Date(dateUtc * 1000) }}
    <v-divider class="my-16"/>
    <datetime-utc-input v-model="datetime" as-string mask/>
    "{{ datetime }}": {{ new Date(datetime) }}
    <datetime-utc-input v-model="date" as-string mask only-date/>
    "{{ date }}": {{ new Date(date) }}
    <v-divider class="my-16"/>
  </v-card>
</template>
<script>
import DatetimeUtcInput from "@/tags/DatetimeUtcInput.vue";
import {mapGetters}     from "vuex";

export default {
  name:       'Index',
  components: {DatetimeUtcInput},
  data() {
    return {
      datetimeUtc: undefined,
      dateUtc:     undefined,
      datetime:    undefined,
      date:        undefined,
    }
  },
  computed: {
    ...mapGetters(['role'])
  },
  watch:    {},
  methods:  {},
}
</script>
<style scoped>

</style>
<template>
  <v-footer v-if="$vuetify.breakpoint.mdAndUp" app color="grey lighten-2" elevation="2">
    <div class="text-center w-100">
      © 2015 «Success Finance» ИНН 7717293829 ОГРН 1157746606930 БИК 044525225 ПАО Сбербанк Р/сч 40702810638000042893
      К/сч 30101810400000000225 mail: forclient@fin-success.biz
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "FootBar"
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}
</style>
<template>
  <modal v-model="modal" :loading="model.loading" :title="id ? 'Изменение сделки' : 'Создание сделки'" max-width="800px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12" md="4">
            <v-autocomplete v-model="model.attr.bank_id" :items="bankList" :label="model.labels.bank_id" :readonly="!!readOnlyBank" :rules="model.rule('bank_id')" item-text="name" item-value="id"/>
          </v-col>
          <v-col cols="12" md="4">
            <datetime-utc-input v-model="model.attr.agreement_date" :label="model.labels.agreement_date" :readonly="!!readOnlyDate" :rules="model.rule('agreement_date')" as-string only-date/>
          </v-col>
          <v-col cols="12" md="4">
            <mask-input v-model="model.attr.sum" :label="model.labels.sum" :mask="mask" :readonly="!!readonlySum" :rules="model.rule('sum')" suffix="₽" unmask/>
          </v-col>
        </v-row>
        <v-divider class="my-4"/>

        <div v-if="showAddPaymentType" class="text-right mt-n4 mb-n5">
          <v-menu close-on-click left offset-y>
            <template #activator="{on,attrs}">
              <btn-icon color="success" icon="plus" title="Добавить оплату" v-bind="attrs" v-on="on"/>
            </template>
            <v-list dense min-width="120px">
              <template v-for="paymentType in availablePaymentTypeList">
                <v-list-item :key="paymentType.id" @click="addPaymentType(paymentType)" v-text="paymentType.name"/>
              </template>
            </v-list>
          </v-menu>
        </div>

        <template v-for="payment in payments">
          <deal-payment-form
              :key="payment.id || `type_${payment.type_id}` "
              ref="payments"
              :bank="currentBank"
              :deal="model.attr"
              :value="payment"
              @error="validateForm()"
              @remove="removePaymentType"
              @save="addPaymentSavedCount()"
          />
        </template>

        <v-divider class="my-4"/>
        <v-row>
          <v-col cols="12" md="4">
            <v-select v-model="model.attr.status_id" :items="availableStatusList" :label="model.labels.status_id" :readonly="!!readonlyStatus" :rules="model.rule('status_id')" item-text="name" item-value="id"/>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="model.attr.description" :label="model.labels.description" :rules="model.rule('description')"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-dialog v-model="successDialog" content-class="elevation-0" max-width="600px">
      <v-img :src="successImg" class="blink" max-height="600px" max-width="600px"/>
      <audio ref="successAlarm" src="@/assets/deals/success.mp3"/>
    </v-dialog>
    <template v-slot:actions>
      <confirm v-if="needConfirm" title="Вы уверены, что хотите завершить сделку? После сохранения изменения в сделке невозможны." @confirm="model.save()">
        <v-btn :disabled="loading" color="primary">{{ $t('btn.save') }}</v-btn>
      </confirm>
      <span v-else>
        <v-btn :disabled="loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
      </span>
    </template>
  </modal>
</template>

<script>
import Modal              from '@/tags/Modal';
import {mapGetters}       from 'vuex';
import {Deal}             from '@/models/Deal';
import {random}           from 'lodash';
import {formatMoney}      from '@/helpers/stringHelpers';
import Confirm            from "@/tags/Confirm";
import MaskInput          from "@/tags/MaskInput.vue";
import DealPaymentForm    from "@/views/deals/DealPaymentForm.vue";
import BtnIcon            from "@/tags/BtnIcon.vue";
import DatetimeUtcInput   from "@/tags/DatetimeUtcInput.vue";
import {DEAL_STATUSES}    from "@/models/DealStatus";
import {DEAL_PRICE_TYPES} from "@/models/DealPaymentType";

export default {
  name:       'DealForm',
  components: {DatetimeUtcInput, BtnIcon, DealPaymentForm, MaskInput, Modal, Confirm},
  props:      [
    'id',
    'clientId',
    'clientTypeId',
    'sum'
  ],
  data() {
    return {
      modal:              false,
      model:              Deal.get(this.id, {
        on:   {
          save: () => {
            this.model.attr.status_id = +this.model.attr.status_id;
            this.model.attr.bank_id   = +this.model.attr.bank_id;
            this.savePayments();
          },
          error: () => {
            this.$refs.form.validate();
          },
          load: () => {
            this.loadingPayments = false;
            this.payments        = this.model.attr.payments;
            this.successDialog   = false;
            this.oldStatusId     = this.model.attr.status_id;
            this.fillRequiredPaymentTypes();
          }
        },
        with: ['payments', 'payments.type'],
      }),
      oldStatusId:        undefined,
      statusList:         [],
      bankList:           [],
      paymentTypeList:    [],
      payments:           [],
      successDialog:      false,
      loadingTypes:       true,
      loadingPayments:    false,
      paymentsSavedCount: 0,
      mask:               {
        alias:          'numeric',
        digits:         2,
        min:            0,
        groupSeparator: ' '
      },
    };
  },
  computed: {
    ...mapGetters(['can']),
    successImg() {
      return require('@/assets/deals/success.' + random(1, 4) + '.png');
    },
    oldStatus() {
      return this.oldStatusId ? this.statusList.find(status => status.id === this.oldStatusId) : undefined;
    },
    currentStatus() {
      return this.statusList.find(status => status.id === +this.model.attr.status_id);
    },
    needConfirm() {
      return !this.currentStatus?.editable;
    },
    availableBankList() {
      return this.bankList.filter(bank => bank.type_ids.includes(this.clientTypeId))
    },
    readOnlyBank() {
      if (this.loading) {
        return false;
      }
      return !!this.id || !!this.model.attr.id;
    },
    readOnlyDate() {
      return this.oldStatus && !this.oldStatus.editable;
    },
    readonlySum() {
      if (this.loading) {
        return true;
      }
      if (!this.sum && !this.id) {
        return false;
      }
      return this.oldStatus && (!this.oldStatus.editable || this.oldStatus.fix_sum)

    },
    readonlyStatus() {
      return this.oldStatus && !this.oldStatus.editable;
    },
    currentBank() {
      return this.bankList.find(bank => bank.id === +this.model.attr.bank_id);
    },
    disabledPaymentTypeList() {
      return this.paymentTypeList.filter(paymentType => {
        if (this.payments.some(payment => payment.type_id === paymentType.id && payment.id)) {
          return false;
        }
        if ([paymentType.price_type, paymentType.income_type].includes(DEAL_PRICE_TYPES.KV_PERCENTAGE)) {
          if (!this.currentBank) {
            return true;
          }
          if (!this.currentBank.commission_reward) {
            return true;
          }
        }
        return false;
      });
    },
    availablePaymentTypeList() {
      return this.paymentTypeList.filter(paymentType => {
        if (this.payments.some(payment => payment.type_id === paymentType.id)) {
          return false;
        }
        return !this.disabledPaymentTypeList.some(disablePaymentType => disablePaymentType.id === paymentType.id);
      })
    },
    requiredPaymentTypeList() {
      return this.availablePaymentTypeList.filter(paymentType => {
        return !((this.oldStatus && !this.oldStatus.editable)) && !!paymentType.required;
      });
    },
    loading() {
      return this.model.loading || this.loadingTypes || this.loadingPayments;
    },
    showAddPaymentType() {
      return this.availablePaymentTypeList.length > 0;
    },
    availableStatusList() {
      return this.statusList.filter(
          status => this.oldStatus ? this.oldStatus.available_status_ids.includes(status.id) ||
              this.oldStatus.id === status.id : status.is_default
      );
    },
  },
  watch:    {
    modal(value) {
      this.payments = [];
      if (value) {
        if (this.id) {
          this.model.load();
        } else {
          this.model.clear();
          this.model.attr.client_id = this.clientId;
          if (this.sum) {
            this.model.attr.sum = this.sum;
          }
        }
        this.loadPaymentTypes();
        this.axios.get('deal-status/list').then(response => {
          this.statusList = response.data
          this.setDefaultStatus();
        });
        this.axios.get('bank/list').then(response => {
          this.bankList = response.data
        });
      } else {
        this.payments = [];
      }
    },
    'model.attr.bank_id'() {
      this.fillRequiredPaymentTypes();
    }
  },
  methods:  {
    loadPaymentTypes() {
      this.axios.get('deal-payment-type/list').then(response => {
        this.loadingTypes    = false;
        this.paymentTypeList = response.data;
        if (!this.id) {
          this.fillRequiredPaymentTypes();
        }
      })
    },
    fillRequiredPaymentTypes() {
      this.requiredPaymentTypeList.forEach(paymentType => this.addPaymentType(paymentType));
    },
    close() {
      this.modal           = false;
      this.loadingPayments = false;
    },
    runSuccessImg() {
      this.successDialog = true;
      this.$nextTick(() => this.$refs.successAlarm.play(), 200);
      setTimeout(() => {
        this.close();
      }, 3000);
    },
    setDefaultStatus() {
      if (!this.id) {
        let status = this.statusList.find((item) => item.is_default);
        if (status) {
          this.model.attr.status_id = status.id
        }
      }
    },
    addPaymentType(type) {
      this.payments.push({type_id: type.id, rewarded: 0, deal_id: this.id, type})
    },
    removePaymentType(type) {
      this.payments = this.payments.filter(payment => payment.type_id !== type.id)
    },
    savePayments() {
      this.loadingPayments = true;
      if (this.$refs.payments && (this.can('deal-payment.reward') || this.can('deal-payment.manage'))) {
        this.paymentsSavedCount = 0;
        this.$refs.payments.forEach(payment => payment.save());
      } else {
        this.afterSave();
      }
    },
    validateForm() {
      this.loadingPayments = false;
      this.$refs.form.validate();
    },
    addPaymentSavedCount() {
      this.paymentsSavedCount++;
      if (this.paymentsSavedCount === this.payments.length) {
        this.afterSave();
      }
    },
    afterSave() {
      if (this.currentStatus.id === DEAL_STATUSES.COMPLETE) {
        this.runSuccessImg();
        setTimeout(() => this.$emit('save', this.model.attr.id), 3000);
      } else {
        this.$emit('save', this.model.attr.id);
        this.close();
      }
    },
    formatMoney
  }
};
</script>

<style scoped>
.blink {
  animation: blink-animation 1s steps(10, jump-both) infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
"use strict";

import Vue      from 'vue';
import axios    from "axios";
import VueAxios from "vue-axios";

axios.defaults.baseURL = process.env.VUE_APP_API_ADDR;
axios.defaults.timeout = 15000;
let authKey = localStorage.getItem("authKey");
if (authKey) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + authKey;
}
Vue.use(VueAxios, axios);

import {Model} from "@/models/Model";

export class Service extends Model {
    modelUri = 'service';
    labels = {
        id:         'ID',
        client_id:  'Клиент',
        type_id:    'Тип услуги',
        manager_id: 'Менеджер',
        price:      'Стоимость услуги',
        income:     'Доход менеджера',
        rewarded:   'Оплачено',
        created_at: 'Создан',
        updated_at: 'Изменен',
        deleted_at: 'Удален',
    }

    fields = [
        'client_id',
        'type_id',
        'price',
        'income',
        'rewarded'
    ]
}
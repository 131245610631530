<template>
    <v-form class="d-flex align-center" ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-text-field prepend-icon="mdi-forum" v-model="model.attr.name" :label="model.labels.name" placeholder="Ответ..." :rules="model.rule('name')"/>
        <v-checkbox v-model="model.attr.correct" :false-value="0" :true-value="1" class="mx-5" color="green"/>
        <v-spacer/>

        <div class="text-right">
            <v-btn v-if="id" icon :disabled="model.loading" color="primary" @click="model.remove()"><v-icon>mdi-delete</v-icon></v-btn>
            <v-btn v-if="!id" color="primary" text rounded @click="createAnswer">
                <v-icon class="pr-2">mdi-plus-circle</v-icon>
                {{ $t('hrTest.addAnswer') }}
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import {HrAnswer} from '@/models/HrAnswer.ts';

export default {
    name:  'HrAnswerListItem',
    props: {
        id: {
            type:    Number,
            default: null
        },
        questionId: {
            type:    Number,
            default: null
        }
    },
    data() {
        return {
            modal: false,
            model: HrAnswer.get(this.id, {
                on: {
                    save: () => {
                        this.$emit('save');
                        this.id && this.model.load();
                        !this.id && this.model.clear();
                    },
                    error: () => {
                        this.$refs.form.validate();
                    },
                    remove: () => {
                        this.$emit('remove');
                    }
                }
            })
        };
    },
    created() {
        this.model.attr.question_id = this.questionId;
        this.id && this.model.load();
    },
    methods: {
        close() {
            this.$refs.form.reset();
            this.modal = false;
        },
        createAnswer() {
            this.model.attr.question_id = this.questionId;
            this.model.save();
        }
    }
};
</script>

<style scoped></style>
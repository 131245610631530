import {Model} from "@/models/Model";

export class VideoCategory extends Model {
    modelUri = 'video-category';
    labels = {
        name: 'Название'
    }

    fields = [
        'name'
    ]
}
<template>
  <v-card :class="{expandable}" :color="color" class="no-bg-image" elevation="0">
    <div :style="{maxHeight}" class="content">
      <div ref="content">
        <slot/>
      </div>
    </div>
    <template v-if="needExpand">
      <v-fade-transition>
        <div v-if="expandable && !expanded" class="gradient"/>
      </v-fade-transition>
      <v-btn v-if="expandable" absolute bottom class="expand-btn" color="primary" dark fab right @click="toggle">
        <v-icon v-if="expanded">mdi-chevron-up</v-icon>
        <v-icon v-else>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
  </v-card>
</template>
<script>

export default {
  name:  "RCard",
  props: {
    color: {
      type:    String,
      default: 'white'
    },
    expandable: {
      type:    Boolean,
      default: false
    },
    height: {
      type:    Number,
      default: 100
    },
    value: {
      type:    Boolean,
      default: false
    }
  },
  mounted() {
    if (this.expandable) {
      this.interval = setInterval(() => this.contentHeight = this.$refs.content?.offsetHeight, 10);
    }
  },
  destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  data() {
    return {
      expanded:      this.value,
      contentHeight: undefined,
      interval:      undefined
    }
  },
  computed: {
    maxHeight() {
      if (this.expandable) {
        return this.expanded ? (this.contentHeight ? this.contentHeight + 'px' : 'auto') : this.height + 'px';
      }
      return 'none';
    },
    needExpand() {
      return this.expandable && this.contentHeight && this.contentHeight > this.height;
    }
  },
  watch: {
    value(value) {
      this.expanded = value;
    }
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
      this.$emit('input', this.expanded);
    }
  }
}
</script>

<style scoped>
.no-bg-image {
  background-image: none !important;
  overflow: visible;
  border-radius: 12px !important;
}

.expand-btn {
  right: 24px !important;
  bottom: -20px !important;
}

.expandable {
  margin-bottom: 20px;
}

.gradient {
  height: 20px;
  margin-top: -20px;
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .3));
  border-radius: 0 0 12px 12px;
}

.content {
  border-radius: 12px;
  overflow: hidden;
  transition: max-height .2s ease-in-out;
}
</style>
<template>
  <modal v-model="modal" :loading="model.loading" :title="id ? 'Изменение банка' : 'Создание банка'" max-width="1000px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.agent" :label="model.labels.agent" :rules="model.rule('agent')"/>
          </v-col>
          <v-col cols="12" md="6">
            <file-input v-model="model.attr.agent_photo_file" :label="model.labels.agent_photo_file" :rules="model.rule('agent_photo_file')" accept=".png,.jpg,.svg" icon="mdi-file-image"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.email" :label="model.labels.email" :rules="model.rule('email')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.extra_email" :label="model.labels.extra_email" :rules="model.rule('extra_email')"/>
          </v-col>
          <v-col cols="12" md="6">
            <phone-input v-model="model.attr.phone" :label="model.labels.phone" :rules="model.rule('phone')"/>
          </v-col>
          <v-col cols="12" md="6">
            <phone-input v-model="model.attr.extra_phone" :label="model.labels.extra_phone" :rules="model.rule('extra_phone')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.address" :label="model.labels.address" :rules="model.rule('address')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.url_site" :label="model.labels.url_site" :rules="model.rule('url_site')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.url_pa" :label="model.labels.url_pa" :rules="model.rule('url_pa')"/>
          </v-col>
          <v-col cols="12" md="6">
            <file-input v-model="model.attr.logo_file" :label="model.labels.logo_file" :rules="model.rule('logo_file')" accept=".png,.jpg,.svg" icon="mdi-file-image"/>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="model.attr.description" :label="model.labels.description" :rules="model.rule('description')" rows="3"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.attr.commission_reward" :label="model.labels.commission_reward" :rules="model.rule('commission_reward')" suffix="%" type="number"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
                v-model="model.attr.type_ids"
                :items="typeList"
                :label="model.labels.type_ids"
                :menu-props="{offsetY: true}"
                :rules="model.rule('type_ids')"
                clearable
                item-text="name"
                item-value="id"
                multiple
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-switch v-model="model.attr.view" :false-value="0" :label="model.labels.view" :rules="model.rule('view')" :true-value="1"/>
          </v-col>
        </v-row>

        <h3 class="mt-4 mb-2">Документы</h3>
        <v-row>
          <v-col v-if="!showFilesList" class="text-center text--disabled" cols="12">
            Нет доступных документов для добавления
          </v-col>
          <template v-else>
            <v-col v-for="file in bankFileList" :key="file.id" cols="12" md="6">
              <file-input v-model="uploadedFiles[file.id]" :label="file.name" :rules="model.rule(`uploaded_file_${file.id}`)" accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.xlsx,.xls" icon="mdi-file-document">
                <template #append-outer>
                  <btn-icon v-if="hasFile(file.id)" color="error" icon="delete" title="Удалить файл" @click="removeFile(file.id)"/>
                </template>
              </file-input>
            </v-col>
          </template>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal      from '@/tags/Modal';
import PhoneInput from '@/tags/PhoneInput';
import FileInput  from '@/tags/FileInput';
import {Bank}     from '@/models/Bank';
import BtnIcon    from "@/tags/BtnIcon.vue";

export default {
  name:       'BankForm',
  components: {BtnIcon, FileInput, PhoneInput, Modal},
  props:      ['id'],
  data() {
    return {
      modal:    false,
      model:    Bank.get(this.id, {
        with: ['availableFilesList', 'filesList'],
        on:   {
          save: () => {
            delete this.model.attr.action;
            if (this.noClose) {
              this.noClose = false;
            } else {
              this.close();
            }
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      bankFileList: [],
      typeList: [],
      noClose:      false
    };
  },
  computed: {
    showFilesList() {
      return this.bankFileList.length > 0;
    },
    filesList() {
      return this.model.attr.availableFilesList || [];
    },
    uploadedFiles() {
      return this.model.attr.uploaded_files || {};
    }
  },
  watch:   {
    modal(value) {
      if (value) {
        if (this.id) {
          this.model.load();
        } else {
          this.model.clear();
        }
        this.axios.get('bank-file/list').then((response) => {
          this.bankFileList = response.data
        });
        this.axios.get('lead-type/list', {params: {expand: 'fields'}}).then((response) => {
          this.typeList = response.data;
        })
      }
    },
    uploadedFiles: {
      deep: true,
      handler(value) {
        this.model.attr.uploaded_files = value;
      }
    }
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.modal = false;
    },
    hasFile(id) {
      return this.model.attr.filesList?.some(item => item.id === id) || false;
    },
    removeFile(id) {
      this.noClose = true;
      this.model.fill({action: 'remove', uploaded_files: [id]}).save();
    }
  }
};
</script>

<style scoped></style>
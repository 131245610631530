<template>
  <section class="wrap mx-md-n6 mt-n6">
    <div class="banner">
      <div class="banner-block logo">
        <span>
          <img alt="pro CRM SF" class="banner-img" src="@/assets/logo.svg"/>
        </span>
      </div>
      <div class="banner-block monitor">
        <span>
          <img alt="Monitor" class="banner-img" src="@/assets/index/banner-monitor.png"/>

        </span>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "SectionBanner"
}
</script>


<style lang="scss" scoped>

.banner {
  background-image: url('@/assets/index/banner-bg.png');
  background-position: center;
  height: 525px;
  display: flex;
  justify-content: center;

  & .banner-block {
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
      padding: 0 2vw;
    }

    &.logo img {
      width: 460px;
    }

    &.monitor img {
      width: 450px;
      height: 463px;
    }
  }
}

@media (max-width: 1263px) {
  .banner {
    height: 231px;
    padding: 32px 0;

    & .banner-block {
      &.logo img {
        width: 198px;
      }

      &.monitor img {
        width: 195px;
        height: auto;
        max-width: 100%;
      }
    }
  }
}

</style>
import {Model} from '@/models/Model';

export class HrQuestion extends Model {
    modelUri = 'hr-question';
    labels = {
        name:     'Текст вопроса',
        category: 'Категория'
    };

    fields = ['name', 'category', 'test_id'];
}
import { render, staticRenderFns } from "./BtnIcon.vue?vue&type=template&id=5883d987&scoped=true"
import script from "./BtnIcon.vue?vue&type=script&lang=js"
export * from "./BtnIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5883d987",
  null
  
)

export default component.exports
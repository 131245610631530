<template>
  <confirm ref="confirm" :loading="model.loading" :title="'Вы действительно хотите удалить видео '+name+'?'" color="error" @confirm="model.remove()"/>
</template>

<script>
import Confirm from "@/tags/Confirm";
import {Video} from "../../models/Video";

export default {
  name:       "Delete",
  components: {Confirm},
  props:      ['id', 'name'],
  data() {
    return {
      model: Video.get(this.id, {
        on: {
          remove: () => {
            this.$emit('remove')
          }
        }
      })
    }
  },
  methods: {
    open() {
      this.$refs.confirm.open();
    }
  }
}
</script>

<style scoped>

</style>
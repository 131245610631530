<template>
<span>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :content-class="hideDetails?'':'mt-n5'"
      :return-value.sync="datetime"
      min-width="auto"
      offset-y
      transition="scale-transition"
      @input="showDate = true"
      :disabled="readonly||disabled"
  >
    <template v-slot:activator="{  on, attrs }">
      <v-text-field
          ref="field"
          v-model="datetimeFormatted"
          :disabled="!!disabled"
          :label="label"
          :hide-details="hideDetails"
          :rules="rules"
          clearable
          prepend-inner-icon="mdi-calendar"
          :hint="hint"
          :persistent-hint="persistentHint"
          :readonly="readonlyTextField"
          v-bind="attrs"
          v-on="on"
          @click:clear="clear()"
      >
        <template v-slot:append-outer>
          <slot name="append-outer"/>
        </template>
      </v-text-field>
    </template>
    <v-date-picker v-if="showDate" v-model="date" :allowed-dates="allowedDates" first-day-of-week="1" no-title @click:date="setDate()"/>
    <v-time-picker v-if="!showDate" v-model="time" format="24hr" no-title @click:minute="save()"/>
  </v-menu>
</span>
</template>

<script>
import {toDate, toTime} from "@/helpers/dateHelper";
import Inputmask        from "inputmask";

export default {
  name:  "DatetimeUtcInput",
  props: {
    value:          {
      default: ''
    },
    label:          {
      type:    String,
      default: ''
    },
    rules:          {
      type: Array
    },
    hideDetails:    {
      type: Boolean
    },
    onlyDate:       {
      type:    Boolean,
      default: false
    },
    asString:       {
      type:    Boolean,
      default: false,
    },
    mask:           {
      type:    Boolean,
      default: false,
    },
    max:            {
      type: Number
    },
    hint:           {
      type: String
    },
    persistentHint: {
      type: Boolean
    },
    readonly:       {
      type: Boolean,
    },
    disabled:       {
      type: Boolean
    }
  },
  mounted() {
    if (this.mask) {
      this.initMask()
    }
    this.$nextTick(() => {
      this.formatValues();
    });
  },
  data() {
    return {
      date:              undefined,
      time:              undefined,
      datetime:          undefined,
      datetimeFormatted: undefined,
      menu:              false,
      showDate:          true,
      focus:             false,
    }
  },
  watch:    {
    date() {
      this.showDate = false;
    },
    time() {
      this.datetime = this.date + 'T' + this.time;
    },
    datetimeFormatted() {
      if (this.mask) {
        this.$nextTick(() => {
          this.saveMask();
        });
      }
    },
    value() {
      this.formatValues();
    },
  },
  computed: {
    readonlyTextField() {
      return !this.mask || this.readonly;
    },
  },
  methods:  {
    setDate() {
      if (this.onlyDate) {
        this.time = '00:00';
        this.save();
      } else {
        this.showTime()
      }
    },
    showTime() {
      this.showDate = false;
    },
    save() {
      this.datetime = this.date + 'T' + this.time;
      let value = this.asString ? (this.onlyDate ? this.date : this.datetime) : Math.floor((new Date(this.datetime)).getTime() / 1000);
      this.$refs.menu.save(this.datetime, this.time, this.date, this.datetimeFormatted);
      this.$emit('input', value);
    },
    saveMask() {
      let value = this.datetimeFormatted;
      if (!value || value.indexOf('_') !== -1 || value.indexOf('undefined') !== -1) {
        return;
      }
      let date = value.substring(0, 10).split('.').reverse().join('-');
      let time = value.substring(11, 16);
      if (date && (this.onlyDate || time)) {
        this.datetime = date + 'T' + (time || '00:00');
        value         = this.onlyDate ? date : this.datetime;
        if (!this.asString) {
          value = Math.floor(new Date(value).getTime() / 1000);
        }
        this.$emit('input', value);
        this.$refs.menu.save(this.datetime, this.time, this.date, this.datetimeFormatted);
      }
    },
    getDate() {
      let value = this.value;
      if (this.asString) {
        value = typeof value == 'string' ? value.substring(0, 10) : undefined;
      } else {
        value = value ? toDate(+this.value * 1000) : undefined
      }
      return this.date = value;
    },
    getTime() {
      let value = this.value;
      if (this.asString) {
        value = typeof value == 'string' ? value.substring(11, 16) : undefined;
      } else {
        value = value ? toTime(+this.value * 1000) : undefined;
      }
      return this.time = value;
    },
    getDatetime() {
      return this.datetime = this.value ? this.getDate() + 'T' + this.getTime() : undefined;
    },
    getDatetimeFormatted() {
      if (this.mask) {
        let date = this.value;
        let time = this.value;
        if (this.asString) {
          date = date ? date.substring(0, 10).split('-').reverse().join('.') : undefined;
          time = time ? time.substring(11, 16) : undefined;
        } else {
          date = date ? toDate(+date * 1000).split('-').reverse().join('.') : undefined;
          time = time ? toTime(+time * 1000) : undefined;
        }
        let value = this.onlyDate ? date : (date && time ? date + ' ' + time : undefined);
        return this.datetimeFormatted = value;
      } else {
        let showTime = !this.onlyDate || (this.time && this.time !== '00:00');
        let value    = this.asString ? this.value : +this.value * 1000;
        return this.datetimeFormatted = this.value ? this.$d(new Date(value).getTime(), showTime ? 'datetime' : 'date') : undefined;
      }
    },
    resetValues() {
      this.time              = undefined;
      this.date              = undefined
      this.datetime          = undefined;
      this.datetimeFormatted = undefined;
    },
    formatValues() {
      this.getTime()
      this.getDate()
      this.getDatetime();
      this.getDatetimeFormatted();
    },
    clear() {
      this.resetValues()
      this.$emit('input', undefined);
    },
    allowedDates(value) {
      return !(this.max && this.max * 1000 < new Date(value).getTime());
    },
    initMask() {
      let input  = this.$refs.field.$el.querySelector('input');
      const mask = {
        alias:       'datetime',
        inputFormat: this.onlyDate ? 'dd.mm.yyyy' : 'dd.mm.yyyy HH:MM',
        placeholder: '_',
        prefillYear: false,
      }
      new Inputmask(mask).mask(input);
    },
  }
}
</script>

<style scoped>
</style>
<template>
  <v-list-item>
    <template v-if="editable">
      <v-list-item-content>
        <v-form ref="form" :disabled="model.loading" @submit.prevent.stop="save">
          <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          <v-textarea v-model="model.attr.description" :label="model.labels.description" :rules="model.rule('description')" rows="1"/>
          <div class="d-flex align-baseline">
            <div class="text-left">
              <v-switch v-model="model.attr.required" :false-value="0" :label="model.labels.required" :rules="model.rule('required')" :true-value="1"/>
            </div>
            <v-spacer/>
            <div class="text-right">
              <v-btn color="grey" dark @click="cancel">Отмена</v-btn>
              <v-btn :loading="model.loading" class="ml-2" color="primary" @click="save">Сохранить</v-btn>
            </div>
          </div>
        </v-form>
      </v-list-item-content>
    </template>
    <template v-else>
      <v-list-item-content>
        <v-list-item-title>
          {{ model.attr.name }}
          <v-icon v-if="model.attr.required" color="error" size="16">mdi-asterisk</v-icon>
        </v-list-item-title>
        <v-list-item-subtitle v-text="model.attr.description"/>
      </v-list-item-content>
      <v-list-item-action class="flex-row">
        <btn-icon :disabled="model.loading" color="primary" icon="pencil" title="Изменить" @click="edit"/>
        <btn-icon :disabled="model.loading" class="ml-2" color="error" icon="delete" title="Удалить" @click="remove"/>
      </v-list-item-action>
    </template>
  </v-list-item>
</template>
<script>

import {BankFile} from "@/models/BankFile";
import BtnIcon    from "@/tags/BtnIcon.vue";

export default {
  name:       "BankFileItem",
  components: {BtnIcon},
  props:      {
    value: {
      type:     Object,
      required: true
    }
  },
  mounted() {
    if (this.model.isNew) {
      this.model.fill(this.value);
    }
    this.oldAttr = this.value;
    if (this.model.isNew) {
      this.edit();
    }
  },
  data() {
    return {
      model: BankFile.get(null, {
        on: {
          save: () => {
            this.model.attr.required = +this.model.attr.required;
            this.oldAttr = this.model.attr;
            this.close()
          },
          remove: () => {
            this.$emit('remove', this.model.attr);
          },
          error: () => {
            this.$refs.form?.validate()
          }
        }
      }),
      editable: false,
      oldAttr:  {}
    }
  },
  computed: {},
  watch:    {
    value() {
      if (this.model.isNew) {
        this.edit();
      }
    }
  },
  methods: {
    edit() {
      this.editable = true;
    },
    cancel() {
      this.model.fill(this.oldAttr);
      if (this.model.isNew) {
        this.$emit('remove', this.model.attr);
      }
      this.editable = false;
    },
    save() {
      this.model.save();
    },
    close() {
      this.editable = false;
    },
    remove() {
      if (this.model.isNew) {
        this.$emit('remove', this.model.attr);
      } else {
        this.model.remove();
      }
    }
  }
}
</script>
<style scoped>

</style>
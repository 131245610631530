<template>
  <v-card>
    <v-title title="Офисы"/>
    <v-card-title>
      <span class="mr-5">Офисы</span>
      <span>
      <office-form @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </office-form>
      </span>
      <toggle-deleted v-model="showDeleted" class="ml-2"/>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
          hide-details
      />
    </v-card-title>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.logo="{ item }">
        <v-img v-if="item.logo" :src="apiUri(item.logo)" contain max-height="32px" max-width="64px" position="left"/>
      </template>
      <template v-slot:item.phone="{ item }">
        <span class="text-no-wrap">{{ formatPhone(item.phone) }}</span>
      </template>
      <template v-slot:item.params="{ item }">
        <btn-icon v-if="item.is_head" color="warning" icon="bank" title="Менеджеры видят банки"/>
        <btn-icon v-if="item.show_denied" color="info" icon="account-arrow-right" title="Передача лидов"/>
        <btn-icon v-if="item.hr_test" color="error" icon="list-status" title="HR SF Test"/>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <span v-if="!item.deleted_at" class="ml-2">
            <office-form :id="item.id" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </office-form>
          </span>
          <span v-if="!item.deleted_at" class="ml-2">
            <delete :id="item.id" :name="item.name" @remove="models.load()"/>
          </span>
          <span v-if="item.deleted_at" class="ml-2">
            <restore :id="item.id" :name="item.name" @restore="models.load()"/>
          </span>
        </div>
      </template>
    </data-table>

  </v-card>
</template>

<script>
import {Search}   from "@/models/Search";
import {debounce} from "lodash/function";

import Delete                from "@/views/office/Delete";
import {mapGetters}          from "vuex";
import ToggleDeleted         from "@/views/office/ToggleDeleted";
import Restore               from "@/views/office/Restore";
import DataTable             from "@/tags/DataTable";
import VTitle                from "@/tags/VTitle";
import BtnIcon               from "@/tags/BtnIcon";
import OfficeForm            from "./OfficeForm";
import {apiUri, formatPhone} from "@/helpers/stringHelpers";


export default {
  name:       "Index",
  components: {OfficeForm, BtnIcon, VTitle, DataTable, Restore, ToggleDeleted, Delete},
  data: () => ({
    search: '',
    models: Search.create({
      uri:    'office',
      fields: ['id', 'logo', 'name', 'org_name', 'email', 'phone', 'general_manager', 'is_head', 'show_denied', 'hr_test', 'deleted_at'],
      params: {deleted: 0},
      filter: {or: [{name: {like: ''}}, {org_name: {like: ''}}]}
    }).load(),
    showDeleted: 0
  }),
  computed: {
    ...mapGetters(['user', 'role']),
    headers() {
      return [
        {text: 'Логотип', value: 'logo'},
        {text: 'Название офиса', value: 'name', sortable: true},
        {text: 'Организация', value: 'org_name', sortable: true},
        {text: 'E-mail', value: 'email', sortable: true},
        {text: 'Телефон', value: 'phone', sortable: true},
        {text: 'Директор', value: 'general_manager', sortable: true},
        {text: 'Параметры', value: 'params'},
        {text: 'Действия', value: 'actions', align: 'end'}
      ]
    }
  },
  watch: {
    search(value) {
      this.models.config.filter.or[0].name.like = value || '';
      this.models.config.filter.or[1].org_name.like = value || '';
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    }
  },
  methods: {
    update: debounce((vm) => vm.models.load(), 200),
    formatPhone,
    apiUri
  }
}
</script>

<style scoped>

</style>
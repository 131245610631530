<template>
  <confirm :loading="model.loading" :title="'Вы действительно хотите удалить каталог ' + name + '?'" color="error" @confirm="model.remove()">
    <btn-icon :title="$t('btn.delete')" color="error" icon="delete"/>
  </confirm>
</template>

<script>
import Confirm          from '@/tags/Confirm.vue';
import BtnIcon          from '@/tags/BtnIcon.vue';
import {DocumentFolder} from "../../models/DocumentFolder";

export default {
  name:       'Delete',
  components: {BtnIcon, Confirm},
  props:      [
    'id',
    'name'
  ],
  data() {
    return {
      model: DocumentFolder.get(this.id, {
        on: {
          remove: () => {
            this.$emit('remove');
          }
        }
      })
    };
  }
};
</script>

<style scoped></style>
<template>
  <modal v-model="modal" :loading="model.loading" :title="id?'Изменение видео':'Добавление видео'" max-width="800px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="model.attr.url" :label="model.labels.url" :rules="model.rule('url')"/>
          </v-col>
          <v-col cols="12">
            <v-select v-model="model.attr.category_id" :items="categoryList" :label="model.labels.category_id" :rules="model.rule('category_id')" item-text="name" item-value="id"/>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="model.attr.description" :label="model.labels.description" :rules="model.rule('description')"/>
          </v-col>
          <v-col cols="6">
            <v-switch v-model="model.attr.public" :false-value="0" :label="model.labels.public" :rules="model.rule('public')" :true-value="1"/>
          </v-col>
          <v-col cols="6">
            <v-switch v-model="model.attr.fixed" :false-value="0" :label="model.labels.fixed" :rules="model.rule('fixed')" :true-value="1"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal   from "@/tags/Modal";
import {Video} from "@/models/Video";


export default {
  name:       "VideoForm",
  components: {Modal},
  props:      ['id'],
  data() {
    return {
      modal:        false,
      model:        Video.get(this.id, {
        on: {
          save:  () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      categoryList: [],
    }
  },
  computed: {},
  watch:    {
    modal(value) {
      if (value) {
        this.id && this.model.load();
        !this.id && this.model.clear();
        this.axios.get('video-category/list').then((response) => this.categoryList = response.data)
      }
    }
  },
  methods:  {
    close() {
      this.$refs.form.reset();
      this.modal = false
    },
    open() {
      this.modal = true;
    }
  }
}
</script>

<style scoped>

</style>
import {Model} from "@/models/Model";

export class ContractFile extends Model {
    modelUri = 'contract-file';

    labels = {
        name:          'Название',
        description:   'Описание',
        type_id:       'Тип Лида',
        uploaded_file: 'Файл',
        tags:          'Теги',
    }

    fields = [
        'name',
        'description',
        'type_id',
        'uploaded_file',
    ];
}
<template>
  <modal v-model="modal" max-width="1200px" title="Календарь">
    <template v-slot:activator>
      <slot/>
    </template>
    <client-calendar
        v-if="modal"
        ref="calendar"
        :current-item="currentItem"
        :search-params="searchParams"
        @loading="changeLoading"
        @update="updateNoticeDate"
    />
    <template #actions>
      <v-btn :disabled="loading" color="primary" @click="saveNoticeDate()">Сохранить изменения</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal          from "../../tags/Modal";
import ClientCalendar from "./ClientCalendar";

export default {
  name:       "ClientCalendarModal",
  components: {ClientCalendar, Modal},
  props:      {
    currentItem: {
      type:     Object,
      required: true
    }
  },
  mounted() {
    this.noticeDate = this.currentItem.notice_date;
  },
  data: () => ({
    modal:      false,
    noticeDate: undefined,
    loading:    true
  }),
  computed: {
    searchParams() {
      return {
        params: {},
        filter: {
          manager_id: this.currentItem.manager_id || 0
        }
      }
    }
  },
  watch:   {},
  methods: {
    saveNoticeDate() {
      this.$emit('update', this.noticeDate);
      this.modal = false;
    },
    changeLoading(value) {
      this.loading = value
    },
    updateNoticeDate(date) {
      this.noticeDate = date;
    }
  }
}
</script>

<style scoped>

</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('editor',{ref:"editor",attrs:{"init":{
             language: 'ru',
             height: 500,
             menubar: false,
             plugins: [
               'advlist autolink lists link image charmap print preview anchor',
               'searchreplace visualblocks code fullscreen',
               'insertdatetime media table paste code help wordcount'
             ],
             toolbar:
               'undo redo | formatselect | bold italic backcolor | \
               alignleft aligncenter alignright alignjustify | \
               bullist numlist outdent indent | removeformat | help'
           },"api-key":"0irzml8f32oo25n5c50dm8d83a91roaeyo8nvtgp2qebdqlt"},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})
}
var staticRenderFns = []

export { render, staticRenderFns }
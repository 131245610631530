import {Model} from "@/models/Model";

export class ClientCategory extends Model {
    modelUri = 'client-category';
    labels = {
        name:     'Название',
        color:    'Цвет',
        priority:   'Приоритет',
        is_default: 'По умолчанию',
    }

    fields = [
        'name',
        'color',
        'priority',
        'is_default',
    ];
}
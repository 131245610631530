import { render, staticRenderFns } from "./HrAnswerList.vue?vue&type=template&id=2c8e3894&scoped=true"
import script from "./HrAnswerList.vue?vue&type=script&lang=js"
export * from "./HrAnswerList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c8e3894",
  null
  
)

export default component.exports
<template>
  <editor
      ref="editor"
      v-model="text"
      :init="{
               language: 'ru',
               height: 500,
               menubar: false,
               plugins: [
                 'advlist autolink lists link image charmap print preview anchor',
                 'searchreplace visualblocks code fullscreen',
                 'insertdatetime media table paste code help wordcount'
               ],
               toolbar:
                 'undo redo | formatselect | bold italic backcolor | \
                 alignleft aligncenter alignright alignjustify | \
                 bullist numlist outdent indent | removeformat | help'
             }"
      api-key="0irzml8f32oo25n5c50dm8d83a91roaeyo8nvtgp2qebdqlt"
  />
</template>

<script>
import Editor from '@tinymce/tinymce-vue'


export default {
  name:       "TinyEditor",
  components: {Editor},
  props:      ['value'],
  data() {
    return {
      text: this.value,
    }
  },
  watch: {
    value(value) {
      this.text = value;
    },
    text(value) {
      this.$emit('input', value);
    }
  }
}
</script>

<style scoped>

</style>
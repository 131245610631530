<template>
  <v-tooltip :disabled="!showTooltip" top>
    <template #activator="{on,attrs}">
      <v-btn :color="state ? trueColor : falseColor" :disabled="disabled" :readonly="readonly" :small="small" icon v-bind="attrs" v-on="on" @click.native.stop="change">
        <v-icon :small="small">mdi-{{ icon }}</v-icon>
      </v-btn>
    </template>
    {{ state ? trueTitle : falseTitle }}
  </v-tooltip>
</template>
<script>
export default {
  name:       "SwitchBtnIcon",
  components: {},
  props:      {
    value: {
      default: false
    },
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    },
    trueColor: {
      type:    String,
      default: 'primary'
    },
    falseColor: {
      type:    String,
      default: 'grey'
    },
    trueTitle: {
      type: String
    },
    falseTitle: {
      type: String
    },
    icon: {
      type:    String,
      default: 'checkbox-marked'
    },
    readonly: {
      type:    Boolean,
      default: false
    },
    disabled: {
      type:    Boolean,
      default: false
    },
    small: {
      type:    Boolean,
      default: false
    }
  },
  mounted() {
    this.update();
  },
  data() {
    return {
      state: false
    }
  },
  computed: {
    showTooltip() {
      return (this.state && this.trueTitle) || (!this.state && this.falseTitle);
    }
  },
  watch: {
    value() {
      this.update();
    }
  },
  methods: {
    change() {
      if (this.readonly || this.disabled) {
        return;
      }
      this.state = !this.state;
      this.$emit('input', this.state ? this.trueValue : this.falseValue)
    },
    update() {
      this.state = this.value === this.trueValue;
    }
  }
}
</script>
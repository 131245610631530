<template>
  <v-btn :color="color" :disabled="disabled" :loading="loading" :tag="tag" icon>
    <v-tooltip :bottom="titleBottom" :disabled="tooltipDisabled" :left="titleLeft" :right="titleRight" :top="titleTop">
      <template #activator="{on, attrs}">
        <v-icon v-bind="attrs" v-on="{...on,...$listeners}">mdi-{{ icon }}</v-icon>
      </template>
      <span :inner-html.prop="title"/>
    </v-tooltip>
  </v-btn>
</template>

<script>
export default {
  name:  "BtnIcon",
  props: {
    title: {
      default: false
    },
    icon: {
      default: ''
    },
    color: {
      default: ''
    },
    titlePlace: {
      type:    String,
      default: 'top'
    },
    loading: {
      type:    Boolean,
      default: false
    },
    disabled: {
      type:    Boolean,
      default: false
    }
  },
  computed: {
    tooltipDisabled() {
      return this.title === false || this.$vuetify.breakpoint.smAndDown;
    },
    titleTop() {
      return this.titlePlace === 'top';
    },
    titleBottom() {
      return this.titlePlace === 'bottom';
    },
    titleLeft() {
      return this.titlePlace === 'left';
    },
    titleRight() {
      return this.titlePlace === 'right';
    },
    tag() {
      return this.$attrs.href ? 'a' : 'button';
    }
  }
}
</script>

<style scoped>

</style>
import {ceil, max, round} from "lodash";

export function formatPhone(value: string) {
    if (!value) return '';
    return value.replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
}

export function apiUri(path: string) {
    if (path) {
        if (path[0] === '/') {
            path = path.substring(1);
        }
    }
    return process.env.VUE_APP_API_ADDR + path
}

export function formatClientNumber(value: any) {
    return (1000000000 + parseInt(value)).toLocaleString().substring(2);
}

export function formatGender(value: string) {
    switch (value) {
        case 'male':
            return 'Мужской';
        case 'female':
            return 'Женский';
        default:
            return 'Не указано'
    }
}

export function formatMoney(value: number) {
    if (typeof value === 'undefined') {
        return 'Не указано';
    }
    return '₽ ' + value.toLocaleString('ru-RU', {maximumFractionDigits: 2});
}

export function plural(n: number, forms: string[]) {
    let idx;
    if (n % 10 === 1 && n % 100 !== 11) {
        idx = 0; // one
    } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
        idx = 1; // few
    } else {
        idx = 2; // many
    }
    return forms[idx] || '';
}

export function shortNumber(value: number, digits: number = 4) {
    const suffixes = [
        {n: 5, d: 3, s: 'тыс.'},
        {n: 7, d: 6, s: 'млн'},
        {n: 10, d: 9, s: 'млрд'},
        {n: 13, d: 12, s: 'трлн'},
    ];
    let d: number = 0;
    let suffix: string = '';
    for (let s of suffixes) {
        if (ceil(Math.log10(value)) >= s.n) {
            d = s.d;
            suffix = s.s;
        }
    }
    if (d > 0) {
        value /= Math.pow(10, d);
        value = round(value, max([0, digits - ceil(Math.log10(value))]))
    }
    let result = value.toLocaleString();
    return {value: result, suffix}
}


function copyTextOld(text: string) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
}

export function copyText(text: string) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
        }, (err) => {
            copyTextOld(text)
        })
    } else {
        copyTextOld(text)
    }
}

export enum NAME_CASE {
    NOMINATIVE = 1,
    GENITIVE = 10,
    DATIVE = 8,
    ACCUSATIVE = 3,
    ABLATIVE = 2,
    PREPOSITIONAL = 14,
}


export function parseVkVideoUrl(url: string): number[] {
    const regex = /video(-?\d+)_(\d+)/;
    const match = url.match(regex);

    if (match && match.length === 3) {
        return [parseInt(match[1]), parseInt(match[2])];
    }

    throw new Error('Invalid VK video URL');
}

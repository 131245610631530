<template>
  <modal v-model="modal" :loading="model.loading" :title="id ? 'Изменение задачи' : 'Создание задачи'" max-width="1000px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="model.attr.description" :label="model.labels.description" :rules="model.rule('description')"/>
          </v-col>
          <v-col cols="12" md="6">
            <datetime-utc-input v-model="model.attr.notice_date" :label="model.labels.notice_date" :rules="model.rule('notice_date')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-select v-model="model.attr.status_id" :items="statusList" :label="model.labels.status_id" :rules="model.rule('status_id')" item-text="name" item-value="id"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-select v-model="model.attr.category_id" :items="categoryList" :label="model.labels.category_id" :rules="model.rule('category_id')" item-text="name" item-value="id"/>
          </v-col>
          <v-col cols="12" md="6"></v-col>
          <v-col v-if="canOffice()" cols="12" md="6">
            <v-autocomplete v-model="model.attr.office_id" :items="officeList" :label="model.labels.office_id" :rules="model.rule('office_id')" item-text="name" item-value="id" @change="updateManagerList"/>
          </v-col>
          <v-col v-if="canManage()" cols="12" md="6">
            <v-autocomplete v-model="model.attr.user_id" :items="managerList" :label="model.labels.user_id" :rules="model.rule('user_id')" item-text="username" item-value="id"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal            from '@/tags/Modal.vue';
import {mapGetters}     from 'vuex';
import {Task}           from '@/models/Task.ts';
import DatetimeUtcInput from '@/tags/DatetimeUtcInput.vue';

export default {
  name:       'TaskForm',
  components: {DatetimeUtcInput, Modal},
  props:      [
'id',
'clientId',
'sum'
],
  data() {
    return {
      modal: false,
      model: Task.get(this.id, {
        on: {
          save: () => {
            this.close();
            this.$emit('save', this.model.attr.id);
          },
          error: () => {
            this.$refs.form.validate();
          },
          load: () => {
            this.model.attr.office_id = this.model.attr.user.office_id;
            this.updateManagerList();
          }
        },
        with: ['user']
      }),
      statusList:   [],
      officeList:   [],
      managerList:  [],
      categoryList: []
    };
  },
  computed: {
    ...mapGetters(['can', 'user']),
    currentStatusName() {
      let status_id = this.model.attr.status_id;
      for (let value of this.statusList) {
        if (value.id === status_id) {
          return value.name;
        }
      }
      return undefined;
    },
    oldStatusName() {
      let status_id = this.oldStatusID;
      for (let value of this.statusList) {
        if (value.id === status_id) {
          return value.name;
        }
      }
      return undefined;
    },
    currentTypeName() {
      let type_id = this.model.attr.type_id;
      for (let value of this.typeList) {
        if (value.id === type_id) {
          return value.name;
        }
      }
      return undefined;
    }
  },
  watch: {
    modal(value) {
      if (value) {
        this.id && this.model.load();
        !this.id && this.model.clear();
        this.model.attr.client_id = this.clientId;
        this.sum && (this.model.attr.sum = this.sum);
        this.axios.get('task-status/list').then((response) => (this.statusList = response.data));
        if (this.can('office.manage')) {
          this.axios.get('office/list').then((response) => {
            (this.officeList = response.data), this.selectCurrentOffice();
            this.updateManagerList();
          });
        }
        if (this.can('user.manage') || this.can('client.agent')) {
          this.axios.get('user/list').then((response) => {
            (this.managerList = response.data), this.selectCurrentManager();
          });
        }
        this.axios.get('task-category/list').then((response) => {
          this.categoryList = response.data;
          this.setDefaultCategory();
        });
      }
    }
  },
  methods: {
    updateManagerList() {
      this.axios.get('user/list?office_id=' + this.model.attr.office_id).then((response) => (this.managerList = response.data));
    },
    close() {
      this.$refs.form.reset();
      this.modal = false;
    },
    updatePhoneDuplicate(count) {
      this.havePhoneDuplicate = !!count;
    },
    updateExtraPhoneDuplicate(count) {
      this.haveExtraPhoneDuplicate = !!count;
    },
    canManage() {
      return this.can('user.manage') || this.can('client.agent');
    },
    canOffice() {
      return this.can('office.manage');
    },
    selectCurrentManager() {
      if (!this.canManage() || this.id) return;
      for (let value of this.managerList) {
        if (value.id === this.user.id) {
          return (this.model.attr.user_id = value.id);
        }
      }
    },
    selectCurrentOffice() {
      if (!this.canOffice() || this.id) return;
      for (let value of this.officeList) {
        if (value.id === this.user.office_id) {
          return (this.model.attr.office_id = value.id);
        }
      }
    },
    setDefaultCategory() {
      if (!this.id) {
        let category = this.categoryList.find((item) => item.is_default);
        category && (this.model.attr.category_id = category.id);
      }
    },
  }
};
</script>

<style scoped></style>

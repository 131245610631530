<template>
  <modal v-model="modal" :loading="model.loading" :title="titleText" max-width="720">
    <template #activator>
      <slot/>
    </template>
    <v-tabs v-model="tab" :mobile-breakpoint="0" background-color="transparent" color="primary" fixed-tabs>
      <v-tab>Список файлов</v-tab>
      <v-tab>Результат прохождения анкеты</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="transparent">
      <v-tab-item>
        <v-card-text>
          <r-card>
            <v-card-text v-if="model.loading && !filesList.length" class="text--disabled">Загружается...</v-card-text>
            <v-card-text v-else class="pa-0">
              <v-card-text v-if="!filesList.length" class="text--disabled">Файлы не требуются</v-card-text>
              <ms-result-file-item v-else :completed="model.attr.status === 2" :files="filesList"/>
            </v-card-text>
          </r-card>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <r-card>
            <v-card-text v-if="model.loading && !questionsList.length" class="text--disabled">Загружается...</v-card-text>
            <v-card-text v-else class="py-2">
              <v-card-text v-if="!questionsList.length" class="text--disabled">Нет отвеченных вопросов</v-card-text>
              <ms-result-question-item v-else :questions="questionsList"/>
            </v-card-text>
          </r-card>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
    <ms-result-form v-if="!isChild" :id="id" ref="form" is-child @save="onSave"/>
    <template #actions>
      <v-btn v-if="showContinueBtn" color="primary" @click="continueForm">{{ nameContinueBtn }}</v-btn>
      <v-btn v-else color="primary" @click="close">{{ nameCloseBtn }}</v-btn>
      <v-spacer/>
      <ms-result-link-form v-model="model.attr" :disabled="disableDownloadBtn" @input="model.save()">
        <v-btn v-if="showDownloadBtn" :disabled="disableDownloadBtn" color="primary">
          <v-icon>mdi-share-outline</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">Поделиться</span>
        </v-btn>
      </ms-result-link-form>
      <v-btn v-if="showDownloadBtn" :disabled="disableDownloadBtn" class="ml-2" color="primary" @click="downloadArchive">
        <v-icon>mdi-download</v-icon>
        <span v-if="$vuetify.breakpoint.smAndUp">Скачать</span>
      </v-btn>
      <v-btn v-if="showPrintBtn" :disabled="disablePrintBtn" class="ml-2" color="primary" @click="printFiles">
        <v-icon>mdi-printer-outline</v-icon>
        <span v-if="$vuetify.breakpoint.smAndUp">Распечатать</span>
      </v-btn>

    </template>
  </modal>
</template>
<script>

import Modal                from "@/tags/Modal.vue";
import {MsResult}           from "@/models/MsResult";
import RCard                from "@/tags/RCard.vue";
import MsResultQuestionItem from "@/views/msScript/MsResultQuestionItem.vue";
import MsResultFileItem     from "@/views/msScript/MsResultFileItem.vue";
import {apiUri}             from "@/helpers/stringHelpers";
import MsResultLinkForm     from "@/views/msScript/MsResultLinkForm.vue";

export default {
  name:       "MsResultView",
  components: {MsResultLinkForm, MsResultFileItem, MsResultForm: () => import('@/views/msScript/MsResultForm'), MsResultQuestionItem, RCard, Modal},
  props:      {
    id: {
      type:     Number,
      required: true
    },
    isChild: {
      type:    Boolean,
      default: false
    }
  },
  mounted() {
    this.$emit('mounted');
  },
  data() {
    return {
      modal: false,
      model: MsResult.get(this.id, {
        with: ['script', 'answersList', 'answersList.selected_answers', 'requiredFilesList', 'filesList', 'filesList.files', 'linksList'],
        on:   {
          load: () => {
            this.questions = this.model.attr.answersList;
            this.requiredFilesList = this.model.attr.requiredFilesList;
            this.files = this.model.attr.filesList;
          },
          save: () => {
            if (this.waitLink) {
              this.openFirstLink();
            }
          }
        }
      }),
      questions:         [],
      requiredFilesList: [],
      files:             [],
      tab:               0,
      waitLink:          false,
      waitLinkType:      undefined
    }
  },
  computed: {
    questionsList() {
      const questions = [];
      const questionMap = new Map();

      for (const question of this.questions) {
        question.questions = [];
        questionMap.set(question.id, question);
      }

      for (const question of this.questions) {
        const parentQuestion = questionMap.get(question.required_question_id);
        if (parentQuestion) {
          parentQuestion.questions.push(question);
        } else if (!question.required_question_id) {
          questions.push(question);
        }
      }
      return questions;
    },
    filesList() {
      const files = [];
      for (const requiredFile of this.requiredFilesList) {
        let idx = this.files.findIndex(file => file.id === requiredFile.id);
        requiredFile.files = idx > -1 ? this.files[idx].files : [];
        files.push(requiredFile);
      }
      for (const file of this.files) {
        let idx = this.requiredFilesList.findIndex(requiredFile => requiredFile.id === file.id);
        if (idx < 0) {
          files.push(file);
        }
      }
      return files;
    },
    titleText() {
      return this.model.loading ? 'Загрузка...' : 'Информация: ' + this.model.attr?.script?.name;
    },
    showContinueBtn() {
      return [0, 1].includes(this.model.attr.status) && !this.isChild && !this.model.loading;
    },
    nameContinueBtn() {
      return this.model.attr.status === 1 ? 'Загрузить файлы' : 'Продолжить';
    },
    nameCloseBtn() {
      return this.model.attr.status === 1 ? 'Загрузить файлы' : 'Закрыть';
    },
    showDownloadBtn() {
      return this.model.attr.status === 2;
    },
    showPrintBtn() {
      return this.model.attr.status > 0;
    },
    disablePrintBtn() {
      return this.filesList.length === 0 || this.model.loading;
    },
    disableDownloadBtn() {
      const fileCount = this.filesList.reduce((prev, cur) => prev + cur.files.length, 0);
      return this.model.loading || !fileCount;
    }
  },
  watch: {
    modal(value) {
      if (value && this.id) {
        this.model.fill({id: this.id});
        this.model.load();
      }
    }
  },
  methods: {
    open() {
      this.modal = true;
    },
    close() {
      this.model.clear();
      this.modal = false;
    },
    continueForm() {
      if (this.showContinueBtn) {
        if (this.isChild) {
          this.close()
        } else {
          this.$refs.form.open();
        }
      }
    },
    onSave() {
      this.$emit('update');
      this.model.load();
    },
    downloadArchive() {
      if (!this.openFirstLink()) {
        this.createLink(null, 'download');
      }
    },
    printFiles() {
      if (!this.openFirstLink()) {
        this.createLink(null, 'print');
      }
    },
    createLink(period, type) {
      this.model.attr.action = 'add';
      this.model.attr.period = period;
      this.model.attr.type = 'link';
      this.model.save();
      this.waitLink = true;
      this.waitLinkType = type;
    },
    openFirstLink() {
      const firstLink = Object.values(this.model.attr.linksList).find(link => link.expired_at === null);
      if (firstLink) {
        let url = apiUri('ms-result/' + this.waitLinkType + '?' + (new URLSearchParams({id: this.model.attr.id, key: firstLink.key})).toString());
        window.open(url, '_blank').focus();
        for (let key in this.model.attr.linksList) {
          if (this.model.attr.linksList[key].key === firstLink.key) {
            delete this.model.attr.linksList[key];
          }
        }
        this.waitLink = false;
        this.waitLinkType = undefined;
        return true;
      }
      this.waitLink = false;
      this.waitLinkType = undefined;
      return false;
    }
  }
}
</script>
<style scoped>

</style>
import {Model} from "@/models/Model";

export class Comment extends Model {
    modelUri = 'comment';
    labels = {
        text: 'Напишите комментарий',
    }

    fields = [
        'type',
        'type_id',
        'text'
    ];
}
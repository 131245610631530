<template>
  <v-menu v-if="notifyCount > 0" left offset-y>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" class="mr-2">
        <v-badge :content="notifyCount" bordered offset-x="24px" offset-y="24px">
        <btn-icon color="secondary-lite" icon="bell"/>
        </v-badge>
        <audio ref="notifyAlarm" src="@/assets/notify/notify.mp3"/>
      </span>
    </template>
    <v-list dense>
      <div v-for="(params, category) in categories" :key="category">
        <v-list-item v-if="notifyData[category].count > 0" :to="params.to">{{ params.name }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<v-spacer/>
          <b>{{ notifyData[category].count }}</b>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import {mapGetters} from 'vuex';

import BtnIcon from '@/tags/BtnIcon.vue';

export default {
  name:       'Notify',
  components: {BtnIcon},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'user',
      'isGuest',
      'can',
      'notifyData',
      'notifyCount'
    ]),
    categories() {
      return {
        news: {
          name: 'Новости',
          to:   {name: 'News'}
        },
        faq: {
          name: 'Статьи',
          to: {name: 'Faq', query: {time: Date.now()}}
        },
        event: {
          name: 'Мероприятия',
          to: {name: 'Event', query: {time: Date.now()}}
        },
        task: {
          name: 'Задачи',
          to:   {name: 'Tasks', query: {time: this.notifyData.task.time}}
        },
        client_meet: {
          name: 'Встречи',
          to:   {name: 'Clients', query: {time: this.notifyData.client_meet.time, status: 3}}
        },
        client_callback: {
          name: 'Перезвонить',
          to:   {name: 'Clients', query: {time: this.notifyData.client_callback.time, status: [2, 10]}}
        },
        setting_video_start: {
          name: 'Обучение',
          to: {name: 'Dashboard', query: {time: Date.now()}}
        }

      }
    }
  },
  watch: {
    notifyCount(to, from) {
      if (to > from && to > 0) {
        setTimeout(() => this.$refs.notifyAlarm.play(), 0);
      }
    },
  },
};
</script>

<style lang="sass" scoped></style>
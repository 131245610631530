<template>
  <v-text-field
      ref="field"
      v-model="phone"
      :label="label"
      :rules="rules"
      prepend-inner-icon="mdi-phone"
  >
    <template v-slot:append-outer>
      <slot name="append-outer"/>
    </template>
  </v-text-field>
</template>

<script>
import Inputmask from 'inputmask'

export default {
  name:  "PhoneInput",
  props: {
    value: {
      default: null
    },
    label: {
      type:    String,
      default: ''
    },
    rules: {
      type: Array,
    },
  },
  mounted() {
    let input = this.$refs.field.$el.querySelector('input');
    new Inputmask('+9 (999) 999-99-99').mask(input);
    this.phone = this.value;
  },
  data() {
    return {
      phone: null,
    }
  },
  watch: {
    phone(value) {
      value = value ? value.replace(/\D/g, '') : '';
      this.$emit('input', value);
    },
    value(value) {
      this.phone = value
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
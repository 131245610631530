<template>
  <modal v-model="modal" :loading="model.loading" :title="id?'Изменение дополнительного поля':'Создание дополнительного поля'" max-width="500px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col v-if="!typeId" cols="12">
            <v-autocomplete v-model="model.attr.type_id" :items="leadTypeList" :label="model.labels.type_id" :rules="model.rule('type_id')" item-text="name" item-value="id"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="model.attr.name" :label="model.labels.name" :rules="model.rule('name')"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="model.attr.hint" :label="model.labels.hint" :rules="model.rule('hint')"/>
          </v-col>
          <v-col cols="6">
            <v-select v-model="model.attr.type" :items="typeList" :label="model.labels.type" :rules="model.rule('type')"/>
          </v-col>
          <v-col cols="6">
            <v-switch v-model="model.attr.required" :false-value="0" :label="model.labels.required" :rules="model.rule('required')" :true-value="1"/>
          </v-col>
          <v-col cols="12">
            <v-list-item-subtitle class="text--secondary">Параметры</v-list-item-subtitle>
            <v-divider/>
          </v-col>
          <template v-if="model.attr.type === 0">
            <v-col cols="12">
              <v-text-field v-model="model.attr.params.mask_string" clearable label="Маска форматирования строки"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="model.attr.params.min" :max="model.attr.params.max" :min="0" clearable label="Мин. длина" type="number"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="model.attr.params.max" :min="Math.max(1,model.attr.params.min*1+1)" clearable label="Макс. длина" type="number"/>
            </v-col>
          </template>
          <template v-if="model.attr.type === 1">
            <v-col cols="6">
              <v-text-field v-model="model.attr.params.min" :step="stepNumber" clearable label="Минимум" type="number"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="model.attr.params.max" :step="stepNumber" clearable label="Максимум" type="number"/>
            </v-col>
            <v-col cols="12">
              <v-slider v-model="model.attr.params.digits" :max="8" :min="0" :step="1" inverse-label label="Цифр после запятой" thumb-label="always"/>
            </v-col>
            <v-col cols="6">
              <v-switch v-model="model.attr.params.fixed" label="Фикс. после запятой"/>
            </v-col>
            <v-col cols="6">
              <v-switch v-model="model.attr.params.format" label="Форматирование"/>
            </v-col>
            <v-col cols="8">
              <v-switch v-model="model.attr.autofill_sum" label="Автозаполнение суммы сделки"/>
            </v-col>
            <v-col cols=4>
              <v-text-field v-model="model.attr.autofill_factor" label="Коэф. суммы" type="number"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="model.attr.params.prefix" clearable label="Префикс" maxlength="6"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="model.attr.params.suffix" clearable label="Суффикс" maxlength="6"/>
            </v-col>
          </template>
          <template v-if="model.attr.type === 2">
            <v-col cols="6">
              <v-text-field v-model="model.attr.values[0]" :rules="[rules.required]" clearable label="Значение выкл."/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="model.attr.values[1]" :rules="[rules.required]" clearable label="Значение вкл."/>
            </v-col>
          </template>
          <template v-if="model.attr.type === 3">
            <v-col cols="12">
              <v-switch v-model="model.attr.multiple" :false-value="0" :true-value="1" label="Множественный выбор"/>
            </v-col>
            <v-col cols="12">
              <v-text-field v-for="(value, i) in model.attr.values" :key="i" v-model="model.attr.values[i]" :label="'Ответ #'+(i+1)" :rules="[rules.required]" append-outer-icon="mdi-delete" clearable @click:append-outer="removeValue(i)"/>
              <v-btn class="mt-2" color="secondary" small @click="addValue">Добавить вариант ответа</v-btn>
            </v-col>
          </template>
          <template v-if="model.attr.type === 4">
            <v-col cols="6">
              <v-switch v-model="model.attr.params.mask" label="Ручной ввод" @change="updateShowPreview"/>
            </v-col>
            <v-col cols="6">
              <v-switch v-model="model.attr.params.local" hint="Учитывается временная зона" label="Локальное время" persistent-hint @change="updateShowPreview"/>
            </v-col>
            <v-col cols="6">
              <v-switch v-model="model.attr.params.only_date" label="Только дата" @change="updateShowPreview"/>
            </v-col>
          </template>
          <v-col cols="12">
            <v-list-item-subtitle class="text--secondary">Предпросмотр</v-list-item-subtitle>
            <v-divider/>
          </v-col>
          <v-col :style="{height:'94px'}" cols="12">
            <div v-if="showPreview" :key="key">
              <template v-if="model.attr.type === 0">
                <mask-input :hint="model.attr.hint" :label="model.attr.name" :mask="fieldMask" :maxlength="model.attr.params.max" clearable persistent-hint/>
              </template>
              <template v-else-if="model.attr.type === 1">
                <mask-input v-model="value" :hint="model.attr.hint" :label="model.attr.name" :mask="fieldMask" :unmask="true" clearable persistent-hint/>
              </template>
              <template v-else-if="model.attr.type === 2">
                <v-switch :hint="model.attr.hint" :label="model.attr.name" persistent-hint/>
              </template>
              <template v-else-if="model.attr.type === 3">
                <v-select :hint="model.attr.hint" :items="model.attr.values" :label="model.attr.name" :multiple="!!model.attr.multiple" clearable persistent-hint/>
              </template>
              <template v-else-if="model.attr.type === 4">
                <datetime-utc-input v-model="value" :as-string="!datetimeParams.local" :hint="model.attr.hint" :label="model.attr.name" :mask="!!datetimeParams.mask" :only-date="!!datetimeParams.only_date" persistent-hint/>
              </template>
              <template v-else>
                Выберите тип поля
              </template>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal            from "@/tags/Modal";
import {ClientField}    from "@/models/ClientField";
import {isArray}        from "highcharts";
import MaskInput        from "@/tags/MaskInput.vue";
import DatetimeUtcInput from "@/tags/DatetimeUtcInput.vue";


export default {
  name:       "FieldForm",
  components: {DatetimeUtcInput, MaskInput, Modal},
  props:      ['id', 'typeId'],
  data() {
    return {
      modal:        false,
      model:        ClientField.get(this.id, {
        on: {
          save: () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      leadTypeList: [],
      typeList:     [
        {text: 'Текст', value: 0},
        {text: 'Число', value: 1},
        {text: 'Переключатель', value: 2},
        {text: 'Список', value: 3},
        {text: 'Дата и время', value: 4}
      ],
      rules:        {
        required: v => !!v || 'Заполните значение'
      },
      showPreview:  false,
      value:        null,
      key:          Math.random(),
    }
  },
  computed: {
    stepNumber() {
      return this.model.attr.params.digits ? Math.pow(0.1, this.model.attr.params.digits) : 1
    },
    fieldMask() {
      const params = this.model.attr.params;
      const mask = {};
      switch (this.model.attr.type) {
        case 0:
          if (params.mask_string) {
            mask.mask = params.mask_string;
          }
          break;
        case 1:
          mask.digits = params.digits;
          mask.alias = 'numeric';
          if (typeof params.min === 'number') {
            mask.min = params.min;
          }
          if (typeof params.max === 'number') {
            mask.max = params.max;
          }
          if (params.format) {
            mask.groupSeparator = ' ';
          }
          if (params.fixed) {
            mask.digitsOptional = false;
          }
          if (params.prefix) {
            mask.prefix = params.prefix;
          }
          if (params.suffix) {
            mask.suffix = params.suffix;
          }
          break;
      }
      return mask;
    },
    datetimeParams() {
      return {
        mask:      !!this.model.attr.params?.mask,
        local:     !!this.model.attr.params?.local,
        only_date: !!this.model.attr.params?.only_date,
      }
    }
  },
  watch:    {
    modal(value) {
      if (value) {
        if (this.id) {
          this.model.load();
        } else {
          this.model.clear();
          this.setDefaults();
          if (this.typeId) {
            this.model.attr.type_id = this.typeId;
          }
        }
        this.axios.get('lead-type/list').then((response) => this.leadTypeList = response.data);
      }
    },
    'model.attr.type'(value) {
      if (!this.model.attr.id || !isArray(this.model.attr.values)) {
        switch (value) {
          case 2:
            this.model.attr.values = ['Нет', 'Да'];
            break;
          case 3:
            this.model.attr.values = ['', ''];
            break;
        }
      }
      this.updateShowPreview();
    },
    fieldMask() {
      this.updateShowPreview();
    },
    'model.attr.values'() {
      this.updateShowPreview();
    },
    datetimeParams() {
      this.updateShowPreview();
    }
  },
  methods:  {
    close() {
      this.$refs.form.reset();
      this.modal = false
    },
    addValue() {
      this.model.attr.values.push('');
      this.model.attr = Object.assign({}, this.model.attr);
    },
    removeValue(i) {
      this.model.attr.values.splice(i, 1);
    },
    setDefaults() {
      this.model.attr.params = {
        mask_string: null,
        max:         null,
        min:         null,
        digits:      0,
        format:      false,
        fixed:       false,
        unmask:      true,
        prefix:      '',
        suffix:    '',
        mask:      false,
        local:     false,
        only_date: false,
      }
      this.model.attr.autofill_factor = 1;
    },
    updateShowPreview() {
      this.key = Math.random();
      this.showPreview = false;
      setTimeout(() => this.showPreview = true, 0);
    }
  }
}
</script>

<style>
.v-slider__thumb-container--active .v-slider__thumb:before {
  transform: scale(1.2) !important;
}
</style>
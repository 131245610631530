import {padStart} from "lodash";

export function dateUtcDayOffset(offset: number = 0) {
    let tzOffset = (new Date().getTimezoneOffset() * 60 * 1000);
    let date = new Date(Date.now() - tzOffset).toISOString().substring(0, 10);
    let time = new Date(date + 'T00:00:00').getTime() + (3600 * 24 * 1000 * offset);
    return Math.floor(time / 1000);
}

export function toDate(ts: number): string {
    let dt = new Date(ts);
    let y = dt.getFullYear().toString();
    let m = (dt.getMonth() + 1).toString();
    let d = dt.getDate().toString();
    return padStart(y, 4, '0') + '-' + padStart(m, 2, '0') + '-' + padStart(d, 2, '0');
}

export function toTime(ts: number) {
    let dt = new Date(ts);
    let h = dt.getHours().toString();
    let m = dt.getMinutes().toString();
    return padStart(h, 2, '0') + ':' + padStart(m, 2, '0');
}